.layout-menu-symmetry {
    $menuBgImage:'symmetry-menu.jpg';
    $menuitemTextColor:#ffffff;
    $menuitemIconColor:#ffffff;
    $menuitemHoverBgColor:rgba(255,255,255,0.32);
    $menuitemHoverTextColor:#ffffff;
    $menuitemHoverIconColor:#ffffff;
    $menuitemActiveTextColor:#00BCD4;
    $menuitemActiveIconColor:#00BCD4;
    $verticalActiveRootMenuitemBgColor:#795548; 
    $verticalActiveRootMenuitemTextColor:#ffffff;   
    $verticalActiveRootMenuitemIconColor:#ffffff;
    $verticalSubmenuBgColor:rgba(255,255,255,0.2);

    @import '../_menu_theme';
}