.layout-menu-materialtwo {
    $menuBgImage:'materialtwo-menu.jpg';
    $menuitemTextColor:#ffffff;
    $menuitemIconColor:#ffffff;
    $menuitemHoverBgColor:rgba(0,0,0,0.32);
    $menuitemHoverTextColor:#ffffff;
    $menuitemHoverIconColor:#ffffff;
    $menuitemActiveTextColor:#ffc107;
    $menuitemActiveIconColor:#ffc107;
    $verticalActiveRootMenuitemBgColor:#34fcd6; 
    $verticalActiveRootMenuitemTextColor:#181a21;   
    $verticalActiveRootMenuitemIconColor:#181a21;
    $verticalSubmenuBgColor:rgba(255,255,255,0.2);

    @import '../_menu_theme';
}