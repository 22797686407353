.layout-menu-urban {
    $menuBgImage:'urban-menu.jpg';
    $menuitemTextColor:#ffffff;
    $menuitemIconColor:#ffffff;
    $menuitemHoverBgColor:rgba(255,255,255,0.32);
    $menuitemHoverTextColor:#ffffff;
    $menuitemHoverIconColor:#ffffff;
    $menuitemActiveTextColor:#ffc107;
    $menuitemActiveIconColor:#ffc107;
    $verticalActiveRootMenuitemBgColor:#26A69A; 
    $verticalActiveRootMenuitemTextColor:#212121;   
    $verticalActiveRootMenuitemIconColor:#212121;
    $verticalSubmenuBgColor:rgba(255,255,255,0.2);

    @import '../_menu_theme';
}