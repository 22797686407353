.layout-config-button {
    display: block;
    position: fixed;
    width: 52px;
    height: 52px;
    line-height: 52px;
    background-color: #fafafa;
    text-align: center;
    top: 230px;
    right: 0;
    z-index: 1000000;
    @include border-radius-left($borderRadius);
    @include transition(background-color $transitionDuration);
    @include multi-shadow(0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 5px 22px 4px rgba(0, 0, 0, 0.12), 0 12px 17px 2px rgba(0, 0, 0, 0.14));

    i {
        font-size: 32px;
        color: #00bcd4;
        line-height: inherit;
    }

    &:hover {
        background-color: #e0e0e0;
    }
}

.layout-config {
    z-index: 1000002;
    position: fixed;
    padding: 0;
    display: none;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    background-color: #ffffff;
    width: 52vw;
    @include overlay-content-shadow();

    .layout-config-content {
        padding: 1em;
        position: relative;
    }

    .layout-config-close {
        position: absolute;
        width: 36px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        right: -18px;
        top: -18px;
        background-color: #E91E63;
        @include border-radius(50%);
        @include transition(background-color $transitionDuration);

        i {
            color: #ffffff;
            line-height: inherit;
            font-size: 18px;
        }

        &:hover {
            background-color: #D81B60;
        }
    }

    &.layout-config-enter {
        display: block;
        opacity: 0;
        transform: translateX(-50%) translateY(-50%) scale(0.7);
    }

    &.layout-config-enter-active {
        opacity: 1;
        transform: translateX(-50%) translateY(-50%) scale(1);
        transition: all 150ms cubic-bezier(0, 0, 0.2, 1);
    }

    &.layout-config-enter-done {
        display: block;
    }

    &.layout-config-exit {
        opacity: 1;
        display: block;
    }

    &.layout-config-exit-active {
        opacity: 0;
        transform:  translateX(-50%) translateY(-50%);
        transition: all 150ms cubic-bezier(0.4, 0.0, 0.2, 1);
    }

    &.layout-config-exit-done {
        display: none;
    }

    .layout-config-option {
        width: 100px;
        height: 80px;
        line-height: 80px;
        text-align: center;
        display: inline-block;
        @include transition(transform $transitionDuration);

        i {
            line-height: inherit;
            font-size: 36px;
            color: #ffffff;
        }

        &:hover {
            @include scale(1.1);
        }

        &.layout-config-option-image {
            position: relative;

            i {
                position: absolute;
                top: 50%;
                left: 50%;
                margin-left: -24px;
                margin-top: -40px;
            }

            img {
                border: 0 none;
            }
        }

        &.layout-config-option-light {
            i {
                color: #000000;
            }
        }
    }

    .p-col {
        padding: 1em;
        -ms-flex-preferred-size: auto;
    }

    .p-tabview {
        border: 0 none;

        &.p-tabview-top > .p-tabview-nav {
            li {
                vertical-align: bottom;
                top: auto;
                margin: 0 0.125em 0px 0;
            }
        }

        .p-tabview-panels {
            padding: 1em 0;
            height: 500px;
            overflow: auto;

            .p-tabview-panel {
                padding: 0.857em 1em;
                line-height: 1.5;
            }
        }
    }

    .layout-config-subtitle {
        font-size: 16px;
        letter-spacing: 0.2px;
        margin: .75em 0;
        color: $textSecondaryColor;

        &:first-child {
            margin-top: 0;
        }
    }

    p {
        line-height: 1.5;
        margin-top: 0;
        color: $textSecondaryColor;
    }
}

.layout-config-mask {
    background-color: $maskBgColor;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000001;
    width: 100%;
    height: 100%;
}

@media screen and (max-width: $mobileBreakpoint) {
    .layout-config {
        width: 80vw;
    }
}
