@charset "UTF-8";
@import '~@ng-select/ng-select/themes/default.theme.css';
@tailwind base;
@tailwind components;
@tailwind utilities;
/* based on angular-toastr css https://github.com/Foxandxss/angular-toastr/blob/cb508fe6801d6b288d3afc525bb40fee1b101650/dist/angular-toastr.css */
/* position */
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: 12px;
  right: 12px;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

/* toast styles */
.toast-title {
  font-weight: bold;
}

.toast-message {
  word-wrap: break-word;
}

.toast-message a,
.toast-message label {
  color: #FFFFFF;
}

.toast-message a:hover {
  color: #CCCCCC;
  text-decoration: none;
}

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #FFFFFF;
  text-shadow: 0 1px 0 #ffffff;
  /* opacity: 0.8; */
}

.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}

/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}

.toast-container * {
  box-sizing: border-box;
}

.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 12px #999999;
  color: #FFFFFF;
}

.toast-container .ngx-toastr:hover {
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  cursor: pointer;
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
.toast-info {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOS4wNDMgOCA4IDExOS4wODMgOCAyNTZjMCAxMzYuOTk3IDExMS4wNDMgMjQ4IDI0OCAyNDhzMjQ4LTExMS4wMDMgMjQ4LTI0OEM1MDQgMTE5LjA4MyAzOTIuOTU3IDggMjU2IDh6bTAgMTEwYzIzLjE5NiAwIDQyIDE4LjgwNCA0MiA0MnMtMTguODA0IDQyLTQyIDQyLTQyLTE4LjgwNC00Mi00MiAxOC44MDQtNDIgNDItNDJ6bTU2IDI1NGMwIDYuNjI3LTUuMzczIDEyLTEyIDEyaC04OGMtNi42MjcgMC0xMi01LjM3My0xMi0xMnYtMjRjMC02LjYyNyA1LjM3My0xMiAxMi0xMmgxMnYtNjRoLTEyYy02LjYyNyAwLTEyLTUuMzczLTEyLTEydi0yNGMwLTYuNjI3IDUuMzczLTEyIDEyLTEyaDY0YzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MTAwaDEyYzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MjR6Jy8+PC9zdmc+");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
.toast-error {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOSA4IDggMTE5IDggMjU2czExMSAyNDggMjQ4IDI0OCAyNDgtMTExIDI0OC0yNDhTMzkzIDggMjU2IDh6bTEyMS42IDMxMy4xYzQuNyA0LjcgNC43IDEyLjMgMCAxN0wzMzggMzc3LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwyNTYgMzEybC02NS4xIDY1LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwxMzQuNCAzMzhjLTQuNy00LjctNC43LTEyLjMgMC0xN2w2NS42LTY1LTY1LjYtNjUuMWMtNC43LTQuNy00LjctMTIuMyAwLTE3bDM5LjYtMzkuNmM0LjctNC43IDEyLjMtNC43IDE3IDBsNjUgNjUuNyA2NS4xLTY1LjZjNC43LTQuNyAxMi4zLTQuNyAxNyAwbDM5LjYgMzkuNmM0LjcgNC43IDQuNyAxMi4zIDAgMTdMMzEyIDI1Nmw2NS42IDY1LjF6Jy8+PC9zdmc+");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
.toast-success {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTE3My44OTggNDM5LjQwNGwtMTY2LjQtMTY2LjRjLTkuOTk3LTkuOTk3LTkuOTk3LTI2LjIwNiAwLTM2LjIwNGwzNi4yMDMtMzYuMjA0YzkuOTk3LTkuOTk4IDI2LjIwNy05Ljk5OCAzNi4yMDQgMEwxOTIgMzEyLjY5IDQzMi4wOTUgNzIuNTk2YzkuOTk3LTkuOTk3IDI2LjIwNy05Ljk5NyAzNi4yMDQgMGwzNi4yMDMgMzYuMjA0YzkuOTk3IDkuOTk3IDkuOTk3IDI2LjIwNiAwIDM2LjIwNGwtMjk0LjQgMjk0LjQwMWMtOS45OTggOS45OTctMjYuMjA3IDkuOTk3LTM2LjIwNC0uMDAxeicvPjwvc3ZnPg==");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
.toast-warning {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1NzYgNTEyJyB3aWR0aD0nNTc2JyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTU2OS41MTcgNDQwLjAxM0M1ODcuOTc1IDQ3Mi4wMDcgNTY0LjgwNiA1MTIgNTI3Ljk0IDUxMkg0OC4wNTRjLTM2LjkzNyAwLTU5Ljk5OS00MC4wNTUtNDEuNTc3LTcxLjk4N0wyNDYuNDIzIDIzLjk4NWMxOC40NjctMzIuMDA5IDY0LjcyLTMxLjk1MSA4My4xNTQgMGwyMzkuOTQgNDE2LjAyOHpNMjg4IDM1NGMtMjUuNDA1IDAtNDYgMjAuNTk1LTQ2IDQ2czIwLjU5NSA0NiA0NiA0NiA0Ni0yMC41OTUgNDYtNDYtMjAuNTk1LTQ2LTQ2LTQ2em0tNDMuNjczLTE2NS4zNDZsNy40MTggMTM2Yy4zNDcgNi4zNjQgNS42MDkgMTEuMzQ2IDExLjk4MiAxMS4zNDZoNDguNTQ2YzYuMzczIDAgMTEuNjM1LTQuOTgyIDExLjk4Mi0xMS4zNDZsNy40MTgtMTM2Yy4zNzUtNi44NzQtNS4wOTgtMTIuNjU0LTExLjk4Mi0xMi42NTRoLTYzLjM4M2MtNi44ODQgMC0xMi4zNTYgNS43OC0xMS45ODEgMTIuNjU0eicvPjwvc3ZnPg==");
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.ngx-toastr {
  background-color: #030303;
  pointer-events: auto;
}

.toast-success {
  background-color: #51A351;
}

.toast-error {
  background-color: #BD362F;
}

.toast-info {
  background-color: #2F96B4;
}

.toast-warning {
  background-color: #F89406;
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
}

/* Responsive Design */
@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }

  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }

  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}
:root {
  --surface-a:#ffffff;
  --surface-b:#fafafa;
  --surface-c:rgba(0,0,0,.04);
  --surface-d:rgba(0,0,0,.12);
  --surface-e:#ffffff;
  --surface-f:#ffffff;
  --text-color:rgba(0, 0, 0, 0.87);
  --text-color-secondary:rgba(0, 0, 0, 0.6);
  --primary-color:#1E88E5;
  --primary-color-text:#ffffff;
  --font-family:Roboto, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif;
  --surface-0: #ffffff;
  --surface-50: #FAFAFA;
  --surface-100: #F5F5F5;
  --surface-200: #EEEEEE;
  --surface-300: #E0E0E0;
  --surface-400: #BDBDBD;
  --surface-500: #9E9E9E;
  --surface-600: #757575;
  --surface-700: #616161;
  --surface-800: #424242;
  --surface-900: #212121;
  --gray-50: #FAFAFA;
  --gray-100: #F5F5F5;
  --gray-200: #EEEEEE;
  --gray-300: #E0E0E0;
  --gray-400: #BDBDBD;
  --gray-500: #9E9E9E;
  --gray-600: #757575;
  --gray-700: #616161;
  --gray-800: #424242;
  --gray-900: #212121;
  --content-padding:1rem;
  --inline-spacing:0.5rem;
  --border-radius:4px;
  --surface-ground:#f4f4f7;
  --surface-section:#f4f4f7;
  --surface-card:#ffffff;
  --surface-overlay:#ffffff;
  --surface-border:#dbdbdb;
  --surface-hover:rgba(0,0,0,.04);
  --maskbg: rgba(0, 0, 0, 0.32);
  --focus-ring: none;
}

/* Add your variable customizations of theme here */
* {
  box-sizing: border-box;
}

.p-component {
  font-family: Roboto, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif;
  font-size: 1rem;
  font-weight: normal;
}

.p-component-overlay {
  background-color: rgba(0, 0, 0, 0.32);
  transition-duration: 0.2s;
}

.p-disabled, .p-component:disabled {
  opacity: 0.38;
}

.p-error {
  color: #B00020;
}

.p-text-secondary {
  color: rgba(0, 0, 0, 0.6);
}

.pi {
  font-size: 1rem;
}

.p-link {
  font-size: 1rem;
  font-family: Roboto, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif;
  border-radius: 4px;
}
.p-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-component-overlay-enter {
  animation: p-component-overlay-enter-animation 150ms forwards;
}

.p-component-overlay-leave {
  animation: p-component-overlay-leave-animation 150ms forwards;
}

@keyframes p-component-overlay-enter-animation {
  from {
    background-color: transparent;
  }
  to {
    background-color: var(--maskbg);
  }
}
@keyframes p-component-overlay-leave-animation {
  from {
    background-color: var(--maskbg);
  }
  to {
    background-color: transparent;
  }
}

:root {
  --blue-50:#f4fafe;
  --blue-100:#cae6fc;
  --blue-200:#a0d2fa;
  --blue-300:#75bef8;
  --blue-400:#4baaf5;
  --blue-500:#2196f3;
  --blue-600:#1c80cf;
  --blue-700:#1769aa;
  --blue-800:#125386;
  --blue-900:#0d3c61;
  --green-50:#f7faf5;
  --green-100:#dbe8cf;
  --green-200:#bed6a9;
  --green-300:#a1c384;
  --green-400:#85b15e;
  --green-500:#689f38;
  --green-600:#588730;
  --green-700:#496f27;
  --green-800:#39571f;
  --green-900:#2a4016;
  --yellow-50:#fffcf5;
  --yellow-100:#fef0cd;
  --yellow-200:#fde4a5;
  --yellow-300:#fdd87d;
  --yellow-400:#fccc55;
  --yellow-500:#fbc02d;
  --yellow-600:#d5a326;
  --yellow-700:#b08620;
  --yellow-800:#8a6a19;
  --yellow-900:#644d12;
  --cyan-50:#f2fcfd;
  --cyan-100:#c2eff5;
  --cyan-200:#91e2ed;
  --cyan-300:#61d5e4;
  --cyan-400:#30c9dc;
  --cyan-500:#00bcd4;
  --cyan-600:#00a0b4;
  --cyan-700:#008494;
  --cyan-800:#006775;
  --cyan-900:#004b55;
  --pink-50:#fef4f7;
  --pink-100:#fac9da;
  --pink-200:#f69ebc;
  --pink-300:#f1749e;
  --pink-400:#ed4981;
  --pink-500:#e91e63;
  --pink-600:#c61a54;
  --pink-700:#a31545;
  --pink-800:#801136;
  --pink-900:#5d0c28;
  --indigo-50:#f6f7fc;
  --indigo-100:#d5d9ef;
  --indigo-200:#b3bae2;
  --indigo-300:#919cd5;
  --indigo-400:#707dc8;
  --indigo-500:#4e5fbb;
  --indigo-600:#42519f;
  --indigo-700:#374383;
  --indigo-800:#2b3467;
  --indigo-900:#1f264b;
  --teal-50:#f2faf9;
  --teal-100:#c2e6e2;
  --teal-200:#91d2cc;
  --teal-300:#61beb5;
  --teal-400:#30aa9f;
  --teal-500:#009688;
  --teal-600:#008074;
  --teal-700:#00695f;
  --teal-800:#00534b;
  --teal-900:#003c36;
  --orange-50:#fffaf2;
  --orange-100:#ffe6c2;
  --orange-200:#ffd391;
  --orange-300:#ffbf61;
  --orange-400:#ffac30;
  --orange-500:#ff9800;
  --orange-600:#d98100;
  --orange-700:#b36a00;
  --orange-800:#8c5400;
  --orange-900:#663d00;
  --bluegray-50:#f7f9f9;
  --bluegray-100:#d9e0e3;
  --bluegray-200:#bbc7cd;
  --bluegray-300:#9caeb7;
  --bluegray-400:#7e96a1;
  --bluegray-500:#607d8b;
  --bluegray-600:#526a76;
  --bluegray-700:#435861;
  --bluegray-800:#35454c;
  --bluegray-900:#263238;
  --purple-50:#faf4fb;
  --purple-100:#e7cbec;
  --purple-200:#d4a2dd;
  --purple-300:#c279ce;
  --purple-400:#af50bf;
  --purple-500:#9c27b0;
  --purple-600:#852196;
  --purple-700:#6d1b7b;
  --purple-800:#561561;
  --purple-900:#3e1046;
}

.p-autocomplete .p-autocomplete-loader {
  right: 1rem;
}
.p-autocomplete.p-autocomplete-dd .p-autocomplete-loader {
  right: 4rem;
}
.p-autocomplete .p-autocomplete-multiple-container {
  padding: 0.5rem 1rem;
}
.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled):hover {
  border-color: rgba(0, 0, 0, 0.87);
}
.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
  border-color: #1E88E5;
}
.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token {
  padding: 0.5rem 0;
}
.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token input {
  font-family: Roboto, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif;
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.87);
  padding: 0;
  margin: 0;
}
.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token {
  padding: 0.5rem 1rem;
  margin-right: 0.5rem;
  background: rgba(30, 136, 229, 0.12);
  color: #1E88E5;
  border-radius: 4px;
}
.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token .p-autocomplete-token-icon {
  margin-left: 0.5rem;
}

p-autocomplete.ng-dirty.ng-invalid > .p-autocomplete > .p-inputtext {
  border-color: #B00020;
}

.p-autocomplete-panel {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border: 0 none;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.p-autocomplete-panel .p-autocomplete-items:not(.p-autocomplete-virtualscroll) {
  padding: 0;
}
.p-autocomplete-panel .p-autocomplete-items.p-autocomplete-virtualscroll .cdk-virtual-scroll-content-wrapper {
  padding: 0;
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
  margin: 0;
  padding: 1rem 1rem;
  border: 0 none;
  color: rgba(0, 0, 0, 0.87);
  background: transparent;
  transition: none;
  border-radius: 0;
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:hover {
  color: rgba(0, 0, 0, 0.87);
  background: rgba(0, 0, 0, 0.04);
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item.p-highlight {
  color: #1E88E5;
  background: rgba(30, 136, 229, 0.12);
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-empty-message {
  padding: 1rem 1rem;
  color: rgba(0, 0, 0, 0.87);
  background: transparent;
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item-group {
  margin: 0;
  padding: 1rem;
  color: rgba(0, 0, 0, 0.6);
  background: #ffffff;
  font-weight: 400;
}

p-calendar.ng-dirty.ng-invalid > .p-calendar > .p-inputtext {
  border-color: #B00020;
}

.p-datepicker {
  padding: 0.5rem;
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #e5e5e5;
  border-radius: 4px;
}
.p-datepicker:not(.p-datepicker-inline) {
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
  background: #ffffff;
}
.p-datepicker .p-datepicker-header {
  padding: 0.5rem;
  color: rgba(0, 0, 0, 0.87);
  background: #ffffff;
  font-weight: 500;
  margin: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev,
.p-datepicker .p-datepicker-header .p-datepicker-next {
  width: 2.5rem;
  height: 2.5rem;
  color: rgba(0, 0, 0, 0.6);
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev:enabled:hover,
.p-datepicker .p-datepicker-header .p-datepicker-next:enabled:hover {
  color: rgba(0, 0, 0, 0.6);
  border-color: transparent;
  background: rgba(0, 0, 0, 0.04);
}
.p-datepicker .p-datepicker-header .p-datepicker-prev:focus,
.p-datepicker .p-datepicker-header .p-datepicker-next:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-datepicker .p-datepicker-header .p-datepicker-title {
  line-height: 2.5rem;
}
.p-datepicker .p-datepicker-header .p-datepicker-title select {
  transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
}
.p-datepicker .p-datepicker-header .p-datepicker-title select:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
  border-color: #1E88E5;
}
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  margin-right: 0.5rem;
}
.p-datepicker table {
  font-size: 1rem;
  margin: 0.5rem 0;
}
.p-datepicker table th {
  padding: 0.5rem;
}
.p-datepicker table th > span {
  width: 2.5rem;
  height: 2.5rem;
}
.p-datepicker table td {
  padding: 0.5rem;
}
.p-datepicker table td > span {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  transition: none;
  border: 1px solid transparent;
}
.p-datepicker table td > span.p-highlight {
  color: #1E88E5;
  background: rgba(30, 136, 229, 0.12);
}
.p-datepicker table td > span:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-datepicker table td.p-datepicker-today > span {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border-color: black;
}
.p-datepicker table td.p-datepicker-today > span.p-highlight {
  color: #1E88E5;
  background: rgba(30, 136, 229, 0.12);
}
.p-datepicker .p-datepicker-buttonbar {
  padding: 1rem 0;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.p-datepicker .p-datepicker-buttonbar .p-button {
  width: auto;
}
.p-datepicker .p-timepicker {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  padding: 0.5rem;
}
.p-datepicker .p-timepicker button {
  width: 2.5rem;
  height: 2.5rem;
  color: rgba(0, 0, 0, 0.6);
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-datepicker .p-timepicker button:enabled:hover {
  color: rgba(0, 0, 0, 0.6);
  border-color: transparent;
  background: rgba(0, 0, 0, 0.04);
}
.p-datepicker .p-timepicker button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-datepicker .p-timepicker button:last-child {
  margin-top: 0.2em;
}
.p-datepicker .p-timepicker span {
  font-size: 1.25rem;
}
.p-datepicker .p-timepicker > div {
  padding: 0 0.5rem;
}
.p-datepicker.p-datepicker-timeonly .p-timepicker {
  border-top: 0 none;
}
.p-datepicker .p-monthpicker {
  margin: 0.5rem 0;
}
.p-datepicker .p-monthpicker .p-monthpicker-month {
  padding: 0.5rem;
  transition: none;
  border-radius: 4px;
}
.p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
  color: #1E88E5;
  background: rgba(30, 136, 229, 0.12);
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  padding-top: 0;
  padding-bottom: 0;
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:first-child {
  padding-left: 0;
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:last-child {
  padding-right: 0;
  border-right: 0 none;
}
.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
  background: rgba(0, 0, 0, 0.04);
}
.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-highlight):not(.p-disabled):hover {
  background: rgba(0, 0, 0, 0.04);
}
.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-highlight):not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

@media screen and (max-width: 769px) {
  .p-datepicker table th, .p-datepicker table td {
    padding: 0;
  }
}
.p-cascadeselect {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.38);
  transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
  border-radius: 4px;
}
.p-cascadeselect:not(.p-disabled):hover {
  border-color: rgba(0, 0, 0, 0.87);
}
.p-cascadeselect:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
  border-color: #1E88E5;
}
.p-cascadeselect .p-cascadeselect-label {
  background: transparent;
  border: 0 none;
  padding: 1rem 1rem;
}
.p-cascadeselect .p-cascadeselect-label.p-placeholder {
  color: rgba(0, 0, 0, 0.6);
}
.p-cascadeselect .p-cascadeselect-label:enabled:focus {
  outline: 0 none;
  box-shadow: none;
}
.p-cascadeselect .p-cascadeselect-trigger {
  background: transparent;
  color: rgba(0, 0, 0, 0.6);
  width: 2.357rem;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.p-cascadeselect-panel {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border: 0 none;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.p-cascadeselect-panel .p-cascadeselect-items {
  padding: 0;
}
.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item {
  margin: 0;
  border: 0 none;
  color: rgba(0, 0, 0, 0.87);
  background: transparent;
  transition: none;
  border-radius: 0;
}
.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-item-content {
  padding: 1rem 1rem;
}
.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-item-content:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item.p-highlight {
  color: #1E88E5;
  background: rgba(30, 136, 229, 0.12);
}
.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item:not(.p-highlight):not(.p-disabled):hover {
  color: rgba(0, 0, 0, 0.87);
  background: rgba(0, 0, 0, 0.04);
}
.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-group-icon {
  font-size: 0.875rem;
}

p-cascadeselect.ng-dirty.ng-invalid > .p-cascadeselect {
  border-color: #B00020;
}

.p-input-filled .p-cascadeselect {
  background: #f5f5f5;
}
.p-input-filled .p-cascadeselect:not(.p-disabled):hover {
  background-color: #ececec;
}
.p-input-filled .p-cascadeselect:not(.p-disabled).p-focus {
  background-color: #dcdcdc;
}

.p-checkbox {
  width: 18px;
  height: 18px;
}
.p-checkbox .p-checkbox-box {
  border: 2px solid #757575;
  background: #ffffff;
  width: 18px;
  height: 18px;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 4px;
  transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
}
.p-checkbox .p-checkbox-box .p-checkbox-icon {
  transition-duration: 0.2s;
  color: #ffffff;
  font-size: 14px;
}
.p-checkbox .p-checkbox-box.p-highlight {
  border-color: #1E88E5;
  background: #1E88E5;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: rgba(0, 0, 0, 0.87);
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
  border-color: #1E88E5;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: #1E88E5;
  background: #1E88E5;
  color: #ffffff;
}

p-checkbox.ng-dirty.ng-invalid > .p-checkbox > .p-checkbox-box {
  border-color: #B00020;
}

.p-input-filled .p-checkbox .p-checkbox-box {
  background-color: #f5f5f5;
}
.p-input-filled .p-checkbox .p-checkbox-box.p-highlight {
  background: #1E88E5;
}
.p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  background-color: #ececec;
}
.p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  background: #1E88E5;
}

.p-checkbox-label {
  margin-left: 0.5rem;
}

.p-chips .p-chips-multiple-container {
  padding: 0.5rem 1rem;
}
.p-chips .p-chips-multiple-container:not(.p-disabled):hover {
  border-color: rgba(0, 0, 0, 0.87);
}
.p-chips .p-chips-multiple-container:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
  border-color: #1E88E5;
}
.p-chips .p-chips-multiple-container .p-chips-token {
  padding: 0.5rem 1rem;
  margin-right: 0.5rem;
  background: rgba(30, 136, 229, 0.12);
  color: #1E88E5;
  border-radius: 4px;
}
.p-chips .p-chips-multiple-container .p-chips-token .p-chips-token-icon {
  margin-left: 0.5rem;
}
.p-chips .p-chips-multiple-container .p-chips-input-token {
  padding: 0.5rem 0;
}
.p-chips .p-chips-multiple-container .p-chips-input-token input {
  font-family: Roboto, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif;
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.87);
  padding: 0;
  margin: 0;
}

p-chips.ng-dirty.ng-invalid > .p-chips > .p-inputtext {
  border-color: #B00020;
}

.p-colorpicker-preview,
.p-fluid .p-colorpicker-preview.p-inputtext {
  width: 2rem;
  height: 2rem;
}

.p-colorpicker-panel {
  background: #323232;
  border-color: #191919;
}
.p-colorpicker-panel .p-colorpicker-color-handle,
.p-colorpicker-panel .p-colorpicker-hue-handle {
  border-color: #ffffff;
}

.p-colorpicker-overlay-panel {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.p-dropdown {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.38);
  transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
  border-radius: 4px;
}
.p-dropdown:not(.p-disabled):hover {
  border-color: rgba(0, 0, 0, 0.87);
}
.p-dropdown:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
  border-color: #1E88E5;
}
.p-dropdown.p-dropdown-clearable .p-dropdown-label {
  padding-right: 2rem;
}
.p-dropdown .p-dropdown-label {
  background: transparent;
  border: 0 none;
}
.p-dropdown .p-dropdown-label.p-placeholder {
  color: rgba(0, 0, 0, 0.6);
}
.p-dropdown .p-dropdown-label:enabled:focus {
  outline: 0 none;
  box-shadow: none;
}
.p-dropdown .p-dropdown-trigger {
  background: transparent;
  color: rgba(0, 0, 0, 0.6);
  width: 2.357rem;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.p-dropdown .p-dropdown-clear-icon {
  color: rgba(0, 0, 0, 0.6);
  right: 2.357rem;
}

.p-dropdown-panel {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border: 0 none;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.p-dropdown-panel .p-dropdown-header {
  padding: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.87);
  background: #ffffff;
  margin: 0;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
  padding-right: 2rem;
  margin-right: -2rem;
}
.p-dropdown-panel .p-dropdown-header .p-dropdown-filter-icon {
  right: 1rem;
  color: rgba(0, 0, 0, 0.6);
}
.p-dropdown-panel .p-dropdown-items:not(.p-dropdown-virtualscroll) {
  padding: 0;
}
.p-dropdown-panel .p-dropdown-items.p-dropdown-virtualscroll .cdk-virtual-scroll-content-wrapper {
  padding: 0;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  margin: 0;
  padding: 1rem 1rem;
  border: 0 none;
  color: rgba(0, 0, 0, 0.87);
  background: transparent;
  transition: none;
  border-radius: 0;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: #1E88E5;
  background: rgba(30, 136, 229, 0.12);
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
  color: rgba(0, 0, 0, 0.87);
  background: rgba(0, 0, 0, 0.04);
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message {
  padding: 1rem 1rem;
  color: rgba(0, 0, 0, 0.87);
  background: transparent;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
  margin: 0;
  padding: 1rem;
  color: rgba(0, 0, 0, 0.6);
  background: #ffffff;
  font-weight: 400;
}

p-dropdown.ng-dirty.ng-invalid > .p-dropdown {
  border-color: #B00020;
}

.p-input-filled .p-dropdown {
  background: #f5f5f5;
}
.p-input-filled .p-dropdown:not(.p-disabled):hover {
  background-color: #ececec;
}
.p-input-filled .p-dropdown:not(.p-disabled).p-focus {
  background-color: #dcdcdc;
}

.p-editor-container .p-editor-toolbar {
  background: #ffffff;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.p-editor-container .p-editor-toolbar.ql-snow {
  border: 1px solid #e0e0e0;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-stroke {
  stroke: rgba(0, 0, 0, 0.6);
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-fill {
  fill: rgba(0, 0, 0, 0.6);
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label {
  border: 0 none;
  color: rgba(0, 0, 0, 0.6);
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover {
  color: rgba(0, 0, 0, 0.87);
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-stroke {
  stroke: rgba(0, 0, 0, 0.87);
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-fill {
  fill: rgba(0, 0, 0, 0.87);
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  color: rgba(0, 0, 0, 0.87);
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
  stroke: rgba(0, 0, 0, 0.87);
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
  fill: rgba(0, 0, 0, 0.87);
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 0;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item {
  color: rgba(0, 0, 0, 0.87);
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item:hover {
  color: rgba(0, 0, 0, 0.87);
  background: rgba(0, 0, 0, 0.04);
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded:not(.ql-icon-picker) .ql-picker-item {
  padding: 1rem 1rem;
}
.p-editor-container .p-editor-content {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.p-editor-container .p-editor-content.ql-snow {
  border: 1px solid #e0e0e0;
}
.p-editor-container .p-editor-content .ql-editor {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.p-editor-container .ql-snow.ql-toolbar button:hover,
.p-editor-container .ql-snow.ql-toolbar button:focus {
  color: rgba(0, 0, 0, 0.87);
}
.p-editor-container .ql-snow.ql-toolbar button:hover .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar button:focus .ql-stroke {
  stroke: rgba(0, 0, 0, 0.87);
}
.p-editor-container .ql-snow.ql-toolbar button:hover .ql-fill,
.p-editor-container .ql-snow.ql-toolbar button:focus .ql-fill {
  fill: rgba(0, 0, 0, 0.87);
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected {
  color: #1E88E5;
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke {
  stroke: #1E88E5;
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-fill,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill {
  fill: #1E88E5;
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-picker-label,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-picker-label,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-picker-label {
  color: #1E88E5;
}

.p-inputgroup-addon {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.6);
  border-top: 1px solid rgba(0, 0, 0, 0.38);
  border-left: 1px solid rgba(0, 0, 0, 0.38);
  border-bottom: 1px solid rgba(0, 0, 0, 0.38);
  padding: 1rem 1rem;
  min-width: 2.357rem;
}
.p-inputgroup-addon:last-child {
  border-right: 1px solid rgba(0, 0, 0, 0.38);
}

.p-inputgroup > .p-component,
.p-inputgroup > .p-element,
.p-inputgroup > .p-inputwrapper > .p-component > .p-inputtext,
.p-inputgroup > .p-float-label > .p-component {
  border-radius: 0;
  margin: 0;
}
.p-inputgroup > .p-component + .p-inputgroup-addon,
.p-inputgroup > .p-element + .p-inputgroup-addon,
.p-inputgroup > .p-inputwrapper > .p-component > .p-inputtext + .p-inputgroup-addon,
.p-inputgroup > .p-float-label > .p-component + .p-inputgroup-addon {
  border-left: 0 none;
}
.p-inputgroup > .p-component:focus,
.p-inputgroup > .p-element:focus,
.p-inputgroup > .p-inputwrapper > .p-component > .p-inputtext:focus,
.p-inputgroup > .p-float-label > .p-component:focus {
  z-index: 1;
}
.p-inputgroup > .p-component:focus ~ label,
.p-inputgroup > .p-element:focus ~ label,
.p-inputgroup > .p-inputwrapper > .p-component > .p-inputtext:focus ~ label,
.p-inputgroup > .p-float-label > .p-component:focus ~ label {
  z-index: 1;
}

.p-inputgroup-addon:first-child,
.p-inputgroup button:first-child,
.p-inputgroup input:first-child,
.p-inputgroup > .p-inputwrapper:first-child > .p-component,
.p-inputgroup > .p-inputwrapper:first-child > .p-component > .p-inputtext {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.p-inputgroup .p-float-label:first-child input {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.p-inputgroup-addon:last-child,
.p-inputgroup button:last-child,
.p-inputgroup input:last-child,
.p-inputgroup > .p-inputwrapper:last-child > .p-component,
.p-inputgroup > .p-inputwrapper:last-child > .p-component > .p-inputtext {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.p-inputgroup .p-float-label:last-child input {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.p-fluid .p-inputgroup .p-button {
  width: auto;
}
.p-fluid .p-inputgroup .p-button.p-button-icon-only {
  width: 3rem;
}

p-inputmask.ng-dirty.ng-invalid > .p-inputtext {
  border-color: #B00020;
}

p-inputnumber.ng-dirty.ng-invalid > .p-inputnumber > .p-inputtext {
  border-color: #B00020;
}

.p-inputswitch {
  width: 2.75rem;
  height: 1rem;
}
.p-inputswitch .p-inputswitch-slider {
  background: rgba(0, 0, 0, 0.38);
  transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
  border-radius: 0.5rem;
}
.p-inputswitch .p-inputswitch-slider:before {
  background: #ffffff;
  width: 1.5rem;
  height: 1.5rem;
  left: -1px;
  margin-top: -0.75rem;
  border-radius: 50%;
  transition-duration: 0.2s;
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  transform: translateX(1.5rem);
}
.p-inputswitch.p-focus .p-inputswitch-slider {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
  background: rgba(0, 0, 0, 0.38);
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: rgba(30, 136, 229, 0.5);
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  background: #1E88E5;
}
.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
  background: rgba(30, 136, 229, 0.5);
}

p-inputswitch.ng-dirty.ng-invalid > .p-inputswitch {
  border-color: #B00020;
}

.p-inputtext {
  font-family: Roboto, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif;
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.87);
  background: #ffffff;
  padding: 1rem 1rem;
  border: 1px solid rgba(0, 0, 0, 0.38);
  transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
  appearance: none;
  border-radius: 4px;
}
.p-inputtext:enabled:hover {
  border-color: rgba(0, 0, 0, 0.87);
}
.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
  border-color: #1E88E5;
}
.p-inputtext.ng-dirty.ng-invalid {
  border-color: #B00020;
}
.p-inputtext.p-inputtext-sm {
  font-size: 0.875rem;
  padding: 0.875rem 0.875rem;
}
.p-inputtext.p-inputtext-lg {
  font-size: 1.25rem;
  padding: 1.25rem 1.25rem;
}

.p-float-label > label {
  left: 1rem;
  color: rgba(0, 0, 0, 0.6);
  transition-duration: 0.2s;
}

.p-float-label > .ng-invalid.ng-dirty + label {
  color: #B00020;
}

.p-input-icon-left > i:first-of-type {
  left: 1rem;
  color: rgba(0, 0, 0, 0.6);
}

.p-input-icon-left > .p-inputtext {
  padding-left: 3rem;
}

.p-input-icon-left.p-float-label > label {
  left: 3rem;
}

.p-input-icon-right > i:last-of-type {
  right: 1rem;
  color: rgba(0, 0, 0, 0.6);
}

.p-input-icon-right > .p-inputtext {
  padding-right: 3rem;
}

::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.6);
}

:-moz-placeholder {
  color: rgba(0, 0, 0, 0.6);
}

::-moz-placeholder {
  color: rgba(0, 0, 0, 0.6);
}

:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.6);
}

.p-input-filled .p-inputtext {
  background-color: #f5f5f5;
}
.p-input-filled .p-inputtext:enabled:hover {
  background-color: #ececec;
}
.p-input-filled .p-inputtext:enabled:focus {
  background-color: #dcdcdc;
}

.p-inputtext-sm .p-inputtext {
  font-size: 0.875rem;
  padding: 0.875rem 0.875rem;
}

.p-inputtext-lg .p-inputtext {
  font-size: 1.25rem;
  padding: 1.25rem 1.25rem;
}

.p-listbox {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #e5e5e5;
  border-radius: 4px;
}
.p-listbox .p-listbox-header {
  padding: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.87);
  background: #ffffff;
  margin: 0;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.p-listbox .p-listbox-header .p-listbox-filter {
  padding-right: 2rem;
}
.p-listbox .p-listbox-header .p-listbox-filter-icon {
  right: 1rem;
  color: rgba(0, 0, 0, 0.6);
}
.p-listbox .p-listbox-header .p-checkbox {
  margin-right: 0.5rem;
}
.p-listbox .p-listbox-list {
  padding: 0;
}
.p-listbox .p-listbox-list .p-listbox-item {
  margin: 0;
  padding: 1rem 1rem;
  border: 0 none;
  color: rgba(0, 0, 0, 0.87);
  transition: none;
  border-radius: 0;
}
.p-listbox .p-listbox-list .p-listbox-item.p-highlight {
  color: #1E88E5;
  background: rgba(30, 136, 229, 0.12);
}
.p-listbox .p-listbox-list .p-listbox-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-listbox .p-listbox-list .p-listbox-item .p-checkbox {
  margin-right: 0.5rem;
}
.p-listbox .p-listbox-list .p-listbox-item-group {
  margin: 0;
  padding: 1rem;
  color: rgba(0, 0, 0, 0.6);
  background: #ffffff;
  font-weight: 400;
}
.p-listbox .p-listbox-list .p-listbox-empty-message {
  padding: 1rem 1rem;
  color: rgba(0, 0, 0, 0.87);
  background: transparent;
}
.p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled):hover {
  color: rgba(0, 0, 0, 0.87);
  background: rgba(0, 0, 0, 0.04);
}

p-listbox.ng-dirty.ng-invalid > .p-listbox {
  border-color: #B00020;
}

.p-multiselect {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.38);
  transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
  border-radius: 4px;
}
.p-multiselect:not(.p-disabled):hover {
  border-color: rgba(0, 0, 0, 0.87);
}
.p-multiselect:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
  border-color: #1E88E5;
}
.p-multiselect .p-multiselect-label {
  padding: 1rem 1rem;
  transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
}
.p-multiselect .p-multiselect-label.p-placeholder {
  color: rgba(0, 0, 0, 0.6);
}
.p-multiselect.p-multiselect-chip .p-multiselect-token {
  padding: 0.5rem 1rem;
  margin-right: 0.5rem;
  background: rgba(30, 136, 229, 0.12);
  color: #1E88E5;
  border-radius: 4px;
}
.p-multiselect.p-multiselect-chip .p-multiselect-token .p-multiselect-token-icon {
  margin-left: 0.5rem;
}
.p-multiselect .p-multiselect-trigger {
  background: transparent;
  color: rgba(0, 0, 0, 0.6);
  width: 2.357rem;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.p-inputwrapper-filled .p-multiselect.p-multiselect-chip .p-multiselect-label {
  padding: 0.5rem 1rem;
}

.p-multiselect-panel {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border: 0 none;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.p-multiselect-panel .p-multiselect-header {
  padding: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.87);
  background: #ffffff;
  margin: 0;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext {
  padding-right: 2rem;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon {
  right: 1rem;
  color: rgba(0, 0, 0, 0.6);
}
.p-multiselect-panel .p-multiselect-header .p-checkbox {
  margin-right: 0.5rem;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-close {
  margin-left: 0.5rem;
  width: 2.5rem;
  height: 2.5rem;
  color: rgba(0, 0, 0, 0.6);
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-close:enabled:hover {
  color: rgba(0, 0, 0, 0.6);
  border-color: transparent;
  background: rgba(0, 0, 0, 0.04);
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-multiselect-panel .p-multiselect-items:not(.p-multiselect-virtualscroll) {
  padding: 0;
}
.p-multiselect-panel .p-multiselect-items.p-multiselect-virtualscroll .cdk-virtual-scroll-content-wrapper {
  padding: 0;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  margin: 0;
  padding: 1rem 1rem;
  border: 0 none;
  color: rgba(0, 0, 0, 0.87);
  background: transparent;
  transition: none;
  border-radius: 0;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  color: #1E88E5;
  background: rgba(30, 136, 229, 0.12);
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
  color: rgba(0, 0, 0, 0.87);
  background: rgba(0, 0, 0, 0.04);
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
  margin-right: 0.5rem;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item-group {
  margin: 0;
  padding: 1rem;
  color: rgba(0, 0, 0, 0.6);
  background: #ffffff;
  font-weight: 400;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-empty-message {
  padding: 1rem 1rem;
  color: rgba(0, 0, 0, 0.87);
  background: transparent;
}

p-multiselect.ng-dirty.ng-invalid > .p-multiselect {
  border-color: #B00020;
}

.p-input-filled .p-multiselect {
  background: #f5f5f5;
}
.p-input-filled .p-multiselect:not(.p-disabled):hover {
  background-color: #ececec;
}
.p-input-filled .p-multiselect:not(.p-disabled).p-focus {
  background-color: #dcdcdc;
}

.p-password.p-invalid.p-component > .p-inputtext {
  border-color: #B00020;
}

.p-password-panel {
  padding: 1rem;
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border: 0 none;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}
.p-password-panel .p-password-meter {
  margin-bottom: 0.5rem;
  background: rgba(30, 136, 229, 0.32);
}
.p-password-panel .p-password-meter .p-password-strength.weak {
  background: #D32F2F;
}
.p-password-panel .p-password-meter .p-password-strength.medium {
  background: #FBC02D;
}
.p-password-panel .p-password-meter .p-password-strength.strong {
  background: #689F38;
}

.p-radiobutton {
  width: 20px;
  height: 20px;
}
.p-radiobutton .p-radiobutton-box {
  border: 2px solid #757575;
  background: #ffffff;
  width: 20px;
  height: 20px;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 50%;
  transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
}
.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
  border-color: rgba(0, 0, 0, 0.87);
}
.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
  border-color: #1E88E5;
}
.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  width: 10px;
  height: 10px;
  transition-duration: 0.2s;
  background-color: #1E88E5;
}
.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: #1E88E5;
  background: #ffffff;
}
.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  border-color: #1E88E5;
  background: #ffffff;
  color: #1E88E5;
}

p-radiobutton.ng-dirty.ng-invalid > .p-radiobutton > .p-radiobutton-box {
  border-color: #B00020;
}

.p-input-filled .p-radiobutton .p-radiobutton-box {
  background-color: #f5f5f5;
}
.p-input-filled .p-radiobutton .p-radiobutton-box:not(.p-disabled):hover {
  background-color: #ececec;
}
.p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight {
  background: #ffffff;
}
.p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  background: #ffffff;
}

.p-radiobutton-label {
  margin-left: 0.5rem;
}

.p-rating .p-rating-icon {
  color: #1E88E5;
  margin-left: 0.5rem;
  transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
  font-size: 1.143rem;
}
.p-rating .p-rating-icon.p-rating-cancel {
  color: #B00020;
}
.p-rating .p-rating-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-rating .p-rating-icon:first-child {
  margin-left: 0;
}
.p-rating .p-rating-icon.pi-star {
  color: #1E88E5;
}
.p-rating:not(.p-disabled):not(.p-readonly) .p-rating-icon:hover {
  color: #1E88E5;
}
.p-rating:not(.p-disabled):not(.p-readonly) .p-rating-icon.p-rating-cancel:hover {
  color: #B00020;
}

.p-selectbutton .p-button {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.87);
  transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
}
.p-selectbutton .p-button .p-button-icon-left,
.p-selectbutton .p-button .p-button-icon-right {
  color: rgba(0, 0, 0, 0.6);
}
.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
  background: #f6f6f6;
  border-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.87);
}
.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left,
.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
  color: rgba(0, 0, 0, 0.6);
}
.p-selectbutton .p-button.p-highlight {
  background: #e0e0e1;
  border-color: #e0e0e1;
  color: rgba(0, 0, 0, 0.87);
}
.p-selectbutton .p-button.p-highlight .p-button-icon-left,
.p-selectbutton .p-button.p-highlight .p-button-icon-right {
  color: rgba(0, 0, 0, 0.6);
}
.p-selectbutton .p-button.p-highlight:hover {
  background: #d9d8d9;
  border-color: #d9d8d9;
  color: rgba(0, 0, 0, 0.87);
}
.p-selectbutton .p-button.p-highlight:hover .p-button-icon-left,
.p-selectbutton .p-button.p-highlight:hover .p-button-icon-right {
  color: rgba(0, 0, 0, 0.6);
}

p-selectbutton.ng-dirty.ng-invalid > .p-selectbutton > .p-button {
  border-color: #B00020;
}

.p-slider {
  background: #c1c1c1;
  border: 0 none;
  border-radius: 4px;
}
.p-slider.p-slider-horizontal {
  height: 2px;
}
.p-slider.p-slider-horizontal .p-slider-handle {
  margin-top: -10px;
  margin-left: -10px;
}
.p-slider.p-slider-vertical {
  width: 2px;
}
.p-slider.p-slider-vertical .p-slider-handle {
  margin-left: -10px;
  margin-bottom: -10px;
}
.p-slider .p-slider-handle {
  height: 20px;
  width: 20px;
  background: #1E88E5;
  border: 0 none;
  border-radius: 50%;
  transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
}
.p-slider .p-slider-handle:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-slider .p-slider-range {
  background: #1E88E5;
}
.p-slider:not(.p-disabled) .p-slider-handle:hover {
  background: #1E88E5;
  border-color: 0 none;
}
.p-slider.p-slider-animate.p-slider-horizontal .p-slider-handle {
  transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1), left 0.2s;
}
.p-slider.p-slider-animate.p-slider-horizontal .p-slider-range {
  transition: width 0.2s;
}
.p-slider.p-slider-animate.p-slider-vertical .p-slider-handle {
  transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1), bottom 0.2s;
}
.p-slider.p-slider-animate.p-slider-vertical .p-slider-range {
  transition: height 0.2s;
}

.p-togglebutton.p-button {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.87);
  transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
}
.p-togglebutton.p-button .p-button-icon-left,
.p-togglebutton.p-button .p-button-icon-right {
  color: rgba(0, 0, 0, 0.6);
}
.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover {
  background: #f6f6f6;
  border-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.87);
}
.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left,
.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
  color: rgba(0, 0, 0, 0.6);
}
.p-togglebutton.p-button.p-highlight {
  background: #e0e0e1;
  border-color: #e0e0e1;
  color: rgba(0, 0, 0, 0.87);
}
.p-togglebutton.p-button.p-highlight .p-button-icon-left,
.p-togglebutton.p-button.p-highlight .p-button-icon-right {
  color: rgba(0, 0, 0, 0.6);
}
.p-togglebutton.p-button.p-highlight:hover {
  background: #d9d8d9;
  border-color: #d9d8d9;
  color: rgba(0, 0, 0, 0.87);
}
.p-togglebutton.p-button.p-highlight:hover .p-button-icon-left,
.p-togglebutton.p-button.p-highlight:hover .p-button-icon-right {
  color: rgba(0, 0, 0, 0.6);
}

p-togglebutton.ng-dirty.ng-invalid > .p-togglebutton.p-button {
  border-color: #B00020;
}

.p-treeselect {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.38);
  transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
  border-radius: 4px;
}
.p-treeselect:not(.p-disabled):hover {
  border-color: rgba(0, 0, 0, 0.87);
}
.p-treeselect:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
  border-color: #1E88E5;
}
.p-treeselect .p-treeselect-label {
  padding: 1rem 1rem;
  transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
}
.p-treeselect .p-treeselect-label.p-placeholder {
  color: rgba(0, 0, 0, 0.6);
}
.p-treeselect.p-treeselect-chip .p-treeselect-token {
  padding: 0.5rem 1rem;
  margin-right: 0.5rem;
  background: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 16px;
}
.p-treeselect .p-treeselect-trigger {
  background: transparent;
  color: rgba(0, 0, 0, 0.6);
  width: 2.357rem;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.p-treeselect.p-invalid.p-component {
  border-color: #B00020;
}

.p-inputwrapper-filled .p-treeselect.p-treeselect-chip .p-treeselect-label {
  padding: 0.5rem 1rem;
}

.p-treeselect-panel {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border: 0 none;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.p-treeselect-panel .p-treeselect-items-wrapper .p-tree {
  border: 0 none;
}
.p-treeselect-panel .p-treeselect-items-wrapper .p-treeselect-empty-message {
  padding: 1rem 1rem;
  color: rgba(0, 0, 0, 0.87);
  background: transparent;
}

.p-input-filled .p-treeselect {
  background: #f5f5f5;
}
.p-input-filled .p-treeselect:not(.p-disabled):hover {
  background-color: #ececec;
}
.p-input-filled .p-treeselect:not(.p-disabled).p-focus {
  background-color: #dcdcdc;
}

.p-button {
  color: #ffffff;
  background: #1E88E5;
  border: 0 none;
  padding: 0.714rem 1rem;
  font-size: 1rem;
  transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
  border-radius: 4px;
}
.p-button:enabled:hover {
  background: rgba(30, 136, 229, 0.92);
  color: #ffffff;
  border-color: transparent;
}
.p-button:enabled:active {
  background: rgba(30, 136, 229, 0.68);
  color: #ffffff;
  border-color: transparent;
}
.p-button.p-button-outlined {
  background-color: transparent;
  color: #1E88E5;
  border: 0 none;
}
.p-button.p-button-outlined:enabled:hover {
  background: rgba(30, 136, 229, 0.04);
  color: #1E88E5;
  border: 0 none;
}
.p-button.p-button-outlined:enabled:active {
  background: rgba(30, 136, 229, 0.16);
  color: #1E88E5;
  border: 0 none;
}
.p-button.p-button-outlined.p-button-plain {
  color: rgba(0, 0, 0, 0.6);
  border-color: rgba(0, 0, 0, 0.6);
}
.p-button.p-button-outlined.p-button-plain:enabled:hover {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.6);
}
.p-button.p-button-outlined.p-button-plain:enabled:active {
  background: rgba(0, 0, 0, 0.16);
  color: rgba(0, 0, 0, 0.6);
}
.p-button.p-button-text {
  background-color: transparent;
  color: #1E88E5;
  border-color: transparent;
}
.p-button.p-button-text:enabled:hover {
  background: rgba(30, 136, 229, 0.04);
  color: #1E88E5;
  border-color: transparent;
}
.p-button.p-button-text:enabled:active {
  background: rgba(30, 136, 229, 0.16);
  color: #1E88E5;
  border-color: transparent;
}
.p-button.p-button-text.p-button-plain {
  color: rgba(0, 0, 0, 0.6);
}
.p-button.p-button-text.p-button-plain:enabled:hover {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.6);
}
.p-button.p-button-text.p-button-plain:enabled:active {
  background: rgba(0, 0, 0, 0.16);
  color: rgba(0, 0, 0, 0.6);
}
.p-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-button .p-button-icon-left {
  margin-right: 0.5rem;
}
.p-button .p-button-icon-right {
  margin-left: 0.5rem;
}
.p-button .p-button-icon-bottom {
  margin-top: 0.5rem;
}
.p-button .p-button-icon-top {
  margin-bottom: 0.5rem;
}
.p-button .p-badge {
  margin-left: 0.5rem;
  min-width: 1rem;
  height: 1rem;
  line-height: 1rem;
  color: #1E88E5;
  background-color: #ffffff;
}
.p-button.p-button-raised {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.p-button.p-button-rounded {
  border-radius: 2rem;
}
.p-button.p-button-icon-only {
  width: 3rem;
  padding: 0.714rem;
}
.p-button.p-button-icon-only .p-button-icon-left,
.p-button.p-button-icon-only .p-button-icon-right {
  margin: 0;
}
.p-button.p-button-icon-only.p-button-rounded {
  border-radius: 50%;
  height: 3rem;
}
.p-button.p-button-sm {
  font-size: 0.875rem;
  padding: 0.62475rem 0.875rem;
}
.p-button.p-button-sm .p-button-icon {
  font-size: 0.875rem;
}
.p-button.p-button-lg {
  font-size: 1.25rem;
  padding: 0.8925rem 1.25rem;
}
.p-button.p-button-lg .p-button-icon {
  font-size: 1.25rem;
}
.p-button.p-button-loading-label-only .p-button-label {
  margin-left: 0.5rem;
}
.p-button.p-button-loading-label-only .p-button-loading-icon {
  margin-right: 0;
}

.p-fluid .p-button {
  width: 100%;
}
.p-fluid .p-button-icon-only {
  width: 3rem;
}
.p-fluid .p-buttonset {
  display: flex;
}
.p-fluid .p-buttonset .p-button {
  flex: 1;
}

.p-button.p-button-secondary, .p-buttonset.p-button-secondary > .p-button, .p-splitbutton.p-button-secondary > .p-button {
  color: #212121;
  background: #FFB300;
  border: 0 none;
}
.p-button.p-button-secondary:enabled:hover, .p-buttonset.p-button-secondary > .p-button:enabled:hover, .p-splitbutton.p-button-secondary > .p-button:enabled:hover {
  background: rgba(255, 179, 0, 0.92);
  color: #212121;
  border-color: transparent;
}
.p-button.p-button-secondary:enabled:focus, .p-buttonset.p-button-secondary > .p-button:enabled:focus, .p-splitbutton.p-button-secondary > .p-button:enabled:focus {
  box-shadow: none;
}
.p-button.p-button-secondary:enabled:active, .p-buttonset.p-button-secondary > .p-button:enabled:active, .p-splitbutton.p-button-secondary > .p-button:enabled:active {
  background: rgba(255, 179, 0, 0.68);
  color: #212121;
  border-color: transparent;
}
.p-button.p-button-secondary.p-button-outlined, .p-buttonset.p-button-secondary > .p-button.p-button-outlined, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined {
  background-color: transparent;
  color: #FFB300;
  border: 0 none;
}
.p-button.p-button-secondary.p-button-outlined:enabled:hover, .p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:hover {
  background: rgba(255, 179, 0, 0.04);
  color: #FFB300;
  border: 0 none;
}
.p-button.p-button-secondary.p-button-outlined:enabled:active, .p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:active {
  background: rgba(255, 179, 0, 0.16);
  color: #FFB300;
  border: 0 none;
}
.p-button.p-button-secondary.p-button-text, .p-buttonset.p-button-secondary > .p-button.p-button-text, .p-splitbutton.p-button-secondary > .p-button.p-button-text {
  background-color: transparent;
  color: #FFB300;
  border-color: transparent;
}
.p-button.p-button-secondary.p-button-text:enabled:hover, .p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:hover {
  background: rgba(255, 179, 0, 0.04);
  border-color: transparent;
  color: #FFB300;
}
.p-button.p-button-secondary.p-button-text:enabled:active, .p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:active {
  background: rgba(255, 179, 0, 0.16);
  border-color: transparent;
  color: #FFB300;
}

.p-button.p-button-info, .p-buttonset.p-button-info > .p-button, .p-splitbutton.p-button-info > .p-button {
  color: #ffffff;
  background: #2196F3;
  border: 0 none;
}
.p-button.p-button-info:enabled:hover, .p-buttonset.p-button-info > .p-button:enabled:hover, .p-splitbutton.p-button-info > .p-button:enabled:hover {
  background: rgba(33, 150, 243, 0.92);
  color: #ffffff;
  border-color: transparent;
}
.p-button.p-button-info:enabled:focus, .p-buttonset.p-button-info > .p-button:enabled:focus, .p-splitbutton.p-button-info > .p-button:enabled:focus {
  box-shadow: none;
}
.p-button.p-button-info:enabled:active, .p-buttonset.p-button-info > .p-button:enabled:active, .p-splitbutton.p-button-info > .p-button:enabled:active {
  background: rgba(33, 150, 243, 0.68);
  color: #ffffff;
  border-color: transparent;
}
.p-button.p-button-info.p-button-outlined, .p-buttonset.p-button-info > .p-button.p-button-outlined, .p-splitbutton.p-button-info > .p-button.p-button-outlined {
  background-color: transparent;
  color: #2196F3;
  border: 0 none;
}
.p-button.p-button-info.p-button-outlined:enabled:hover, .p-buttonset.p-button-info > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-info > .p-button.p-button-outlined:enabled:hover {
  background: rgba(33, 150, 243, 0.04);
  color: #2196F3;
  border: 0 none;
}
.p-button.p-button-info.p-button-outlined:enabled:active, .p-buttonset.p-button-info > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-info > .p-button.p-button-outlined:enabled:active {
  background: rgba(33, 150, 243, 0.16);
  color: #2196F3;
  border: 0 none;
}
.p-button.p-button-info.p-button-text, .p-buttonset.p-button-info > .p-button.p-button-text, .p-splitbutton.p-button-info > .p-button.p-button-text {
  background-color: transparent;
  color: #2196F3;
  border-color: transparent;
}
.p-button.p-button-info.p-button-text:enabled:hover, .p-buttonset.p-button-info > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-info > .p-button.p-button-text:enabled:hover {
  background: rgba(33, 150, 243, 0.04);
  border-color: transparent;
  color: #2196F3;
}
.p-button.p-button-info.p-button-text:enabled:active, .p-buttonset.p-button-info > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-info > .p-button.p-button-text:enabled:active {
  background: rgba(33, 150, 243, 0.16);
  border-color: transparent;
  color: #2196F3;
}

.p-button.p-button-success, .p-buttonset.p-button-success > .p-button, .p-splitbutton.p-button-success > .p-button {
  color: #ffffff;
  background: #689F38;
  border: 0 none;
}
.p-button.p-button-success:enabled:hover, .p-buttonset.p-button-success > .p-button:enabled:hover, .p-splitbutton.p-button-success > .p-button:enabled:hover {
  background: rgba(104, 159, 56, 0.92);
  color: #ffffff;
  border-color: transparent;
}
.p-button.p-button-success:enabled:focus, .p-buttonset.p-button-success > .p-button:enabled:focus, .p-splitbutton.p-button-success > .p-button:enabled:focus {
  box-shadow: none;
}
.p-button.p-button-success:enabled:active, .p-buttonset.p-button-success > .p-button:enabled:active, .p-splitbutton.p-button-success > .p-button:enabled:active {
  background: rgba(104, 159, 56, 0.68);
  color: #ffffff;
  border-color: transparent;
}
.p-button.p-button-success.p-button-outlined, .p-buttonset.p-button-success > .p-button.p-button-outlined, .p-splitbutton.p-button-success > .p-button.p-button-outlined {
  background-color: transparent;
  color: #689F38;
  border: 0 none;
}
.p-button.p-button-success.p-button-outlined:enabled:hover, .p-buttonset.p-button-success > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-success > .p-button.p-button-outlined:enabled:hover {
  background: rgba(104, 159, 56, 0.04);
  color: #689F38;
  border: 0 none;
}
.p-button.p-button-success.p-button-outlined:enabled:active, .p-buttonset.p-button-success > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-success > .p-button.p-button-outlined:enabled:active {
  background: rgba(104, 159, 56, 0.16);
  color: #689F38;
  border: 0 none;
}
.p-button.p-button-success.p-button-text, .p-buttonset.p-button-success > .p-button.p-button-text, .p-splitbutton.p-button-success > .p-button.p-button-text {
  background-color: transparent;
  color: #689F38;
  border-color: transparent;
}
.p-button.p-button-success.p-button-text:enabled:hover, .p-buttonset.p-button-success > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-success > .p-button.p-button-text:enabled:hover {
  background: rgba(104, 159, 56, 0.04);
  border-color: transparent;
  color: #689F38;
}
.p-button.p-button-success.p-button-text:enabled:active, .p-buttonset.p-button-success > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-success > .p-button.p-button-text:enabled:active {
  background: rgba(104, 159, 56, 0.16);
  border-color: transparent;
  color: #689F38;
}

.p-button.p-button-warning, .p-buttonset.p-button-warning > .p-button, .p-splitbutton.p-button-warning > .p-button {
  color: #212529;
  background: #FBC02D;
  border: 0 none;
}
.p-button.p-button-warning:enabled:hover, .p-buttonset.p-button-warning > .p-button:enabled:hover, .p-splitbutton.p-button-warning > .p-button:enabled:hover {
  background: rgba(251, 192, 45, 0.92);
  color: #212529;
  border-color: transparent;
}
.p-button.p-button-warning:enabled:focus, .p-buttonset.p-button-warning > .p-button:enabled:focus, .p-splitbutton.p-button-warning > .p-button:enabled:focus {
  box-shadow: none;
}
.p-button.p-button-warning:enabled:active, .p-buttonset.p-button-warning > .p-button:enabled:active, .p-splitbutton.p-button-warning > .p-button:enabled:active {
  background: rgba(251, 192, 45, 0.68);
  color: #212529;
  border-color: transparent;
}
.p-button.p-button-warning.p-button-outlined, .p-buttonset.p-button-warning > .p-button.p-button-outlined, .p-splitbutton.p-button-warning > .p-button.p-button-outlined {
  background-color: transparent;
  color: #FBC02D;
  border: 0 none;
}
.p-button.p-button-warning.p-button-outlined:enabled:hover, .p-buttonset.p-button-warning > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-warning > .p-button.p-button-outlined:enabled:hover {
  background: rgba(251, 192, 45, 0.04);
  color: #FBC02D;
  border: 0 none;
}
.p-button.p-button-warning.p-button-outlined:enabled:active, .p-buttonset.p-button-warning > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-warning > .p-button.p-button-outlined:enabled:active {
  background: rgba(251, 192, 45, 0.16);
  color: #FBC02D;
  border: 0 none;
}
.p-button.p-button-warning.p-button-text, .p-buttonset.p-button-warning > .p-button.p-button-text, .p-splitbutton.p-button-warning > .p-button.p-button-text {
  background-color: transparent;
  color: #FBC02D;
  border-color: transparent;
}
.p-button.p-button-warning.p-button-text:enabled:hover, .p-buttonset.p-button-warning > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-warning > .p-button.p-button-text:enabled:hover {
  background: rgba(251, 192, 45, 0.04);
  border-color: transparent;
  color: #FBC02D;
}
.p-button.p-button-warning.p-button-text:enabled:active, .p-buttonset.p-button-warning > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-warning > .p-button.p-button-text:enabled:active {
  background: rgba(251, 192, 45, 0.16);
  border-color: transparent;
  color: #FBC02D;
}

.p-button.p-button-help, .p-buttonset.p-button-help > .p-button, .p-splitbutton.p-button-help > .p-button {
  color: #ffffff;
  background: #9C27B0;
  border: 0 none;
}
.p-button.p-button-help:enabled:hover, .p-buttonset.p-button-help > .p-button:enabled:hover, .p-splitbutton.p-button-help > .p-button:enabled:hover {
  background: rgba(156, 39, 176, 0.92);
  color: #ffffff;
  border-color: transparent;
}
.p-button.p-button-help:enabled:focus, .p-buttonset.p-button-help > .p-button:enabled:focus, .p-splitbutton.p-button-help > .p-button:enabled:focus {
  box-shadow: none;
}
.p-button.p-button-help:enabled:active, .p-buttonset.p-button-help > .p-button:enabled:active, .p-splitbutton.p-button-help > .p-button:enabled:active {
  background: rgba(156, 39, 176, 0.68);
  color: #ffffff;
  border-color: transparent;
}
.p-button.p-button-help.p-button-outlined, .p-buttonset.p-button-help > .p-button.p-button-outlined, .p-splitbutton.p-button-help > .p-button.p-button-outlined {
  background-color: transparent;
  color: #9C27B0;
  border: 0 none;
}
.p-button.p-button-help.p-button-outlined:enabled:hover, .p-buttonset.p-button-help > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-help > .p-button.p-button-outlined:enabled:hover {
  background: rgba(156, 39, 176, 0.04);
  color: #9C27B0;
  border: 0 none;
}
.p-button.p-button-help.p-button-outlined:enabled:active, .p-buttonset.p-button-help > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-help > .p-button.p-button-outlined:enabled:active {
  background: rgba(156, 39, 176, 0.16);
  color: #9C27B0;
  border: 0 none;
}
.p-button.p-button-help.p-button-text, .p-buttonset.p-button-help > .p-button.p-button-text, .p-splitbutton.p-button-help > .p-button.p-button-text {
  background-color: transparent;
  color: #9C27B0;
  border-color: transparent;
}
.p-button.p-button-help.p-button-text:enabled:hover, .p-buttonset.p-button-help > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-help > .p-button.p-button-text:enabled:hover {
  background: rgba(156, 39, 176, 0.04);
  border-color: transparent;
  color: #9C27B0;
}
.p-button.p-button-help.p-button-text:enabled:active, .p-buttonset.p-button-help > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-help > .p-button.p-button-text:enabled:active {
  background: rgba(156, 39, 176, 0.16);
  border-color: transparent;
  color: #9C27B0;
}

.p-button.p-button-danger, .p-buttonset.p-button-danger > .p-button, .p-splitbutton.p-button-danger > .p-button {
  color: #ffffff;
  background: #D32F2F;
  border: 0 none;
}
.p-button.p-button-danger:enabled:hover, .p-buttonset.p-button-danger > .p-button:enabled:hover, .p-splitbutton.p-button-danger > .p-button:enabled:hover {
  background: rgba(211, 47, 47, 0.92);
  color: #ffffff;
  border-color: transparent;
}
.p-button.p-button-danger:enabled:focus, .p-buttonset.p-button-danger > .p-button:enabled:focus, .p-splitbutton.p-button-danger > .p-button:enabled:focus {
  box-shadow: none;
}
.p-button.p-button-danger:enabled:active, .p-buttonset.p-button-danger > .p-button:enabled:active, .p-splitbutton.p-button-danger > .p-button:enabled:active {
  background: rgba(211, 47, 47, 0.68);
  color: #ffffff;
  border-color: transparent;
}
.p-button.p-button-danger.p-button-outlined, .p-buttonset.p-button-danger > .p-button.p-button-outlined, .p-splitbutton.p-button-danger > .p-button.p-button-outlined {
  background-color: transparent;
  color: #D32F2F;
  border: 0 none;
}
.p-button.p-button-danger.p-button-outlined:enabled:hover, .p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:hover {
  background: rgba(211, 47, 47, 0.04);
  color: #D32F2F;
  border: 0 none;
}
.p-button.p-button-danger.p-button-outlined:enabled:active, .p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:active {
  background: rgba(211, 47, 47, 0.16);
  color: #D32F2F;
  border: 0 none;
}
.p-button.p-button-danger.p-button-text, .p-buttonset.p-button-danger > .p-button.p-button-text, .p-splitbutton.p-button-danger > .p-button.p-button-text {
  background-color: transparent;
  color: #D32F2F;
  border-color: transparent;
}
.p-button.p-button-danger.p-button-text:enabled:hover, .p-buttonset.p-button-danger > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:hover {
  background: rgba(211, 47, 47, 0.04);
  border-color: transparent;
  color: #D32F2F;
}
.p-button.p-button-danger.p-button-text:enabled:active, .p-buttonset.p-button-danger > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:active {
  background: rgba(211, 47, 47, 0.16);
  border-color: transparent;
  color: #D32F2F;
}

.p-button.p-button-link {
  color: #1E88E5;
  background: transparent;
  border: transparent;
}
.p-button.p-button-link:enabled:hover {
  background: transparent;
  color: #1E88E5;
  border-color: transparent;
}
.p-button.p-button-link:enabled:hover .p-button-label {
  text-decoration: underline;
}
.p-button.p-button-link:enabled:focus {
  background: transparent;
  box-shadow: none;
  border-color: transparent;
}
.p-button.p-button-link:enabled:active {
  background: transparent;
  color: #1E88E5;
  border-color: transparent;
}

.p-speeddial-button.p-button.p-button-icon-only {
  width: 4rem;
  height: 4rem;
}
.p-speeddial-button.p-button.p-button-icon-only .p-button-icon {
  font-size: 2rem;
}

.p-speeddial-action {
  width: 3rem;
  height: 3rem;
  background: #FFB300;
  color: #fff;
}
.p-speeddial-action:hover {
  background: rgba(255, 179, 0, 0.92);
  color: #fff;
}

.p-speeddial-direction-up .p-speeddial-item {
  margin: 0.25rem 0;
}
.p-speeddial-direction-up .p-speeddial-item:first-child {
  margin-bottom: 0.5rem;
}

.p-speeddial-direction-down .p-speeddial-item {
  margin: 0.25rem 0;
}
.p-speeddial-direction-down .p-speeddial-item:first-child {
  margin-top: 0.5rem;
}

.p-speeddial-direction-left .p-speeddial-item {
  margin: 0 0.25rem;
}
.p-speeddial-direction-left .p-speeddial-item:first-child {
  margin-right: 0.5rem;
}

.p-speeddial-direction-right .p-speeddial-item {
  margin: 0 0.25rem;
}
.p-speeddial-direction-right .p-speeddial-item:first-child {
  margin-left: 0.5rem;
}

.p-speeddial-circle .p-speeddial-item,
.p-speeddial-semi-circle .p-speeddial-item,
.p-speeddial-quarter-circle .p-speeddial-item {
  margin: 0;
}
.p-speeddial-circle .p-speeddial-item:first-child, .p-speeddial-circle .p-speeddial-item:last-child,
.p-speeddial-semi-circle .p-speeddial-item:first-child,
.p-speeddial-semi-circle .p-speeddial-item:last-child,
.p-speeddial-quarter-circle .p-speeddial-item:first-child,
.p-speeddial-quarter-circle .p-speeddial-item:last-child {
  margin: 0;
}

.p-speeddial-mask {
  background-color: rgba(0, 0, 0, 0.32);
}

.p-carousel .p-carousel-content .p-carousel-prev,
.p-carousel .p-carousel-content .p-carousel-next {
  width: 2.5rem;
  height: 2.5rem;
  color: rgba(0, 0, 0, 0.6);
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  margin: 0.5rem;
}
.p-carousel .p-carousel-content .p-carousel-prev:enabled:hover,
.p-carousel .p-carousel-content .p-carousel-next:enabled:hover {
  color: rgba(0, 0, 0, 0.6);
  border-color: transparent;
  background: rgba(0, 0, 0, 0.04);
}
.p-carousel .p-carousel-content .p-carousel-prev:focus,
.p-carousel .p-carousel-content .p-carousel-next:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-carousel .p-carousel-indicators {
  padding: 1rem;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator {
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator button {
  background-color: #dcdcdc;
  width: 2rem;
  height: 0.5rem;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 0;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator button:hover {
  background: #ececec;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
  background: rgba(30, 136, 229, 0.12);
  color: #1E88E5;
}

.p-datatable .p-paginator-top {
  border-width: 0 0 1px 0;
  border-radius: 0;
}
.p-datatable .p-paginator-bottom {
  border-width: 0 0 1px 0;
  border-radius: 0;
}
.p-datatable .p-datatable-header {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #e4e4e4;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 500;
}
.p-datatable .p-datatable-footer {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #e4e4e4;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 500;
}
.p-datatable .p-datatable-thead > tr > th {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #e4e4e4;
  border-width: 0 0 1px 0;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.87);
  background: #ffffff;
  transition: none;
}
.p-datatable .p-datatable-tfoot > tr > td {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #e4e4e4;
  border-width: 0 0 1px 0;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.87);
  background: #ffffff;
}
.p-datatable .p-sortable-column .p-sortable-column-icon {
  color: rgba(0, 0, 0, 0.6);
  margin-left: 0.5rem;
}
.p-datatable .p-sortable-column .p-sortable-column-badge {
  border-radius: 50%;
  height: 1.143rem;
  min-width: 1.143rem;
  line-height: 1.143rem;
  color: #1E88E5;
  background: rgba(30, 136, 229, 0.12);
  margin-left: 0.5rem;
}
.p-datatable .p-sortable-column:not(.p-highlight):hover {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.p-datatable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
  color: rgba(0, 0, 0, 0.6);
}
.p-datatable .p-sortable-column.p-highlight {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
}
.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: rgba(0, 0, 0, 0.87);
}
.p-datatable .p-sortable-column.p-highlight:hover {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.p-datatable .p-sortable-column.p-highlight:hover .p-sortable-column-icon {
  color: rgba(0, 0, 0, 0.87);
}
.p-datatable .p-sortable-column:focus {
  box-shadow: none;
  outline: 0 none;
}
.p-datatable .p-datatable-tbody > tr {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  transition: none;
  outline-color: transparent;
}
.p-datatable .p-datatable-tbody > tr > td {
  text-align: left;
  border: 1px solid #e4e4e4;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
}
.p-datatable .p-datatable-tbody > tr > td .p-row-toggler,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel {
  width: 2.5rem;
  height: 2.5rem;
  color: rgba(0, 0, 0, 0.6);
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:enabled:hover {
  color: rgba(0, 0, 0, 0.6);
  border-color: transparent;
  background: rgba(0, 0, 0, 0.04);
}
.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save {
  margin-right: 0.5rem;
}
.p-datatable .p-datatable-tbody > tr.p-highlight {
  background: rgba(30, 136, 229, 0.12);
  color: #1E88E5;
}
.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-top > td {
  box-shadow: inset 0 2px 0 0 rgba(30, 136, 229, 0.12);
}
.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
  box-shadow: inset 0 -2px 0 0 rgba(30, 136, 229, 0.12);
}
.p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.p-datatable .p-column-resizer-helper {
  background: #1E88E5;
}
.p-datatable .p-datatable-scrollable-header,
.p-datatable .p-datatable-scrollable-footer {
  background: #ffffff;
}
.p-datatable .p-datatable-loading-icon {
  font-size: 2rem;
}
.p-datatable.p-datatable-gridlines .p-datatable-header {
  border-width: 1px 1px 0 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-footer {
  border-width: 0 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-paginator-top {
  border-width: 0 1px 0 1px;
}
.p-datatable.p-datatable-gridlines .p-paginator-bottom {
  border-width: 0 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th {
  border-width: 1px 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td {
  border-width: 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-tfoot > tr > td {
  border-width: 1px;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even) {
  background: rgba(0, 0, 0, 0.02);
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight {
  background: rgba(30, 136, 229, 0.12);
  color: #1E88E5;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight .p-row-toggler {
  color: #1E88E5;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight .p-row-toggler:hover {
  color: #1E88E5;
}
.p-datatable.p-datatable-sm .p-datatable-header {
  padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-sm .p-datatable-thead > tr > th {
  padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
  padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-sm .p-datatable-tfoot > tr > td {
  padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-sm .p-datatable-footer {
  padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-lg .p-datatable-header {
  padding: 1.25rem 1.25rem;
}
.p-datatable.p-datatable-lg .p-datatable-thead > tr > th {
  padding: 1.25rem 1.25rem;
}
.p-datatable.p-datatable-lg .p-datatable-tbody > tr > td {
  padding: 1.25rem 1.25rem;
}
.p-datatable.p-datatable-lg .p-datatable-tfoot > tr > td {
  padding: 1.25rem 1.25rem;
}
.p-datatable.p-datatable-lg .p-datatable-footer {
  padding: 1.25rem 1.25rem;
}

.p-dataview .p-paginator-top {
  border-width: 0 0 1px 0;
  border-radius: 0;
}
.p-dataview .p-paginator-bottom {
  border-width: 0 0 1px 0;
  border-radius: 0;
}
.p-dataview .p-dataview-header {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #e4e4e4;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 500;
}
.p-dataview .p-dataview-content {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border: 0 none;
  padding: 1rem 0;
}
.p-dataview.p-dataview-list .p-dataview-content > .p-grid > div {
  border: solid rgba(0, 0, 0, 0.12);
  border-width: 0 0 1px 0;
}
.p-dataview .p-dataview-footer {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #e4e4e4;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 500;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.p-dataview .p-dataview-loading-icon {
  font-size: 2rem;
}
.p-dataview .p-dataview-emptymessage {
  padding: 1rem;
}

.p-column-filter-row .p-column-filter-menu-button,
.p-column-filter-row .p-column-filter-clear-button {
  margin-left: 0.5rem;
}

.p-column-filter-menu-button {
  width: 2.5rem;
  height: 2.5rem;
  color: rgba(0, 0, 0, 0.6);
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-column-filter-menu-button:hover {
  color: rgba(0, 0, 0, 0.6);
  border-color: transparent;
  background: rgba(0, 0, 0, 0.04);
}
.p-column-filter-menu-button.p-column-filter-menu-button-open, .p-column-filter-menu-button.p-column-filter-menu-button-open:hover {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.6);
}
.p-column-filter-menu-button.p-column-filter-menu-button-active, .p-column-filter-menu-button.p-column-filter-menu-button-active:hover {
  background: rgba(30, 136, 229, 0.12);
  color: #1E88E5;
}
.p-column-filter-menu-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-column-filter-clear-button {
  width: 2.5rem;
  height: 2.5rem;
  color: rgba(0, 0, 0, 0.6);
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-column-filter-clear-button:hover {
  color: rgba(0, 0, 0, 0.6);
  border-color: transparent;
  background: rgba(0, 0, 0, 0.04);
}
.p-column-filter-clear-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-column-filter-overlay {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border: 0 none;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  min-width: 12.5rem;
}
.p-column-filter-overlay .p-column-filter-row-items {
  padding: 0;
}
.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item {
  margin: 0;
  padding: 1rem 1rem;
  border: 0 none;
  color: rgba(0, 0, 0, 0.87);
  background: transparent;
  transition: none;
  border-radius: 0;
}
.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item.p-highlight {
  color: #1E88E5;
  background: rgba(30, 136, 229, 0.12);
}
.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:not(.p-highlight):not(.p-disabled):hover {
  color: rgba(0, 0, 0, 0.87);
  background: rgba(0, 0, 0, 0.04);
}
.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-separator {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  margin: 0.5rem 0;
}

.p-column-filter-overlay-menu .p-column-filter-operator {
  padding: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.87);
  background: #ffffff;
  margin: 0;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.p-column-filter-overlay-menu .p-column-filter-constraint {
  padding: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-matchmode-dropdown {
  margin-bottom: 0.5rem;
}
.p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-remove-button {
  margin-top: 0.5rem;
}
.p-column-filter-overlay-menu .p-column-filter-constraint:last-child {
  border-bottom: 0 none;
}
.p-column-filter-overlay-menu .p-column-filter-add-rule {
  padding: 1rem 1rem;
}
.p-column-filter-overlay-menu .p-column-filter-buttonbar {
  padding: 1rem;
}

.fc {
  /* FullCalendar 4 */
  /* FullCalendar 5 */
}
.fc.fc-unthemed .fc-view-container th {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.fc.fc-unthemed .fc-view-container td.fc-widget-content {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.fc.fc-unthemed .fc-view-container td.fc-head-container {
  border: 1px solid #e0e0e0;
}
.fc.fc-unthemed .fc-view-container .fc-row {
  border-right: 1px solid #e0e0e0;
}
.fc.fc-unthemed .fc-view-container .fc-event {
  background: rgba(30, 136, 229, 0.12);
  border: 1px solid rgba(30, 136, 229, 0.12);
  color: #1E88E5;
}
.fc.fc-unthemed .fc-view-container .fc-divider {
  background: #ffffff;
  border: 1px solid #e0e0e0;
}
.fc.fc-unthemed .fc-toolbar .fc-button {
  color: #ffffff;
  background: #1E88E5;
  border: 0 none;
  font-size: 1rem;
  transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
  border-radius: 4px;
  display: flex;
  align-items: center;
}
.fc.fc-unthemed .fc-toolbar .fc-button:enabled:hover {
  background: rgba(30, 136, 229, 0.92);
  color: #ffffff;
  border-color: transparent;
}
.fc.fc-unthemed .fc-toolbar .fc-button:enabled:active {
  background: rgba(30, 136, 229, 0.68);
  color: #ffffff;
  border-color: transparent;
}
.fc.fc-unthemed .fc-toolbar .fc-button:enabled:active:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-left {
  font-family: "PrimeIcons" !important;
  text-indent: 0;
  font-size: 1rem;
}
.fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-left:before {
  content: "";
}
.fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-right {
  font-family: "PrimeIcons" !important;
  text-indent: 0;
  font-size: 1rem;
}
.fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-right:before {
  content: "";
}
.fc.fc-unthemed .fc-toolbar .fc-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.87);
  transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
}
.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button:hover, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button:hover, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button:hover {
  background: #f6f6f6;
  border-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.87);
}
.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active {
  background: #e0e0e1;
  border-color: #e0e0e1;
  color: rgba(0, 0, 0, 0.87);
}
.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active:hover, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active:hover, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active:hover {
  background: #d9d8d9;
  border-color: #d9d8d9;
  color: rgba(0, 0, 0, 0.87);
}
.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button:focus, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button:focus, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
  z-index: 1;
}
.fc.fc-unthemed .fc-toolbar .fc-button-group .fc-button {
  border-radius: 0;
}
.fc.fc-unthemed .fc-toolbar .fc-button-group .fc-button:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.fc.fc-unthemed .fc-toolbar .fc-button-group .fc-button:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.fc.fc-theme-standard .fc-view-harness .fc-scrollgrid {
  border-color: #e0e0e0;
}
.fc.fc-theme-standard .fc-view-harness th {
  background: #ffffff;
  border-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.fc.fc-theme-standard .fc-view-harness td {
  color: rgba(0, 0, 0, 0.87);
  border-color: #e0e0e0;
}
.fc.fc-theme-standard .fc-view-harness .fc-view {
  background: #ffffff;
}
.fc.fc-theme-standard .fc-view-harness .fc-popover {
  background: none;
  border: 0 none;
}
.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header {
  border: 1px solid #e0e0e0;
  padding: 1rem;
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
}
.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header .fc-popover-close {
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  font-family: "PrimeIcons" !important;
  font-size: 1rem;
  width: 2.5rem;
  height: 2.5rem;
  color: rgba(0, 0, 0, 0.6);
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header .fc-popover-close:before {
  content: "";
}
.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header .fc-popover-close:enabled:hover {
  color: rgba(0, 0, 0, 0.6);
  border-color: transparent;
  background: rgba(0, 0, 0, 0.04);
}
.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header .fc-popover-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-body {
  padding: 1rem;
  border: 1px solid #e0e0e0;
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border-top: 0 none;
}
.fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-block-event {
  color: #1E88E5;
  background: rgba(30, 136, 229, 0.12);
  border-color: rgba(30, 136, 229, 0.12);
}
.fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-block-event .fc-event-main {
  color: #1E88E5;
}
.fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-dot-event .fc-daygrid-event-dot {
  background: rgba(30, 136, 229, 0.12);
  border-color: rgba(30, 136, 229, 0.12);
}
.fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-dot-event:hover {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.fc.fc-theme-standard .fc-view-harness .fc-cell-shaded {
  background: #ffffff;
}
.fc.fc-theme-standard .fc-toolbar .fc-button {
  color: #ffffff;
  background: #1E88E5;
  border: 0 none;
  font-size: 1rem;
  transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
  border-radius: 4px;
}
.fc.fc-theme-standard .fc-toolbar .fc-button:enabled:hover {
  background: rgba(30, 136, 229, 0.92);
  color: #ffffff;
  border-color: transparent;
}
.fc.fc-theme-standard .fc-toolbar .fc-button:enabled:active {
  background: rgba(30, 136, 229, 0.68);
  color: #ffffff;
  border-color: transparent;
}
.fc.fc-theme-standard .fc-toolbar .fc-button:enabled:active:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.fc.fc-theme-standard .fc-toolbar .fc-button:disabled {
  opacity: 0.38;
  color: #ffffff;
  background: #1E88E5;
  border: 0 none;
}
.fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-left {
  font-family: "PrimeIcons" !important;
  text-indent: 0;
  font-size: 1rem;
}
.fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-left:before {
  content: "";
}
.fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-right {
  font-family: "PrimeIcons" !important;
  text-indent: 0;
  font-size: 1rem;
}
.fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-right:before {
  content: "";
}
.fc.fc-theme-standard .fc-toolbar .fc-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.87);
  transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
}
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button:hover, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button:hover, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button:hover {
  background: #f6f6f6;
  border-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.87);
}
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active {
  background: #e0e0e1;
  border-color: #e0e0e1;
  color: rgba(0, 0, 0, 0.87);
}
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active:hover, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active:hover, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active:hover {
  background: #d9d8d9;
  border-color: #d9d8d9;
  color: rgba(0, 0, 0, 0.87);
}
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button:not(:disabled):focus, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button:not(:disabled):focus, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button:not(:disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
  z-index: 1;
}
.fc.fc-theme-standard .fc-toolbar .fc-button-group .fc-button {
  border-radius: 0;
}
.fc.fc-theme-standard .fc-toolbar .fc-button-group .fc-button:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.fc.fc-theme-standard .fc-toolbar .fc-button-group .fc-button:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.fc.fc-theme-standard a {
  color: rgba(0, 0, 0, 0.87);
}

.p-orderlist .p-orderlist-controls {
  padding: 1rem;
}
.p-orderlist .p-orderlist-controls .p-button {
  margin-bottom: 0.5rem;
}
.p-orderlist .p-orderlist-header {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #e0e0e0;
  padding: 1rem;
  border-bottom: 0 none;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.p-orderlist .p-orderlist-header .p-orderlist-title {
  font-weight: 500;
}
.p-orderlist .p-orderlist-filter-container {
  padding: 1rem;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-bottom: 0 none;
}
.p-orderlist .p-orderlist-filter-container .p-orderlist-filter-input {
  padding-right: 2rem;
}
.p-orderlist .p-orderlist-filter-container .p-orderlist-filter-icon {
  right: 1rem;
  color: rgba(0, 0, 0, 0.6);
}
.p-orderlist .p-orderlist-list {
  border: 1px solid #e0e0e0;
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  padding: 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.p-orderlist .p-orderlist-list .p-orderlist-item {
  padding: 1rem 1rem;
  margin: 0;
  border: 0 none;
  color: rgba(0, 0, 0, 0.87);
  background: transparent;
  transition: none;
}
.p-orderlist .p-orderlist-list .p-orderlist-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight {
  color: #1E88E5;
  background: rgba(30, 136, 229, 0.12);
}
.p-orderlist .p-orderlist-list .p-orderlist-empty-message {
  padding: 1rem 1rem;
  color: rgba(0, 0, 0, 0.87);
}
.p-orderlist .p-orderlist-list:not(.cdk-drop-list-dragging) .p-orderlist-item:not(.p-highlight):hover {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}

.p-orderlist-item.cdk-drag-preview {
  padding: 1rem 1rem;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  border: 0 none;
  color: rgba(0, 0, 0, 0.87);
  background: #ffffff;
  margin: 0;
}

.p-organizationchart .p-organizationchart-node-content.p-organizationchart-selectable-node:not(.p-highlight):hover {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.p-organizationchart .p-organizationchart-node-content.p-highlight {
  background: rgba(30, 136, 229, 0.12);
  color: #1E88E5;
}
.p-organizationchart .p-organizationchart-node-content.p-highlight .p-node-toggler i {
  color: rgba(14, 69, 118, 0.12);
}
.p-organizationchart .p-organizationchart-line-down {
  background: rgba(0, 0, 0, 0.12);
}
.p-organizationchart .p-organizationchart-line-left {
  border-right: 1px solid #e0e0e0;
  border-color: rgba(0, 0, 0, 0.12);
}
.p-organizationchart .p-organizationchart-line-top {
  border-top: 1px solid #e0e0e0;
  border-color: rgba(0, 0, 0, 0.12);
}
.p-organizationchart .p-organizationchart-node-content {
  border: 1px solid #e0e0e0;
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  padding: 1rem;
}
.p-organizationchart .p-organizationchart-node-content .p-node-toggler {
  background: inherit;
  color: inherit;
  border-radius: 50%;
}
.p-organizationchart .p-organizationchart-node-content .p-node-toggler:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-paginator {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border: solid #e4e4e4;
  border-width: 0;
  padding: 0.5rem 1rem;
  border-radius: 4px;
}
.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
  background-color: transparent;
  border: 0 none;
  color: rgba(0, 0, 0, 0.6);
  min-width: 3rem;
  height: 3rem;
  margin: 0.143rem;
  transition: none;
  border-radius: 50%;
}
.p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
  background: rgba(0, 0, 0, 0.04);
  border-color: transparent;
  color: rgba(0, 0, 0, 0.6);
}
.p-paginator .p-paginator-first {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.p-paginator .p-paginator-last {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.p-paginator .p-dropdown {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  height: 3rem;
}
.p-paginator .p-dropdown .p-dropdown-label {
  padding-right: 0;
}
.p-paginator .p-paginator-page-input {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.p-paginator .p-paginator-page-input .p-inputtext {
  max-width: 3rem;
}
.p-paginator .p-paginator-current {
  background-color: transparent;
  border: 0 none;
  color: rgba(0, 0, 0, 0.6);
  min-width: 3rem;
  height: 3rem;
  margin: 0.143rem;
  padding: 0 0.5rem;
}
.p-paginator .p-paginator-pages .p-paginator-page {
  background-color: transparent;
  border: 0 none;
  color: rgba(0, 0, 0, 0.6);
  min-width: 3rem;
  height: 3rem;
  margin: 0.143rem;
  transition: none;
  border-radius: 50%;
}
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: rgba(30, 136, 229, 0.12);
  border-color: rgba(30, 136, 229, 0.12);
  color: #1E88E5;
}
.p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
  background: rgba(0, 0, 0, 0.04);
  border-color: transparent;
  color: rgba(0, 0, 0, 0.6);
}

.p-picklist .p-picklist-buttons {
  padding: 1rem;
}
.p-picklist .p-picklist-buttons .p-button {
  margin-bottom: 0.5rem;
}
.p-picklist .p-picklist-header {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #e0e0e0;
  padding: 1rem;
  border-bottom: 0 none;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.p-picklist .p-picklist-header .p-picklist-title {
  font-weight: 500;
}
.p-picklist .p-picklist-filter-container {
  padding: 1rem;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-bottom: 0 none;
}
.p-picklist .p-picklist-filter-container .p-picklist-filter-input {
  padding-right: 2rem;
}
.p-picklist .p-picklist-filter-container .p-picklist-filter-icon {
  right: 1rem;
  color: rgba(0, 0, 0, 0.6);
}
.p-picklist .p-picklist-list {
  border: 1px solid #e0e0e0;
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  padding: 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.p-picklist .p-picklist-list .p-picklist-item {
  padding: 1rem 1rem;
  margin: 0;
  border: 0 none;
  color: rgba(0, 0, 0, 0.87);
  background: transparent;
  transition: none;
}
.p-picklist .p-picklist-list .p-picklist-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-picklist .p-picklist-list .p-picklist-item.p-highlight {
  color: #1E88E5;
  background: rgba(30, 136, 229, 0.12);
}
.p-picklist .p-picklist-list .p-picklist-empty-message {
  padding: 1rem 1rem;
  color: rgba(0, 0, 0, 0.87);
}
.p-picklist .p-picklist-list:not(.cdk-drop-list-dragging) .p-picklist-item:not(.p-highlight):hover {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}

.p-picklist-item.cdk-drag-preview {
  padding: 1rem 1rem;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  border: 0 none;
  color: rgba(0, 0, 0, 0.87);
  background: #ffffff;
  margin: 0;
}

.p-timeline .p-timeline-event-marker {
  border: 0 none;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  background-color: #bdbdbd;
}
.p-timeline .p-timeline-event-connector {
  background-color: #bdbdbd;
}
.p-timeline.p-timeline-vertical .p-timeline-event-opposite,
.p-timeline.p-timeline-vertical .p-timeline-event-content {
  padding: 0 1rem;
}
.p-timeline.p-timeline-vertical .p-timeline-event-connector {
  width: 2px;
}
.p-timeline.p-timeline-horizontal .p-timeline-event-opposite,
.p-timeline.p-timeline-horizontal .p-timeline-event-content {
  padding: 1rem 0;
}
.p-timeline.p-timeline-horizontal .p-timeline-event-connector {
  height: 2px;
}

.p-tree {
  border: 1px solid #e0e0e0;
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  padding: 1rem;
  border-radius: 4px;
}
.p-tree .p-tree-container .p-treenode {
  padding: 0.25rem;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content {
  border-radius: 4px;
  transition: none;
  padding: 0.75rem;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
  margin-right: 0.5rem;
  width: 2.5rem;
  height: 2.5rem;
  color: rgba(0, 0, 0, 0.6);
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:enabled:hover {
  color: rgba(0, 0, 0, 0.6);
  border-color: transparent;
  background: rgba(0, 0, 0, 0.04);
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-icon {
  margin-right: 0.5rem;
  color: rgba(0, 0, 0, 0.6);
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox {
  margin-right: 0.5rem;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox .p-indeterminate .p-checkbox-icon {
  color: rgba(0, 0, 0, 0.87);
}
.p-tree .p-tree-container .p-treenode .p-treenode-content:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
  background: rgba(30, 136, 229, 0.12);
  color: #1E88E5;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler,
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
  color: #1E88E5;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler:hover,
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon:hover {
  color: #1E88E5;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-dragover {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.p-tree .p-tree-filter-container {
  margin-bottom: 0.5rem;
}
.p-tree .p-tree-filter-container .p-tree-filter {
  width: 100%;
  padding-right: 2rem;
}
.p-tree .p-tree-filter-container .p-tree-filter-icon {
  right: 1rem;
  color: rgba(0, 0, 0, 0.6);
}
.p-tree .p-treenode-children {
  padding: 0 0 0 1rem;
}
.p-tree .p-tree-loading-icon {
  font-size: 2rem;
}
.p-tree .p-treenode-droppoint.p-treenode-droppoint-active {
  background-color: rgba(21, 109, 186, 0.12);
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content {
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  background-color: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  padding: 0.75rem;
  transition: none;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-highlight {
  background-color: rgba(30, 136, 229, 0.12);
  color: #1E88E5;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
  color: #1E88E5;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-tree-toggler {
  margin-right: 0.5rem;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-treenode-icon {
  color: rgba(0, 0, 0, 0.6);
  margin-right: 0.5rem;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-checkbox {
  margin-right: 0.5rem;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-treenode-label:not(.p-highlight):hover {
  background-color: inherit;
  color: inherit;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-treetable .p-paginator-top {
  border-width: 0 0 1px 0;
  border-radius: 0;
}
.p-treetable .p-paginator-bottom {
  border-width: 0 0 1px 0;
  border-radius: 0;
}
.p-treetable .p-treetable-header {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #e4e4e4;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 500;
}
.p-treetable .p-treetable-footer {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #e4e4e4;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 500;
}
.p-treetable .p-treetable-thead > tr > th {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #e4e4e4;
  border-width: 0 0 1px 0;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.87);
  background: #ffffff;
  transition: none;
}
.p-treetable .p-treetable-tfoot > tr > td {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #e4e4e4;
  border-width: 0 0 1px 0;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.87);
  background: #ffffff;
}
.p-treetable .p-sortable-column {
  outline-color: transparent;
}
.p-treetable .p-sortable-column .p-sortable-column-icon {
  color: rgba(0, 0, 0, 0.6);
  margin-left: 0.5rem;
}
.p-treetable .p-sortable-column .p-sortable-column-badge {
  border-radius: 50%;
  height: 1.143rem;
  min-width: 1.143rem;
  line-height: 1.143rem;
  color: #1E88E5;
  background: rgba(30, 136, 229, 0.12);
  margin-left: 0.5rem;
}
.p-treetable .p-sortable-column:not(.p-highlight):hover {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.p-treetable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
  color: rgba(0, 0, 0, 0.6);
}
.p-treetable .p-sortable-column.p-highlight {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
}
.p-treetable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: rgba(0, 0, 0, 0.87);
}
.p-treetable .p-treetable-tbody > tr {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  transition: none;
  outline-color: transparent;
}
.p-treetable .p-treetable-tbody > tr > td {
  text-align: left;
  border: 1px solid #e4e4e4;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
}
.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler {
  width: 2.5rem;
  height: 2.5rem;
  color: rgba(0, 0, 0, 0.6);
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  margin-right: 0.5rem;
}
.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:enabled:hover {
  color: rgba(0, 0, 0, 0.6);
  border-color: transparent;
  background: rgba(0, 0, 0, 0.04);
}
.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-treetable .p-treetable-tbody > tr > td p-treetablecheckbox .p-checkbox {
  margin-right: 0.5rem;
}
.p-treetable .p-treetable-tbody > tr > td p-treetablecheckbox .p-checkbox .p-indeterminate .p-checkbox-icon {
  color: rgba(0, 0, 0, 0.87);
}
.p-treetable .p-treetable-tbody > tr.p-highlight {
  background: rgba(30, 136, 229, 0.12);
  color: #1E88E5;
}
.p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler {
  color: #1E88E5;
}
.p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler:hover {
  color: #1E88E5;
}
.p-treetable.p-treetable-hoverable-rows .p-treetable-tbody > tr:not(.p-highlight):hover {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.p-treetable.p-treetable-hoverable-rows .p-treetable-tbody > tr:not(.p-highlight):hover .p-treetable-toggler {
  color: rgba(0, 0, 0, 0.87);
}
.p-treetable .p-column-resizer-helper {
  background: #1E88E5;
}
.p-treetable .p-treetable-scrollable-header,
.p-treetable .p-treetable-scrollable-footer {
  background: #ffffff;
}
.p-treetable .p-treetable-loading-icon {
  font-size: 2rem;
}
.p-treetable.p-treetable-gridlines .p-datatable-header {
  border-width: 1px 1px 0 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-footer {
  border-width: 0 1px 1px 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-top {
  border-width: 0 1px 0 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-bottom {
  border-width: 0 1px 1px 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-thead > tr > th {
  border-width: 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-tbody > tr > td {
  border-width: 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-tfoot > tr > td {
  border-width: 1px;
}
.p-treetable.p-treetable-sm .p-treetable-header {
  padding: 0.875rem 0.875rem;
}
.p-treetable.p-treetable-sm .p-treetable-thead > tr > th {
  padding: 0.5rem 0.5rem;
}
.p-treetable.p-treetable-sm .p-treetable-tbody > tr > td {
  padding: 0.5rem 0.5rem;
}
.p-treetable.p-treetable-sm .p-treetable-tfoot > tr > td {
  padding: 0.5rem 0.5rem;
}
.p-treetable.p-treetable-sm .p-treetable-footer {
  padding: 0.5rem 0.5rem;
}
.p-treetable.p-treetable-lg .p-treetable-header {
  padding: 1.25rem 1.25rem;
}
.p-treetable.p-treetable-lg .p-treetable-thead > tr > th {
  padding: 1.25rem 1.25rem;
}
.p-treetable.p-treetable-lg .p-treetable-tbody > tr > td {
  padding: 1.25rem 1.25rem;
}
.p-treetable.p-treetable-lg .p-treetable-tfoot > tr > td {
  padding: 1.25rem 1.25rem;
}
.p-treetable.p-treetable-lg .p-treetable-footer {
  padding: 1.25rem 1.25rem;
}

.p-virtualscroller .p-virtualscroller-header {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #e4e4e4;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 500;
}
.p-virtualscroller .p-virtualscroller-content {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border: 0 none;
  padding: 1rem 0;
}
.p-virtualscroller .p-virtualscroller-footer {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #e4e4e4;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 500;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 1.5rem;
  border: 0 none;
  color: rgba(0, 0, 0, 0.87);
  background: #ffffff;
  font-weight: 400;
  border-radius: 4px;
  transition: none;
}
.p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
  margin-right: 0.5rem;
}
.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link {
  background: #f6f6f6;
  border-color: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
  background: #ffffff;
  border-color: transparent;
  color: rgba(0, 0, 0, 0.87);
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
  border-color: transparent;
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
}
.p-accordion .p-accordion-content {
  padding: 1rem 1.5rem;
  border: 0 none;
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.p-accordion p-accordiontab .p-accordion-tab {
  margin-bottom: 0;
}
.p-accordion p-accordiontab .p-accordion-header .p-accordion-header-link {
  border-radius: 0;
}
.p-accordion p-accordiontab .p-accordion-content {
  border-radius: 0;
}
.p-accordion p-accordiontab:not(:first-child) .p-accordion-header .p-accordion-header-link {
  border-top: 0 none;
}
.p-accordion p-accordiontab:not(:first-child) .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link, .p-accordion p-accordiontab:not(:first-child) .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
  border-top: 0 none;
}
.p-accordion p-accordiontab:first-child .p-accordion-header .p-accordion-header-link {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.p-accordion p-accordiontab:last-child .p-accordion-header:not(.p-highlight) .p-accordion-header-link {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.p-accordion p-accordiontab:last-child .p-accordion-content {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.p-card {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}
.p-card .p-card-body {
  padding: 1rem;
}
.p-card .p-card-title {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}
.p-card .p-card-subtitle {
  font-weight: 400;
  margin-bottom: 0.5rem;
  color: rgba(0, 0, 0, 0.6);
}
.p-card .p-card-content {
  padding: 1rem 0;
}
.p-card .p-card-footer {
  padding: 1rem 0 0 0;
}

.p-divider .p-divider-content {
  background-color: #ffffff;
}
.p-divider.p-divider-horizontal {
  margin: 1.25rem 0;
  padding: 0 1.25rem;
}
.p-divider.p-divider-horizontal:before {
  border-top: 1px rgba(0, 0, 0, 0.12);
}
.p-divider.p-divider-horizontal .p-divider-content {
  padding: 0 0.5rem;
}
.p-divider.p-divider-vertical {
  margin: 0 1.25rem;
  padding: 1.25rem 0;
}
.p-divider.p-divider-vertical:before {
  border-left: 1px rgba(0, 0, 0, 0.12);
}
.p-divider.p-divider-vertical .p-divider-content {
  padding: 0.5rem 0;
}

.p-fieldset {
  border: 1px solid #e0e0e0;
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 4px;
}
.p-fieldset .p-fieldset-legend {
  padding: 1rem;
  border: 1px solid #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
  background: #ffffff;
  font-weight: 500;
  border-radius: 4px;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend {
  padding: 0;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a {
  padding: 1rem;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 4px;
  transition: none;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a .p-fieldset-toggler {
  margin-right: 0.5rem;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend:hover {
  background: rgba(0, 0, 0, 0.04);
  border-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.p-fieldset .p-fieldset-content {
  padding: 1rem;
}

.p-panel .p-panel-header {
  border: 1px solid #e0e0e0;
  padding: 1rem;
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.p-panel .p-panel-header .p-panel-title {
  font-weight: 500;
}
.p-panel .p-panel-header .p-panel-header-icon {
  width: 2.5rem;
  height: 2.5rem;
  color: rgba(0, 0, 0, 0.6);
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-panel .p-panel-header .p-panel-header-icon:enabled:hover {
  color: rgba(0, 0, 0, 0.6);
  border-color: transparent;
  background: rgba(0, 0, 0, 0.04);
}
.p-panel .p-panel-header .p-panel-header-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-panel.p-panel-toggleable .p-panel-header {
  padding: 0.5rem 1rem;
}
.p-panel .p-panel-content {
  padding: 1rem;
  border: 1px solid #e0e0e0;
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top: 0 none;
}
.p-panel .p-panel-footer {
  padding: 1rem 1rem;
  border: 1px solid #e0e0e0;
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border-top: 0 none;
}

.p-scrollpanel .p-scrollpanel-bar {
  background: rgba(0, 0, 0, 0.12);
  border: 0 none;
}

.p-splitter {
  border: 1px solid #e0e0e0;
  background: #ffffff;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.87);
}
.p-splitter .p-splitter-gutter {
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  background: rgba(0, 0, 0, 0.04);
}
.p-splitter .p-splitter-gutter .p-splitter-gutter-handle {
  background: rgba(0, 0, 0, 0.12);
}
.p-splitter .p-splitter-gutter-resizing {
  background: rgba(0, 0, 0, 0.12);
}

.p-tabview .p-tabview-nav {
  background: #ffffff;
  border: solid rgba(0, 0, 0, 0.12);
  border-width: 0 0 1px 0;
}
.p-tabview .p-tabview-nav li {
  margin-right: 0;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  border: none;
  border-width: 0 0 0 0;
  border-color: transparent transparent transparent transparent;
  background: #ffffff;
  color: rgba(0, 0, 0, 0.6);
  padding: 1rem 1.5rem;
  font-weight: 500;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  transition: none;
  margin: 0 0 0 0;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset none;
}
.p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
  background: rgba(30, 136, 229, 0.04);
  border-color: transparent;
  color: rgba(0, 0, 0, 0.6);
}
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: #ffffff;
  border-color: transparent;
  color: #1E88E5;
}
.p-tabview .p-tabview-left-icon {
  margin-right: 0.5rem;
}
.p-tabview .p-tabview-right-icon {
  margin-left: 0.5rem;
}
.p-tabview .p-tabview-close {
  margin-left: 0.5rem;
}
.p-tabview .p-tabview-nav-btn.p-link {
  background: #ffffff;
  color: #1E88E5;
  width: 3rem;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 0;
}
.p-tabview .p-tabview-nav-btn.p-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset none;
}
.p-tabview .p-tabview-panels {
  background: #ffffff;
  padding: 1rem;
  border: 0 none;
  color: rgba(0, 0, 0, 0.87);
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.p-toolbar {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  padding: 1rem;
  border-radius: 4px;
}
.p-toolbar .p-toolbar-separator {
  margin: 0 0.5rem;
}

.p-confirm-popup {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border: 0 none;
  border-radius: 4px;
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.p-confirm-popup .p-confirm-popup-content {
  padding: 1rem;
}
.p-confirm-popup .p-confirm-popup-footer {
  text-align: right;
  padding: 1rem 1rem;
}
.p-confirm-popup .p-confirm-popup-footer button {
  margin: 0 0.5rem 0 0;
  width: auto;
}
.p-confirm-popup .p-confirm-popup-footer button:last-child {
  margin: 0;
}
.p-confirm-popup:after {
  border: solid transparent;
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #ffffff;
}
.p-confirm-popup:before {
  border: solid transparent;
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #ffffff;
}
.p-confirm-popup.p-confirm-popup-flipped:after {
  border-top-color: #ffffff;
}
.p-confirm-popup.p-confirm-popup-flipped:before {
  border-top-color: #ffffff;
}
.p-confirm-popup .p-confirm-popup-icon {
  font-size: 1.5rem;
}
.p-confirm-popup .p-confirm-popup-message {
  margin-left: 1rem;
}

.p-dialog {
  border-radius: 4px;
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
  border: 0 none;
}
.p-dialog .p-dialog-header {
  border-bottom: 0 none;
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  padding: 1.5rem;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.p-dialog .p-dialog-header .p-dialog-title {
  font-weight: 500;
  font-size: 1.25rem;
}
.p-dialog .p-dialog-header .p-dialog-header-icon {
  width: 2.5rem;
  height: 2.5rem;
  color: rgba(0, 0, 0, 0.6);
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  margin-right: 0.5rem;
}
.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
  color: rgba(0, 0, 0, 0.6);
  border-color: transparent;
  background: rgba(0, 0, 0, 0.04);
}
.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-dialog .p-dialog-header .p-dialog-header-icon:last-child {
  margin-right: 0;
}
.p-dialog .p-dialog-content {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  padding: 0 1.5rem 1.5rem 1.5rem;
}
.p-dialog .p-dialog-footer {
  border-top: 0 none;
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  padding: 1rem 1.5rem;
  text-align: right;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.p-dialog .p-dialog-footer button {
  margin: 0 0.5rem 0 0;
  width: auto;
}
.p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
  font-size: 2rem;
}
.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
  margin-left: 1rem;
}

.p-overlaypanel {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border: 0 none;
  border-radius: 4px;
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.p-overlaypanel .p-overlaypanel-content {
  padding: 1rem;
}
.p-overlaypanel .p-overlaypanel-close {
  background: #1E88E5;
  color: #ffffff;
  width: 2.5rem;
  height: 2.5rem;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 50%;
  position: absolute;
  top: -1.25rem;
  right: -1.25rem;
}
.p-overlaypanel .p-overlaypanel-close:enabled:hover {
  background: rgba(30, 136, 229, 0.92);
  color: #ffffff;
}
.p-overlaypanel:after {
  border: solid transparent;
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #ffffff;
}
.p-overlaypanel:before {
  border: solid transparent;
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #ffffff;
}
.p-overlaypanel.p-overlaypanel-flipped:after {
  border-top-color: #ffffff;
}
.p-overlaypanel.p-overlaypanel-flipped:before {
  border-top-color: #ffffff;
}

.p-sidebar {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border: 0 none;
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.p-sidebar .p-sidebar-header {
  padding: 1rem;
}
.p-sidebar .p-sidebar-header .p-sidebar-close,
.p-sidebar .p-sidebar-header .p-sidebar-icon {
  width: 2.5rem;
  height: 2.5rem;
  color: rgba(0, 0, 0, 0.6);
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-sidebar .p-sidebar-header .p-sidebar-close:enabled:hover,
.p-sidebar .p-sidebar-header .p-sidebar-icon:enabled:hover {
  color: rgba(0, 0, 0, 0.6);
  border-color: transparent;
  background: rgba(0, 0, 0, 0.04);
}
.p-sidebar .p-sidebar-header .p-sidebar-close:focus,
.p-sidebar .p-sidebar-header .p-sidebar-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-sidebar .p-sidebar-header + .p-sidebar-content {
  padding-top: 0;
}
.p-sidebar .p-sidebar-content {
  padding: 1rem;
}

.p-tooltip .p-tooltip-text {
  background: rgba(97, 97, 97, 0.9);
  color: #ffffff;
  padding: 0.5rem;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}
.p-tooltip.p-tooltip-right .p-tooltip-arrow {
  border-right-color: rgba(97, 97, 97, 0.9);
}
.p-tooltip.p-tooltip-left .p-tooltip-arrow {
  border-left-color: rgba(97, 97, 97, 0.9);
}
.p-tooltip.p-tooltip-top .p-tooltip-arrow {
  border-top-color: rgba(97, 97, 97, 0.9);
}
.p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
  border-bottom-color: rgba(97, 97, 97, 0.9);
}

.p-fileupload .p-fileupload-buttonbar {
  background: #ffffff;
  padding: 1rem;
  border: 1px solid #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
  border-bottom: 0 none;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.p-fileupload .p-fileupload-buttonbar .p-button {
  margin-right: 0.5rem;
}
.p-fileupload .p-fileupload-buttonbar .p-button.p-fileupload-choose.p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-fileupload .p-fileupload-content {
  background: #ffffff;
  padding: 2rem 1rem;
  border: 1px solid #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.p-fileupload .p-progressbar {
  height: 4px;
}
.p-fileupload .p-fileupload-row > div {
  padding: 1rem 1rem;
}
.p-fileupload.p-fileupload-advanced .p-message {
  margin-top: 0;
}

.p-fileupload-choose:not(.p-disabled):hover {
  background: rgba(30, 136, 229, 0.92);
  color: #ffffff;
  border-color: transparent;
}
.p-fileupload-choose:not(.p-disabled):active {
  background: rgba(30, 136, 229, 0.68);
  color: #ffffff;
  border-color: transparent;
}

.p-breadcrumb {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  padding: 1rem;
}
.p-breadcrumb ul li .p-menuitem-link {
  transition: none;
  border-radius: 4px;
}
.p-breadcrumb ul li .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-breadcrumb ul li .p-menuitem-link .p-menuitem-text {
  color: rgba(0, 0, 0, 0.87);
}
.p-breadcrumb ul li .p-menuitem-link .p-menuitem-icon {
  color: rgba(0, 0, 0, 0.6);
}
.p-breadcrumb ul li.p-breadcrumb-chevron {
  margin: 0 0.5rem 0 0.5rem;
  color: rgba(0, 0, 0, 0.6);
}
.p-breadcrumb ul li:last-child .p-menuitem-text {
  color: rgba(0, 0, 0, 0.87);
}
.p-breadcrumb ul li:last-child .p-menuitem-icon {
  color: rgba(0, 0, 0, 0.6);
}

.p-contextmenu {
  padding: 0.5rem 0;
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border: 0 none;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  width: 12.5rem;
}
.p-contextmenu .p-menuitem-link {
  padding: 1rem 1rem;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0;
  transition: none;
  user-select: none;
}
.p-contextmenu .p-menuitem-link .p-menuitem-text {
  color: rgba(0, 0, 0, 0.87);
}
.p-contextmenu .p-menuitem-link .p-menuitem-icon {
  color: rgba(0, 0, 0, 0.6);
  margin-right: 0.5rem;
}
.p-contextmenu .p-menuitem-link .p-submenu-icon {
  color: rgba(0, 0, 0, 0.6);
}
.p-contextmenu .p-menuitem-link:not(.p-disabled):hover {
  background: rgba(0, 0, 0, 0.04);
}
.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: rgba(0, 0, 0, 0.87);
}
.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: rgba(0, 0, 0, 0.6);
}
.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: rgba(0, 0, 0, 0.6);
}
.p-contextmenu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-contextmenu .p-submenu-list {
  padding: 0.5rem 0;
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.p-contextmenu .p-menuitem {
  margin: 0;
}
.p-contextmenu .p-menuitem:last-child {
  margin: 0;
}
.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: rgba(0, 0, 0, 0.04);
}
.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: rgba(0, 0, 0, 0.87);
}
.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: rgba(0, 0, 0, 0.6);
}
.p-contextmenu .p-menu-separator {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  margin: 0.5rem 0;
}
.p-contextmenu .p-submenu-icon {
  font-size: 0.875rem;
}

.p-dock .p-dock-list {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 0.5rem 0.5rem;
  border-radius: 0.5rem;
}
.p-dock .p-dock-item {
  padding: 0.5rem;
}
.p-dock .p-dock-action {
  width: 4rem;
  height: 4rem;
}
.p-dock.p-dock-top .p-dock-item-second-prev,
.p-dock.p-dock-top .p-dock-item-second-next, .p-dock.p-dock-bottom .p-dock-item-second-prev,
.p-dock.p-dock-bottom .p-dock-item-second-next {
  margin: 0 0.9rem;
}
.p-dock.p-dock-top .p-dock-item-prev,
.p-dock.p-dock-top .p-dock-item-next, .p-dock.p-dock-bottom .p-dock-item-prev,
.p-dock.p-dock-bottom .p-dock-item-next {
  margin: 0 1.3rem;
}
.p-dock.p-dock-top .p-dock-item-current, .p-dock.p-dock-bottom .p-dock-item-current {
  margin: 0 1.5rem;
}
.p-dock.p-dock-left .p-dock-item-second-prev,
.p-dock.p-dock-left .p-dock-item-second-next, .p-dock.p-dock-right .p-dock-item-second-prev,
.p-dock.p-dock-right .p-dock-item-second-next {
  margin: 0.9rem 0;
}
.p-dock.p-dock-left .p-dock-item-prev,
.p-dock.p-dock-left .p-dock-item-next, .p-dock.p-dock-right .p-dock-item-prev,
.p-dock.p-dock-right .p-dock-item-next {
  margin: 1.3rem 0;
}
.p-dock.p-dock-left .p-dock-item-current, .p-dock.p-dock-right .p-dock-item-current {
  margin: 1.5rem 0;
}

@media screen and (max-width: 960px) {
  .p-dock.p-dock-top .p-dock-list-container, .p-dock.p-dock-bottom .p-dock-list-container {
    overflow-x: auto;
    width: 100%;
  }
  .p-dock.p-dock-top .p-dock-list-container .p-dock-list, .p-dock.p-dock-bottom .p-dock-list-container .p-dock-list {
    margin: 0 auto;
  }
  .p-dock.p-dock-left .p-dock-list-container, .p-dock.p-dock-right .p-dock-list-container {
    overflow-y: auto;
    height: 100%;
  }
  .p-dock.p-dock-left .p-dock-list-container .p-dock-list, .p-dock.p-dock-right .p-dock-list-container .p-dock-list {
    margin: auto 0;
  }
  .p-dock .p-dock-list .p-dock-item {
    transform: none;
    margin: 0;
  }
}
.p-megamenu {
  padding: 1rem;
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #e5e5e5;
  border-radius: 4px;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link {
  padding: 1rem;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 4px;
  transition: none;
  user-select: none;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
  color: rgba(0, 0, 0, 0.87);
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
  color: rgba(0, 0, 0, 0.6);
  margin-right: 0.5rem;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
  color: rgba(0, 0, 0, 0.6);
  margin-left: 0.5rem;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
  background: rgba(0, 0, 0, 0.04);
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: rgba(0, 0, 0, 0.87);
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: rgba(0, 0, 0, 0.6);
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: rgba(0, 0, 0, 0.6);
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link,
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover {
  background: rgba(0, 0, 0, 0.04);
}
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text,
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: rgba(0, 0, 0, 0.87);
}
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: rgba(0, 0, 0, 0.6);
}
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon,
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: rgba(0, 0, 0, 0.6);
}
.p-megamenu .p-menuitem-link {
  padding: 1rem 1rem;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0;
  transition: none;
  user-select: none;
}
.p-megamenu .p-menuitem-link .p-menuitem-text {
  color: rgba(0, 0, 0, 0.87);
}
.p-megamenu .p-menuitem-link .p-menuitem-icon {
  color: rgba(0, 0, 0, 0.6);
  margin-right: 0.5rem;
}
.p-megamenu .p-menuitem-link .p-submenu-icon {
  color: rgba(0, 0, 0, 0.6);
}
.p-megamenu .p-menuitem-link:not(.p-disabled):hover {
  background: rgba(0, 0, 0, 0.04);
}
.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: rgba(0, 0, 0, 0.87);
}
.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: rgba(0, 0, 0, 0.6);
}
.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: rgba(0, 0, 0, 0.6);
}
.p-megamenu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-megamenu .p-megamenu-panel {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border: 0 none;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.p-megamenu .p-megamenu-submenu-header {
  margin: 0;
  padding: 1rem;
  color: rgba(0, 0, 0, 0.6);
  background: #ffffff;
  font-weight: 400;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.p-megamenu .p-megamenu-submenu {
  padding: 0.5rem 0;
  width: 12.5rem;
}
.p-megamenu .p-megamenu-submenu .p-menu-separator {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  margin: 0.5rem 0;
}
.p-megamenu .p-megamenu-submenu .p-menuitem {
  margin: 0;
}
.p-megamenu .p-megamenu-submenu .p-menuitem:last-child {
  margin: 0;
}
.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: rgba(0, 0, 0, 0.04);
}
.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: rgba(0, 0, 0, 0.87);
}
.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: rgba(0, 0, 0, 0.6);
}
.p-megamenu.p-megamenu-vertical {
  width: 12.5rem;
  padding: 0.5rem 0;
}
.p-megamenu.p-megamenu-vertical .p-menuitem {
  margin: 0;
}
.p-megamenu.p-megamenu-vertical .p-menuitem:last-child {
  margin: 0;
}

.p-menu {
  padding: 0.5rem 0;
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  width: 12.5rem;
}
.p-menu .p-menuitem-link {
  padding: 1rem 1rem;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0;
  transition: none;
  user-select: none;
}
.p-menu .p-menuitem-link .p-menuitem-text {
  color: rgba(0, 0, 0, 0.87);
}
.p-menu .p-menuitem-link .p-menuitem-icon {
  color: rgba(0, 0, 0, 0.6);
  margin-right: 0.5rem;
}
.p-menu .p-menuitem-link .p-submenu-icon {
  color: rgba(0, 0, 0, 0.6);
}
.p-menu .p-menuitem-link:not(.p-disabled):hover {
  background: rgba(0, 0, 0, 0.04);
}
.p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: rgba(0, 0, 0, 0.87);
}
.p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: rgba(0, 0, 0, 0.6);
}
.p-menu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: rgba(0, 0, 0, 0.6);
}
.p-menu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-menu.p-menu-overlay {
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.p-menu .p-submenu-header {
  margin: 0;
  padding: 1rem;
  color: rgba(0, 0, 0, 0.6);
  background: #ffffff;
  font-weight: 400;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.p-menu .p-menu-separator {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  margin: 0.5rem 0;
}
.p-menu .p-menuitem {
  margin: 0;
}
.p-menu .p-menuitem:last-child {
  margin: 0;
}

.p-menubar {
  padding: 1rem;
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #e5e5e5;
  border-radius: 4px;
}
.p-menubar .p-menuitem-link {
  padding: 1rem 1rem;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0;
  transition: none;
  user-select: none;
}
.p-menubar .p-menuitem-link .p-menuitem-text {
  color: rgba(0, 0, 0, 0.87);
}
.p-menubar .p-menuitem-link .p-menuitem-icon {
  color: rgba(0, 0, 0, 0.6);
  margin-right: 0.5rem;
}
.p-menubar .p-menuitem-link .p-submenu-icon {
  color: rgba(0, 0, 0, 0.6);
}
.p-menubar .p-menuitem-link:not(.p-disabled):hover {
  background: rgba(0, 0, 0, 0.04);
}
.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: rgba(0, 0, 0, 0.87);
}
.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: rgba(0, 0, 0, 0.6);
}
.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: rgba(0, 0, 0, 0.6);
}
.p-menubar .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
  padding: 1rem;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 4px;
  transition: none;
  user-select: none;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
  color: rgba(0, 0, 0, 0.87);
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
  color: rgba(0, 0, 0, 0.6);
  margin-right: 0.5rem;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
  color: rgba(0, 0, 0, 0.6);
  margin-left: 0.5rem;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
  background: rgba(0, 0, 0, 0.04);
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: rgba(0, 0, 0, 0.87);
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: rgba(0, 0, 0, 0.6);
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: rgba(0, 0, 0, 0.6);
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link,
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover {
  background: rgba(0, 0, 0, 0.04);
}
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text,
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: rgba(0, 0, 0, 0.87);
}
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: rgba(0, 0, 0, 0.6);
}
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon,
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: rgba(0, 0, 0, 0.6);
}
.p-menubar .p-submenu-list {
  padding: 0.5rem 0;
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  width: 12.5rem;
}
.p-menubar .p-submenu-list .p-menu-separator {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  margin: 0.5rem 0;
}
.p-menubar .p-submenu-list .p-submenu-icon {
  font-size: 0.875rem;
}
.p-menubar .p-submenu-list .p-menuitem {
  margin: 0;
}
.p-menubar .p-submenu-list .p-menuitem:last-child {
  margin: 0;
}
.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: rgba(0, 0, 0, 0.04);
}
.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: rgba(0, 0, 0, 0.87);
}
.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: rgba(0, 0, 0, 0.6);
}

@media screen and (max-width: 960px) {
  .p-menubar {
    position: relative;
  }
  .p-menubar .p-menubar-button {
    display: flex;
    width: 2.5rem;
    height: 2.5rem;
    color: rgba(0, 0, 0, 0.6);
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  }
  .p-menubar .p-menubar-button:hover {
    color: rgba(0, 0, 0, 0.6);
    background: rgba(0, 0, 0, 0.04);
  }
  .p-menubar .p-menubar-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
  }
  .p-menubar .p-menubar-root-list {
    position: absolute;
    display: none;
    padding: 0.5rem 0;
    background: #ffffff;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    width: 100%;
  }
  .p-menubar .p-menubar-root-list .p-menu-separator {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    margin: 0.5rem 0;
  }
  .p-menubar .p-menubar-root-list .p-submenu-icon {
    font-size: 0.875rem;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem {
    width: 100%;
    position: static;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
    padding: 1rem 1rem;
    color: rgba(0, 0, 0, 0.87);
    border-radius: 0;
    transition: none;
    user-select: none;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
    color: rgba(0, 0, 0, 0.87);
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
    color: rgba(0, 0, 0, 0.6);
    margin-right: 0.5rem;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
    color: rgba(0, 0, 0, 0.6);
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
    background: rgba(0, 0, 0, 0.04);
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: rgba(0, 0, 0, 0.87);
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: rgba(0, 0, 0, 0.6);
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: rgba(0, 0, 0, 0.6);
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link > .p-submenu-icon {
    margin-left: auto;
    transition: transform 0.2s;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link > .p-submenu-icon {
    transform: rotate(-180deg);
  }
  .p-menubar .p-menubar-root-list .p-submenu-list {
    width: 100%;
    position: static;
    box-shadow: none;
    border: 0 none;
  }
  .p-menubar .p-menubar-root-list .p-submenu-list .p-submenu-icon {
    transition: transform 0.2s;
    transform: rotate(90deg);
  }
  .p-menubar .p-menubar-root-list .p-submenu-list .p-menuitem-active > .p-menuitem-link > .p-submenu-icon {
    transform: rotate(-90deg);
  }
  .p-menubar .p-menubar-root-list .p-menuitem {
    width: 100%;
    position: static;
  }
  .p-menubar .p-menubar-root-list ul li a {
    padding-left: 3rem;
  }
  .p-menubar .p-menubar-root-list ul li ul li a {
    padding-left: 5rem;
  }
  .p-menubar .p-menubar-root-list ul li ul li ul li a {
    padding-left: 7rem;
  }
  .p-menubar .p-menubar-root-list ul li ul li ul li ul li a {
    padding-left: 9rem;
  }
  .p-menubar .p-menubar-root-list ul li ul li ul li ul li ul li a {
    padding-left: 11rem;
  }
  .p-menubar.p-menubar-mobile-active .p-menubar-root-list {
    display: flex;
    flex-direction: column;
    top: 100%;
    left: 0;
    z-index: 1;
  }
}
.p-panelmenu .p-panelmenu-header > a {
  padding: 1.5rem;
  border: 0 none;
  color: rgba(0, 0, 0, 0.87);
  background: #ffffff;
  font-weight: 400;
  border-radius: 4px;
  transition: none;
}
.p-panelmenu .p-panelmenu-header > a .p-panelmenu-icon {
  margin-right: 0.5rem;
}
.p-panelmenu .p-panelmenu-header > a .p-menuitem-icon {
  margin-right: 0.5rem;
}
.p-panelmenu .p-panelmenu-header > a:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-panelmenu .p-panelmenu-header:not(.p-highlight):not(.p-disabled) > a:hover {
  background: #f6f6f6;
  border-color: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.p-panelmenu .p-panelmenu-header.p-highlight {
  margin-bottom: 0;
}
.p-panelmenu .p-panelmenu-header.p-highlight > a {
  background: #ffffff;
  border-color: transparent;
  color: rgba(0, 0, 0, 0.87);
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.p-panelmenu .p-panelmenu-header.p-highlight:not(.p-disabled) > a:hover {
  border-color: transparent;
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
}
.p-panelmenu .p-panelmenu-content {
  padding: 0.5rem 0;
  border: 0 none;
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 0;
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
  padding: 1rem 1rem;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0;
  transition: none;
  user-select: none;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-text {
  color: rgba(0, 0, 0, 0.87);
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-icon {
  color: rgba(0, 0, 0, 0.6);
  margin-right: 0.5rem;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-submenu-icon {
  color: rgba(0, 0, 0, 0.6);
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover {
  background: rgba(0, 0, 0, 0.04);
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: rgba(0, 0, 0, 0.87);
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: rgba(0, 0, 0, 0.6);
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: rgba(0, 0, 0, 0.6);
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-panelmenu-icon {
  margin-right: 0.5rem;
}
.p-panelmenu .p-panelmenu-content .p-submenu-list:not(.p-panelmenu-root-submenu) {
  padding: 0 0 0 1rem;
}
.p-panelmenu .p-panelmenu-panel {
  margin-bottom: 0;
}
.p-panelmenu .p-panelmenu-panel .p-panelmenu-header > a {
  border-radius: 0;
}
.p-panelmenu .p-panelmenu-panel .p-panelmenu-content {
  border-radius: 0;
}
.p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header > a {
  border-top: 0 none;
}
.p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header:not(.p-highlight):not(.p-disabled):hover > a, .p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header:not(.p-disabled).p-highlight:hover > a {
  border-top: 0 none;
}
.p-panelmenu .p-panelmenu-panel:first-child .p-panelmenu-header > a {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.p-panelmenu .p-panelmenu-panel:last-child .p-panelmenu-header:not(.p-highlight) > a {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.p-panelmenu .p-panelmenu-panel:last-child .p-panelmenu-content {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.p-slidemenu {
  padding: 0.5rem 0;
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  width: 12.5rem;
}
.p-slidemenu .p-menuitem-link {
  padding: 1rem 1rem;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0;
  transition: none;
  user-select: none;
}
.p-slidemenu .p-menuitem-link .p-menuitem-text {
  color: rgba(0, 0, 0, 0.87);
}
.p-slidemenu .p-menuitem-link .p-menuitem-icon {
  color: rgba(0, 0, 0, 0.6);
  margin-right: 0.5rem;
}
.p-slidemenu .p-menuitem-link .p-submenu-icon {
  color: rgba(0, 0, 0, 0.6);
}
.p-slidemenu .p-menuitem-link:not(.p-disabled):hover {
  background: rgba(0, 0, 0, 0.04);
}
.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: rgba(0, 0, 0, 0.87);
}
.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: rgba(0, 0, 0, 0.6);
}
.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: rgba(0, 0, 0, 0.6);
}
.p-slidemenu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-slidemenu.p-slidemenu-overlay {
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.p-slidemenu .p-slidemenu-list {
  padding: 0.5rem 0;
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link {
  background: rgba(0, 0, 0, 0.04);
}
.p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-text {
  color: rgba(0, 0, 0, 0.87);
}
.p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-icon, .p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-icon {
  color: rgba(0, 0, 0, 0.6);
}
.p-slidemenu .p-slidemenu-separator {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  margin: 0.5rem 0;
}
.p-slidemenu .p-slidemenu-icon {
  font-size: 0.875rem;
}
.p-slidemenu .p-slidemenu-backward {
  padding: 1rem 1rem;
  color: rgba(0, 0, 0, 0.87);
}

.p-steps .p-steps-item .p-menuitem-link {
  background: transparent;
  transition: none;
  border-radius: 4px;
  background: transparent;
}
.p-steps .p-steps-item .p-menuitem-link .p-steps-number {
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid transparent;
  background: transparent;
  min-width: 2rem;
  height: 2rem;
  line-height: 2rem;
  font-size: 1.143rem;
  z-index: 1;
  border-radius: 50%;
}
.p-steps .p-steps-item .p-menuitem-link .p-steps-title {
  margin-top: 0.5rem;
  color: rgba(0, 0, 0, 0.87);
}
.p-steps .p-steps-item .p-menuitem-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-steps .p-steps-item.p-highlight .p-steps-number {
  background: rgba(30, 136, 229, 0.12);
  color: #1E88E5;
}
.p-steps .p-steps-item.p-highlight .p-steps-title {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.87);
}
.p-steps .p-steps-item:before {
  content: " ";
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  width: 100%;
  top: 50%;
  left: 0;
  display: block;
  position: absolute;
  margin-top: -1rem;
}

.p-tabmenu .p-tabmenu-nav {
  background: #ffffff;
  border: solid rgba(0, 0, 0, 0.12);
  border-width: 0 0 1px 0;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
  margin-right: 0;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
  border: none;
  border-width: 0 0 0 0;
  border-color: transparent transparent transparent transparent;
  background: #ffffff;
  color: rgba(0, 0, 0, 0.6);
  padding: 1rem 1.5rem;
  font-weight: 500;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  transition: none;
  margin: 0 0 0 0;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-icon {
  margin-right: 0.5rem;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset none;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link {
  background: rgba(30, 136, 229, 0.04);
  border-color: transparent;
  color: rgba(0, 0, 0, 0.6);
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
  background: #ffffff;
  border-color: transparent;
  color: #1E88E5;
}

.p-tieredmenu {
  padding: 0.5rem 0;
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  width: 12.5rem;
}
.p-tieredmenu .p-menuitem-link {
  padding: 1rem 1rem;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0;
  transition: none;
  user-select: none;
}
.p-tieredmenu .p-menuitem-link .p-menuitem-text {
  color: rgba(0, 0, 0, 0.87);
}
.p-tieredmenu .p-menuitem-link .p-menuitem-icon {
  color: rgba(0, 0, 0, 0.6);
  margin-right: 0.5rem;
}
.p-tieredmenu .p-menuitem-link .p-submenu-icon {
  color: rgba(0, 0, 0, 0.6);
}
.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover {
  background: rgba(0, 0, 0, 0.04);
}
.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: rgba(0, 0, 0, 0.87);
}
.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: rgba(0, 0, 0, 0.6);
}
.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: rgba(0, 0, 0, 0.6);
}
.p-tieredmenu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-tieredmenu.p-tieredmenu-overlay {
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.p-tieredmenu .p-submenu-list {
  padding: 0.5rem 0;
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.p-tieredmenu .p-menuitem {
  margin: 0;
}
.p-tieredmenu .p-menuitem:last-child {
  margin: 0;
}
.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: rgba(0, 0, 0, 0.04);
}
.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: rgba(0, 0, 0, 0.87);
}
.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: rgba(0, 0, 0, 0.6);
}
.p-tieredmenu .p-menu-separator {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  margin: 0.5rem 0;
}
.p-tieredmenu .p-submenu-icon {
  font-size: 0.875rem;
}

.p-inline-message {
  padding: 1rem 1rem;
  margin: 0;
  border-radius: 4px;
}
.p-inline-message.p-inline-message-info {
  background: #B3E5FC;
  border: solid transparent;
  border-width: 1px;
  color: #01579B;
}
.p-inline-message.p-inline-message-info .p-inline-message-icon {
  color: #01579B;
}
.p-inline-message.p-inline-message-success {
  background: #C8E6C9;
  border: solid transparent;
  border-width: 1px;
  color: #1B5E20;
}
.p-inline-message.p-inline-message-success .p-inline-message-icon {
  color: #1B5E20;
}
.p-inline-message.p-inline-message-warn {
  background: #FFECB3;
  border: solid transparent;
  border-width: 1px;
  color: #7f6003;
}
.p-inline-message.p-inline-message-warn .p-inline-message-icon {
  color: #7f6003;
}
.p-inline-message.p-inline-message-error {
  background: #FFCDD2;
  border: solid transparent;
  border-width: 1px;
  color: #B71C1C;
}
.p-inline-message.p-inline-message-error .p-inline-message-icon {
  color: #B71C1C;
}
.p-inline-message .p-inline-message-icon {
  font-size: 1rem;
  margin-right: 0.5rem;
}
.p-inline-message .p-inline-message-text {
  font-size: 1rem;
}
.p-inline-message.p-inline-message-icon-only .p-inline-message-icon {
  margin-right: 0;
}

.p-message {
  margin: 1rem 0;
  border-radius: 4px;
}
.p-message .p-message-wrapper {
  padding: 1.25rem 1.5rem;
}
.p-message .p-message-close {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background: transparent;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-message .p-message-close:hover {
  background: rgba(255, 255, 255, 0.3);
}
.p-message .p-message-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-message.p-message-info {
  background: #B3E5FC;
  border: solid transparent;
  border-width: 0 0 0 0;
  color: #01579B;
}
.p-message.p-message-info .p-message-icon {
  color: #01579B;
}
.p-message.p-message-info .p-message-close {
  color: #01579B;
}
.p-message.p-message-success {
  background: #C8E6C9;
  border: solid transparent;
  border-width: 0 0 0 0;
  color: #1B5E20;
}
.p-message.p-message-success .p-message-icon {
  color: #1B5E20;
}
.p-message.p-message-success .p-message-close {
  color: #1B5E20;
}
.p-message.p-message-warn {
  background: #FFECB3;
  border: solid transparent;
  border-width: 0 0 0 0;
  color: #7f6003;
}
.p-message.p-message-warn .p-message-icon {
  color: #7f6003;
}
.p-message.p-message-warn .p-message-close {
  color: #7f6003;
}
.p-message.p-message-error {
  background: #FFCDD2;
  border: solid transparent;
  border-width: 0 0 0 0;
  color: #B71C1C;
}
.p-message.p-message-error .p-message-icon {
  color: #B71C1C;
}
.p-message.p-message-error .p-message-close {
  color: #B71C1C;
}
.p-message .p-message-text {
  font-size: 1rem;
  font-weight: 500;
}
.p-message .p-message-icon {
  font-size: 1.5rem;
  margin-right: 0.5rem;
}
.p-message .p-message-summary {
  font-weight: 700;
}
.p-message .p-message-detail {
  margin-left: 0.5rem;
}

.p-toast {
  opacity: 0.9;
}
.p-toast .p-toast-message {
  margin: 0 0 1rem 0;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}
.p-toast .p-toast-message .p-toast-message-content {
  padding: 1.5rem;
  border-width: 0 0 0 0;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-message-text {
  margin: 0 0 0 1rem;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-message-icon {
  font-size: 2rem;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-summary {
  font-weight: 700;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
  margin: 0.5rem 0 0 0;
}
.p-toast .p-toast-message .p-toast-icon-close {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background: transparent;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-toast .p-toast-message .p-toast-icon-close:hover {
  background: rgba(255, 255, 255, 0.3);
}
.p-toast .p-toast-message .p-toast-icon-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-toast .p-toast-message.p-toast-message-info {
  background: #B3E5FC;
  border: solid transparent;
  border-width: 0 0 0 0;
  color: #01579B;
}
.p-toast .p-toast-message.p-toast-message-info .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-info .p-toast-icon-close {
  color: #01579B;
}
.p-toast .p-toast-message.p-toast-message-success {
  background: #C8E6C9;
  border: solid transparent;
  border-width: 0 0 0 0;
  color: #1B5E20;
}
.p-toast .p-toast-message.p-toast-message-success .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close {
  color: #1B5E20;
}
.p-toast .p-toast-message.p-toast-message-warn {
  background: #FFECB3;
  border: solid transparent;
  border-width: 0 0 0 0;
  color: #7f6003;
}
.p-toast .p-toast-message.p-toast-message-warn .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-warn .p-toast-icon-close {
  color: #7f6003;
}
.p-toast .p-toast-message.p-toast-message-error {
  background: #FFCDD2;
  border: solid transparent;
  border-width: 0 0 0 0;
  color: #B71C1C;
}
.p-toast .p-toast-message.p-toast-message-error .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-error .p-toast-icon-close {
  color: #B71C1C;
}

.p-galleria .p-galleria-close {
  margin: 0.5rem;
  background: transparent;
  color: rgba(255, 255, 255, 0.87);
  width: 4rem;
  height: 4rem;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 50%;
}
.p-galleria .p-galleria-close .p-galleria-close-icon {
  font-size: 2rem;
}
.p-galleria .p-galleria-close:hover {
  background: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.87);
}
.p-galleria .p-galleria-item-nav {
  background: transparent;
  color: #f6f6f6;
  width: 4rem;
  height: 4rem;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 50%;
  margin: 0 0.5rem;
}
.p-galleria .p-galleria-item-nav .p-galleria-item-prev-icon,
.p-galleria .p-galleria-item-nav .p-galleria-item-next-icon {
  font-size: 2rem;
}
.p-galleria .p-galleria-item-nav:not(.p-disabled):hover {
  background: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.87);
}
.p-galleria .p-galleria-caption {
  background: rgba(0, 0, 0, 0.5);
  color: rgba(255, 255, 255, 0.87);
  padding: 1rem;
}
.p-galleria .p-galleria-indicators {
  padding: 1rem;
}
.p-galleria .p-galleria-indicators .p-galleria-indicator button {
  background-color: #dcdcdc;
  width: 1.25rem;
  height: 1.25rem;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 50%;
}
.p-galleria .p-galleria-indicators .p-galleria-indicator button:hover {
  background: #ececec;
}
.p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
  background: rgba(30, 136, 229, 0.12);
  color: #1E88E5;
}
.p-galleria.p-galleria-indicators-bottom .p-galleria-indicator, .p-galleria.p-galleria-indicators-top .p-galleria-indicator {
  margin-right: 0.5rem;
}
.p-galleria.p-galleria-indicators-left .p-galleria-indicator, .p-galleria.p-galleria-indicators-right .p-galleria-indicator {
  margin-bottom: 0.5rem;
}
.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators {
  background: rgba(0, 0, 0, 0.5);
}
.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator button {
  background: rgba(255, 255, 255, 0.4);
}
.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator button:hover {
  background: rgba(255, 255, 255, 0.6);
}
.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator.p-highlight button {
  background: rgba(30, 136, 229, 0.12);
  color: #1E88E5;
}
.p-galleria .p-galleria-thumbnail-container {
  background: rgba(0, 0, 0, 0.9);
  padding: 1rem 0.25rem;
}
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev,
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next {
  margin: 0.5rem;
  background-color: transparent;
  color: rgba(255, 255, 255, 0.87);
  width: 2rem;
  height: 2rem;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 50%;
}
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev:hover,
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next:hover {
  background: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.87);
}
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-item-content:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-galleria-mask {
  --maskbg: rgba(0, 0, 0, 0.9);
}

.p-image-mask {
  --maskbg: rgba(0, 0, 0, 0.9);
}

.p-image-preview-indicator {
  background-color: transparent;
  color: #f8f9fa;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-image-preview-container:hover > .p-image-preview-indicator {
  background-color: rgba(0, 0, 0, 0.5);
}

.p-image-toolbar {
  padding: 1rem;
}

.p-image-action.p-link {
  color: #f8f9fa;
  background-color: transparent;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  margin-right: 0.5rem;
}
.p-image-action.p-link:last-child {
  margin-right: 0;
}
.p-image-action.p-link:hover {
  color: #f8f9fa;
  background-color: rgba(255, 255, 255, 0.1);
}
.p-image-action.p-link i {
  font-size: 1.5rem;
}

.p-avatar {
  background-color: rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}
.p-avatar.p-avatar-lg {
  width: 3rem;
  height: 3rem;
  font-size: 1.5rem;
}
.p-avatar.p-avatar-lg .p-avatar-icon {
  font-size: 1.5rem;
}
.p-avatar.p-avatar-xl {
  width: 4rem;
  height: 4rem;
  font-size: 2rem;
}
.p-avatar.p-avatar-xl .p-avatar-icon {
  font-size: 2rem;
}

.p-avatar-group .p-avatar {
  border: 2px solid #ffffff;
}

.p-badge {
  background: #1E88E5;
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 700;
  min-width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
}
.p-badge.p-badge-secondary {
  background-color: #FFB300;
  color: #212121;
}
.p-badge.p-badge-success {
  background-color: #689F38;
  color: #ffffff;
}
.p-badge.p-badge-info {
  background-color: #2196F3;
  color: #ffffff;
}
.p-badge.p-badge-warning {
  background-color: #FBC02D;
  color: #212529;
}
.p-badge.p-badge-danger {
  background-color: #D32F2F;
  color: #ffffff;
}
.p-badge.p-badge-lg {
  font-size: 1.125rem;
  min-width: 2.25rem;
  height: 2.25rem;
  line-height: 2.25rem;
}
.p-badge.p-badge-xl {
  font-size: 1.5rem;
  min-width: 3rem;
  height: 3rem;
  line-height: 3rem;
}

.p-chip {
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 16px;
  padding: 0 1rem;
}
.p-chip .p-chip-text {
  line-height: 1.5;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.p-chip .p-chip-icon {
  margin-right: 0.5rem;
}
.p-chip .pi-chip-remove-icon {
  margin-left: 0.5rem;
}
.p-chip img {
  width: 2.5rem;
  height: 2.5rem;
  margin-left: -1rem;
  margin-right: 0.5rem;
}
.p-chip .pi-chip-remove-icon {
  border-radius: 4px;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-chip .pi-chip-remove-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-inplace .p-inplace-display {
  padding: 1rem 1rem;
  border-radius: 4px;
  transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
}
.p-inplace .p-inplace-display:not(.p-disabled):hover {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.p-inplace .p-inplace-display:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-progressbar {
  border: 0 none;
  height: 4px;
  background: rgba(30, 136, 229, 0.32);
  border-radius: 4px;
}
.p-progressbar .p-progressbar-value {
  border: 0 none;
  margin: 0;
  background: #1E88E5;
}
.p-progressbar .p-progressbar-label {
  color: rgba(0, 0, 0, 0.87);
  line-height: 4px;
}

.p-scrolltop {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-scrolltop.p-link {
  background: #FFB300;
}
.p-scrolltop.p-link:hover {
  background: rgba(255, 179, 0, 0.92);
}
.p-scrolltop .p-scrolltop-icon {
  font-size: 1.5rem;
  color: #212121;
}

.p-skeleton {
  background-color: rgba(0, 0, 0, 0.08);
  border-radius: 4px;
}
.p-skeleton:after {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0));
}

.p-tag {
  background: #1E88E5;
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 700;
  padding: 0.25rem 0.4rem;
  border-radius: 4px;
}
.p-tag.p-tag-success {
  background-color: #689F38;
  color: #ffffff;
}
.p-tag.p-tag-info {
  background-color: #2196F3;
  color: #ffffff;
}
.p-tag.p-tag-warning {
  background-color: #FBC02D;
  color: #212529;
}
.p-tag.p-tag-danger {
  background-color: #D32F2F;
  color: #ffffff;
}
.p-tag .p-tag-icon {
  margin-right: 0.25rem;
  font-size: 0.75rem;
}

.p-terminal {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #e0e0e0;
  padding: 1rem;
}
.p-terminal .p-terminal-input {
  font-size: 1rem;
  font-family: Roboto, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif;
}

.p-accordion .p-accordion-tab {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  margin-bottom: 0;
  border-radius: 0;
  position: relative;
  transition: margin-bottom 225ms;
}
.p-accordion .p-accordion-tab:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.p-accordion .p-accordion-tab:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.p-accordion .p-accordion-tab .p-accordion-toggle-icon {
  order: 1;
  margin-left: auto;
  transition: transform 0.2s;
}
.p-accordion .p-accordion-tab:not(.p-accordion-tab-active) .p-accordion-header-link:focus {
  background: #f6f6f6;
}
.p-accordion .p-accordion-tab:not(.p-accordion-tab-active) .p-accordion-toggle-icon {
  transform: rotate(-270deg);
}
.p-accordion .p-accordion-tab.p-accordion-tab-active {
  margin-bottom: 1rem;
}
.p-accordion .p-accordion-tab.p-accordion-tab-active .p-accordion-toggle-icon {
  transform: rotate(-180deg);
}
.p-accordion .p-accordion-tab .p-accordion-header.p-disabled {
  opacity: 1;
}
.p-accordion .p-accordion-tab .p-accordion-header.p-disabled .p-accordion-header-link > * {
  opacity: 0.38;
}

.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled).p-focus {
  box-shadow: inset 0 0 0 1px #1E88E5, inset 0 0 0 1px #1E88E5, inset 0 0 0 1px #1E88E5, inset 0 0 0 1px #1E88E5;
}

.p-input-filled .p-autocomplete .p-autocomplete-multiple-container {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid transparent;
  background: #f5f5f5 no-repeat;
  background-image: linear-gradient(to bottom, #1E88E5, #1E88E5), linear-gradient(to bottom, rgba(0, 0, 0, 0.38), rgba(0, 0, 0, 0.38));
  background-size: 0 2px, 100% 1px;
  background-position: 50% 100%, 50% 100%;
  background-origin: border-box;
}
.p-input-filled .p-autocomplete .p-autocomplete-multiple-container .p-inputtext {
  background-image: none;
  background-color: transparent;
}
.p-input-filled .p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled):hover {
  background-color: #ececec;
  border-color: transparent;
  background-image: linear-gradient(to bottom, #1E88E5, #1E88E5), linear-gradient(to bottom, rgba(0, 0, 0, 0.87), rgba(0, 0, 0, 0.87));
}
.p-input-filled .p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled).p-focus, .p-input-filled .p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled).p-inputwrapper-focus {
  box-shadow: none;
  background-color: #dcdcdc;
  border-color: transparent;
  background-size: 100% 2px, 100% 1px;
}
.p-input-filled .p-autocomplete .p-autocomplete-multiple-container .p-inputtext:enabled:hover, .p-input-filled .p-autocomplete .p-autocomplete-multiple-container .p-inputtext:enabled:focus {
  background-image: none;
  background: transparent;
}

.p-float-label .p-autocomplete-multiple-container .p-autocomplete-token {
  padding: 0.25rem 1rem;
}

.p-input-filled .p-float-label .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token {
  padding-top: 0;
  padding-bottom: 0;
}
.p-input-filled .p-float-label .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token .p-autocomplete-token-icon {
  font-size: 75%;
}
.p-input-filled .p-float-label .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token {
  padding: 0;
}
.p-input-filled p-autocomplete.ng-dirty.ng-invalid .p-autocomplete > .p-inputtext {
  border-color: transparent;
  background-image: linear-gradient(to bottom, #B00020, #B00020), linear-gradient(to bottom, #B00020, #B00020);
}
.p-input-filled p-autocomplete.ng-dirty.ng-invalid .p-autocomplete > .p-inputtext:enabled:focus {
  box-shadow: none;
  border-color: transparent;
}
.p-input-filled p-autocomplete.ng-dirty.ng-invalid .p-autocomplete .p-autocomplete-multiple-container {
  border-color: transparent;
  background-image: linear-gradient(to bottom, #B00020, #B00020), linear-gradient(to bottom, #B00020, #B00020);
}
.p-input-filled p-autocomplete.ng-dirty.ng-invalid .p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled).p-focus {
  box-shadow: none;
  border-color: transparent;
}

p-autocomplete.ng-dirty.ng-invalid .p-autocomplete > .p-inputtext:enabled:focus {
  box-shadow: inset 0 0 0 1px #B00020, inset 0 0 0 1px #B00020, inset 0 0 0 1px #B00020, inset 0 0 0 1px #B00020;
}
p-autocomplete.ng-dirty.ng-invalid .p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled).p-focus {
  box-shadow: inset 0 0 0 1px #B00020, inset 0 0 0 1px #B00020, inset 0 0 0 1px #B00020, inset 0 0 0 1px #B00020;
}

.p-button {
  font-weight: 500;
  min-width: 4rem;
}
.p-button.p-button-icon-only {
  min-width: auto;
}
.p-button:enabled:focus {
  background: rgba(30, 136, 229, 0.76);
}
.p-button:enabled:active {
  background: rgba(30, 136, 229, 0.68);
}
.p-button .p-ink {
  background-color: rgba(255, 255, 255, 0.32);
}
.p-button.p-button-outlined {
  box-shadow: inset 0 0 0 1px;
}
.p-button.p-button-outlined:enabled:focus {
  box-shadow: inset 0 0 0 1px;
}
.p-button.p-button-outlined:enabled:active {
  box-shadow: inset 0 0 0 1px;
}
.p-button.p-button-text:enabled:focus, .p-button.p-button-outlined:enabled:focus {
  background: rgba(30, 136, 229, 0.12);
}
.p-button.p-button-text:enabled:active, .p-button.p-button-outlined:enabled:active {
  background: rgba(30, 136, 229, 0.16);
}
.p-button.p-button-text .p-ink, .p-button.p-button-outlined .p-ink {
  background-color: rgba(30, 136, 229, 0.16);
}
.p-button:disabled {
  background-color: rgba(0, 0, 0, 0.12) !important;
  color: rgba(0, 0, 0, 0.38) !important;
  opacity: 1;
}
.p-button:disabled.p-button-text {
  background-color: transparent !important;
  color: rgba(0, 0, 0, 0.38) !important;
}
.p-button:disabled.p-button-outlined {
  background-color: transparent !important;
  color: rgba(0, 0, 0, 0.38) !important;
  border-color: rgba(0, 0, 0, 0.38) !important;
}
.p-button.p-button-raised:enabled:focus {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.p-button.p-button-secondary:enabled:focus, .p-buttonset.p-button-secondary > .p-button:enabled:focus, .p-splitbutton.p-button-secondary > .p-button:enabled:focus {
  background: rgba(255, 179, 0, 0.76);
}
.p-button.p-button-secondary:enabled:active, .p-buttonset.p-button-secondary > .p-button:enabled:active, .p-splitbutton.p-button-secondary > .p-button:enabled:active {
  background: rgba(255, 179, 0, 0.68);
}
.p-button.p-button-secondary.p-button-text:enabled:focus, .p-button.p-button-secondary.p-button-outlined:enabled:focus, .p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:focus, .p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:focus, .p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:focus, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:focus {
  background: rgba(255, 179, 0, 0.12);
}
.p-button.p-button-secondary.p-button-text:enabled:active, .p-button.p-button-secondary.p-button-outlined:enabled:active, .p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:active, .p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:active {
  background: rgba(255, 179, 0, 0.16);
}
.p-button.p-button-secondary.p-button-text .p-ink, .p-button.p-button-secondary.p-button-outlined .p-ink, .p-buttonset.p-button-secondary > .p-button.p-button-text .p-ink, .p-buttonset.p-button-secondary > .p-button.p-button-outlined .p-ink, .p-splitbutton.p-button-secondary > .p-button.p-button-text .p-ink, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined .p-ink {
  background-color: rgba(255, 179, 0, 0.16);
}

.p-button.p-button-info:enabled:focus, .p-buttonset.p-button-info > .p-button:enabled:focus, .p-splitbutton.p-button-info > .p-button:enabled:focus {
  background: rgba(33, 150, 243, 0.76);
}
.p-button.p-button-info:enabled:active, .p-buttonset.p-button-info > .p-button:enabled:active, .p-splitbutton.p-button-info > .p-button:enabled:active {
  background: rgba(33, 150, 243, 0.68);
}
.p-button.p-button-info.p-button-text:enabled:focus, .p-button.p-button-info.p-button-outlined:enabled:focus, .p-buttonset.p-button-info > .p-button.p-button-text:enabled:focus, .p-buttonset.p-button-info > .p-button.p-button-outlined:enabled:focus, .p-splitbutton.p-button-info > .p-button.p-button-text:enabled:focus, .p-splitbutton.p-button-info > .p-button.p-button-outlined:enabled:focus {
  background: rgba(33, 150, 243, 0.12);
}
.p-button.p-button-info.p-button-text:enabled:active, .p-button.p-button-info.p-button-outlined:enabled:active, .p-buttonset.p-button-info > .p-button.p-button-text:enabled:active, .p-buttonset.p-button-info > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-info > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-info > .p-button.p-button-outlined:enabled:active {
  background: rgba(33, 150, 243, 0.16);
}
.p-button.p-button-info.p-button-text .p-ink, .p-button.p-button-info.p-button-outlined .p-ink, .p-buttonset.p-button-info > .p-button.p-button-text .p-ink, .p-buttonset.p-button-info > .p-button.p-button-outlined .p-ink, .p-splitbutton.p-button-info > .p-button.p-button-text .p-ink, .p-splitbutton.p-button-info > .p-button.p-button-outlined .p-ink {
  background-color: rgba(33, 150, 243, 0.16);
}

.p-button.p-button-success:enabled:focus, .p-buttonset.p-button-success > .p-button:enabled:focus, .p-splitbutton.p-button-success > .p-button:enabled:focus {
  background: rgba(104, 159, 56, 0.76);
}
.p-button.p-button-success:enabled:active, .p-buttonset.p-button-success > .p-button:enabled:active, .p-splitbutton.p-button-success > .p-button:enabled:active {
  background: rgba(104, 159, 56, 0.68);
}
.p-button.p-button-success.p-button-text:enabled:focus, .p-button.p-button-success.p-button-outlined:enabled:focus, .p-buttonset.p-button-success > .p-button.p-button-text:enabled:focus, .p-buttonset.p-button-success > .p-button.p-button-outlined:enabled:focus, .p-splitbutton.p-button-success > .p-button.p-button-text:enabled:focus, .p-splitbutton.p-button-success > .p-button.p-button-outlined:enabled:focus {
  background: rgba(104, 159, 56, 0.12);
}
.p-button.p-button-success.p-button-text:enabled:active, .p-button.p-button-success.p-button-outlined:enabled:active, .p-buttonset.p-button-success > .p-button.p-button-text:enabled:active, .p-buttonset.p-button-success > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-success > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-success > .p-button.p-button-outlined:enabled:active {
  background: rgba(104, 159, 56, 0.16);
}
.p-button.p-button-success.p-button-text .p-ink, .p-button.p-button-success.p-button-outlined .p-ink, .p-buttonset.p-button-success > .p-button.p-button-text .p-ink, .p-buttonset.p-button-success > .p-button.p-button-outlined .p-ink, .p-splitbutton.p-button-success > .p-button.p-button-text .p-ink, .p-splitbutton.p-button-success > .p-button.p-button-outlined .p-ink {
  background-color: rgba(104, 159, 56, 0.16);
}

.p-button.p-button-warning:enabled:focus, .p-buttonset.p-button-warning > .p-button:enabled:focus, .p-splitbutton.p-button-warning > .p-button:enabled:focus {
  background: rgba(251, 192, 45, 0.76);
}
.p-button.p-button-warning:enabled:active, .p-buttonset.p-button-warning > .p-button:enabled:active, .p-splitbutton.p-button-warning > .p-button:enabled:active {
  background: rgba(251, 192, 45, 0.68);
}
.p-button.p-button-warning.p-button-text:enabled:focus, .p-button.p-button-warning.p-button-outlined:enabled:focus, .p-buttonset.p-button-warning > .p-button.p-button-text:enabled:focus, .p-buttonset.p-button-warning > .p-button.p-button-outlined:enabled:focus, .p-splitbutton.p-button-warning > .p-button.p-button-text:enabled:focus, .p-splitbutton.p-button-warning > .p-button.p-button-outlined:enabled:focus {
  background: rgba(251, 192, 45, 0.12);
}
.p-button.p-button-warning.p-button-text:enabled:active, .p-button.p-button-warning.p-button-outlined:enabled:active, .p-buttonset.p-button-warning > .p-button.p-button-text:enabled:active, .p-buttonset.p-button-warning > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-warning > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-warning > .p-button.p-button-outlined:enabled:active {
  background: rgba(251, 192, 45, 0.16);
}
.p-button.p-button-warning.p-button-text .p-ink, .p-button.p-button-warning.p-button-outlined .p-ink, .p-buttonset.p-button-warning > .p-button.p-button-text .p-ink, .p-buttonset.p-button-warning > .p-button.p-button-outlined .p-ink, .p-splitbutton.p-button-warning > .p-button.p-button-text .p-ink, .p-splitbutton.p-button-warning > .p-button.p-button-outlined .p-ink {
  background-color: rgba(251, 192, 45, 0.16);
}

.p-button.p-button-help:enabled:focus, .p-buttonset.p-button-help > .p-button:enabled:focus, .p-splitbutton.p-button-help > .p-button:enabled:focus {
  background: rgba(156, 39, 176, 0.76);
}
.p-button.p-button-help:enabled:active, .p-buttonset.p-button-help > .p-button:enabled:active, .p-splitbutton.p-button-help > .p-button:enabled:active {
  background: rgba(156, 39, 176, 0.68);
}
.p-button.p-button-help.p-button-text:enabled:focus, .p-button.p-button-help.p-button-outlined:enabled:focus, .p-buttonset.p-button-help > .p-button.p-button-text:enabled:focus, .p-buttonset.p-button-help > .p-button.p-button-outlined:enabled:focus, .p-splitbutton.p-button-help > .p-button.p-button-text:enabled:focus, .p-splitbutton.p-button-help > .p-button.p-button-outlined:enabled:focus {
  background: rgba(156, 39, 176, 0.12);
}
.p-button.p-button-help.p-button-text:enabled:active, .p-button.p-button-help.p-button-outlined:enabled:active, .p-buttonset.p-button-help > .p-button.p-button-text:enabled:active, .p-buttonset.p-button-help > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-help > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-help > .p-button.p-button-outlined:enabled:active {
  background: rgba(156, 39, 176, 0.16);
}
.p-button.p-button-help.p-button-text .p-ink, .p-button.p-button-help.p-button-outlined .p-ink, .p-buttonset.p-button-help > .p-button.p-button-text .p-ink, .p-buttonset.p-button-help > .p-button.p-button-outlined .p-ink, .p-splitbutton.p-button-help > .p-button.p-button-text .p-ink, .p-splitbutton.p-button-help > .p-button.p-button-outlined .p-ink {
  background-color: rgba(156, 39, 176, 0.16);
}

.p-button.p-button-danger:enabled:focus, .p-buttonset.p-button-danger > .p-button:enabled:focus, .p-splitbutton.p-button-danger > .p-button:enabled:focus {
  background: rgba(211, 47, 47, 0.76);
}
.p-button.p-button-danger:enabled:active, .p-buttonset.p-button-danger > .p-button:enabled:active, .p-splitbutton.p-button-danger > .p-button:enabled:active {
  background: rgba(211, 47, 47, 0.68);
}
.p-button.p-button-danger.p-button-text:enabled:focus, .p-button.p-button-danger.p-button-outlined:enabled:focus, .p-buttonset.p-button-danger > .p-button.p-button-text:enabled:focus, .p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:focus, .p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:focus, .p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:focus {
  background: rgba(211, 47, 47, 0.12);
}
.p-button.p-button-danger.p-button-text:enabled:active, .p-button.p-button-danger.p-button-outlined:enabled:active, .p-buttonset.p-button-danger > .p-button.p-button-text:enabled:active, .p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:active {
  background: rgba(211, 47, 47, 0.16);
}
.p-button.p-button-danger.p-button-text .p-ink, .p-button.p-button-danger.p-button-outlined .p-ink, .p-buttonset.p-button-danger > .p-button.p-button-text .p-ink, .p-buttonset.p-button-danger > .p-button.p-button-outlined .p-ink, .p-splitbutton.p-button-danger > .p-button.p-button-text .p-ink, .p-splitbutton.p-button-danger > .p-button.p-button-outlined .p-ink {
  background-color: rgba(211, 47, 47, 0.16);
}

.p-calendar-w-btn {
  border: 1px solid rgba(0, 0, 0, 0.38);
  background: #ffffff;
  border-radius: 4px;
  transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
}
.p-calendar-w-btn .p-inputtext {
  background-image: none;
  background-color: transparent;
  border: 0 none;
}
.p-calendar-w-btn .p-inputtext:enabled:focus {
  box-shadow: none;
}
.p-calendar-w-btn .p-datepicker-trigger.p-button {
  background-color: transparent;
  border: 0 none;
}
.p-calendar-w-btn .p-datepicker-trigger.p-button span {
  color: rgba(0, 0, 0, 0.6);
}
.p-calendar-w-btn .p-datepicker-trigger.p-button:enabled:hover {
  background: rgba(0, 0, 0, 0.04);
}
.p-calendar-w-btn .p-datepicker-trigger.p-button:enabled:active, .p-calendar-w-btn .p-datepicker-trigger.p-button:focus {
  background: rgba(0, 0, 0, 0.12);
}
.p-calendar-w-btn:not(.p-disabled):hover {
  border-color: rgba(0, 0, 0, 0.87);
}
.p-calendar-w-btn:not(.p-disabled).p-focus {
  border-color: #1E88E5;
  box-shadow: inset 0 0 0 1px #1E88E5, inset 0 0 0 1px #1E88E5, inset 0 0 0 1px #1E88E5, inset 0 0 0 1px #1E88E5;
}

.p-datepicker .p-datepicker-header {
  border-bottom: 0 none;
}
.p-datepicker .p-datepicker-header .p-datepicker-title {
  margin: 0 auto 0 0;
  order: 1;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev {
  order: 2;
}
.p-datepicker .p-datepicker-header .p-datepicker-next {
  order: 3;
}
.p-datepicker table th {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.38);
  font-weight: 400;
  font-size: 0.875rem;
}
.p-datepicker table td.p-datepicker-today > span {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.38);
}
.p-datepicker table td.p-datepicker-today.p-highlight {
  box-shadow: 0 0 0 1px rgba(30, 136, 229, 0.12);
}

p-calendar.ng-dirty.ng-invalid .p-inputtext:enabled:focus {
  box-shadow: inset 0 0 0 1px #B00020, inset 0 0 0 1px #B00020, inset 0 0 0 1px #B00020, inset 0 0 0 1px #B00020;
}
p-calendar.ng-dirty.ng-invalid .p-calendar-w-btn {
  border-color: #B00020;
}
p-calendar.ng-dirty.ng-invalid .p-calendar-w-btn .p-inputtext:enabled:focus {
  box-shadow: none;
}
p-calendar.ng-dirty.ng-invalid .p-calendar-w-btn:not(.p-disabled).p-focus {
  box-shadow: inset 0 0 0 1px #B00020, inset 0 0 0 1px #B00020, inset 0 0 0 1px #B00020, inset 0 0 0 1px #B00020;
}

.p-input-filled .p-calendar-w-btn {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid transparent;
  background: #f5f5f5 no-repeat;
  background-image: linear-gradient(to bottom, #1E88E5, #1E88E5), linear-gradient(to bottom, rgba(0, 0, 0, 0.38), rgba(0, 0, 0, 0.38));
  background-size: 0 2px, 100% 1px;
  background-position: 50% 100%, 50% 100%;
  background-origin: border-box;
}
.p-input-filled .p-calendar-w-btn .p-inputtext {
  background-image: none;
  background-color: transparent;
}
.p-input-filled .p-calendar-w-btn:not(.p-disabled):hover {
  background-color: #ececec;
  border-color: transparent;
  background-image: linear-gradient(to bottom, #1E88E5, #1E88E5), linear-gradient(to bottom, rgba(0, 0, 0, 0.87), rgba(0, 0, 0, 0.87));
}
.p-input-filled .p-calendar-w-btn:not(.p-disabled).p-focus, .p-input-filled .p-calendar-w-btn:not(.p-disabled).p-inputwrapper-focus {
  box-shadow: none;
  background-color: #dcdcdc;
  border-color: transparent;
  background-size: 100% 2px, 100% 1px;
}
.p-input-filled .p-calendar-w-btn .p-inputtext:enabled:hover, .p-input-filled .p-calendar-w-btn .p-inputtext:enabled:focus {
  background-image: none;
  background: transparent;
}
.p-input-filled .p-calendar-w-btn .p-inputtext {
  border: 0 none;
}
.p-input-filled p-calendar.ng-dirty.ng-invalid .p-inputtext {
  border-color: transparent;
  background-image: linear-gradient(to bottom, #B00020, #B00020), linear-gradient(to bottom, #B00020, #B00020);
}
.p-input-filled p-calendar.ng-dirty.ng-invalid .p-inputtext:enabled:focus {
  box-shadow: none;
  border-color: transparent;
}
.p-input-filled p-calendar.ng-dirty.ng-invalid .p-calendar-w-btn {
  border-color: transparent;
  background-image: linear-gradient(to bottom, #B00020, #B00020), linear-gradient(to bottom, #B00020, #B00020);
}
.p-input-filled p-calendar.ng-dirty.ng-invalid .p-calendar-w-btn:not(.p-disabled).p-focus {
  box-shadow: none;
  border-color: transparent;
}
.p-input-filled p-calendar.ng-dirty.ng-invalid .p-calendar-w-btn .p-inputtext {
  border: 0 none;
  background-image: none;
}

.p-cascadeselect .p-inputtext, .p-cascadeselect .p-cascadeselect-trigger {
  background-image: none;
  background: transparent;
}
.p-cascadeselect .p-inputtext {
  border: 0 none;
}
.p-cascadeselect:not(.p-disabled).p-focus {
  box-shadow: inset 0 0 0 1px #1E88E5, inset 0 0 0 1px #1E88E5, inset 0 0 0 1px #1E88E5, inset 0 0 0 1px #1E88E5;
}

.p-cascadeselect-item .p-ink {
  background-color: rgba(30, 136, 229, 0.16);
}

.p-input-filled .p-cascadeselect {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid transparent;
  background: #f5f5f5 no-repeat;
  background-image: linear-gradient(to bottom, #1E88E5, #1E88E5), linear-gradient(to bottom, rgba(0, 0, 0, 0.38), rgba(0, 0, 0, 0.38));
  background-size: 0 2px, 100% 1px;
  background-position: 50% 100%, 50% 100%;
  background-origin: border-box;
}
.p-input-filled .p-cascadeselect .p-inputtext {
  background-image: none;
  background-color: transparent;
}
.p-input-filled .p-cascadeselect:not(.p-disabled):hover {
  background-color: #ececec;
  border-color: transparent;
  background-image: linear-gradient(to bottom, #1E88E5, #1E88E5), linear-gradient(to bottom, rgba(0, 0, 0, 0.87), rgba(0, 0, 0, 0.87));
}
.p-input-filled .p-cascadeselect:not(.p-disabled).p-focus, .p-input-filled .p-cascadeselect:not(.p-disabled).p-inputwrapper-focus {
  box-shadow: none;
  background-color: #dcdcdc;
  border-color: transparent;
  background-size: 100% 2px, 100% 1px;
}
.p-input-filled .p-cascadeselect .p-inputtext:enabled:hover, .p-input-filled .p-cascadeselect .p-inputtext:enabled:focus {
  background-image: none;
  background: transparent;
}
.p-input-filled .p-cascadeselect .p-inputtext {
  border: 0 none;
}
.p-input-filled p-cascadeselect.ng-dirty.ng-invalid .p-cascadeselect {
  border-color: transparent;
  background-image: linear-gradient(to bottom, #B00020, #B00020), linear-gradient(to bottom, #B00020, #B00020);
}
.p-input-filled p-cascadeselect.ng-dirty.ng-invalid .p-cascadeselect:not(.p-disabled).p-focus {
  box-shadow: none;
  border-color: transparent;
}

p-cascadeselect.ng-dirty.ng-invalid .p-cascadeselect:not(.p-disabled).p-focus {
  box-shadow: inset 0 0 0 1px #B00020, inset 0 0 0 1px #B00020, inset 0 0 0 1px #B00020, inset 0 0 0 1px #B00020;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
  background: #FFB300;
  color: #212121;
}

.p-checkbox {
  border-radius: 50%;
  transition: box-shadow 0.2s;
}
.p-checkbox .p-checkbox-box {
  border-color: #757575;
  border-radius: 2px;
  position: relative;
}
.p-checkbox .p-checkbox-box:not(.p-disabled):hover {
  border-color: #757575;
}
.p-checkbox .p-checkbox-box:not(.p-disabled).p-focus {
  border-color: #757575;
}
.p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled).p-focus {
  border-color: #1E88E5;
}
.p-checkbox .p-checkbox-box.p-highlight .p-checkbox-icon.pi-check:before {
  content: "";
  position: absolute;
  top: 6px;
  left: 1px;
  border-right: 2px solid transparent;
  border-bottom: 2px solid transparent;
  transform: rotate(45deg);
  transform-origin: 0% 100%;
  animation: checkbox-check 125ms 50ms linear forwards;
}
.p-checkbox:not(.p-checkbox-disabled):hover {
  box-shadow: 0 0 1px 10px rgba(0, 0, 0, 0.04);
}
.p-checkbox:not(.p-checkbox-disabled).p-checkbox-focused {
  box-shadow: 0 0 1px 10px rgba(0, 0, 0, 0.12);
}
.p-checkbox.p-checkbox-checked:not(.p-checkbox-disabled):hover {
  box-shadow: 0 0 1px 10px rgba(30, 136, 229, 0.04);
}
.p-checkbox.p-checkbox-checked:not(.p-checkbox-disabled).p-checkbox-focused {
  box-shadow: 0 0 1px 10px rgba(30, 136, 229, 0.12);
}

.p-input-filled .p-checkbox .p-checkbox-box {
  background-color: #ffffff;
}
.p-input-filled .p-checkbox .p-checkbox-box:not(.p-disabled):hover {
  background-color: #ffffff;
}

@keyframes checkbox-check {
  0% {
    width: 0;
    height: 0;
    border-color: #ffffff;
    transform: translate3d(0, 0, 0) rotate(45deg);
  }
  33% {
    width: 4px;
    height: 0;
    transform: translate3d(0, 0, 0) rotate(45deg);
  }
  100% {
    width: 4px;
    height: 10px;
    border-color: #ffffff;
    transform: translate3d(0, -10px, 0) rotate(45deg);
  }
}
.p-chips .p-chips-multiple-container:not(.p-disabled).p-focus {
  box-shadow: inset 0 0 0 1px #1E88E5, inset 0 0 0 1px #1E88E5, inset 0 0 0 1px #1E88E5, inset 0 0 0 1px #1E88E5;
}

.p-input-filled .p-chips-multiple-container {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid transparent;
  background: #f5f5f5 no-repeat;
  background-image: linear-gradient(to bottom, #1E88E5, #1E88E5), linear-gradient(to bottom, rgba(0, 0, 0, 0.38), rgba(0, 0, 0, 0.38));
  background-size: 0 2px, 100% 1px;
  background-position: 50% 100%, 50% 100%;
  background-origin: border-box;
}
.p-input-filled .p-chips-multiple-container .p-inputtext {
  background-image: none;
  background-color: transparent;
}
.p-input-filled .p-chips-multiple-container:not(.p-disabled):hover {
  background-color: #ececec;
  border-color: transparent;
  background-image: linear-gradient(to bottom, #1E88E5, #1E88E5), linear-gradient(to bottom, rgba(0, 0, 0, 0.87), rgba(0, 0, 0, 0.87));
}
.p-input-filled .p-chips-multiple-container:not(.p-disabled).p-focus, .p-input-filled .p-chips-multiple-container:not(.p-disabled).p-inputwrapper-focus {
  box-shadow: none;
  background-color: #dcdcdc;
  border-color: transparent;
  background-size: 100% 2px, 100% 1px;
}
.p-input-filled .p-chips-multiple-container .p-inputtext:enabled:hover, .p-input-filled .p-chips-multiple-container .p-inputtext:enabled:focus {
  background-image: none;
  background: transparent;
}

.p-float-label .p-chips-multiple-container .p-chips-token {
  padding: 0.25rem 1rem;
}

.p-input-filled .p-float-label .p-chips .p-chips-multiple-container .p-chips-token {
  padding-top: 0;
  padding-bottom: 0;
}
.p-input-filled .p-float-label .p-chips .p-chips-multiple-container .p-chips-token .p-chips-token-icon {
  font-size: 75%;
}
.p-input-filled .p-float-label .p-chips .p-chips-multiple-container .p-chips-input-token {
  padding: 0;
}
.p-input-filled p-chips.ng-dirty.ng-invalid .p-chips .p-chips-multiple-container {
  border-color: transparent;
  background-image: linear-gradient(to bottom, #B00020, #B00020), linear-gradient(to bottom, #B00020, #B00020);
}
.p-input-filled p-chips.ng-dirty.ng-invalid .p-chips .p-chips-multiple-container:not(.p-disabled).p-focus {
  box-shadow: none;
  border-color: transparent;
}

p-chips.ng-dirty.ng-invalid .p-chips .p-chips-multiple-container:not(.p-disabled).p-focus {
  box-shadow: inset 0 0 0 1px #B00020, inset 0 0 0 1px #B00020, inset 0 0 0 1px #B00020, inset 0 0 0 1px #B00020;
}

.p-datatable .p-sortable-column {
  outline: 0 none;
}
.p-datatable .p-sortable-column:focus {
  background-color: rgba(0, 0, 0, 0.03);
}
.p-datatable .p-datatable-tbody > tr {
  outline: 0 none;
}
.p-datatable .p-datatable-tbody > tr:not(.p-highlight):focus {
  background-color: rgba(0, 0, 0, 0.03);
}

.p-dropdown .p-inputtext, .p-dropdown .p-dropdown-trigger {
  background-image: none;
  background: transparent;
}
.p-dropdown .p-inputtext {
  border: 0 none;
}
.p-dropdown:not(.p-disabled).p-focus {
  box-shadow: inset 0 0 0 1px #1E88E5, inset 0 0 0 1px #1E88E5, inset 0 0 0 1px #1E88E5, inset 0 0 0 1px #1E88E5;
}

.p-dropdown-item .p-ink {
  background-color: rgba(30, 136, 229, 0.16);
}

.p-input-filled .p-dropdown {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid transparent;
  background: #f5f5f5 no-repeat;
  background-image: linear-gradient(to bottom, #1E88E5, #1E88E5), linear-gradient(to bottom, rgba(0, 0, 0, 0.38), rgba(0, 0, 0, 0.38));
  background-size: 0 2px, 100% 1px;
  background-position: 50% 100%, 50% 100%;
  background-origin: border-box;
}
.p-input-filled .p-dropdown .p-inputtext {
  background-image: none;
  background-color: transparent;
}
.p-input-filled .p-dropdown:not(.p-disabled):hover {
  background-color: #ececec;
  border-color: transparent;
  background-image: linear-gradient(to bottom, #1E88E5, #1E88E5), linear-gradient(to bottom, rgba(0, 0, 0, 0.87), rgba(0, 0, 0, 0.87));
}
.p-input-filled .p-dropdown:not(.p-disabled).p-focus, .p-input-filled .p-dropdown:not(.p-disabled).p-inputwrapper-focus {
  box-shadow: none;
  background-color: #dcdcdc;
  border-color: transparent;
  background-size: 100% 2px, 100% 1px;
}
.p-input-filled .p-dropdown .p-inputtext:enabled:hover, .p-input-filled .p-dropdown .p-inputtext:enabled:focus {
  background-image: none;
  background: transparent;
}
.p-input-filled .p-dropdown .p-inputtext {
  border: 0 none;
}
.p-input-filled p-dropdown.ng-dirty.ng-invalid .p-dropdown {
  border-color: transparent;
  background-image: linear-gradient(to bottom, #B00020, #B00020), linear-gradient(to bottom, #B00020, #B00020);
}
.p-input-filled p-dropdown.ng-dirty.ng-invalid .p-dropdown:not(.p-disabled).p-focus {
  box-shadow: none;
  border-color: transparent;
}

p-dropdown.ng-dirty.ng-invalid .p-dropdown:not(.p-disabled).p-focus {
  box-shadow: inset 0 0 0 1px #B00020, inset 0 0 0 1px #B00020, inset 0 0 0 1px #B00020, inset 0 0 0 1px #B00020;
}

.p-galleria .p-galleria-indicators {
  padding: 1rem;
}
.p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
  background: #FFB300;
  color: #212121;
}
.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator.p-highlight button {
  background: rgba(255, 179, 0, 0.68);
  color: #212121;
}

.p-inputtext:enabled:focus {
  box-shadow: inset 0 0 0 1px #1E88E5, inset 0 0 0 1px #1E88E5, inset 0 0 0 1px #1E88E5, inset 0 0 0 1px #1E88E5;
}
.p-inputtext:enabled:focus.ng-invalid.ng-dirty {
  box-shadow: inset 0 0 0 1px #B00020, inset 0 0 0 1px #B00020, inset 0 0 0 1px #B00020, inset 0 0 0 1px #B00020;
}

.p-input-filled .p-inputtext {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid transparent;
  background: #f5f5f5 no-repeat;
  background-image: linear-gradient(to bottom, #1E88E5, #1E88E5), linear-gradient(to bottom, rgba(0, 0, 0, 0.38), rgba(0, 0, 0, 0.38));
  background-size: 0 2px, 100% 1px;
  background-position: 50% 100%, 50% 100%;
  background-origin: border-box;
}
.p-input-filled .p-inputtext:enabled:hover {
  background-color: #ececec;
  border-color: transparent;
  background-image: linear-gradient(to bottom, #1E88E5, #1E88E5), linear-gradient(to bottom, rgba(0, 0, 0, 0.87), rgba(0, 0, 0, 0.87));
}
.p-input-filled .p-inputtext:enabled:focus {
  box-shadow: none;
  background-color: #dcdcdc;
  border-color: transparent;
  background-size: 100% 2px, 100% 1px;
}
.p-input-filled .p-inputtext.ng-invalid.ng-dirty {
  border-color: transparent;
  background-image: linear-gradient(to bottom, #B00020, #B00020), linear-gradient(to bottom, #B00020, #B00020);
}
.p-input-filled .p-inputtext.ng-invalid.ng-dirty:enabled:focus {
  box-shadow: none;
  border-color: transparent;
}

.p-input-filled .p-inputgroup .p-inputgroup-addon {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid transparent;
  background: #f5f5f5 no-repeat;
  background-image: linear-gradient(to bottom, #1E88E5, #1E88E5), linear-gradient(to bottom, rgba(0, 0, 0, 0.38), rgba(0, 0, 0, 0.38));
  background-size: 0 2px, 100% 1px;
  background-position: 50% 100%, 50% 100%;
  background-origin: border-box;
}
.p-input-filled .p-inputgroup .p-inputgroup-addon:last-child {
  border-right-color: transparent;
}
.p-input-filled .p-inputgroup-addon:first-child,
.p-input-filled .p-inputgroup button:first-child,
.p-input-filled .p-inputgroup input:first-child {
  border-bottom-left-radius: 0;
}
.p-input-filled .p-inputgroup .p-float-label:first-child input {
  border-bottom-left-radius: 0;
}
.p-input-filled .p-inputgroup-addon:last-child,
.p-input-filled .p-inputgroup button:last-child,
.p-input-filled .p-inputgroup input:last-child {
  border-bottom-right-radius: 0;
}
.p-input-filled .p-inputgroup .p-float-label:last-child input {
  border-bottom-right-radius: 0;
}

p-inputmask.ng-dirty.ng-invalid .p-inputtext:enabled:focus {
  box-shadow: inset 0 0 0 1px #B00020, inset 0 0 0 1px #B00020, inset 0 0 0 1px #B00020, inset 0 0 0 1px #B00020;
}

.p-input-filled p-inputmask.ng-dirty.ng-invalid .p-inputtext {
  border-color: transparent;
  background-image: linear-gradient(to bottom, #B00020, #B00020), linear-gradient(to bottom, #B00020, #B00020);
}
.p-input-filled p-inputmask.ng-dirty.ng-invalid .p-inputtext:enabled:focus {
  box-shadow: none;
  border-color: transparent;
}

p-inputnumber.ng-dirty.ng-invalid .p-inputtext:enabled:focus {
  box-shadow: inset 0 0 0 1px #B00020, inset 0 0 0 1px #B00020, inset 0 0 0 1px #B00020, inset 0 0 0 1px #B00020;
}

.p-input-filled p-inputnumber.ng-dirty.ng-invalid .p-inputtext {
  border-color: transparent;
  background-image: linear-gradient(to bottom, #B00020, #B00020), linear-gradient(to bottom, #B00020, #B00020);
}
.p-input-filled p-inputnumber.ng-dirty.ng-invalid .p-inputtext:enabled:focus {
  box-shadow: none;
  border-color: transparent;
}

.p-inputswitch .p-inputswitch-slider:before {
  transition-property: box-shadow transform;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider:before {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0 0 1px 10px rgba(0, 0, 0, 0.04);
}
.p-inputswitch.p-inputswitch-focus .p-inputswitch-slider:before, .p-inputswitch.p-inputswitch-focus:not(.p-disabled):hover .p-inputswitch-slider:before {
  box-shadow: 0 0 1px 10px rgba(0, 0, 0, 0.12), 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider:before {
  box-shadow: 0 0 1px 10px rgba(30, 136, 229, 0.04), 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.p-inputswitch.p-inputswitch-checked.p-inputswitch-focus .p-inputswitch-slider:before, .p-inputswitch.p-inputswitch-checked.p-inputswitch-focus:not(.p-disabled):hover .p-inputswitch-slider:before {
  box-shadow: 0 0 1px 10px rgba(30, 136, 229, 0.12), 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.p-fieldset .p-fieldset-legend {
  border: 0 none;
}

.p-float-label input:focus ~ label,
.p-float-label input.p-filled ~ label,
.p-float-label textarea:focus ~ label,
.p-float-label textarea.p-filled ~ label,
.p-float-label .p-inputwrapper-focus ~ label,
.p-float-label .p-inputwrapper-filled ~ label {
  top: -0.5rem !important;
  background-color: #ffffff;
  padding: 2px 4px;
  margin-left: -4px;
  margin-top: 0;
}

.p-float-label textarea ~ label {
  margin-top: 0;
}

.p-float-label input:focus ~ label,
.p-float-label .p-inputwrapper-focus ~ label {
  color: #1E88E5;
}

.p-input-filled .p-float-label .p-inputtext {
  padding-top: 1.5rem;
  padding-bottom: 0.5rem;
}
.p-input-filled .p-float-label input:focus ~ label,
.p-input-filled .p-float-label input.p-filled ~ label,
.p-input-filled .p-float-label textarea:focus ~ label,
.p-input-filled .p-float-label textarea.p-filled ~ label,
.p-input-filled .p-float-label .p-inputwrapper-focus ~ label,
.p-input-filled .p-float-label .p-inputwrapper-filled ~ label {
  top: 0.25rem !important;
  margin-top: 0;
  background: transparent;
}

.p-listbox-item .p-ink {
  background-color: rgba(30, 136, 229, 0.16);
}

.p-multiselect .p-multiselect-label, .p-multiselect .p-multiselect-trigger {
  background-image: none;
  background: transparent;
}
.p-multiselect .p-multiselect-label {
  border: 0 none;
}
.p-multiselect:not(.p-disabled).p-focus {
  box-shadow: inset 0 0 0 1px #1E88E5, inset 0 0 0 1px #1E88E5, inset 0 0 0 1px #1E88E5, inset 0 0 0 1px #1E88E5;
}

.p-multiselect-item .p-ink {
  background-color: rgba(30, 136, 229, 0.16);
}

.p-input-filled .p-multiselect {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid transparent;
  background: #f5f5f5 no-repeat;
  background-image: linear-gradient(to bottom, #1E88E5, #1E88E5), linear-gradient(to bottom, rgba(0, 0, 0, 0.38), rgba(0, 0, 0, 0.38));
  background-size: 0 2px, 100% 1px;
  background-position: 50% 100%, 50% 100%;
  background-origin: border-box;
}
.p-input-filled .p-multiselect .p-inputtext {
  background-image: none;
  background-color: transparent;
}
.p-input-filled .p-multiselect:not(.p-disabled):hover {
  background-color: #ececec;
  border-color: transparent;
  background-image: linear-gradient(to bottom, #1E88E5, #1E88E5), linear-gradient(to bottom, rgba(0, 0, 0, 0.87), rgba(0, 0, 0, 0.87));
}
.p-input-filled .p-multiselect:not(.p-disabled).p-focus, .p-input-filled .p-multiselect:not(.p-disabled).p-inputwrapper-focus {
  box-shadow: none;
  background-color: #dcdcdc;
  border-color: transparent;
  background-size: 100% 2px, 100% 1px;
}
.p-input-filled .p-multiselect .p-inputtext:enabled:hover, .p-input-filled .p-multiselect .p-inputtext:enabled:focus {
  background-image: none;
  background: transparent;
}
.p-input-filled .p-multiselect .p-multiselect-label:hover {
  background-image: none;
  background: transparent;
}

.p-float-label .p-multiselect-label .p-multiselect-token {
  padding: 0.25rem 1rem;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.p-input-filled .p-float-label .p-multiselect .p-multiselect-label {
  padding-top: 1.5rem;
  padding-bottom: 0.5rem;
}
.p-input-filled .p-float-label .p-inputwrapper-filled .p-multiselect .p-multiselect-label {
  padding-top: 1.5rem;
  padding-bottom: 0.5rem;
}
.p-input-filled .p-float-label .p-inputwrapper-filled .p-multiselect.p-multiselect-chip .p-multiselect-token {
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 0;
  margin-bottom: 0;
}
.p-input-filled .p-float-label .p-inputwrapper-filled .p-multiselect.p-multiselect-chip .p-multiselect-token .p-multiselect-token-icon {
  font-size: 75%;
}
.p-input-filled p-multiselect.ng-dirty.ng-invalid .p-multiselect {
  border-color: transparent;
  background-image: linear-gradient(to bottom, #B00020, #B00020), linear-gradient(to bottom, #B00020, #B00020);
}
.p-input-filled p-multiselect.ng-dirty.ng-invalid .p-multiselect:not(.p-disabled).p-focus {
  box-shadow: none;
  border-color: transparent;
}

p-multiselect.ng-dirty.ng-invalid .p-multiselect:not(.p-disabled).p-focus {
  box-shadow: inset 0 0 0 1px #B00020, inset 0 0 0 1px #B00020, inset 0 0 0 1px #B00020, inset 0 0 0 1px #B00020;
}

.p-overlaypanel .p-overlaypanel-content {
  padding: 1.5rem;
}

.p-paginator {
  justify-content: flex-end;
}

.p-panel {
  border-radius: 4px;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
}
.p-panel .p-panel-header,
.p-panel .p-panel-content,
.p-panel .p-panel-footer {
  border: 0 none;
}
.p-panel .p-panel-content {
  padding-top: 0;
}
.p-panel .p-panel-title {
  font-size: 1.25rem;
}

.p-panelmenu .p-panelmenu-panel {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  margin-bottom: 0;
  border-radius: 0;
  position: relative;
  transition: margin-bottom 225ms;
}
.p-panelmenu .p-panelmenu-panel:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.p-panelmenu .p-panelmenu-panel:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.p-panelmenu .p-panelmenu-panel .p-panelmenu-header .p-panelmenu-icon {
  order: 1;
  margin-left: auto;
  margin-right: 0;
}
.p-panelmenu .p-panelmenu-panel .p-panelmenu-header.p-disabled {
  opacity: 1;
}
.p-panelmenu .p-panelmenu-panel .p-panelmenu-header.p-disabled .p-panelmenu-header-link > * {
  opacity: 0.38;
}
.p-panelmenu .p-panelmenu-panel .p-panelmenu-header:not(.p-highlight) .p-panelmenu-header-link:focus {
  background: #f6f6f6;
}

.p-progressbar {
  border-radius: 0;
}
.p-progressbar .p-progressbar-label {
  display: none;
}

.p-radiobutton {
  border-radius: 50%;
  transition: box-shadow 0.2s;
}
.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
  border: 2px solid #757575;
}
.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
  border: 2px solid #757575;
}
.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled).p-focus {
  border-color: #1E88E5;
}
.p-radiobutton:not(.p-radiobutton-disabled):hover {
  box-shadow: 0 0 1px 10px rgba(0, 0, 0, 0.04);
}
.p-radiobutton:not(.p-radiobutton-disabled).p-radiobutton-focused {
  box-shadow: 0 0 1px 10px rgba(0, 0, 0, 0.12);
}
.p-radiobutton.p-radiobutton-checked:not(.p-radiobutton-disabled):hover {
  box-shadow: 0 0 1px 10px rgba(30, 136, 229, 0.04);
}
.p-radiobutton.p-radiobutton-checked:not(.p-radiobutton-disabled).p-radiobutton-focused {
  box-shadow: 0 0 1px 10px rgba(30, 136, 229, 0.12);
}

.p-input-filled .p-radiobutton .p-radiobutton-box {
  background-color: #ffffff;
}
.p-input-filled .p-radiobutton .p-radiobutton-box:not(.p-disabled):hover {
  background-color: #ffffff;
}

.p-selectbutton .p-button:focus {
  background: #e0e0e1;
  border-color: #e0e0e1;
}
.p-selectbutton .p-button:focus.p-highlight {
  background: #d9d8d9;
  border-color: #d9d8d9;
}

.p-slider .p-slider-handle {
  transition: transform 0.2s, box-shadow 0.2s;
  transform: scale(0.7);
}
.p-slider .p-slider-handle:focus {
  box-shadow: 0 0 1px 10px rgba(255, 179, 0, 0.2);
}
.p-slider.p-slider-sliding .p-slider-handle {
  transform: scale(1);
}

.p-steps {
  padding: 1rem 0;
}
.p-steps .p-steps-item {
  justify-content: flex-start;
  flex-direction: row-reverse;
  align-items: center;
}
.p-steps .p-steps-item:before {
  position: static;
  left: auto;
  top: auto;
  margin-top: 0;
}
.p-steps .p-steps-item:last-child {
  flex-grow: 0;
}
.p-steps .p-steps-item:last-child:before {
  display: none;
}
.p-steps .p-steps-item .p-menuitem-link {
  flex-direction: row;
  flex: 1 1 auto;
  overflow: visible;
  padding: 0 0.5rem;
}
.p-steps .p-steps-item .p-menuitem-link .p-steps-number {
  background-color: #9e9d9e;
  color: #ffffff;
  font-size: 0.857rem;
  min-width: 1.714rem;
  height: 1.714rem;
  line-height: 1.714rem;
}
.p-steps .p-steps-item .p-menuitem-link .p-steps-title {
  margin: 0;
  padding-left: 0.5rem;
}
.p-steps .p-steps-item.p-highlight .p-steps-number {
  background-color: #1E88E5;
  color: #ffffff;
}
.p-steps .p-steps-item.p-highlight .p-steps-title {
  font-weight: 600;
  color: rgba(0, 0, 0, 0.87);
}
.p-steps .p-steps-item.p-disabled {
  opacity: 1;
}

.p-tabview .p-tabview-nav {
  position: relative;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  transition: background-color 0.2s;
  border-radius: 0;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link > .p-ink {
  background-color: rgba(30, 136, 229, 0.16);
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link:focus {
  background-color: rgba(30, 136, 229, 0.12);
}
.p-tabview .p-tabview-nav .p-tabview-ink-bar {
  z-index: 1;
  display: block;
  position: absolute;
  bottom: 0;
  height: 2px;
  background-color: #1E88E5;
  transition: 500ms cubic-bezier(0.35, 0, 0.25, 1);
}

.p-toolbar {
  border: 0 none;
}

.p-tooltip .p-tooltip-text {
  box-shadow: none;
  font-size: 0.875rem;
}
.p-tooltip .p-tooltip-arrow {
  display: none;
}

.p-treetable .p-sortable-column {
  outline: 0 none;
}
.p-treetable .p-sortable-column:focus {
  background-color: rgba(0, 0, 0, 0.03);
}
.p-treetable .p-treetable-tbody > tr {
  outline: 0 none;
}
.p-treetable .p-treetable-tbody > tr:not(.p-highlight):focus {
  background-color: rgba(0, 0, 0, 0.03);
}

.p-tabmenu .p-tabmenu-nav {
  position: relative;
}
.p-tabmenu .p-tabmenu-nav li .p-menuitem-link {
  transition: background-color 0.2s;
  border-radius: 0;
}
.p-tabmenu .p-tabmenu-nav li .p-menuitem-link > .p-ink {
  background-color: rgba(30, 136, 229, 0.16);
}
.p-tabmenu .p-tabmenu-nav li .p-menuitem-link:focus {
  background-color: rgba(30, 136, 229, 0.12);
}
.p-tabmenu .p-tabmenu-nav .p-tabmenu-ink-bar {
  display: block;
  position: absolute;
  bottom: 0;
  height: 2px;
  background-color: #1E88E5;
  transition: 500ms cubic-bezier(0.35, 0, 0.25, 1);
}

.p-timeline.p-timeline-vertical .p-timeline-event-connector {
  margin: 0.5rem 0;
}
.p-timeline.p-timeline-horizontal .p-timeline-event-connector {
  margin: 0 0.5rem;
}

.p-togglebutton.p-button:focus {
  background: #e0e0e1;
  border-color: #e0e0e1;
}
.p-togglebutton.p-button:focus.p-highlight {
  background: #d9d8d9;
  border-color: #d9d8d9;
}

/* Add your customizations of theme here */
/* Add your variable customizations of layout here */
/* roboto-regular - latin-ext_latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"), url("../layout/fonts/roboto-v20-latin-ext_latin-regular.woff2") format("woff2"), url("../layout/fonts/roboto-v20-latin-ext_latin-regular.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-500 - latin-ext_latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: local("Roboto Medium"), local("Roboto-Medium"), url("../layout/fonts/roboto-v20-latin-ext_latin-500.woff2") format("woff2"), url("../layout/fonts/roboto-v20-latin-ext_latin-500.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-700 - latin-ext_latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: local("Roboto Bold"), local("Roboto-Bold"), url("../layout/fonts/roboto-v20-latin-ext_latin-700.woff2") format("woff2"), url("../layout/fonts/roboto-v20-latin-ext_latin-700.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* Utils */
.clearfix:after {
  content: " ";
  display: block;
  clear: both;
}

.card {
  -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16), 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  -moz-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16), 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16), 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  background: #ffffff;
  padding: 1.25em;
  margin-bottom: 1em;
  box-sizing: border-box;
}
.card .card-title {
  font-size: 16px;
  font-weight: 500;
  margin: 2em 0 1em 0;
  letter-spacing: 0.3px;
}
.card .card-title:first-child {
  margin-top: 0;
}
.card:last-child {
  margin-bottom: 0;
}
.card .card-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.card .card-subtitle {
  color: #616161;
  font-weight: 600;
  margin: -1rem 0 1rem 0;
}

.p-toast.p-toast-top-right, .p-toast.p-toast-top-left, .p-toast.p-toast-top-center {
  top: 85px;
}

@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
}
@keyframes fadeOutUp {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
}
.fadeInDown {
  -webkit-animation: fadeInDown 5s;
  /* Safari 4.0 - 8.0 */
  animation: fadeInDown 5s;
}

.fadeOutUp {
  -webkit-animation: fadeOutUp 0.2s;
  /* Safari 4.0 - 8.0 */
  animation: fadeOutUp 0.2s;
}

body {
  background-color: white;
}

.loader {
  position: absolute;
  width: 100px;
  height: 100px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.circular {
  animation: rotate 2s linear infinite;
  height: 100px;
  position: relative;
  width: 100px;
}

.path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke: #B6463A;
  animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  stroke-linecap: round;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
}
@keyframes color {
  100%, 0% {
    stroke: #d62d20;
  }
  40% {
    stroke: #0057e7;
  }
  66% {
    stroke: #008744;
  }
  80%, 90% {
    stroke: #ffa700;
  }
}
.dashboard > div {
  padding: 1em;
}
.dashboard .dashboard-grid {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding: 0;
}
.dashboard .card {
  height: 100%;
  margin-bottom: 0;
}
.dashboard .dot {
  height: 0.5em;
  width: 0.5em;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  display: inline-block;
}
.dashboard .dot.dot-green {
  background-color: #4caf50;
}
.dashboard .dot.dot-red {
  background-color: #e91e63;
}
.dashboard .dot.dot-orange {
  background-color: #ff9800;
}
.dashboard .icon-container {
  width: 48px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  background-color: #eeeeee;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.dashboard .icon-container i {
  color: #9e9e9e;
  font-size: 20px;
  line-height: inherit;
}
.dashboard .overview-box .overview-numbers {
  font-size: 16px;
  color: #4c87c9;
  font-weight: 700;
  margin: 0.5em 0 1em 0;
}
.dashboard .overview-box.monthly-sales img {
  width: 100%;
  margin-top: 1em;
}
.dashboard .overview-box.total-views img {
  width: 100%;
  margin-top: 4em;
}
.dashboard .overview-box.connections .legend-arrow {
  color: #4caf50;
}
.dashboard .overview-box.connections .dot {
  margin-right: 0.5em;
  vertical-align: middle;
}
.dashboard .overview-box.connections .connection-chart {
  text-align: center;
  padding-top: 2em;
}
.dashboard .overview-box.connections .connection-chart-legend {
  padding-left: 4%;
  padding-top: 1.5em;
  color: #616161;
}
.dashboard .summary-box {
  position: relative;
}
.dashboard .summary-box .card {
  min-height: 100px;
}
.dashboard .summary-box .summary-box-title {
  font-size: 16px;
  margin: 0;
  color: #ffffff;
}
.dashboard .summary-box p {
  margin: 0.25em 0;
  color: #ffffff;
}
.dashboard .summary-box .summary-box-icon {
  position: absolute;
  right: 24px;
  top: 32px;
}
.dashboard .summary-box .summary-box-graph {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.dashboard .summary-box.summary-box-messages .card {
  border-top: 4px solid #0097a7;
  background-color: #00bcd4;
}
.dashboard .summary-box.summary-box-checkins .card {
  border-top: 4px solid #ad1457;
  background-color: #e91e63;
}
.dashboard .summary-box.summary-box-filessynced .card {
  border-top: 4px solid #f47b08;
  background-color: #ffb300;
}
.dashboard .summary-box.summary-box-usersonline .card {
  border-top: 4px solid #455a64;
  background-color: #607d8b;
}
.dashboard .tasks {
  padding: 0;
}
.dashboard .tasks .tasks-progress {
  height: 7px;
  background-color: #00bcd4;
}
.dashboard .tasks .tasks-progress .tasks-progress-value {
  height: 7px;
  width: 50%;
  background-color: #0097a7;
}
.dashboard .tasks .tasks-header {
  padding: 16px;
  background-color: #eeeeee;
}
.dashboard .tasks .tasks-header .task-number {
  font-weight: 700;
}
.dashboard .tasks ul {
  list-style-type: none;
  margin: 0.25em 0 0 0;
  padding: 0.25em 1em 0 1em;
}
.dashboard .tasks ul li {
  border-bottom: 1px solid #e4e4e4;
}
.dashboard .tasks ul li:last-child {
  border-bottom: 0 none;
}
.dashboard .tasks .tasks-detail {
  margin-top: 0.5em;
  color: #616161;
}
.dashboard .tasks .task-content {
  position: relative;
  padding: 1em 0;
}
.dashboard .tasks i {
  font-size: 24px;
  color: #616161;
}
.dashboard .tasks .p-col-fixed {
  width: 75px;
  text-align: center;
}
.dashboard .team ul {
  list-style-type: none;
  margin: 0.25em 0 0 0;
  padding: 0;
}
.dashboard .team ul li {
  border-bottom: 1px solid #e4e4e4;
}
.dashboard .team ul li:last-child {
  border-bottom: 0 none;
}
.dashboard .team img {
  width: 40px;
  height: 40px;
}
.dashboard .team .member-content {
  padding: 1em;
}
.dashboard .team .member-name {
  margin-bottom: 0.5em;
}
.dashboard .team button:last-child {
  margin-left: 0.5em;
}
.dashboard .team .member-status {
  color: #616161;
}
.dashboard .team .member-status span {
  vertical-align: middle;
}
.dashboard .team .dot {
  margin-right: 0.3em;
  vertical-align: middle;
}
.dashboard .userprofile {
  padding: 0;
}
.dashboard .userprofile .userprofile-bg {
  width: 100%;
}
.dashboard .userprofile .userprofile-avatar {
  border: 4px solid #ffffff;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  margin: 0 auto;
  width: 96px;
  height: 96px;
  display: block;
  margin-top: -48px;
}
.dashboard .userprofile .userprofile-name {
  text-align: center;
  margin: 0.5em 0;
}
.dashboard .userprofile .userprofile-role {
  color: #616161;
  text-align: center;
  margin-bottom: 2em;
  font-size: 12px;
  letter-spacing: 0.4px;
}
.dashboard .userprofile .userprofile-circle {
  width: 16px;
  height: 16px;
  background-color: #ffffff;
  border-width: 4px;
  border-style: solid;
  margin: 0 auto;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  margin-bottom: 0.5em;
}
.dashboard .userprofile .userprofile-status {
  color: #616161;
  letter-spacing: 2px;
  font-size: 12px;
}
.dashboard .userprofile .userprofile-value {
  margin-top: 0.5em;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.2px;
}
.dashboard .userprofile .userprofile-legend {
  text-align: center;
}
.dashboard .userprofile .userprofile-completed .userprofile-circle {
  border-color: #00bcd4;
}
.dashboard .userprofile .userprofile-snoozed .userprofile-circle {
  border-color: #ffc107;
}
.dashboard .userprofile .userprofile-overdue .userprofile-circle {
  border-color: #e91e63;
}
.dashboard .quarterreport h3 {
  margin: 0;
  font-size: 16px;
}
.dashboard .quarterreport .dashboard-chart-container {
  position: relative;
}
.dashboard .switchpanel {
  border-left: 6px solid #00bcd4;
}
.dashboard .switchpanel .switch-name {
  color: #616161;
  margin-bottom: 0.25em;
}
.dashboard .switchpanel .switch-status {
  color: #0097a7;
  letter-spacing: 0.4px;
  font-size: 12px;
  display: block;
  margin-bottom: 0.5em;
}
.dashboard .switchpanel.switchpanel-off {
  border-left: 6px solid #e91e63;
}
.dashboard .switchpanel.switchpanel-off .switch-status {
  color: #e91e63;
}
.dashboard .switchpanel .ui-inputswitch-checked .ui-inputswitch-slider {
  background-color: #00bcd4;
}
.dashboard .switchpanel .ui-inputswitch-checked .ui-inputswitch-slider:before {
  background-color: #0097a7;
}
.dashboard .switchpanel .ui-inputswitch-checked:not(.ui-state-disabled):hover .ui-inputswitch-slider {
  background-color: #00bcd4;
}
.dashboard .switchpanel .ui-inputswitch-checked.ui-inputswitch-focus .ui-inputswitch-slider {
  background: #00bcd4;
}
.dashboard .timeline h3 {
  margin: 0;
  font-size: 16px;
}
.dashboard .timeline ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.dashboard .timeline li {
  padding: 1em 0;
  border-bottom: 1px solid #e4e4e4;
}
.dashboard .timeline li:last-child {
  border-bottom: 0 none;
}
.dashboard .timeline img {
  width: 100%;
}
.dashboard .timeline .p-col-fixed {
  padding: 0 1em 0.5em 0;
}
.dashboard .timeline .event-owner {
  color: #616161;
  margin-bottom: 0.5em;
}
.dashboard .timeline .event-detail {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.87);
}
.dashboard .timeline .timeline-clock {
  display: inline-block;
  margin-top: 0.5em;
  background-color: #eeeeee;
  color: #616161;
  padding: 0.5em;
  font-size: 13px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
}
.dashboard .timeline .timeline-clock i {
  margin-right: 7px;
  vertical-align: middle;
}
.dashboard .timeline .timeline-clock span {
  vertical-align: middle;
}
.dashboard .location {
  padding: 0;
  margin-top: 1em;
}
.dashboard .location .location-content {
  padding: 1em;
}
.dashboard .location .location-name {
  margin-bottom: 0.5em;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.2px;
}
.dashboard .location p {
  margin: 0.75em 0;
  color: #616161;
}
.dashboard .location img {
  width: 100%;
}
.dashboard .location a {
  color: #00bcd4;
}
.dashboard .location a:hover {
  color: #0097a7;
}
.dashboard .weather {
  padding: 0;
}
.dashboard .weather .weather-image {
  background-image: url("../../layout/images/dashboard/primecity.jpg");
  background-size: cover;
  text-align: center;
  color: rgba(255, 255, 255, 0.87);
  min-height: 250px;
  padding: 1em;
}
.dashboard .weather .weather-image .city-name {
  font-size: 24px;
  margin-bottom: 40px;
}
.dashboard .weather .weather-image .weather-today {
  font-size: 20px;
  margin-bottom: 8px;
}
.dashboard .weather .weather-image .weather-degree {
  font-size: 40px;
}
.dashboard .weather .weekdays {
  margin: 1em;
}
.dashboard .weather .weekdays > div {
  padding: 0.857em;
}
.dashboard .weather .weather-degree-col {
  text-align: right;
}
.dashboard .weather .weather-degree-col img {
  vertical-align: middle;
  margin-left: 0.5em;
}
.dashboard .inventory {
  padding: 0;
}
.dashboard .inventory .card-title {
  padding: 0.714em 0.857em;
}
.dashboard .inventory h3 {
  margin: 0;
  font-size: 16px;
  padding: 1em;
}
.dashboard .inventory .ui-datatable-reflow .ui-reflow-label,
.dashboard .inventory .ui-datatable-reflow .ui-reflow-dropdown {
  margin: 1em;
}

@media screen and (max-width: 640px) {
  .dashboard .quarterreport .dashboard-chart-container {
    height: 250px;
  }
}
.login-body {
  background-image: url("../../layout/images/login/bg-login.jpg");
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.login-body .login-panel {
  width: 565px;
  height: 410px;
  background-color: #fafafa;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -282px;
  margin-top: -232px;
  padding: 0;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 5px 22px 4px rgba(0, 0, 0, 0.12), 0 12px 17px 2px rgba(0, 0, 0, 0.14);
  -moz-box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 5px 22px 4px rgba(0, 0, 0, 0.12), 0 12px 17px 2px rgba(0, 0, 0, 0.14);
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 5px 22px 4px rgba(0, 0, 0, 0.12), 0 12px 17px 2px rgba(0, 0, 0, 0.14);
}
.login-body .login-panel .login-panel-header {
  text-align: center;
  vertical-align: middle;
  width: 108px;
  height: 108px;
  position: relative;
  top: -54px;
  left: 50%;
  margin-left: -54px;
  background: #fafafa;
  border: 10px solid #fafafa;
  border-radius: 50%;
}
.login-body .login-panel .login-panel-header img {
  width: 88px;
  height: 88px;
}
.login-body .login-panel .login-panel-content {
  padding: 12px 98px;
  position: relative;
  top: -54px;
}
.login-body .login-panel .login-panel-content h1 {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1.3px;
  color: #616161;
  margin-top: 0;
  margin-bottom: 6px;
  text-align: center;
}
.login-body .login-panel .login-panel-content h2 {
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0.5px;
  color: #212121;
  margin-top: 0;
  margin-bottom: 1em;
  text-align: center;
}

@media screen and (max-width: 640px) {
  .login-body .login-panel {
    left: 0;
    margin-left: 0;
    width: 100%;
  }
  .login-body .login-panel .login-panel-content {
    padding: 12px 38px;
  }
}
.exception-body {
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.exception-body.error {
  background-image: url("../../layout/images/exception/bg-error.jpg");
}
.exception-body.error .exception-panel .exception-image {
  width: 102px;
  margin-left: -51px;
}
.exception-body.error .exception-panel .exception-image img {
  width: 58px;
  height: 64px;
}
.exception-body.notfound {
  background-image: url("../../layout/images/exception/bg-404.jpg");
}
.exception-body.notfound .exception-panel .exception-image {
  width: 108px;
  margin-left: -54px;
}
.exception-body.notfound .exception-panel .exception-image img {
  width: 64px;
  height: 64px;
}
.exception-body.accessdenied {
  background-image: url("../../layout/images/exception/bg-access.jpg");
}
.exception-body.accessdenied .exception-panel .exception-image {
  width: 108px;
  margin-left: -54px;
}
.exception-body.accessdenied .exception-panel .exception-image img {
  width: 50px;
  height: 64px;
}
.exception-body .exception-panel {
  width: 565px;
  height: 247px;
  background-color: #fafafa;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -282px;
  margin-top: -123px;
  padding: 0;
  text-align: center;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 5px 22px 4px rgba(0, 0, 0, 0.12), 0 12px 17px 2px rgba(0, 0, 0, 0.14);
  -moz-box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 5px 22px 4px rgba(0, 0, 0, 0.12), 0 12px 17px 2px rgba(0, 0, 0, 0.14);
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 5px 22px 4px rgba(0, 0, 0, 0.12), 0 12px 17px 2px rgba(0, 0, 0, 0.14);
}
.exception-body .exception-panel .exception-image {
  vertical-align: middle;
  height: 108px;
  position: relative;
  top: -54px;
  left: 50%;
  background: #fafafa;
  border: 22px solid #fafafa;
  border-radius: 50%;
}
.exception-body .exception-panel .exception-detail {
  position: relative;
  padding: 10px 0 42px 0;
  top: -54px;
}
.exception-body .exception-panel .exception-detail h1 {
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 1.7px;
  color: #616161;
  margin: 0px 0 16px 0;
}
.exception-body .exception-panel .exception-detail p {
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0.5px;
  color: #212121;
  margin: 0 0 42px 0;
}

@media screen and (max-width: 640px) {
  .exception-body .exception-panel {
    left: 0;
    margin-left: 0;
    width: 100%;
  }
}
.landing-body {
  background-color: #f4f4f7;
}
.landing-body * {
  box-sizing: border-box;
}
.landing-body p {
  line-height: 1.5;
}
.landing-body .p-button-text {
  width: 100%;
}
.landing-body #header {
  background-color: rgba(32, 33, 36, 0.98);
}
.landing-body #header > div {
  width: 100%;
  margin: 0 auto;
  height: 96px;
  padding: 36px 146px;
}
.landing-body #header > div img {
  width: 155px;
  height: 24px;
}
.landing-body #header > div #landing-menu {
  float: right;
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.landing-body #header > div #landing-menu > li {
  display: inline-block;
}
.landing-body #header > div #landing-menu > li a {
  border-bottom: 6px solid transparent;
  color: #ffffff;
  display: inline-block;
  text-align: center;
  height: 60px;
  padding: 0 21px;
  font-size: 18px;
  letter-spacing: 1px;
  transition: border-color 0.2s;
}
.landing-body #header > div #landing-menu > li:hover a {
  color: #ffffff;
  border-color: #eeeeee;
}
.landing-body #header > div #landing-menu-button {
  color: #303134;
  background-color: #fafafa;
  border-radius: 50%;
  display: none;
  float: right;
  width: 48px;
  height: 48px;
  text-align: center;
  padding: 10px;
  position: relative;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14);
}
.landing-body #header > div #landing-menu-button .landing-menu-button-icon {
  background-color: #303134;
  width: 28px;
  height: 4px;
  position: absolute;
  top: 22px;
  left: 10px;
  transition-duration: 0.5s;
  transition-timing-function: swing;
}
.landing-body #header > div #landing-menu-button .landing-menu-button-icon:before, .landing-body #header > div #landing-menu-button .landing-menu-button-icon:after {
  background-color: #303134;
  width: 28px;
  height: 4px;
  content: "";
  position: absolute;
  left: 0;
  transition-duration: 0.5s;
  transition-timing-function: swing;
}
.landing-body #header > div #landing-menu-button .landing-menu-button-icon:before {
  top: -9px;
}
.landing-body #header > div #landing-menu-button .landing-menu-button-icon:after {
  top: 9px;
}
.landing-body #introduction > div {
  background: url("../../layout/images/landing/bg-hero.jpg") no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 600px;
  color: #ffffff;
  text-align: left;
  padding-top: 102px;
  padding-left: 145px;
}
.landing-body #introduction > div h1 {
  padding: 0;
  margin: 0 0 48px 0;
  font-size: 42px;
  font-weight: 500;
  line-height: 1.24;
  letter-spacing: 0.3px;
  color: #ffffff;
}
.landing-body #introduction > div button {
  min-width: 180px;
  height: 42px;
}
.landing-body #features > div {
  margin: 0;
  padding: 72px 145px;
}
.landing-body #features > div h1 {
  font-size: 32px;
  font-weight: 500;
  letter-spacing: 0.3px;
  color: #202124;
  margin: 0 0 42px 0;
}
.landing-body #features > div .feature-box {
  padding: 1em;
}
.landing-body #features > div .feature-box img {
  width: 100%;
  margin-bottom: 12px;
}
.landing-body #features > div .feature-box > div h3 {
  font-size: 15px;
  margin: 0;
}
.landing-body #features > div .feature-box > div p {
  font-size: 16px;
  letter-spacing: 0.5px;
  color: #616161;
  margin: 8px 0;
}
.landing-body #stats {
  background-color: #212121;
}
.landing-body #stats > div {
  margin: 0;
  padding: 38px 145px;
}
.landing-body #stats > div .stat-box {
  padding-right: 160px;
  text-align: left;
  color: #ffffff;
}
.landing-body #stats > div .stat-box.component img {
  width: 32px;
  height: 32px;
}
.landing-body #stats > div .stat-box.development img {
  width: 48px;
  height: 32px;
}
.landing-body #stats > div .stat-box.coffee img {
  width: 40px;
  height: 32px;
}
.landing-body #stats > div .stat-box .p-progressbar {
  margin-top: 12px;
  margin-bottom: 8px;
  height: 4px;
  overflow: visible;
  background-color: #eeeeee;
}
.landing-body #stats > div .stat-box .p-progressbar .p-progressbar-value {
  background-color: #ffc107;
  border-color: #ffc107;
  height: 4px;
  margin: -1px;
  padding: 0;
}
.landing-body #stats > div .stat-box p {
  font-size: 16px;
  letter-spacing: 0.5px;
  color: #ffffff;
}
.landing-body #pricing {
  background-color: #ffffff;
}
.landing-body #pricing .p-col-12 {
  padding: 0.5em 1em;
}
.landing-body #pricing > div {
  margin: 0;
  padding: 75px 145px 87px 145px;
  text-align: left;
}
.landing-body #pricing > div > h1 {
  font-size: 32px;
  font-weight: 500;
  letter-spacing: 0.3px;
  margin: 0 0 42px 0;
  color: #202124;
}
.landing-body #pricing > div .pricing-box {
  width: 100%;
  max-height: 358px;
  text-align: left;
  background-color: #fafafa;
  border-left: 6px solid rgba(97, 97, 97, 0.07);
  border-radius: 4px;
  box-shadow: 0 5px 6px -3px rgba(0, 0, 0, 0.2), 0 3px 16px 2px rgba(0, 0, 0, 0.12), 0 9px 12px 1px rgba(0, 0, 0, 0.14);
}
.landing-body #pricing > div .pricing-box .pricing-header {
  padding: 24px 24px 18px 24px;
  text-align: center;
}
.landing-body #pricing > div .pricing-box .pricing-header h3 {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.3px;
  color: #212121;
  margin: 0;
}
.landing-body #pricing > div .pricing-box .pricing-header .pricing-fee {
  font-size: 20px;
  letter-spacing: 0.3px;
  color: #616161;
  margin: 0;
}
.landing-body #pricing > div .pricing-box .pricing-header .pricing-fee b {
  margin: 0;
  font-size: 34px;
  font-weight: 500;
  letter-spacing: 0.3px;
  color: #212121;
  text-align: center;
}
.landing-body #pricing > div .pricing-box .pricing-content {
  padding: 0 20px 19px 20px;
  min-height: 228px;
  position: relative;
}
.landing-body #pricing > div .pricing-box .pricing-content ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.landing-body #pricing > div .pricing-box .pricing-content ul li {
  padding: 12px 0 0 0;
  font-size: 16px;
  letter-spacing: 0.3px;
  color: #212121;
}
.landing-body #pricing > div .pricing-box .pricing-content ul li i {
  color: #43a047;
  font-size: 18px;
  vertical-align: middle;
  margin-right: 6px;
}
.landing-body #pricing > div .pricing-box .pricing-content button {
  background-color: #457fca;
  position: absolute;
  min-width: 136px;
  bottom: 20px;
  left: 50%;
  margin-left: -68px;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
}
.landing-body #pricing > div .pricing-box .pricing-content button:enabled:hover {
  background-color: #1565C0;
}
.landing-body #multimedia {
  background-color: #202124;
}
.landing-body #multimedia > div {
  margin: 0;
  padding: 72px 145px 99px 145px;
  text-align: left;
}
.landing-body #multimedia > div h1 {
  font-size: 32px;
  font-weight: 500;
  letter-spacing: 0.3px;
  color: #ffffff;
  margin: 0 0 51px 0;
}
.landing-body #multimedia > div .multimedia-content1 {
  padding-right: 51px;
}
.landing-body #multimedia > div .multimedia-content1 img {
  width: 726px;
  height: 342px;
}
.landing-body #multimedia > div .multimedia-content2 img {
  width: 370px;
  height: 342px;
}
.landing-body #multimedia > div > div h3 {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.3px;
  color: #eeeeee;
  margin: 24px 0 10px 0;
}
.landing-body #multimedia > div > div p {
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.3px;
  color: #eeeeee;
  margin: 0;
}
.landing-body #footer {
  background-color: #202124;
  color: #ffffff;
}
.landing-body #footer > div {
  margin: 0 145px;
  padding: 18px 0 30px 0;
  border-top: 6px solid #00bcd4;
}
.landing-body #footer > div .footer-logo {
  height: 23px;
}
.landing-body #footer > div p {
  margin: 10px 0;
  font-size: 14px;
  letter-spacing: 0.3px;
  color: #9f9f9f;
}
.landing-body #footer > div .footer-social {
  text-align: right;
}
.landing-body #footer > div .footer-social a {
  margin-left: 50px;
  opacity: 0.7;
  filter: alpha(opacity=70);
}
.landing-body #footer > div .footer-social a img {
  height: 22px;
}
.landing-body #footer > div .footer-social a:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}

@media screen and (max-width: 640px) {
  .landing-body {
    padding-top: 95px;
  }
  .landing-body #header {
    position: fixed;
    top: 0;
    z-index: 100;
    width: 100%;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.14);
  }
  .landing-body #header > div {
    width: 100%;
    padding: 24px;
  }
  .landing-body #header > div img {
    margin-top: 12px;
  }
  .landing-body #header > div #landing-menu-button {
    display: block;
  }
  .landing-body #header > div #landing-menu-button.landing-menu-active {
    background-color: #424242;
  }
  .landing-body #header > div #landing-menu-button.landing-menu-active .landing-menu-button-icon {
    width: 0;
    background-color: #eeeeee;
  }
  .landing-body #header > div #landing-menu-button.landing-menu-active .landing-menu-button-icon:before {
    background-color: #eeeeee;
    transform: translateY(9px) rotate(45deg);
  }
  .landing-body #header > div #landing-menu-button.landing-menu-active .landing-menu-button-icon:after {
    background-color: #eeeeee;
    transform: translateY(-9px) rotate(-45deg);
  }
  .landing-body #header > div #landing-menu {
    animation-duration: 0.5s;
    display: none;
    float: none;
    width: 100%;
    position: fixed;
    top: 95px;
    left: 0;
    padding-bottom: 50px;
    opacity: 0.95;
    background-color: rgba(32, 33, 36, 0.99);
    box-shadow: 0 5px 6px -3px rgba(0, 0, 0, 0.2), 0 3px 16px 2px rgba(0, 0, 0, 0.12), 0 9px 12px 1px rgba(0, 0, 0, 0.14);
  }
  .landing-body #header > div #landing-menu li {
    display: block;
  }
  .landing-body #header > div #landing-menu li a {
    height: auto;
    border-bottom: 0 none;
    padding: 15px;
  }
  .landing-body #header > div #landing-menu.landing-menu-active {
    display: block;
  }
  .landing-body #introduction {
    width: 100%;
  }
  .landing-body #introduction > div {
    text-align: center;
    padding: 82px 34px 0 34px;
  }
  .landing-body #introduction > div h1 {
    margin-bottom: 42px;
    font-size: 34px;
    font-weight: 500;
    line-height: 1.24;
    letter-spacing: 0.2px;
    text-align: center;
    color: #ffffff;
  }
  .landing-body #features > div {
    width: 100%;
    padding: 72px 23px;
  }
  .landing-body #features > div h1 {
    text-align: center;
  }
  .landing-body #stats > div {
    width: 100%;
    padding: 42px 52px;
  }
  .landing-body #stats > div .stat-box {
    padding-right: 0;
  }
  .landing-body #pricing > div {
    width: 100%;
    padding: 75px 62px;
    text-align: center;
  }
  .landing-body #pricing > div .pricing-box {
    margin: 0 0 42px 0;
    max-width: none;
  }
  .landing-body #multimedia > div {
    width: 100%;
    padding: 72px 15px;
  }
  .landing-body #multimedia > div h1 {
    text-align: center;
  }
  .landing-body #multimedia > div .multimedia-content1, .landing-body #multimedia > div .multimedia-content2 {
    padding-right: 15px;
  }
  .landing-body #multimedia > div .multimedia-content1 img, .landing-body #multimedia > div .multimedia-content2 img {
    width: 100%;
    height: 275px;
  }
  .landing-body #footer > div {
    text-align: center;
    margin: 0 23px 0 14px;
  }
  .landing-body #footer > div .footer-social {
    text-align: center;
  }
  .landing-body #footer > div .footer-social a:first-child {
    margin: 0;
  }
}
.layout-config-button {
  display: block;
  position: fixed;
  width: 52px;
  height: 52px;
  line-height: 52px;
  background-color: #fafafa;
  text-align: center;
  top: 230px;
  right: 0;
  z-index: 1000000;
  -moz-border-radius-topleft: 2px;
  -webkit-border-top-left-radius: 2px;
  border-top-left-radius: 2px;
  -moz-border-radius-bottomleft: 2px;
  -webkit-border-bottom-left-radius: 2px;
  border-bottom-left-radius: 2px;
  -moz-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
  -webkit-box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 5px 22px 4px rgba(0, 0, 0, 0.12), 0 12px 17px 2px rgba(0, 0, 0, 0.14);
  -moz-box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 5px 22px 4px rgba(0, 0, 0, 0.12), 0 12px 17px 2px rgba(0, 0, 0, 0.14);
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 5px 22px 4px rgba(0, 0, 0, 0.12), 0 12px 17px 2px rgba(0, 0, 0, 0.14);
}
.layout-config-button i {
  font-size: 32px;
  color: #00bcd4;
  line-height: inherit;
}
.layout-config-button:hover {
  background-color: #e0e0e0;
}

.layout-config {
  z-index: 1000002;
  position: fixed;
  padding: 0;
  display: none;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-color: #ffffff;
  width: 52vw;
  -webkit-box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.layout-config .layout-config-content {
  padding: 1em;
  position: relative;
}
.layout-config .layout-config-close {
  position: absolute;
  width: 36px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  right: -18px;
  top: -18px;
  background-color: #E91E63;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -moz-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
}
.layout-config .layout-config-close i {
  color: #ffffff;
  line-height: inherit;
  font-size: 18px;
}
.layout-config .layout-config-close:hover {
  background-color: #D81B60;
}
.layout-config.layout-config-enter {
  display: block;
  opacity: 0;
  transform: translateX(-50%) translateY(-50%) scale(0.7);
}
.layout-config.layout-config-enter-active {
  opacity: 1;
  transform: translateX(-50%) translateY(-50%) scale(1);
  transition: all 150ms cubic-bezier(0, 0, 0.2, 1);
}
.layout-config.layout-config-enter-done {
  display: block;
}
.layout-config.layout-config-exit {
  opacity: 1;
  display: block;
}
.layout-config.layout-config-exit-active {
  opacity: 0;
  transform: translateX(-50%) translateY(-50%);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
.layout-config.layout-config-exit-done {
  display: none;
}
.layout-config .layout-config-option {
  width: 100px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  display: inline-block;
  -moz-transition: transform 0.2s;
  -o-transition: transform 0.2s;
  -webkit-transition: transform 0.2s;
  transition: transform 0.2s;
}
.layout-config .layout-config-option i {
  line-height: inherit;
  font-size: 36px;
  color: #ffffff;
}
.layout-config .layout-config-option:hover {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
.layout-config .layout-config-option.layout-config-option-image {
  position: relative;
}
.layout-config .layout-config-option.layout-config-option-image i {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -24px;
  margin-top: -40px;
}
.layout-config .layout-config-option.layout-config-option-image img {
  border: 0 none;
}
.layout-config .layout-config-option.layout-config-option-light i {
  color: #000000;
}
.layout-config .p-col {
  padding: 1em;
  -ms-flex-preferred-size: auto;
}
.layout-config .p-tabview {
  border: 0 none;
}
.layout-config .p-tabview.p-tabview-top > .p-tabview-nav li {
  vertical-align: bottom;
  top: auto;
  margin: 0 0.125em 0px 0;
}
.layout-config .p-tabview .p-tabview-panels {
  padding: 1em 0;
  height: 500px;
  overflow: auto;
}
.layout-config .p-tabview .p-tabview-panels .p-tabview-panel {
  padding: 0.857em 1em;
  line-height: 1.5;
}
.layout-config .layout-config-subtitle {
  font-size: 16px;
  letter-spacing: 0.2px;
  margin: 0.75em 0;
  color: #616161;
}
.layout-config .layout-config-subtitle:first-child {
  margin-top: 0;
}
.layout-config p {
  line-height: 1.5;
  margin-top: 0;
  color: #616161;
}

.layout-config-mask {
  background-color: #252529;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000001;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 640px) {
  .layout-config {
    width: 80vw;
  }
}
html {
  height: 100%;
  font-size: 14px;
}

body {
  font-family: "Roboto", "Helvetica Neue", sans-serif;
  font-size: 14px;
  color: #212121;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  margin: 0;
  min-height: 100%;
  background-color: #f4f4f7;
}
body .ajax-loader {
  font-size: 32px;
  color: #457fca;
}
body.blocked-scroll, body.blocked-scroll-config {
  overflow: hidden;
}
body a {
  text-decoration: none;
  color: #457fca;
}

.layout-top {
  padding-top: 15px;
  padding-left: 1rem;
  padding-right: 1rem;
}

.layout-topbar:before, .layout-topbar:after {
  content: "";
  display: table;
}
.layout-topbar:after {
  clear: both;
}
.layout-topbar .p-grid {
  margin: 0;
  min-height: 4em;
}
.layout-topbar .p-grid .p-col {
  padding: 0;
}
.layout-topbar .layout-topbar-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  min-height: 4em;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.layout-topbar .layout-topbar-grid .layout-topbar-grid-column {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
}
.layout-topbar .layout-topbar-grid .layout-topbar-grid-column-fixed {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}
.layout-topbar .layout-menubutton {
  position: absolute;
  height: 48px;
  width: 48px;
  line-height: 48px;
  left: 29px;
  top: 19px;
  text-align: center;
  -webkit-transition-duration: 0.66s;
  transition-duration: 0.66s;
  -webkit-transition-timing-function: swing;
  transition-timing-function: swing;
  -moz-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -webkit-box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14);
  -moz-box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14);
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14);
}
.layout-topbar .layout-menubutton .layout-menubutton-icon {
  width: 28px;
  height: 4px;
  position: absolute;
  top: 22px;
  left: 10px;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  transition-timing-function: swing;
}
.layout-topbar .layout-menubutton .layout-menubutton-icon:before, .layout-topbar .layout-menubutton .layout-menubutton-icon:after {
  width: 28px;
  height: 4px;
  content: "";
  position: absolute;
  left: 0;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  transition-timing-function: swing;
}
.layout-topbar .layout-menubutton .layout-menubutton-icon:before {
  top: -9px;
}
.layout-topbar .layout-menubutton .layout-menubutton-icon:after {
  top: 9px;
}
.layout-topbar .layout-logo img {
  height: 20px;
}
.layout-topbar .layout-topbar-search .p-inputtext {
  width: 150px;
  padding: 6px 24px 6px 6px;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.layout-topbar .layout-topbar-search i {
  position: absolute;
  color: #616161;
  -moz-transition: color 0.2s;
  -o-transition: color 0.2s;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}
.layout-topbar .profile-menu-button {
  height: 40px;
  margin-left: 2em;
  display: inline-block;
  border: 2px solid transparent;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -moz-transition: border-color 0.2s;
  -o-transition: border-color 0.2s;
  -webkit-transition: border-color 0.2s;
  transition: border-color 0.2s;
}
.layout-topbar .profile-menu-button img {
  width: 36px;
  height: 36px;
}
.layout-topbar .layout-profile-menu {
  display: none;
  list-style-type: none;
  position: absolute;
  min-width: 250px;
  z-index: 999;
  padding: 0.75em;
  overflow: auto;
  right: 1rem;
  margin: 0;
  -webkit-animation-duration: 0.2s;
  -moz-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19), 0 8px 17px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19), 0 8px 17px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19), 0 8px 17px 0 rgba(0, 0, 0, 0.2);
}
.layout-topbar .layout-profile-menu.layout-profile-menu-active {
  display: block;
}
.layout-topbar .layout-profile-menu a {
  display: block;
  position: relative;
  padding: 0.75em;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
}
.layout-topbar .layout-profile-menu a span {
  vertical-align: middle;
}
.layout-topbar .layout-profile-menu a i {
  vertical-align: middle;
  font-size: 14px;
  margin-right: 7px;
}
.layout-topbar .layout-profile-menu .layout-profile-menu-search {
  display: none;
  padding: 1em;
}
.layout-topbar .layout-profile-menu .layout-profile-menu-search .p-inputtext {
  width: 100%;
}

.layout-topbar-separator {
  height: 1px;
  margin-top: -3px;
}

.layout-container.layout-top-small .layout-top {
  padding-bottom: 15px;
  -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.03), 0 2px 5px 0 rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.03), 0 2px 5px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.03), 0 2px 5px 0 rgba(0, 0, 0, 0.05);
}
.layout-container.layout-top-small .layout-breadcrumb,
.layout-container.layout-top-small .layout-topbar-separator {
  display: none;
}
.layout-container.layout-top-small .layout-content {
  margin-top: 15px;
}
.layout-container.layout-top-small .layout-menu-container,
.layout-container.layout-top-small .layout-mask {
  top: 86px;
}
.layout-container.layout-top-medium .layout-top {
  padding-bottom: 15px;
  -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.03), 0 2px 5px 0 rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.03), 0 2px 5px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.03), 0 2px 5px 0 rgba(0, 0, 0, 0.05);
}
.layout-container.layout-top-medium .layout-breadcrumb {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.layout-container.layout-top-medium .layout-content {
  margin-top: 15px;
}
.layout-container.layout-top-large .layout-top {
  padding-bottom: 150px;
}
.layout-container.layout-top-large .layout-content {
  margin-top: -120px;
}

@media screen and (max-width: 640px) {
  .layout-top {
    padding: 0 0 150px 0;
  }

  .layout-topbar {
    padding: 0.5em 1em;
  }
  .layout-topbar .layout-menubutton {
    top: 0.5em;
    left: 1em;
  }
  .layout-topbar .layout-logo {
    margin-left: 5em;
  }
  .layout-topbar .layout-profile-menu {
    right: 1em;
  }
  .layout-topbar .layout-topbar-search {
    display: none;
  }
  .layout-topbar .layout-profile-menu .layout-profile-menu-search {
    display: block;
  }

  .layout-container.layout-top-small .layout-top {
    padding-bottom: 0;
  }
  .layout-container.layout-top-small .layout-menubutton {
    top: 0.786em;
  }
  .layout-container.layout-top-small .layout-menu-container,
.layout-container.layout-top-small .layout-mask {
    top: 70px;
  }
}
.layout-topbar-blue .layout-top {
  background-color: #457fca;
  background-image: -webkit-gradient(linear, left top, right top, from(#457fca), to(#5691c8));
  background-image: -webkit-linear-gradient(left, #457fca, #5691c8);
  background-image: -moz-linear-gradient(left, #457fca, #5691c8);
  background-image: -ms-linear-gradient(left, #457fca, #5691c8);
  background-image: -o-linear-gradient(left, #457fca, #5691c8);
  background-image: linear-gradient(left, #457fca, #5691c8);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#457fca", endColorstr="#5691c8", gradientType="1");
}
.layout-topbar-blue .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-blue .layout-topbar .layout-topbar-search i {
  color: #cbdeee;
}
.layout-topbar-blue .layout-topbar .layout-topbar-search label {
  color: #cbdeee;
}
.layout-topbar-blue .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-blue .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-blue .layout-menubutton .layout-menubutton-icon {
  background-color: #447dc6;
}
.layout-topbar-blue .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-blue .layout-menubutton .layout-menubutton-icon:after {
  background-color: #447dc6;
}
.layout-topbar-blue .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-blue .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #447dc6;
}
.layout-topbar-blue .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-blue .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #447dc6;
}
.layout-topbar-blue .layout-menubutton.layout-menubutton-active {
  background-color: rgba(13, 19, 52, 0.6);
}
.layout-topbar-blue .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-blue .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-blue .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-blue.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-blue.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-blue.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-blue.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #cbdeee;
  }
  .layout-topbar-blue.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-blue.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-blue.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-blue .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-blue .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-blue .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-blue .layout-breadcrumb li a {
  color: #ffc107;
}

.layout-topbar-polygons .layout-top {
  background-image: url("../../../layout/images/special/polygons-topbar.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-topbar-polygons .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-polygons .layout-topbar .layout-topbar-search i {
  color: #cbdeee;
}
.layout-topbar-polygons .layout-topbar .layout-topbar-search label {
  color: #cbdeee;
}
.layout-topbar-polygons .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-polygons .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-polygons .layout-menubutton .layout-menubutton-icon {
  background-color: #447dc6;
}
.layout-topbar-polygons .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-polygons .layout-menubutton .layout-menubutton-icon:after {
  background-color: #447dc6;
}
.layout-topbar-polygons .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-polygons .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #447dc6;
}
.layout-topbar-polygons .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-polygons .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #447dc6;
}
.layout-topbar-polygons .layout-menubutton.layout-menubutton-active {
  background-color: rgba(13, 19, 52, 0.6);
}
.layout-topbar-polygons .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-polygons .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-polygons .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-polygons.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-polygons.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-polygons.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-polygons.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #cbdeee;
  }
  .layout-topbar-polygons.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-polygons.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-polygons.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-polygons .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-polygons .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-polygons .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-polygons .layout-breadcrumb li a {
  color: #ffc107;
}

.layout-topbar-ash .layout-top {
  background-color: #3F4C6B;
  background-image: -webkit-gradient(linear, left top, right top, from(#3F4C6B), to(#334163));
  background-image: -webkit-linear-gradient(left, #3F4C6B, #334163);
  background-image: -moz-linear-gradient(left, #3F4C6B, #334163);
  background-image: -ms-linear-gradient(left, #3F4C6B, #334163);
  background-image: -o-linear-gradient(left, #3F4C6B, #334163);
  background-image: linear-gradient(left, #3F4C6B, #334163);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#3F4C6B", endColorstr="#334163", gradientType="1");
}
.layout-topbar-ash .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-ash .layout-topbar .layout-topbar-search i {
  color: #D3D9E8;
}
.layout-topbar-ash .layout-topbar .layout-topbar-search label {
  color: #D3D9E8;
}
.layout-topbar-ash .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-ash .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-ash .layout-menubutton .layout-menubutton-icon {
  background-color: #3F4C6B;
}
.layout-topbar-ash .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-ash .layout-menubutton .layout-menubutton-icon:after {
  background-color: #3F4C6B;
}
.layout-topbar-ash .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-ash .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #334163;
}
.layout-topbar-ash .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-ash .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #334163;
}
.layout-topbar-ash .layout-menubutton.layout-menubutton-active {
  background-color: rgba(51, 65, 99, 0.6);
}
.layout-topbar-ash .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-ash .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-ash .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-ash.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-ash.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-ash.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-ash.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #D3D9E8;
  }
  .layout-topbar-ash.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-ash.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-ash.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-ash .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-ash .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-ash .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-ash .layout-breadcrumb li a {
  color: #ffc107;
}

.layout-topbar-kashmir .layout-top {
  background-color: #614385;
  background-image: -webkit-gradient(linear, left top, right top, from(#614385), to(#516395));
  background-image: -webkit-linear-gradient(left, #614385, #516395);
  background-image: -moz-linear-gradient(left, #614385, #516395);
  background-image: -ms-linear-gradient(left, #614385, #516395);
  background-image: -o-linear-gradient(left, #614385, #516395);
  background-image: linear-gradient(left, #614385, #516395);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#614385", endColorstr="#516395", gradientType="1");
}
.layout-topbar-kashmir .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-kashmir .layout-topbar .layout-topbar-search i {
  color: #DCCFEC;
}
.layout-topbar-kashmir .layout-topbar .layout-topbar-search label {
  color: #DCCFEC;
}
.layout-topbar-kashmir .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-kashmir .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-kashmir .layout-menubutton .layout-menubutton-icon {
  background-color: #614385;
}
.layout-topbar-kashmir .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-kashmir .layout-menubutton .layout-menubutton-icon:after {
  background-color: #614385;
}
.layout-topbar-kashmir .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-kashmir .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #516395;
}
.layout-topbar-kashmir .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-kashmir .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #516395;
}
.layout-topbar-kashmir .layout-menubutton.layout-menubutton-active {
  background-color: rgba(81, 99, 149, 0.6);
}
.layout-topbar-kashmir .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-kashmir .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-kashmir .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-kashmir.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-kashmir.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-kashmir.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-kashmir.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #DCCFEC;
  }
  .layout-topbar-kashmir.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-kashmir.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-kashmir.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-kashmir .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-kashmir .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-kashmir .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-kashmir .layout-breadcrumb li a {
  color: #ffc107;
}

.layout-topbar-orange .layout-top {
  background-color: #DD7919;
  background-image: -webkit-gradient(linear, left top, right top, from(#DD7919), to(#E47307));
  background-image: -webkit-linear-gradient(left, #DD7919, #E47307);
  background-image: -moz-linear-gradient(left, #DD7919, #E47307);
  background-image: -ms-linear-gradient(left, #DD7919, #E47307);
  background-image: -o-linear-gradient(left, #DD7919, #E47307);
  background-image: linear-gradient(left, #DD7919, #E47307);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#DD7919", endColorstr="#E47307", gradientType="1");
}
.layout-topbar-orange .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-orange .layout-topbar .layout-topbar-search i {
  color: #EFD3B8;
}
.layout-topbar-orange .layout-topbar .layout-topbar-search label {
  color: #EFD3B8;
}
.layout-topbar-orange .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-orange .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-orange .layout-menubutton .layout-menubutton-icon {
  background-color: #DD7919;
}
.layout-topbar-orange .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-orange .layout-menubutton .layout-menubutton-icon:after {
  background-color: #DD7919;
}
.layout-topbar-orange .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-orange .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #E47307;
}
.layout-topbar-orange .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-orange .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #E47307;
}
.layout-topbar-orange .layout-menubutton.layout-menubutton-active {
  background-color: rgba(228, 115, 7, 0.6);
}
.layout-topbar-orange .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-orange .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-orange .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-orange.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-orange.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-orange.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-orange.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #EFD3B8;
  }
  .layout-topbar-orange.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-orange.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-orange.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-orange .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-orange .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-orange .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-orange .layout-breadcrumb li a {
  color: #FFF176;
}

.layout-topbar-midnight .layout-top {
  background-color: #232526;
  background-image: -webkit-gradient(linear, left top, right top, from(#232526), to(#414345));
  background-image: -webkit-linear-gradient(left, #232526, #414345);
  background-image: -moz-linear-gradient(left, #232526, #414345);
  background-image: -ms-linear-gradient(left, #232526, #414345);
  background-image: -o-linear-gradient(left, #232526, #414345);
  background-image: linear-gradient(left, #232526, #414345);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#232526", endColorstr="#414345", gradientType="1");
}
.layout-topbar-midnight .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-midnight .layout-topbar .layout-topbar-search i {
  color: #CBD1D3;
}
.layout-topbar-midnight .layout-topbar .layout-topbar-search label {
  color: #CBD1D3;
}
.layout-topbar-midnight .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-midnight .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-midnight .layout-menubutton .layout-menubutton-icon {
  background-color: #232526;
}
.layout-topbar-midnight .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-midnight .layout-menubutton .layout-menubutton-icon:after {
  background-color: #232526;
}
.layout-topbar-midnight .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-midnight .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #414345;
}
.layout-topbar-midnight .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-midnight .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #414345;
}
.layout-topbar-midnight .layout-menubutton.layout-menubutton-active {
  background-color: rgba(65, 67, 69, 0.6);
}
.layout-topbar-midnight .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-midnight .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-midnight .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-midnight.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-midnight.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-midnight.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-midnight.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #CBD1D3;
  }
  .layout-topbar-midnight.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-midnight.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-midnight.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-midnight .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-midnight .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-midnight .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-midnight .layout-breadcrumb li a {
  color: #4FC3F7;
}

.layout-topbar-sunset .layout-top {
  background-color: #C94B4B;
  background-image: -webkit-gradient(linear, left top, right top, from(#C94B4B), to(#DE6868));
  background-image: -webkit-linear-gradient(left, #C94B4B, #DE6868);
  background-image: -moz-linear-gradient(left, #C94B4B, #DE6868);
  background-image: -ms-linear-gradient(left, #C94B4B, #DE6868);
  background-image: -o-linear-gradient(left, #C94B4B, #DE6868);
  background-image: linear-gradient(left, #C94B4B, #DE6868);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#C94B4B", endColorstr="#DE6868", gradientType="1");
}
.layout-topbar-sunset .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-sunset .layout-topbar .layout-topbar-search i {
  color: #E5CECE;
}
.layout-topbar-sunset .layout-topbar .layout-topbar-search label {
  color: #E5CECE;
}
.layout-topbar-sunset .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-sunset .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-sunset .layout-menubutton .layout-menubutton-icon {
  background-color: #C94B4B;
}
.layout-topbar-sunset .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-sunset .layout-menubutton .layout-menubutton-icon:after {
  background-color: #C94B4B;
}
.layout-topbar-sunset .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-sunset .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #DE6868;
}
.layout-topbar-sunset .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-sunset .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #DE6868;
}
.layout-topbar-sunset .layout-menubutton.layout-menubutton-active {
  background-color: rgba(222, 104, 104, 0.6);
}
.layout-topbar-sunset .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-sunset .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-sunset .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-sunset.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-sunset.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-sunset.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-sunset.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #E5CECE;
  }
  .layout-topbar-sunset.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-sunset.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-sunset.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-sunset .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-sunset .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-sunset .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-sunset .layout-breadcrumb li a {
  color: #ffc107;
}

.layout-topbar-marley .layout-top {
  background-color: #00838F;
  background-image: -webkit-gradient(linear, left top, right top, from(#00838F), to(#0E7CAC));
  background-image: -webkit-linear-gradient(left, #00838F, #0E7CAC);
  background-image: -moz-linear-gradient(left, #00838F, #0E7CAC);
  background-image: -ms-linear-gradient(left, #00838F, #0E7CAC);
  background-image: -o-linear-gradient(left, #00838F, #0E7CAC);
  background-image: linear-gradient(left, #00838F, #0E7CAC);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00838F", endColorstr="#0E7CAC", gradientType="1");
}
.layout-topbar-marley .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-marley .layout-topbar .layout-topbar-search i {
  color: #BFD7DA;
}
.layout-topbar-marley .layout-topbar .layout-topbar-search label {
  color: #BFD7DA;
}
.layout-topbar-marley .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-marley .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-marley .layout-menubutton .layout-menubutton-icon {
  background-color: #00838F;
}
.layout-topbar-marley .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-marley .layout-menubutton .layout-menubutton-icon:after {
  background-color: #00838F;
}
.layout-topbar-marley .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-marley .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #0E7CAC;
}
.layout-topbar-marley .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-marley .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #0E7CAC;
}
.layout-topbar-marley .layout-menubutton.layout-menubutton-active {
  background-color: rgba(14, 124, 172, 0.6);
}
.layout-topbar-marley .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-marley .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-marley .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-marley.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-marley.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-marley.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-marley.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #BFD7DA;
  }
  .layout-topbar-marley.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-marley.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-marley.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-marley .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-marley .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-marley .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-marley .layout-breadcrumb li a {
  color: #ffc107;
}

.layout-topbar-harvey .layout-top {
  background-color: #1F4037;
  background-image: -webkit-gradient(linear, left top, right top, from(#1F4037), to(#509D7C));
  background-image: -webkit-linear-gradient(left, #1F4037, #509D7C);
  background-image: -moz-linear-gradient(left, #1F4037, #509D7C);
  background-image: -ms-linear-gradient(left, #1F4037, #509D7C);
  background-image: -o-linear-gradient(left, #1F4037, #509D7C);
  background-image: linear-gradient(left, #1F4037, #509D7C);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1F4037", endColorstr="#509D7C", gradientType="1");
}
.layout-topbar-harvey .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-harvey .layout-topbar .layout-topbar-search i {
  color: #A5CBBA;
}
.layout-topbar-harvey .layout-topbar .layout-topbar-search label {
  color: #A5CBBA;
}
.layout-topbar-harvey .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-harvey .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-harvey .layout-menubutton .layout-menubutton-icon {
  background-color: #1F4037;
}
.layout-topbar-harvey .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-harvey .layout-menubutton .layout-menubutton-icon:after {
  background-color: #1F4037;
}
.layout-topbar-harvey .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-harvey .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #1F4037;
}
.layout-topbar-harvey .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-harvey .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #1F4037;
}
.layout-topbar-harvey .layout-menubutton.layout-menubutton-active {
  background-color: rgba(47, 128, 92, 0.6);
}
.layout-topbar-harvey .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-harvey .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-harvey .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-harvey.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-harvey.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-harvey.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-harvey.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #A5CBBA;
  }
  .layout-topbar-harvey.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-harvey.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-harvey.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-harvey .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-harvey .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-harvey .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-harvey .layout-breadcrumb li a {
  color: #ffc107;
}

.layout-topbar-vanusa .layout-top {
  background-color: #89216B;
  background-image: -webkit-gradient(linear, left top, right top, from(#89216B), to(#9E2A64));
  background-image: -webkit-linear-gradient(left, #89216B, #9E2A64);
  background-image: -moz-linear-gradient(left, #89216B, #9E2A64);
  background-image: -ms-linear-gradient(left, #89216B, #9E2A64);
  background-image: -o-linear-gradient(left, #89216B, #9E2A64);
  background-image: linear-gradient(left, #89216B, #9E2A64);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#89216B", endColorstr="#9E2A64", gradientType="1");
}
.layout-topbar-vanusa .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-vanusa .layout-topbar .layout-topbar-search i {
  color: #D7B0C8;
}
.layout-topbar-vanusa .layout-topbar .layout-topbar-search label {
  color: #D7B0C8;
}
.layout-topbar-vanusa .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-vanusa .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-vanusa .layout-menubutton .layout-menubutton-icon {
  background-color: #B4345F;
}
.layout-topbar-vanusa .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-vanusa .layout-menubutton .layout-menubutton-icon:after {
  background-color: #B4345F;
}
.layout-topbar-vanusa .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-vanusa .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #B4345F;
}
.layout-topbar-vanusa .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-vanusa .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #B4345F;
}
.layout-topbar-vanusa .layout-menubutton.layout-menubutton-active {
  background-color: rgba(243, 117, 159, 0.6);
}
.layout-topbar-vanusa .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-vanusa .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-vanusa .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-vanusa.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-vanusa.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-vanusa.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-vanusa.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #D7B0C8;
  }
  .layout-topbar-vanusa.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-vanusa.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-vanusa.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-vanusa .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-vanusa .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-vanusa .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-vanusa .layout-breadcrumb li a {
  color: #ffc107;
}

.layout-topbar-skyline .layout-top {
  background-color: #2764B6;
  background-image: -webkit-gradient(linear, left top, right top, from(#2764B6), to(#303BB5));
  background-image: -webkit-linear-gradient(left, #2764B6, #303BB5);
  background-image: -moz-linear-gradient(left, #2764B6, #303BB5);
  background-image: -ms-linear-gradient(left, #2764B6, #303BB5);
  background-image: -o-linear-gradient(left, #2764B6, #303BB5);
  background-image: linear-gradient(left, #2764B6, #303BB5);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#2764B6", endColorstr="#303BB5", gradientType="1");
}
.layout-topbar-skyline .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-skyline .layout-topbar .layout-topbar-search i {
  color: #B3BAE3;
}
.layout-topbar-skyline .layout-topbar .layout-topbar-search label {
  color: #B3BAE3;
}
.layout-topbar-skyline .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-skyline .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-skyline .layout-menubutton .layout-menubutton-icon {
  background-color: #2764B6;
}
.layout-topbar-skyline .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-skyline .layout-menubutton .layout-menubutton-icon:after {
  background-color: #2764B6;
}
.layout-topbar-skyline .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-skyline .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #2764B6;
}
.layout-topbar-skyline .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-skyline .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #2764B6;
}
.layout-topbar-skyline .layout-menubutton.layout-menubutton-active {
  background-color: rgba(108, 165, 233, 0.6);
}
.layout-topbar-skyline .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-skyline .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-skyline .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-skyline.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-skyline.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-skyline.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-skyline.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #B3BAE3;
  }
  .layout-topbar-skyline.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-skyline.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-skyline.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-skyline .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-skyline .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-skyline .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-skyline .layout-breadcrumb li a {
  color: #ffc107;
}

.layout-topbar-royal .layout-top {
  background-color: #141E30;
  background-image: -webkit-gradient(linear, left top, right top, from(#141E30), to(#243B55));
  background-image: -webkit-linear-gradient(left, #141E30, #243B55);
  background-image: -moz-linear-gradient(left, #141E30, #243B55);
  background-image: -ms-linear-gradient(left, #141E30, #243B55);
  background-image: -o-linear-gradient(left, #141E30, #243B55);
  background-image: linear-gradient(left, #141E30, #243B55);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#141E30", endColorstr="#243B55", gradientType="1");
}
.layout-topbar-royal .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-royal .layout-topbar .layout-topbar-search i {
  color: #AEB6BF;
}
.layout-topbar-royal .layout-topbar .layout-topbar-search label {
  color: #AEB6BF;
}
.layout-topbar-royal .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-royal .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-royal .layout-menubutton .layout-menubutton-icon {
  background-color: #141E30;
}
.layout-topbar-royal .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-royal .layout-menubutton .layout-menubutton-icon:after {
  background-color: #141E30;
}
.layout-topbar-royal .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-royal .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #141E30;
}
.layout-topbar-royal .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-royal .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #141E30;
}
.layout-topbar-royal .layout-menubutton.layout-menubutton-active {
  background-color: rgba(86, 107, 145, 0.6);
}
.layout-topbar-royal .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-royal .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-royal .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-royal.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-royal.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-royal.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-royal.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #AEB6BF;
  }
  .layout-topbar-royal.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-royal.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-royal.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-royal .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-royal .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-royal .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-royal .layout-breadcrumb li a {
  color: #ffc107;
}

.layout-topbar-disco .layout-top {
  background-color: #556270;
  background-image: -webkit-gradient(linear, left top, right top, from(#556270), to(#2C8F88));
  background-image: -webkit-linear-gradient(left, #556270, #2C8F88);
  background-image: -moz-linear-gradient(left, #556270, #2C8F88);
  background-image: -ms-linear-gradient(left, #556270, #2C8F88);
  background-image: -o-linear-gradient(left, #556270, #2C8F88);
  background-image: linear-gradient(left, #556270, #2C8F88);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#556270", endColorstr="#2C8F88", gradientType="1");
}
.layout-topbar-disco .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-disco .layout-topbar .layout-topbar-search i {
  color: #B4D4D2;
}
.layout-topbar-disco .layout-topbar .layout-topbar-search label {
  color: #B4D4D2;
}
.layout-topbar-disco .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-disco .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-disco .layout-menubutton .layout-menubutton-icon {
  background-color: #556270;
}
.layout-topbar-disco .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-disco .layout-menubutton .layout-menubutton-icon:after {
  background-color: #556270;
}
.layout-topbar-disco .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-disco .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #556270;
}
.layout-topbar-disco .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-disco .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #556270;
}
.layout-topbar-disco .layout-menubutton.layout-menubutton-active {
  background-color: rgba(163, 174, 185, 0.6);
}
.layout-topbar-disco .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-disco .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-disco .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-disco.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-disco.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-disco.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-disco.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #B4D4D2;
  }
  .layout-topbar-disco.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-disco.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-disco.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-disco .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-disco .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-disco .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-disco .layout-breadcrumb li a {
  color: #ffc107;
}

.layout-topbar-sky .layout-top {
  background-color: #026E9D;
  background-image: -webkit-gradient(linear, left top, right top, from(#026E9D), to(#1BA6DD));
  background-image: -webkit-linear-gradient(left, #026E9D, #1BA6DD);
  background-image: -moz-linear-gradient(left, #026E9D, #1BA6DD);
  background-image: -ms-linear-gradient(left, #026E9D, #1BA6DD);
  background-image: -o-linear-gradient(left, #026E9D, #1BA6DD);
  background-image: linear-gradient(left, #026E9D, #1BA6DD);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#026E9D", endColorstr="#1BA6DD", gradientType="1");
}
.layout-topbar-sky .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-sky .layout-topbar .layout-topbar-search i {
  color: #ABDCEF;
}
.layout-topbar-sky .layout-topbar .layout-topbar-search label {
  color: #ABDCEF;
}
.layout-topbar-sky .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-sky .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-sky .layout-menubutton .layout-menubutton-icon {
  background-color: #005A82;
}
.layout-topbar-sky .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-sky .layout-menubutton .layout-menubutton-icon:after {
  background-color: #005A82;
}
.layout-topbar-sky .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-sky .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #005A82;
}
.layout-topbar-sky .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-sky .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #005A82;
}
.layout-topbar-sky .layout-menubutton.layout-menubutton-active {
  background-color: rgba(6, 84, 118, 0.6);
}
.layout-topbar-sky .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-sky .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-sky .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-sky.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-sky.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-sky.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-sky.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #ABDCEF;
  }
  .layout-topbar-sky.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-sky.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-sky.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-sky .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-sky .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-sky .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-sky .layout-breadcrumb li a {
  color: #ffc107;
}

.layout-topbar-rose .layout-top {
  background-color: #833859;
  background-image: -webkit-gradient(linear, left top, right top, from(#833859), to(#96607F));
  background-image: -webkit-linear-gradient(left, #833859, #96607F);
  background-image: -moz-linear-gradient(left, #833859, #96607F);
  background-image: -ms-linear-gradient(left, #833859, #96607F);
  background-image: -o-linear-gradient(left, #833859, #96607F);
  background-image: linear-gradient(left, #833859, #96607F);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#833859", endColorstr="#96607F", gradientType="1");
}
.layout-topbar-rose .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-rose .layout-topbar .layout-topbar-search i {
  color: #D8C3CE;
}
.layout-topbar-rose .layout-topbar .layout-topbar-search label {
  color: #D8C3CE;
}
.layout-topbar-rose .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-rose .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-rose .layout-menubutton .layout-menubutton-icon {
  background-color: #833859;
}
.layout-topbar-rose .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-rose .layout-menubutton .layout-menubutton-icon:after {
  background-color: #833859;
}
.layout-topbar-rose .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-rose .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #833859;
}
.layout-topbar-rose .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-rose .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #833859;
}
.layout-topbar-rose .layout-menubutton.layout-menubutton-active {
  background-color: rgba(82, 32, 54, 0.6);
}
.layout-topbar-rose .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-rose .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-rose .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-rose.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-rose.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-rose.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-rose.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #D8C3CE;
  }
  .layout-topbar-rose.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-rose.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-rose.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-rose .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-rose .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-rose .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-rose .layout-breadcrumb li a {
  color: #ffc107;
}

.layout-topbar-revolt .layout-top {
  background-color: #474982;
  background-image: -webkit-gradient(linear, left top, right top, from(#474982), to(#6D97A2));
  background-image: -webkit-linear-gradient(left, #474982, #6D97A2);
  background-image: -moz-linear-gradient(left, #474982, #6D97A2);
  background-image: -ms-linear-gradient(left, #474982, #6D97A2);
  background-image: -o-linear-gradient(left, #474982, #6D97A2);
  background-image: linear-gradient(left, #474982, #6D97A2);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#474982", endColorstr="#6D97A2", gradientType="1");
}
.layout-topbar-revolt .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-revolt .layout-topbar .layout-topbar-search i {
  color: #C8D5DC;
}
.layout-topbar-revolt .layout-topbar .layout-topbar-search label {
  color: #C8D5DC;
}
.layout-topbar-revolt .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-revolt .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-revolt .layout-menubutton .layout-menubutton-icon {
  background-color: #474982;
}
.layout-topbar-revolt .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-revolt .layout-menubutton .layout-menubutton-icon:after {
  background-color: #474982;
}
.layout-topbar-revolt .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-revolt .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #474982;
}
.layout-topbar-revolt .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-revolt .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #474982;
}
.layout-topbar-revolt .layout-menubutton.layout-menubutton-active {
  background-color: rgba(45, 46, 91, 0.6);
}
.layout-topbar-revolt .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-revolt .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-revolt .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-revolt.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-revolt.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-revolt.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-revolt.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #C8D5DC;
  }
  .layout-topbar-revolt.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-revolt.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-revolt.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-revolt .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-revolt .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-revolt .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-revolt .layout-breadcrumb li a {
  color: #FF9800;
}

.layout-topbar-forest .layout-top {
  background-color: #224C3D;
  background-image: -webkit-gradient(linear, left top, right top, from(#224C3D), to(#95A850));
  background-image: -webkit-linear-gradient(left, #224C3D, #95A850);
  background-image: -moz-linear-gradient(left, #224C3D, #95A850);
  background-image: -ms-linear-gradient(left, #224C3D, #95A850);
  background-image: -o-linear-gradient(left, #224C3D, #95A850);
  background-image: linear-gradient(left, #224C3D, #95A850);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#224C3D", endColorstr="#95A850", gradientType="1");
}
.layout-topbar-forest .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-forest .layout-topbar .layout-topbar-search i {
  color: #D3DBBF;
}
.layout-topbar-forest .layout-topbar .layout-topbar-search label {
  color: #D3DBBF;
}
.layout-topbar-forest .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-forest .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-forest .layout-menubutton .layout-menubutton-icon {
  background-color: #224C3D;
}
.layout-topbar-forest .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-forest .layout-menubutton .layout-menubutton-icon:after {
  background-color: #224C3D;
}
.layout-topbar-forest .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-forest .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #224C3D;
}
.layout-topbar-forest .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-forest .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #224C3D;
}
.layout-topbar-forest .layout-menubutton.layout-menubutton-active {
  background-color: rgba(22, 55, 43, 0.6);
}
.layout-topbar-forest .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-forest .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-forest .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-forest.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-forest.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-forest.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-forest.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #D3DBBF;
  }
  .layout-topbar-forest.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-forest.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-forest.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-forest .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-forest .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-forest .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-forest .layout-breadcrumb li a {
  color: #E91E63;
}

.layout-topbar-night .layout-top {
  background-color: #363C7E;
  background-image: -webkit-gradient(linear, left top, right top, from(#363C7E), to(#0D68AF));
  background-image: -webkit-linear-gradient(left, #363C7E, #0D68AF);
  background-image: -moz-linear-gradient(left, #363C7E, #0D68AF);
  background-image: -ms-linear-gradient(left, #363C7E, #0D68AF);
  background-image: -o-linear-gradient(left, #363C7E, #0D68AF);
  background-image: linear-gradient(left, #363C7E, #0D68AF);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#363C7E", endColorstr="#0D68AF", gradientType="1");
}
.layout-topbar-night .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-night .layout-topbar .layout-topbar-search i {
  color: #A9C5DF;
}
.layout-topbar-night .layout-topbar .layout-topbar-search label {
  color: #A9C5DF;
}
.layout-topbar-night .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-night .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-night .layout-menubutton .layout-menubutton-icon {
  background-color: #363C7E;
}
.layout-topbar-night .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-night .layout-menubutton .layout-menubutton-icon:after {
  background-color: #363C7E;
}
.layout-topbar-night .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-night .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #363C7E;
}
.layout-topbar-night .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-night .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #363C7E;
}
.layout-topbar-night .layout-menubutton.layout-menubutton-active {
  background-color: rgba(32, 37, 90, 0.6);
}
.layout-topbar-night .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-night .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-night .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-night.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-night.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-night.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-night.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #A9C5DF;
  }
  .layout-topbar-night.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-night.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-night.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-night .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-night .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-night .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-night .layout-breadcrumb li a {
  color: #FFB300;
}

.layout-topbar-apricot .layout-top {
  background-color: #F1719A;
  background-image: -webkit-gradient(linear, left top, right top, from(#F1719A), to(#FE9473));
  background-image: -webkit-linear-gradient(left, #F1719A, #FE9473);
  background-image: -moz-linear-gradient(left, #F1719A, #FE9473);
  background-image: -ms-linear-gradient(left, #F1719A, #FE9473);
  background-image: -o-linear-gradient(left, #F1719A, #FE9473);
  background-image: linear-gradient(left, #F1719A, #FE9473);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#F1719A", endColorstr="#FE9473", gradientType="1");
}
.layout-topbar-apricot .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-apricot .layout-topbar .layout-topbar-search i {
  color: #FDD5CF;
}
.layout-topbar-apricot .layout-topbar .layout-topbar-search label {
  color: #FDD5CF;
}
.layout-topbar-apricot .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-apricot .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-apricot .layout-menubutton .layout-menubutton-icon {
  background-color: #F1719A;
}
.layout-topbar-apricot .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-apricot .layout-menubutton .layout-menubutton-icon:after {
  background-color: #F1719A;
}
.layout-topbar-apricot .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-apricot .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #F1719A;
}
.layout-topbar-apricot .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-apricot .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #F1719A;
}
.layout-topbar-apricot .layout-menubutton.layout-menubutton-active {
  background-color: rgba(169, 53, 89, 0.6);
}
.layout-topbar-apricot .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-apricot .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-apricot .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-apricot.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-apricot.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-apricot.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-apricot.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #FDD5CF;
  }
  .layout-topbar-apricot.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-apricot.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-apricot.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-apricot .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-apricot .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-apricot .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-apricot .layout-breadcrumb li a {
  color: #80CBC4;
}

.layout-topbar-faraway .layout-top {
  background-color: #D0664E;
  background-image: -webkit-gradient(linear, left top, right top, from(#D0664E), to(#612338));
  background-image: -webkit-linear-gradient(left, #D0664E, #612338);
  background-image: -moz-linear-gradient(left, #D0664E, #612338);
  background-image: -ms-linear-gradient(left, #D0664E, #612338);
  background-image: -o-linear-gradient(left, #D0664E, #612338);
  background-image: linear-gradient(left, #D0664E, #612338);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#D0664E", endColorstr="#612338", gradientType="1");
}
.layout-topbar-faraway .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-faraway .layout-topbar .layout-topbar-search i {
  color: #CDB4B8;
}
.layout-topbar-faraway .layout-topbar .layout-topbar-search label {
  color: #CDB4B8;
}
.layout-topbar-faraway .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-faraway .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-faraway .layout-menubutton .layout-menubutton-icon {
  background-color: #D0664E;
}
.layout-topbar-faraway .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-faraway .layout-menubutton .layout-menubutton-icon:after {
  background-color: #D0664E;
}
.layout-topbar-faraway .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-faraway .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #D0664E;
}
.layout-topbar-faraway .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-faraway .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #D0664E;
}
.layout-topbar-faraway .layout-menubutton.layout-menubutton-active {
  background-color: rgba(153, 63, 44, 0.6);
}
.layout-topbar-faraway .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-faraway .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-faraway .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-faraway.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-faraway.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-faraway.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-faraway.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #CDB4B8;
  }
  .layout-topbar-faraway.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-faraway.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-faraway.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-faraway .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-faraway .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-faraway .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-faraway .layout-breadcrumb li a {
  color: #00BCD4;
}

.layout-topbar-grape .layout-top {
  background-color: #5854F7;
  background-image: -webkit-gradient(linear, left top, right top, from(#5854F7), to(#7A91D8));
  background-image: -webkit-linear-gradient(left, #5854F7, #7A91D8);
  background-image: -moz-linear-gradient(left, #5854F7, #7A91D8);
  background-image: -ms-linear-gradient(left, #5854F7, #7A91D8);
  background-image: -o-linear-gradient(left, #5854F7, #7A91D8);
  background-image: linear-gradient(left, #5854F7, #7A91D8);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#5854F7", endColorstr="#7A91D8", gradientType="1");
}
.layout-topbar-grape .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-grape .layout-topbar .layout-topbar-search i {
  color: #CCD2F3;
}
.layout-topbar-grape .layout-topbar .layout-topbar-search label {
  color: #CCD2F3;
}
.layout-topbar-grape .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-grape .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-grape .layout-menubutton .layout-menubutton-icon {
  background-color: #5854F7;
}
.layout-topbar-grape .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-grape .layout-menubutton .layout-menubutton-icon:after {
  background-color: #5854F7;
}
.layout-topbar-grape .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-grape .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #5854F7;
}
.layout-topbar-grape .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-grape .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #5854F7;
}
.layout-topbar-grape .layout-menubutton.layout-menubutton-active {
  background-color: rgba(60, 57, 181, 0.6);
}
.layout-topbar-grape .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-grape .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-grape .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-grape.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-grape.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-grape.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-grape.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #CCD2F3;
  }
  .layout-topbar-grape.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-grape.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-grape.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-grape .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-grape .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-grape .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-grape .layout-breadcrumb li a {
  color: #F06292;
}

.layout-topbar-river .layout-top {
  background-color: #054769;
  background-image: -webkit-gradient(linear, left top, right top, from(#054769), to(#0596B7));
  background-image: -webkit-linear-gradient(left, #054769, #0596B7);
  background-image: -moz-linear-gradient(left, #054769, #0596B7);
  background-image: -ms-linear-gradient(left, #054769, #0596B7);
  background-image: -o-linear-gradient(left, #054769, #0596B7);
  background-image: linear-gradient(left, #054769, #0596B7);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#054769", endColorstr="#0596B7", gradientType="1");
}
.layout-topbar-river .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-river .layout-topbar .layout-topbar-search i {
  color: #A4D3DF;
}
.layout-topbar-river .layout-topbar .layout-topbar-search label {
  color: #A4D3DF;
}
.layout-topbar-river .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-river .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-river .layout-menubutton .layout-menubutton-icon {
  background-color: #054769;
}
.layout-topbar-river .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-river .layout-menubutton .layout-menubutton-icon:after {
  background-color: #054769;
}
.layout-topbar-river .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-river .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #054769;
}
.layout-topbar-river .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-river .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #054769;
}
.layout-topbar-river .layout-menubutton.layout-menubutton-active {
  background-color: rgba(7, 47, 65, 0.6);
}
.layout-topbar-river .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-river .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-river .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-river.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-river.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-river.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-river.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #A4D3DF;
  }
  .layout-topbar-river.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-river.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-river.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-river .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-river .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-river .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-river .layout-breadcrumb li a {
  color: #FFB300;
}

.layout-topbar-dock .layout-top {
  background-color: #422072;
  background-image: -webkit-gradient(linear, left top, right top, from(#422072), to(#F29C7B));
  background-image: -webkit-linear-gradient(left, #422072, #F29C7B);
  background-image: -moz-linear-gradient(left, #422072, #F29C7B);
  background-image: -ms-linear-gradient(left, #422072, #F29C7B);
  background-image: -o-linear-gradient(left, #422072, #F29C7B);
  background-image: linear-gradient(left, #422072, #F29C7B);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#422072", endColorstr="#F29C7B", gradientType="1");
}
.layout-topbar-dock .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-dock .layout-topbar .layout-topbar-search i {
  color: #EED2CE;
}
.layout-topbar-dock .layout-topbar .layout-topbar-search label {
  color: #EED2CE;
}
.layout-topbar-dock .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-dock .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-dock .layout-menubutton .layout-menubutton-icon {
  background-color: #422072;
}
.layout-topbar-dock .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-dock .layout-menubutton .layout-menubutton-icon:after {
  background-color: #422072;
}
.layout-topbar-dock .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-dock .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #422072;
}
.layout-topbar-dock .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-dock .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #422072;
}
.layout-topbar-dock .layout-menubutton.layout-menubutton-active {
  background-color: rgba(47, 18, 81, 0.6);
}
.layout-topbar-dock .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-dock .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-dock .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-dock.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-dock.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-dock.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-dock.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #EED2CE;
  }
  .layout-topbar-dock.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-dock.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-dock.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-dock .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-dock .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-dock .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-dock .layout-breadcrumb li a {
  color: #FFB300;
}

.layout-topbar-materialone .layout-top {
  background-image: url("../../../layout/images/special/materialone-topbar.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-topbar-materialone .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-materialone .layout-topbar .layout-topbar-search i {
  color: #D2D3DB;
}
.layout-topbar-materialone .layout-topbar .layout-topbar-search label {
  color: #D2D3DB;
}
.layout-topbar-materialone .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-materialone .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-materialone .layout-menubutton .layout-menubutton-icon {
  background-color: #1E1E31;
}
.layout-topbar-materialone .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-materialone .layout-menubutton .layout-menubutton-icon:after {
  background-color: #1E1E31;
}
.layout-topbar-materialone .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-materialone .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #2C2E44;
}
.layout-topbar-materialone .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-materialone .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #2C2E44;
}
.layout-topbar-materialone .layout-menubutton.layout-menubutton-active {
  background-color: rgba(63, 65, 89, 0.6);
}
.layout-topbar-materialone .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-materialone .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-materialone .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-materialone.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-materialone.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-materialone.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-materialone.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #D2D3DB;
  }
  .layout-topbar-materialone.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-materialone.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-materialone.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-materialone .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-materialone .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-materialone .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-materialone .layout-breadcrumb li a {
  color: #ffc107;
}

.layout-topbar-materialtwo .layout-top {
  background-image: url("../../../layout/images/special/materialtwo-topbar.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-topbar-materialtwo .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-materialtwo .layout-topbar .layout-topbar-search i {
  color: #cbdeee;
}
.layout-topbar-materialtwo .layout-topbar .layout-topbar-search label {
  color: #cbdeee;
}
.layout-topbar-materialtwo .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-materialtwo .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-materialtwo .layout-menubutton .layout-menubutton-icon {
  background-color: #447dc6;
}
.layout-topbar-materialtwo .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-materialtwo .layout-menubutton .layout-menubutton-icon:after {
  background-color: #447dc6;
}
.layout-topbar-materialtwo .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-materialtwo .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #447dc6;
}
.layout-topbar-materialtwo .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-materialtwo .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #447dc6;
}
.layout-topbar-materialtwo .layout-menubutton.layout-menubutton-active {
  background-color: rgba(10, 61, 90, 0.6);
}
.layout-topbar-materialtwo .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-materialtwo .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-materialtwo .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-materialtwo.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-materialtwo.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-materialtwo.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-materialtwo.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #cbdeee;
  }
  .layout-topbar-materialtwo.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-materialtwo.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-materialtwo.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-materialtwo .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-materialtwo .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-materialtwo .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-materialtwo .layout-breadcrumb li a {
  color: #ffc107;
}

.layout-topbar-connectionsone .layout-top {
  background-image: url("../../../layout/images/special/connectionsone-topbar.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-topbar-connectionsone .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-connectionsone .layout-topbar .layout-topbar-search i {
  color: #AA9FB5;
}
.layout-topbar-connectionsone .layout-topbar .layout-topbar-search label {
  color: #AA9FB5;
}
.layout-topbar-connectionsone .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-connectionsone .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-connectionsone .layout-menubutton .layout-menubutton-icon {
  background-color: #6A236E;
}
.layout-topbar-connectionsone .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-connectionsone .layout-menubutton .layout-menubutton-icon:after {
  background-color: #6A236E;
}
.layout-topbar-connectionsone .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-connectionsone .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #6A236E;
}
.layout-topbar-connectionsone .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-connectionsone .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #6A236E;
}
.layout-topbar-connectionsone .layout-menubutton.layout-menubutton-active {
  background-color: rgba(106, 35, 110, 0.6);
}
.layout-topbar-connectionsone .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-connectionsone .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-connectionsone .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-connectionsone.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-connectionsone.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-connectionsone.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-connectionsone.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #AA9FB5;
  }
  .layout-topbar-connectionsone.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-connectionsone.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-connectionsone.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-connectionsone .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-connectionsone .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-connectionsone .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-connectionsone .layout-breadcrumb li a {
  color: #ffc107;
}

.layout-topbar-connectionstwo .layout-top {
  background-image: url("../../../layout/images/special/connectionstwo-topbar.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-topbar-connectionstwo .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-connectionstwo .layout-topbar .layout-topbar-search i {
  color: #D6E7EB;
}
.layout-topbar-connectionstwo .layout-topbar .layout-topbar-search label {
  color: #D6E7EB;
}
.layout-topbar-connectionstwo .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-connectionstwo .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-connectionstwo .layout-menubutton .layout-menubutton-icon {
  background-color: #058CA9;
}
.layout-topbar-connectionstwo .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-connectionstwo .layout-menubutton .layout-menubutton-icon:after {
  background-color: #058CA9;
}
.layout-topbar-connectionstwo .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-connectionstwo .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #058CA9;
}
.layout-topbar-connectionstwo .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-connectionstwo .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #058CA9;
}
.layout-topbar-connectionstwo .layout-menubutton.layout-menubutton-active {
  background-color: rgba(5, 140, 169, 0.6);
}
.layout-topbar-connectionstwo .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-connectionstwo .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-connectionstwo .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-connectionstwo.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-connectionstwo.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-connectionstwo.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-connectionstwo.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #D6E7EB;
  }
  .layout-topbar-connectionstwo.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-connectionstwo.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-connectionstwo.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-connectionstwo .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-connectionstwo .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-connectionstwo .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-connectionstwo .layout-breadcrumb li a {
  color: #ffc107;
}

.layout-topbar-road .layout-top {
  background-image: url("../../../layout/images/special/road-topbar.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-topbar-road .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-road .layout-topbar .layout-topbar-search i {
  color: #D3D8E1;
}
.layout-topbar-road .layout-topbar .layout-topbar-search label {
  color: #D3D8E1;
}
.layout-topbar-road .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-road .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-road .layout-menubutton .layout-menubutton-icon {
  background-color: #000A19;
}
.layout-topbar-road .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-road .layout-menubutton .layout-menubutton-icon:after {
  background-color: #000A19;
}
.layout-topbar-road .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-road .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #000A19;
}
.layout-topbar-road .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-road .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #000A19;
}
.layout-topbar-road .layout-menubutton.layout-menubutton-active {
  background-color: rgba(255, 179, 0, 0.6);
}
.layout-topbar-road .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-road .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-road .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-road.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-road.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-road.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-road.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #D3D8E1;
  }
  .layout-topbar-road.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-road.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-road.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-road .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-road .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-road .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-road .layout-breadcrumb li a {
  color: #ffc107;
}

.layout-topbar-reflection .layout-top {
  background-image: url("../../../layout/images/special/reflection-topbar.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-topbar-reflection .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-reflection .layout-topbar .layout-topbar-search i {
  color: #BFDEE2;
}
.layout-topbar-reflection .layout-topbar .layout-topbar-search label {
  color: #BFDEE2;
}
.layout-topbar-reflection .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-reflection .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-reflection .layout-menubutton .layout-menubutton-icon {
  background-color: #1C1F20;
}
.layout-topbar-reflection .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-reflection .layout-menubutton .layout-menubutton-icon:after {
  background-color: #1C1F20;
}
.layout-topbar-reflection .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-reflection .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #1C1F20;
}
.layout-topbar-reflection .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-reflection .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #1C1F20;
}
.layout-topbar-reflection .layout-menubutton.layout-menubutton-active {
  background-color: rgba(0, 172, 193, 0.6);
}
.layout-topbar-reflection .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-reflection .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-reflection .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-reflection.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-reflection.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-reflection.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-reflection.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #BFDEE2;
  }
  .layout-topbar-reflection.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-reflection.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-reflection.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-reflection .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-reflection .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-reflection .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-reflection .layout-breadcrumb li a {
  color: #4DD0E1;
}

.layout-topbar-waves .layout-top {
  background-image: url("../../../layout/images/special/waves-topbar.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-topbar-waves .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-waves .layout-topbar .layout-topbar-search i {
  color: #B3D9E1;
}
.layout-topbar-waves .layout-topbar .layout-topbar-search label {
  color: #B3D9E1;
}
.layout-topbar-waves .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-waves .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-waves .layout-menubutton .layout-menubutton-icon {
  background-color: #000A19;
}
.layout-topbar-waves .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-waves .layout-menubutton .layout-menubutton-icon:after {
  background-color: #000A19;
}
.layout-topbar-waves .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-waves .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #000A19;
}
.layout-topbar-waves .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-waves .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #000A19;
}
.layout-topbar-waves .layout-menubutton.layout-menubutton-active {
  background-color: rgba(35, 137, 161, 0.6);
}
.layout-topbar-waves .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-waves .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-waves .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-waves.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-waves.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-waves.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-waves.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #B3D9E1;
  }
  .layout-topbar-waves.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-waves.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-waves.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-waves .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-waves .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-waves .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-waves .layout-breadcrumb li a {
  color: #ffc107;
}

.layout-topbar-sandiego .layout-top {
  background-image: url("../../../layout/images/special/sandiego-topbar.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-topbar-sandiego .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-sandiego .layout-topbar .layout-topbar-search i {
  color: #C5CDDF;
}
.layout-topbar-sandiego .layout-topbar .layout-topbar-search label {
  color: #C5CDDF;
}
.layout-topbar-sandiego .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-sandiego .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-sandiego .layout-menubutton .layout-menubutton-icon {
  background-color: #03111C;
}
.layout-topbar-sandiego .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-sandiego .layout-menubutton .layout-menubutton-icon:after {
  background-color: #03111C;
}
.layout-topbar-sandiego .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-sandiego .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #03111C;
}
.layout-topbar-sandiego .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-sandiego .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #03111C;
}
.layout-topbar-sandiego .layout-menubutton.layout-menubutton-active {
  background-color: rgba(74, 73, 78, 0.6);
}
.layout-topbar-sandiego .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-sandiego .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-sandiego .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-sandiego.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-sandiego.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-sandiego.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-sandiego.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #C5CDDF;
  }
  .layout-topbar-sandiego.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-sandiego.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-sandiego.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-sandiego .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-sandiego .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-sandiego .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-sandiego .layout-breadcrumb li a {
  color: #ffc107;
}

.layout-topbar-architecture .layout-top {
  background-image: url("../../../layout/images/special/architecture-topbar.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-topbar-architecture .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-architecture .layout-topbar .layout-topbar-search i {
  color: #BCBBC7;
}
.layout-topbar-architecture .layout-topbar .layout-topbar-search label {
  color: #BCBBC7;
}
.layout-topbar-architecture .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-architecture .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-architecture .layout-menubutton .layout-menubutton-icon {
  background-color: #051719;
}
.layout-topbar-architecture .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-architecture .layout-menubutton .layout-menubutton-icon:after {
  background-color: #051719;
}
.layout-topbar-architecture .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-architecture .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #051719;
}
.layout-topbar-architecture .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-architecture .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #051719;
}
.layout-topbar-architecture .layout-menubutton.layout-menubutton-active {
  background-color: rgba(102, 102, 112, 0.6);
}
.layout-topbar-architecture .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-architecture .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-architecture .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-architecture.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-architecture.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-architecture.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-architecture.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #BCBBC7;
  }
  .layout-topbar-architecture.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-architecture.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-architecture.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-architecture .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-architecture .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-architecture .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-architecture .layout-breadcrumb li a {
  color: #B39DDB;
}

.layout-topbar-snow .layout-top {
  background-image: url("../../../layout/images/special/snow-topbar.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-topbar-snow .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-snow .layout-topbar .layout-topbar-search i {
  color: #B1C2C5;
}
.layout-topbar-snow .layout-topbar .layout-topbar-search label {
  color: #B1C2C5;
}
.layout-topbar-snow .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-snow .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-snow .layout-menubutton .layout-menubutton-icon {
  background-color: #040605;
}
.layout-topbar-snow .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-snow .layout-menubutton .layout-menubutton-icon:after {
  background-color: #040605;
}
.layout-topbar-snow .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-snow .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #040605;
}
.layout-topbar-snow .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-snow .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #040605;
}
.layout-topbar-snow .layout-menubutton.layout-menubutton-active {
  background-color: rgba(36, 99, 110, 0.6);
}
.layout-topbar-snow .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-snow .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-snow .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-snow.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-snow.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-snow.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-snow.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #B1C2C5;
  }
  .layout-topbar-snow.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-snow.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-snow.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-snow .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-snow .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-snow .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-snow .layout-breadcrumb li a {
  color: #ffc107;
}

.layout-topbar-palm .layout-top {
  background-image: url("../../../layout/images/special/palm-topbar.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-topbar-palm .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-palm .layout-topbar .layout-topbar-search i {
  color: #B3BAC3;
}
.layout-topbar-palm .layout-topbar .layout-topbar-search label {
  color: #B3BAC3;
}
.layout-topbar-palm .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-palm .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-palm .layout-menubutton .layout-menubutton-icon {
  background-color: #28445A;
}
.layout-topbar-palm .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-palm .layout-menubutton .layout-menubutton-icon:after {
  background-color: #28445A;
}
.layout-topbar-palm .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-palm .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #28445A;
}
.layout-topbar-palm .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-palm .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #28445A;
}
.layout-topbar-palm .layout-menubutton.layout-menubutton-active {
  background-color: rgba(31, 51, 69, 0.6);
}
.layout-topbar-palm .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-palm .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-palm .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-palm.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-palm.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-palm.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-palm.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #B3BAC3;
  }
  .layout-topbar-palm.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-palm.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-palm.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-palm .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-palm .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-palm .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-palm .layout-breadcrumb li a {
  color: #ffc107;
}

.layout-topbar-fluid .layout-top {
  background-image: url("../../../layout/images/special/fluid-topbar.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-topbar-fluid .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-fluid .layout-topbar .layout-topbar-search i {
  color: #DFC7DD;
}
.layout-topbar-fluid .layout-topbar .layout-topbar-search label {
  color: #DFC7DD;
}
.layout-topbar-fluid .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-fluid .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-fluid .layout-menubutton .layout-menubutton-icon {
  background-color: #02102F;
}
.layout-topbar-fluid .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-fluid .layout-menubutton .layout-menubutton-icon:after {
  background-color: #02102F;
}
.layout-topbar-fluid .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-fluid .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #02102F;
}
.layout-topbar-fluid .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-fluid .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #02102F;
}
.layout-topbar-fluid .layout-menubutton.layout-menubutton-active {
  background-color: rgba(106, 109, 130, 0.6);
}
.layout-topbar-fluid .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-fluid .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-fluid .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-fluid.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-fluid.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-fluid.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-fluid.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #DFC7DD;
  }
  .layout-topbar-fluid.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-fluid.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-fluid.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-fluid .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-fluid .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-fluid .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-fluid .layout-breadcrumb li a {
  color: #00BCD4;
}

.layout-topbar-balloon .layout-top {
  background-image: url("../../../layout/images/special/balloon-topbar.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-topbar-balloon .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-balloon .layout-topbar .layout-topbar-search i {
  color: #D4D0AC;
}
.layout-topbar-balloon .layout-topbar .layout-topbar-search label {
  color: #D4D0AC;
}
.layout-topbar-balloon .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-balloon .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-balloon .layout-menubutton .layout-menubutton-icon {
  background-color: #A19000;
}
.layout-topbar-balloon .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-balloon .layout-menubutton .layout-menubutton-icon:after {
  background-color: #A19000;
}
.layout-topbar-balloon .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-balloon .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #A19000;
}
.layout-topbar-balloon .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-balloon .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #A19000;
}
.layout-topbar-balloon .layout-menubutton.layout-menubutton-active {
  background-color: rgba(162, 153, 86, 0.6);
}
.layout-topbar-balloon .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-balloon .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-balloon .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-balloon.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-balloon.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-balloon.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-balloon.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #D4D0AC;
  }
  .layout-topbar-balloon.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-balloon.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-balloon.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-balloon .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-balloon .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-balloon .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-balloon .layout-breadcrumb li a {
  color: #00BCD4;
}

.layout-topbar-downtown .layout-top {
  background-image: url("../../../layout/images/special/downtown-topbar.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-topbar-downtown .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-downtown .layout-topbar .layout-topbar-search i {
  color: #E6E0DD;
}
.layout-topbar-downtown .layout-topbar .layout-topbar-search label {
  color: #E6E0DD;
}
.layout-topbar-downtown .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-downtown .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-downtown .layout-menubutton .layout-menubutton-icon {
  background-color: #1B1920;
}
.layout-topbar-downtown .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-downtown .layout-menubutton .layout-menubutton-icon:after {
  background-color: #1B1920;
}
.layout-topbar-downtown .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-downtown .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #1B1920;
}
.layout-topbar-downtown .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-downtown .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #1B1920;
}
.layout-topbar-downtown .layout-menubutton.layout-menubutton-active {
  background-color: rgba(148, 149, 150, 0.6);
}
.layout-topbar-downtown .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-downtown .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-downtown .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-downtown.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-downtown.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-downtown.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-downtown.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #E6E0DD;
  }
  .layout-topbar-downtown.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-downtown.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-downtown.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-downtown .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-downtown .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-downtown .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-downtown .layout-breadcrumb li a {
  color: #ffc107;
}

.layout-topbar-perfection .layout-top {
  background-image: url("../../../layout/images/special/perfection-topbar.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-topbar-perfection .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-perfection .layout-topbar .layout-topbar-search i {
  color: #C0C8CD;
}
.layout-topbar-perfection .layout-topbar .layout-topbar-search label {
  color: #C0C8CD;
}
.layout-topbar-perfection .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-perfection .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-perfection .layout-menubutton .layout-menubutton-icon {
  background-color: #2E5061;
}
.layout-topbar-perfection .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-perfection .layout-menubutton .layout-menubutton-icon:after {
  background-color: #2E5061;
}
.layout-topbar-perfection .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-perfection .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #2E5061;
}
.layout-topbar-perfection .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-perfection .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #2E5061;
}
.layout-topbar-perfection .layout-menubutton.layout-menubutton-active {
  background-color: rgba(149, 170, 182, 0.6);
}
.layout-topbar-perfection .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-perfection .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-perfection .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-perfection.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-perfection.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-perfection.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-perfection.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #C0C8CD;
  }
  .layout-topbar-perfection.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-perfection.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-perfection.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-perfection .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-perfection .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-perfection .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-perfection .layout-breadcrumb li a {
  color: #ffc107;
}

.layout-topbar-northern .layout-top {
  background-image: url("../../../layout/images/special/northern-topbar.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-topbar-northern .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-northern .layout-topbar .layout-topbar-search i {
  color: #ADB2AF;
}
.layout-topbar-northern .layout-topbar .layout-topbar-search label {
  color: #ADB2AF;
}
.layout-topbar-northern .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-northern .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-northern .layout-menubutton .layout-menubutton-icon {
  background-color: #0F191B;
}
.layout-topbar-northern .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-northern .layout-menubutton .layout-menubutton-icon:after {
  background-color: #0F191B;
}
.layout-topbar-northern .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-northern .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #0F191B;
}
.layout-topbar-northern .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-northern .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #0F191B;
}
.layout-topbar-northern .layout-menubutton.layout-menubutton-active {
  background-color: rgba(177, 187, 164, 0.6);
}
.layout-topbar-northern .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-northern .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-northern .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-northern.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-northern.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-northern.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-northern.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #ADB2AF;
  }
  .layout-topbar-northern.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-northern.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-northern.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-northern .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-northern .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-northern .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-northern .layout-breadcrumb li a {
  color: #ffc107;
}

.layout-topbar-highline .layout-top {
  background-image: url("../../../layout/images/special/highline-topbar.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-topbar-highline .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-highline .layout-topbar .layout-topbar-search i {
  color: #ADABAA;
}
.layout-topbar-highline .layout-topbar .layout-topbar-search label {
  color: #ADABAA;
}
.layout-topbar-highline .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-highline .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-highline .layout-menubutton .layout-menubutton-icon {
  background-color: #0F191B;
}
.layout-topbar-highline .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-highline .layout-menubutton .layout-menubutton-icon:after {
  background-color: #0F191B;
}
.layout-topbar-highline .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-highline .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #0F191B;
}
.layout-topbar-highline .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-highline .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #0F191B;
}
.layout-topbar-highline .layout-menubutton.layout-menubutton-active {
  background-color: rgba(147, 147, 147, 0.6);
}
.layout-topbar-highline .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-highline .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-highline .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-highline.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-highline.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-highline.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-highline.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #ADABAA;
  }
  .layout-topbar-highline.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-highline.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-highline.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-highline .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-highline .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-highline .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-highline .layout-breadcrumb li a {
  color: #ffc107;
}

.layout-topbar-mural .layout-top {
  background-image: url("../../../layout/images/special/mural-topbar.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-topbar-mural .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-mural .layout-topbar .layout-topbar-search i {
  color: #C1C2C6;
}
.layout-topbar-mural .layout-topbar .layout-topbar-search label {
  color: #C1C2C6;
}
.layout-topbar-mural .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-mural .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-mural .layout-menubutton .layout-menubutton-icon {
  background-color: #020205;
}
.layout-topbar-mural .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-mural .layout-menubutton .layout-menubutton-icon:after {
  background-color: #020205;
}
.layout-topbar-mural .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-mural .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #020205;
}
.layout-topbar-mural .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-mural .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #020205;
}
.layout-topbar-mural .layout-menubutton.layout-menubutton-active {
  background-color: rgba(199, 199, 211, 0.6);
}
.layout-topbar-mural .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-mural .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-mural .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-mural.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-mural.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-mural.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-mural.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #C1C2C6;
  }
  .layout-topbar-mural.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-mural.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-mural.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-mural .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-mural .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-mural .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-mural .layout-breadcrumb li a {
  color: #ffc107;
}

.layout-topbar-aeriel .layout-top {
  background-image: url("../../../layout/images/special/aerial-topbar.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-topbar-aeriel .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-aeriel .layout-topbar .layout-topbar-search i {
  color: #C5BFBD;
}
.layout-topbar-aeriel .layout-topbar .layout-topbar-search label {
  color: #C5BFBD;
}
.layout-topbar-aeriel .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-aeriel .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-aeriel .layout-menubutton .layout-menubutton-icon {
  background-color: #01040F;
}
.layout-topbar-aeriel .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-aeriel .layout-menubutton .layout-menubutton-icon:after {
  background-color: #01040F;
}
.layout-topbar-aeriel .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-aeriel .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #01040F;
}
.layout-topbar-aeriel .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-aeriel .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #01040F;
}
.layout-topbar-aeriel .layout-menubutton.layout-menubutton-active {
  background-color: rgba(50, 121, 104, 0.6);
}
.layout-topbar-aeriel .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-aeriel .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-aeriel .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-aeriel.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-aeriel.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-aeriel.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-aeriel.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #C5BFBD;
  }
  .layout-topbar-aeriel.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-aeriel.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-aeriel.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-aeriel .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-aeriel .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-aeriel .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-aeriel .layout-breadcrumb li a {
  color: #ffc107;
}

.layout-topbar-wing .layout-top {
  background-image: url("../../../layout/images/special/wing-topbar.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-topbar-wing .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-wing .layout-topbar .layout-topbar-search i {
  color: #A3B6C0;
}
.layout-topbar-wing .layout-topbar .layout-topbar-search label {
  color: #A3B6C0;
}
.layout-topbar-wing .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-wing .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-wing .layout-menubutton .layout-menubutton-icon {
  background-color: #011C27;
}
.layout-topbar-wing .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-wing .layout-menubutton .layout-menubutton-icon:after {
  background-color: #011C27;
}
.layout-topbar-wing .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-wing .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #011C27;
}
.layout-topbar-wing .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-wing .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #011C27;
}
.layout-topbar-wing .layout-menubutton.layout-menubutton-active {
  background-color: rgba(35, 134, 181, 0.6);
}
.layout-topbar-wing .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-wing .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-wing .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-wing.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-wing.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-wing.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-wing.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #A3B6C0;
  }
  .layout-topbar-wing.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-wing.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-wing.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-wing .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-wing .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-wing .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-wing .layout-breadcrumb li a {
  color: #ffc107;
}

.layout-topbar-skyscraper .layout-top {
  background-image: url("../../../layout/images/special/skyscraper-topbar.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-topbar-skyscraper .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-skyscraper .layout-topbar .layout-topbar-search i {
  color: #bbbbbb;
}
.layout-topbar-skyscraper .layout-topbar .layout-topbar-search label {
  color: #bbbbbb;
}
.layout-topbar-skyscraper .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-skyscraper .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-skyscraper .layout-menubutton .layout-menubutton-icon {
  background-color: #212121;
}
.layout-topbar-skyscraper .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-skyscraper .layout-menubutton .layout-menubutton-icon:after {
  background-color: #212121;
}
.layout-topbar-skyscraper .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-skyscraper .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #212121;
}
.layout-topbar-skyscraper .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-skyscraper .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #212121;
}
.layout-topbar-skyscraper .layout-menubutton.layout-menubutton-active {
  background-color: rgba(104, 104, 104, 0.6);
}
.layout-topbar-skyscraper .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-skyscraper .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-skyscraper .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-skyscraper.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-skyscraper.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-skyscraper.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-skyscraper.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #bbbbbb;
  }
  .layout-topbar-skyscraper.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-skyscraper.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-skyscraper.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-skyscraper .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-skyscraper .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-skyscraper .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-skyscraper .layout-breadcrumb li a {
  color: #00BCD4;
}

.layout-topbar-wall .layout-top {
  background-image: url("../../../layout/images/special/wall-topbar.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-topbar-wall .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-wall .layout-topbar .layout-topbar-search i {
  color: #B2BEC2;
}
.layout-topbar-wall .layout-topbar .layout-topbar-search label {
  color: #B2BEC2;
}
.layout-topbar-wall .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-wall .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-wall .layout-menubutton .layout-menubutton-icon {
  background-color: #0E1C26;
}
.layout-topbar-wall .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-wall .layout-menubutton .layout-menubutton-icon:after {
  background-color: #0E1C26;
}
.layout-topbar-wall .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-wall .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #0E1C26;
}
.layout-topbar-wall .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-wall .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #0E1C26;
}
.layout-topbar-wall .layout-menubutton.layout-menubutton-active {
  background-color: rgba(204, 204, 208, 0.6);
}
.layout-topbar-wall .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-wall .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-wall .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-wall.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-wall.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-wall.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-wall.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #A3B6C0;
  }
  .layout-topbar-wall.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-wall.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-wall.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-wall .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-wall .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-wall .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-wall .layout-breadcrumb li a {
  color: #ffc107;
}

.layout-topbar-dawn .layout-top {
  background-image: url("../../../layout/images/special/dawn-topbar.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-topbar-dawn .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-dawn .layout-topbar .layout-topbar-search i {
  color: #B1B6BD;
}
.layout-topbar-dawn .layout-topbar .layout-topbar-search label {
  color: #B1B6BD;
}
.layout-topbar-dawn .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-dawn .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-dawn .layout-menubutton .layout-menubutton-icon {
  background-color: #0C1322;
}
.layout-topbar-dawn .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-dawn .layout-menubutton .layout-menubutton-icon:after {
  background-color: #0C1322;
}
.layout-topbar-dawn .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-dawn .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #0C1322;
}
.layout-topbar-dawn .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-dawn .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #0C1322;
}
.layout-topbar-dawn .layout-menubutton.layout-menubutton-active {
  background-color: rgba(143, 141, 140, 0.6);
}
.layout-topbar-dawn .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-dawn .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-dawn .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-dawn.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-dawn.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-dawn.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-dawn.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #B1B6BD;
  }
  .layout-topbar-dawn.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-dawn.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-dawn.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-dawn .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-dawn .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-dawn .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-dawn .layout-breadcrumb li a {
  color: #ffc107;
}

.layout-topbar-lille .layout-top {
  background-image: url("../../../layout/images/special/lille-topbar.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-topbar-lille .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-lille .layout-topbar .layout-topbar-search i {
  color: #BEBFC2;
}
.layout-topbar-lille .layout-topbar .layout-topbar-search label {
  color: #BEBFC2;
}
.layout-topbar-lille .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-lille .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-lille .layout-menubutton .layout-menubutton-icon {
  background-color: #0D1317;
}
.layout-topbar-lille .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-lille .layout-menubutton .layout-menubutton-icon:after {
  background-color: #0D1317;
}
.layout-topbar-lille .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-lille .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #0D1317;
}
.layout-topbar-lille .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-lille .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #0D1317;
}
.layout-topbar-lille .layout-menubutton.layout-menubutton-active {
  background-color: rgba(124, 125, 131, 0.6);
}
.layout-topbar-lille .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-lille .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-lille .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-lille.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-lille.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-lille.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-lille.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #BEBFC2;
  }
  .layout-topbar-lille.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-lille.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-lille.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-lille .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-lille .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-lille .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-lille .layout-breadcrumb li a {
  color: #ffc107;
}

.layout-topbar-condo .layout-top {
  background-image: url("../../../layout/images/special/condo-topbar.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-topbar-condo .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-condo .layout-topbar .layout-topbar-search i {
  color: #ADB7BA;
}
.layout-topbar-condo .layout-topbar .layout-topbar-search label {
  color: #ADB7BA;
}
.layout-topbar-condo .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-condo .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-condo .layout-menubutton .layout-menubutton-icon {
  background-color: #0F2327;
}
.layout-topbar-condo .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-condo .layout-menubutton .layout-menubutton-icon:after {
  background-color: #0F2327;
}
.layout-topbar-condo .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-condo .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #0F2327;
}
.layout-topbar-condo .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-condo .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #0F2327;
}
.layout-topbar-condo .layout-menubutton.layout-menubutton-active {
  background-color: rgba(143, 141, 140, 0.6);
}
.layout-topbar-condo .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-condo .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-condo .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-condo.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-condo.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-condo.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-condo.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #ADB7BA;
  }
  .layout-topbar-condo.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-condo.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-condo.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-condo .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-condo .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-condo .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-condo .layout-breadcrumb li a {
  color: #29B6F6;
}

.layout-topbar-waterfall .layout-top {
  background-image: url("../../../layout/images/special/waterfall-topbar.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-topbar-waterfall .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-waterfall .layout-topbar .layout-topbar-search i {
  color: #ABACAA;
}
.layout-topbar-waterfall .layout-topbar .layout-topbar-search label {
  color: #ABACAA;
}
.layout-topbar-waterfall .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-waterfall .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-waterfall .layout-menubutton .layout-menubutton-icon {
  background-color: #242927;
}
.layout-topbar-waterfall .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-waterfall .layout-menubutton .layout-menubutton-icon:after {
  background-color: #242927;
}
.layout-topbar-waterfall .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-waterfall .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #242927;
}
.layout-topbar-waterfall .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-waterfall .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #242927;
}
.layout-topbar-waterfall .layout-menubutton.layout-menubutton-active {
  background-color: rgba(105, 111, 109, 0.6);
}
.layout-topbar-waterfall .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-waterfall .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-waterfall .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-waterfall.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-waterfall.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-waterfall.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-waterfall.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #ABACAA;
  }
  .layout-topbar-waterfall.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-waterfall.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-waterfall.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-waterfall .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-waterfall .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-waterfall .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-waterfall .layout-breadcrumb li a {
  color: #ffc107;
}

.layout-topbar-coffee .layout-top {
  background-image: url("../../../layout/images/special/coffee-topbar.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-topbar-coffee .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-coffee .layout-topbar .layout-topbar-search i {
  color: #ADACAC;
}
.layout-topbar-coffee .layout-topbar .layout-topbar-search label {
  color: #ADACAC;
}
.layout-topbar-coffee .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-coffee .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-coffee .layout-menubutton .layout-menubutton-icon {
  background-color: #180E07;
}
.layout-topbar-coffee .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-coffee .layout-menubutton .layout-menubutton-icon:after {
  background-color: #180E07;
}
.layout-topbar-coffee .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-coffee .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #180E07;
}
.layout-topbar-coffee .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-coffee .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #180E07;
}
.layout-topbar-coffee .layout-menubutton.layout-menubutton-active {
  background-color: rgba(143, 141, 140, 0.6);
}
.layout-topbar-coffee .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-coffee .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-coffee .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-coffee.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-coffee.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-coffee.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-coffee.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #ADACAC;
  }
  .layout-topbar-coffee.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-coffee.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-coffee.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-coffee .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-coffee .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-coffee .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-coffee .layout-breadcrumb li a {
  color: #009688;
}

.layout-topbar-mountain .layout-top {
  background-image: url("../../../layout/images/special/mountain-topbar.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-topbar-mountain .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-mountain .layout-topbar .layout-topbar-search i {
  color: #A1A5A6;
}
.layout-topbar-mountain .layout-topbar .layout-topbar-search label {
  color: #A1A5A6;
}
.layout-topbar-mountain .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-mountain .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-mountain .layout-menubutton .layout-menubutton-icon {
  background-color: #1C2225;
}
.layout-topbar-mountain .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-mountain .layout-menubutton .layout-menubutton-icon:after {
  background-color: #1C2225;
}
.layout-topbar-mountain .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-mountain .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #1C2225;
}
.layout-topbar-mountain .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-mountain .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #1C2225;
}
.layout-topbar-mountain .layout-menubutton.layout-menubutton-active {
  background-color: rgba(124, 125, 131, 0.6);
}
.layout-topbar-mountain .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-mountain .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-mountain .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-mountain.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-mountain.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-mountain.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-mountain.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #A1A5A6;
  }
  .layout-topbar-mountain.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-mountain.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-mountain.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-mountain .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-mountain .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-mountain .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-mountain .layout-breadcrumb li a {
  color: #ffc107;
}

.layout-topbar-lights .layout-top {
  background-image: url("../../../layout/images/special/lights-topbar.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-topbar-lights .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-lights .layout-topbar .layout-topbar-search i {
  color: #A6B3BC;
}
.layout-topbar-lights .layout-topbar .layout-topbar-search label {
  color: #A6B3BC;
}
.layout-topbar-lights .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-lights .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-lights .layout-menubutton .layout-menubutton-icon {
  background-color: #020201;
}
.layout-topbar-lights .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-lights .layout-menubutton .layout-menubutton-icon:after {
  background-color: #020201;
}
.layout-topbar-lights .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-lights .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #020201;
}
.layout-topbar-lights .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-lights .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #020201;
}
.layout-topbar-lights .layout-menubutton.layout-menubutton-active {
  background-color: rgba(255, 255, 255, 0.25);
}
.layout-topbar-lights .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-lights .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-lights .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-lights.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-lights.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-lights.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-lights.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #A6B3BC;
  }
  .layout-topbar-lights.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-lights.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-lights.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-lights .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-lights .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-lights .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-lights .layout-breadcrumb li a {
  color: #ffc107;
}

.layout-topbar-desert .layout-top {
  background-image: url("../../../layout/images/special/desert-topbar.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-topbar-desert .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-desert .layout-topbar .layout-topbar-search i {
  color: #BAC8CF;
}
.layout-topbar-desert .layout-topbar .layout-topbar-search label {
  color: #BAC8CF;
}
.layout-topbar-desert .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-desert .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-desert .layout-menubutton .layout-menubutton-icon {
  background-color: #2E4755;
}
.layout-topbar-desert .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-desert .layout-menubutton .layout-menubutton-icon:after {
  background-color: #2E4755;
}
.layout-topbar-desert .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-desert .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #2E4755;
}
.layout-topbar-desert .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-desert .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #2E4755;
}
.layout-topbar-desert .layout-menubutton.layout-menubutton-active {
  background-color: rgba(255, 255, 255, 0.3);
}
.layout-topbar-desert .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-desert .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-desert .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-desert.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-desert.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-desert.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-desert.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #BAC8CF;
  }
  .layout-topbar-desert.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-desert.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-desert.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-desert .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-desert .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-desert .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-desert .layout-breadcrumb li a {
  color: #ffc107;
}

.layout-topbar-beach .layout-top {
  background-image: url("../../../layout/images/special/beach-topbar.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-topbar-beach .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-beach .layout-topbar .layout-topbar-search i {
  color: #B7B4C1;
}
.layout-topbar-beach .layout-topbar .layout-topbar-search label {
  color: #B7B4C1;
}
.layout-topbar-beach .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-beach .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-beach .layout-menubutton .layout-menubutton-icon {
  background-color: #060013;
}
.layout-topbar-beach .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-beach .layout-menubutton .layout-menubutton-icon:after {
  background-color: #060013;
}
.layout-topbar-beach .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-beach .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #060013;
}
.layout-topbar-beach .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-beach .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #060013;
}
.layout-topbar-beach .layout-menubutton.layout-menubutton-active {
  background-color: rgba(255, 255, 255, 0.3);
}
.layout-topbar-beach .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-beach .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-beach .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-beach.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-beach.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-beach.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-beach.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #B7B4C1;
  }
  .layout-topbar-beach.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-beach.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-beach.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-beach .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-beach .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-beach .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-beach .layout-breadcrumb li a {
  color: #ffc107;
}

.layout-topbar-classic .layout-top {
  background-image: url("../../../layout/images/special/classic-topbar.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-topbar-classic .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-classic .layout-topbar .layout-topbar-search i {
  color: #C8C5C4;
}
.layout-topbar-classic .layout-topbar .layout-topbar-search label {
  color: #C8C5C4;
}
.layout-topbar-classic .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-classic .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-classic .layout-menubutton .layout-menubutton-icon {
  background-color: #422114;
}
.layout-topbar-classic .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-classic .layout-menubutton .layout-menubutton-icon:after {
  background-color: #422114;
}
.layout-topbar-classic .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-classic .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #422114;
}
.layout-topbar-classic .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-classic .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #422114;
}
.layout-topbar-classic .layout-menubutton.layout-menubutton-active {
  background-color: rgba(255, 255, 255, 0.25);
}
.layout-topbar-classic .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-classic .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-classic .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-classic.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-classic.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-classic.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-classic.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #C8C5C4;
  }
  .layout-topbar-classic.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-classic.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-classic.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-classic .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-classic .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-classic .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-classic .layout-breadcrumb li a {
  color: #FFA726;
}

.layout-topbar-hazy .layout-top {
  background-image: url("../../../layout/images/special/hazy-topbar.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-topbar-hazy .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-hazy .layout-topbar .layout-topbar-search i {
  color: #A6A6A6;
}
.layout-topbar-hazy .layout-topbar .layout-topbar-search label {
  color: #A6A6A6;
}
.layout-topbar-hazy .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-hazy .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-hazy .layout-menubutton .layout-menubutton-icon {
  background-color: #1C2225;
}
.layout-topbar-hazy .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-hazy .layout-menubutton .layout-menubutton-icon:after {
  background-color: #1C2225;
}
.layout-topbar-hazy .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-hazy .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #1C2225;
}
.layout-topbar-hazy .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-hazy .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #1C2225;
}
.layout-topbar-hazy .layout-menubutton.layout-menubutton-active {
  background-color: rgba(255, 255, 255, 0.3);
}
.layout-topbar-hazy .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-hazy .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-hazy .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-hazy.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-hazy.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-hazy.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-hazy.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #A6A6A6;
  }
  .layout-topbar-hazy.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-hazy.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-hazy.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-hazy .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-hazy .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-hazy .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-hazy .layout-breadcrumb li a {
  color: #00BCD4;
}

.layout-topbar-exposure .layout-top {
  background-image: url("../../../layout/images/special/exposure-topbar.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-topbar-exposure .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-exposure .layout-topbar .layout-topbar-search i {
  color: #AAAAAA;
}
.layout-topbar-exposure .layout-topbar .layout-topbar-search label {
  color: #AAAAAA;
}
.layout-topbar-exposure .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-exposure .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-exposure .layout-menubutton .layout-menubutton-icon {
  background-color: #181215;
}
.layout-topbar-exposure .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-exposure .layout-menubutton .layout-menubutton-icon:after {
  background-color: #181215;
}
.layout-topbar-exposure .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-exposure .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #181215;
}
.layout-topbar-exposure .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-exposure .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #181215;
}
.layout-topbar-exposure .layout-menubutton.layout-menubutton-active {
  background-color: rgba(255, 255, 255, 0.3);
}
.layout-topbar-exposure .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-exposure .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-exposure .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-exposure.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-exposure.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-exposure.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-exposure.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #AAAAAA;
  }
  .layout-topbar-exposure.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-exposure.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-exposure.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-exposure .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-exposure .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-exposure .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-exposure .layout-breadcrumb li a {
  color: #42A5F5;
}

.layout-topbar-norge .layout-top {
  background-image: url("../../../layout/images/special/norge-topbar.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-topbar-norge .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-norge .layout-topbar .layout-topbar-search i {
  color: #AAB1BB;
}
.layout-topbar-norge .layout-topbar .layout-topbar-search label {
  color: #AAB1BB;
}
.layout-topbar-norge .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-norge .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-norge .layout-menubutton .layout-menubutton-icon {
  background-color: #2E4966;
}
.layout-topbar-norge .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-norge .layout-menubutton .layout-menubutton-icon:after {
  background-color: #2E4966;
}
.layout-topbar-norge .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-norge .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #2E4966;
}
.layout-topbar-norge .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-norge .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #2E4966;
}
.layout-topbar-norge .layout-menubutton.layout-menubutton-active {
  background-color: rgba(255, 255, 255, 0.3);
}
.layout-topbar-norge .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-norge .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-norge .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-norge.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-norge.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-norge.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-norge.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #AAB1BB;
  }
  .layout-topbar-norge.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-norge.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-norge.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-norge .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-norge .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-norge .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-norge .layout-breadcrumb li a {
  color: #ffc107;
}

.layout-topbar-island .layout-top {
  background-image: url("../../../layout/images/special/island-topbar.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-topbar-island .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-island .layout-topbar .layout-topbar-search i {
  color: #AAA7A8;
}
.layout-topbar-island .layout-topbar .layout-topbar-search label {
  color: #AAA7A8;
}
.layout-topbar-island .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-island .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-island .layout-menubutton .layout-menubutton-icon {
  background-color: #005F87;
}
.layout-topbar-island .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-island .layout-menubutton .layout-menubutton-icon:after {
  background-color: #005F87;
}
.layout-topbar-island .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-island .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #005F87;
}
.layout-topbar-island .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-island .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #005F87;
}
.layout-topbar-island .layout-menubutton.layout-menubutton-active {
  background-color: rgba(255, 255, 255, 0.3);
}
.layout-topbar-island .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-island .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-island .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-island.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-island.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-island.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-island.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #AAA7A8;
  }
  .layout-topbar-island.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-island.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-island.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-island .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-island .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-island .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-island .layout-breadcrumb li a {
  color: #ffc107;
}

.layout-topbar-station .layout-top {
  background-image: url("../../../layout/images/special/station-topbar.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-topbar-station .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-station .layout-topbar .layout-topbar-search i {
  color: #A3A3A3;
}
.layout-topbar-station .layout-topbar .layout-topbar-search label {
  color: #A3A3A3;
}
.layout-topbar-station .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-station .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-station .layout-menubutton .layout-menubutton-icon {
  background-color: #0C0407;
}
.layout-topbar-station .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-station .layout-menubutton .layout-menubutton-icon:after {
  background-color: #0C0407;
}
.layout-topbar-station .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-station .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #0C0407;
}
.layout-topbar-station .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-station .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #0C0407;
}
.layout-topbar-station .layout-menubutton.layout-menubutton-active {
  background-color: rgba(255, 255, 255, 0.3);
}
.layout-topbar-station .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-station .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-station .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-station.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-station.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-station.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-station.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #A3A3A3;
  }
  .layout-topbar-station.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-station.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-station.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-station .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-station .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-station .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-station .layout-breadcrumb li a {
  color: #ffc107;
}

.layout-topbar-fruity .layout-top {
  background-image: url("../../../layout/images/special/fruity-topbar.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-topbar-fruity .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-fruity .layout-topbar .layout-topbar-search i {
  color: #C2BFB9;
}
.layout-topbar-fruity .layout-topbar .layout-topbar-search label {
  color: #C2BFB9;
}
.layout-topbar-fruity .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-fruity .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-fruity .layout-menubutton .layout-menubutton-icon {
  background-color: #380812;
}
.layout-topbar-fruity .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-fruity .layout-menubutton .layout-menubutton-icon:after {
  background-color: #380812;
}
.layout-topbar-fruity .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-fruity .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #380812;
}
.layout-topbar-fruity .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-fruity .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #380812;
}
.layout-topbar-fruity .layout-menubutton.layout-menubutton-active {
  background-color: rgba(255, 255, 255, 0.3);
}
.layout-topbar-fruity .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-fruity .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-fruity .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-fruity.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-fruity.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-fruity.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-fruity.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #C2BFB9;
  }
  .layout-topbar-fruity.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-fruity.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-fruity.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-fruity .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-fruity .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-fruity .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-fruity .layout-breadcrumb li a {
  color: #00BCD4;
}

.layout-topbar-tropical .layout-top {
  background-image: url("../../../layout/images/special/tropical-topbar.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-topbar-tropical .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-tropical .layout-topbar .layout-topbar-search i {
  color: #A5A5A2;
}
.layout-topbar-tropical .layout-topbar .layout-topbar-search label {
  color: #A5A5A2;
}
.layout-topbar-tropical .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-tropical .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-tropical .layout-menubutton .layout-menubutton-icon {
  background-color: #00515D;
}
.layout-topbar-tropical .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-tropical .layout-menubutton .layout-menubutton-icon:after {
  background-color: #00515D;
}
.layout-topbar-tropical .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-tropical .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #00515D;
}
.layout-topbar-tropical .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-tropical .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #00515D;
}
.layout-topbar-tropical .layout-menubutton.layout-menubutton-active {
  background-color: rgba(255, 255, 255, 0.25);
}
.layout-topbar-tropical .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-tropical .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-tropical .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-tropical.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-tropical.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-tropical.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-tropical.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #A5A5A2;
  }
  .layout-topbar-tropical.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-tropical.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-tropical.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-tropical .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-tropical .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-tropical .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-tropical .layout-breadcrumb li a {
  color: #D4E157;
}

.layout-topbar-beyoglu .layout-top {
  background-image: url("../../../layout/images/special/beyoglu-topbar.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-topbar-beyoglu .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-beyoglu .layout-topbar .layout-topbar-search i {
  color: #ADACA9;
}
.layout-topbar-beyoglu .layout-topbar .layout-topbar-search label {
  color: #ADACA9;
}
.layout-topbar-beyoglu .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-beyoglu .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-beyoglu .layout-menubutton .layout-menubutton-icon {
  background-color: #0E191A;
}
.layout-topbar-beyoglu .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-beyoglu .layout-menubutton .layout-menubutton-icon:after {
  background-color: #0E191A;
}
.layout-topbar-beyoglu .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-beyoglu .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #0E191A;
}
.layout-topbar-beyoglu .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-beyoglu .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #0E191A;
}
.layout-topbar-beyoglu .layout-menubutton.layout-menubutton-active {
  background-color: rgba(255, 255, 255, 0.2);
}
.layout-topbar-beyoglu .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-beyoglu .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-beyoglu .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-beyoglu.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-beyoglu.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-beyoglu.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-beyoglu.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #ADACA9;
  }
  .layout-topbar-beyoglu.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-beyoglu.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-beyoglu.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-beyoglu .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-beyoglu .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-beyoglu .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-beyoglu .layout-breadcrumb li a {
  color: #ffc107;
}

.layout-topbar-timelapse .layout-top {
  background-image: url("../../../layout/images/special/timelapse-topbar.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-topbar-timelapse .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-timelapse .layout-topbar .layout-topbar-search i {
  color: #DDB1B9;
}
.layout-topbar-timelapse .layout-topbar .layout-topbar-search label {
  color: #DDB1B9;
}
.layout-topbar-timelapse .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-timelapse .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-timelapse .layout-menubutton .layout-menubutton-icon {
  background-color: #26324B;
}
.layout-topbar-timelapse .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-timelapse .layout-menubutton .layout-menubutton-icon:after {
  background-color: #26324B;
}
.layout-topbar-timelapse .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-timelapse .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #26324B;
}
.layout-topbar-timelapse .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-timelapse .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #26324B;
}
.layout-topbar-timelapse .layout-menubutton.layout-menubutton-active {
  background-color: rgba(0, 0, 0, 0.4);
}
.layout-topbar-timelapse .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-timelapse .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-timelapse .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-timelapse.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-timelapse.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-timelapse.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-timelapse.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #DDB1B9;
  }
  .layout-topbar-timelapse.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-timelapse.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-timelapse.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-timelapse .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-timelapse .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-timelapse .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-timelapse .layout-breadcrumb li a {
  color: #ffc107;
}

.layout-topbar-crystal .layout-top {
  background-image: url("../../../layout/images/special/crystal-topbar.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-topbar-crystal .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-crystal .layout-topbar .layout-topbar-search i {
  color: #CFD6DE;
}
.layout-topbar-crystal .layout-topbar .layout-topbar-search label {
  color: #CFD6DE;
}
.layout-topbar-crystal .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-crystal .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-crystal .layout-menubutton .layout-menubutton-icon {
  background-color: #001A28;
}
.layout-topbar-crystal .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-crystal .layout-menubutton .layout-menubutton-icon:after {
  background-color: #001A28;
}
.layout-topbar-crystal .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-crystal .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #001A28;
}
.layout-topbar-crystal .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-crystal .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #001A28;
}
.layout-topbar-crystal .layout-menubutton.layout-menubutton-active {
  background-color: rgba(0, 0, 0, 0.4);
}
.layout-topbar-crystal .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-crystal .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-crystal .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-crystal.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-crystal.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-crystal.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-crystal.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #CFD6DE;
  }
  .layout-topbar-crystal.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-crystal.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-crystal.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-crystal .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-crystal .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-crystal .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-crystal .layout-breadcrumb li a {
  color: #ffc107;
}

.layout-topbar-aquarelle .layout-top {
  background-image: url("../../../layout/images/special/aquarelle-topbar.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-topbar-aquarelle .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-aquarelle .layout-topbar .layout-topbar-search i {
  color: #D2CAD5;
}
.layout-topbar-aquarelle .layout-topbar .layout-topbar-search label {
  color: #D2CAD5;
}
.layout-topbar-aquarelle .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-aquarelle .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-aquarelle .layout-menubutton .layout-menubutton-icon {
  background-color: #2B1239;
}
.layout-topbar-aquarelle .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-aquarelle .layout-menubutton .layout-menubutton-icon:after {
  background-color: #2B1239;
}
.layout-topbar-aquarelle .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-aquarelle .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #2B1239;
}
.layout-topbar-aquarelle .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-aquarelle .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #2B1239;
}
.layout-topbar-aquarelle .layout-menubutton.layout-menubutton-active {
  background-color: rgba(0, 0, 0, 0.2);
}
.layout-topbar-aquarelle .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-aquarelle .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-aquarelle .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-aquarelle.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-aquarelle.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-aquarelle.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-aquarelle.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #D2CAD5;
  }
  .layout-topbar-aquarelle.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-aquarelle.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-aquarelle.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-aquarelle .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-aquarelle .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-aquarelle .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-aquarelle .layout-breadcrumb li a {
  color: #FDD835;
}

.layout-topbar-canvas .layout-top {
  background-image: url("../../../layout/images/special/canvas-topbar.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-topbar-canvas .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-canvas .layout-topbar .layout-topbar-search i {
  color: #DABAA5;
}
.layout-topbar-canvas .layout-topbar .layout-topbar-search label {
  color: #DABAA5;
}
.layout-topbar-canvas .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-canvas .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-canvas .layout-menubutton .layout-menubutton-icon {
  background-color: #640000;
}
.layout-topbar-canvas .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-canvas .layout-menubutton .layout-menubutton-icon:after {
  background-color: #640000;
}
.layout-topbar-canvas .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-canvas .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #640000;
}
.layout-topbar-canvas .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-canvas .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #640000;
}
.layout-topbar-canvas .layout-menubutton.layout-menubutton-active {
  background-color: rgba(0, 0, 0, 0.3);
}
.layout-topbar-canvas .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-canvas .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-canvas .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-canvas.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-canvas.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-canvas.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-canvas.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #DABAA5;
  }
  .layout-topbar-canvas.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-canvas.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-canvas.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-canvas .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-canvas .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-canvas .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-canvas .layout-breadcrumb li a {
  color: #ffc107;
}

.layout-topbar-olympic .layout-top {
  background-image: url("../../../layout/images/special/olympic-topbar.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-topbar-olympic .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-olympic .layout-topbar .layout-topbar-search i {
  color: #C6C1BE;
}
.layout-topbar-olympic .layout-topbar .layout-topbar-search label {
  color: #C6C1BE;
}
.layout-topbar-olympic .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-olympic .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-olympic .layout-menubutton .layout-menubutton-icon {
  background-color: #151909;
}
.layout-topbar-olympic .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-olympic .layout-menubutton .layout-menubutton-icon:after {
  background-color: #151909;
}
.layout-topbar-olympic .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-olympic .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #151909;
}
.layout-topbar-olympic .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-olympic .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #151909;
}
.layout-topbar-olympic .layout-menubutton.layout-menubutton-active {
  background-color: rgba(255, 255, 255, 0.4);
}
.layout-topbar-olympic .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-olympic .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-olympic .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-olympic.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-olympic.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-olympic.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-olympic.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #C6C1BE;
  }
  .layout-topbar-olympic.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-olympic.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-olympic.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-olympic .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-olympic .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-olympic .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-olympic .layout-breadcrumb li a {
  color: #ffc107;
}

.layout-topbar-circuit .layout-top {
  background-image: url("../../../layout/images/special/circuit-topbar.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-topbar-circuit .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-circuit .layout-topbar .layout-topbar-search i {
  color: #A4AFB0;
}
.layout-topbar-circuit .layout-topbar .layout-topbar-search label {
  color: #A4AFB0;
}
.layout-topbar-circuit .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-circuit .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-circuit .layout-menubutton .layout-menubutton-icon {
  background-color: #054646;
}
.layout-topbar-circuit .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-circuit .layout-menubutton .layout-menubutton-icon:after {
  background-color: #054646;
}
.layout-topbar-circuit .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-circuit .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #054646;
}
.layout-topbar-circuit .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-circuit .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #054646;
}
.layout-topbar-circuit .layout-menubutton.layout-menubutton-active {
  background-color: rgba(255, 255, 255, 0.3);
}
.layout-topbar-circuit .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-circuit .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-circuit .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-circuit.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-circuit.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-circuit.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-circuit.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #A4AFB0;
  }
  .layout-topbar-circuit.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-circuit.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-circuit.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-circuit .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-circuit .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-circuit .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-circuit .layout-breadcrumb li a {
  color: #FF9800;
}

.layout-topbar-flamingo .layout-top {
  background-image: url("../../../layout/images/special/flamingo-topbar.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-topbar-flamingo .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-flamingo .layout-topbar .layout-topbar-search i {
  color: #A8A8A6;
}
.layout-topbar-flamingo .layout-topbar .layout-topbar-search label {
  color: #A8A8A6;
}
.layout-topbar-flamingo .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-flamingo .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-flamingo .layout-menubutton .layout-menubutton-icon {
  background-color: #0E0E0B;
}
.layout-topbar-flamingo .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-flamingo .layout-menubutton .layout-menubutton-icon:after {
  background-color: #0E0E0B;
}
.layout-topbar-flamingo .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-flamingo .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #0E0E0B;
}
.layout-topbar-flamingo .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-flamingo .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #0E0E0B;
}
.layout-topbar-flamingo .layout-menubutton.layout-menubutton-active {
  background-color: rgba(255, 255, 255, 0.3);
}
.layout-topbar-flamingo .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-flamingo .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-flamingo .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-flamingo.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-flamingo.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-flamingo.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-flamingo.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #A8A8A6;
  }
  .layout-topbar-flamingo.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-flamingo.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-flamingo.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-flamingo .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-flamingo .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-flamingo .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-flamingo .layout-breadcrumb li a {
  color: #CDDC39;
}

.layout-topbar-flight .layout-top {
  background-image: url("../../../layout/images/special/flight-topbar.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-topbar-flight .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-flight .layout-topbar .layout-topbar-search i {
  color: #ADADAC;
}
.layout-topbar-flight .layout-topbar .layout-topbar-search label {
  color: #ADADAC;
}
.layout-topbar-flight .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-flight .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-flight .layout-menubutton .layout-menubutton-icon {
  background-color: #1C2225;
}
.layout-topbar-flight .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-flight .layout-menubutton .layout-menubutton-icon:after {
  background-color: #1C2225;
}
.layout-topbar-flight .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-flight .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #1C2225;
}
.layout-topbar-flight .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-flight .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #1C2225;
}
.layout-topbar-flight .layout-menubutton.layout-menubutton-active {
  background-color: rgba(255, 255, 255, 0.3);
}
.layout-topbar-flight .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-flight .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-flight .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-flight.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-flight.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-flight.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-flight.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #ADADAC;
  }
  .layout-topbar-flight.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-flight.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-flight.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-flight .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-flight .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-flight .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-flight .layout-breadcrumb li a {
  color: #03A9F4;
}

.layout-topbar-tractor .layout-top {
  background-image: url("../../../layout/images/special/tractor-topbar.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-topbar-tractor .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-tractor .layout-topbar .layout-topbar-search i {
  color: #A8A9A9;
}
.layout-topbar-tractor .layout-topbar .layout-topbar-search label {
  color: #A8A9A9;
}
.layout-topbar-tractor .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-tractor .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-tractor .layout-menubutton .layout-menubutton-icon {
  background-color: #1B1B19;
}
.layout-topbar-tractor .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-tractor .layout-menubutton .layout-menubutton-icon:after {
  background-color: #1B1B19;
}
.layout-topbar-tractor .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-tractor .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #1B1B19;
}
.layout-topbar-tractor .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-tractor .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #1B1B19;
}
.layout-topbar-tractor .layout-menubutton.layout-menubutton-active {
  background-color: rgba(204, 204, 208, 0.3);
}
.layout-topbar-tractor .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-tractor .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-tractor .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-tractor.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-tractor.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-tractor.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-tractor.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #A8A9A9;
  }
  .layout-topbar-tractor.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-tractor.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-tractor.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-tractor .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-tractor .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-tractor .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-tractor .layout-breadcrumb li a {
  color: #ffc107;
}

.layout-topbar-volcano .layout-top {
  background-image: url("../../../layout/images/special/volcano-topbar.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-topbar-volcano .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-volcano .layout-topbar .layout-topbar-search i {
  color: #CDD1D2;
}
.layout-topbar-volcano .layout-topbar .layout-topbar-search label {
  color: #CDD1D2;
}
.layout-topbar-volcano .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-volcano .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-volcano .layout-menubutton .layout-menubutton-icon {
  background-color: #637377;
}
.layout-topbar-volcano .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-volcano .layout-menubutton .layout-menubutton-icon:after {
  background-color: #637377;
}
.layout-topbar-volcano .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-volcano .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #637377;
}
.layout-topbar-volcano .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-volcano .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #637377;
}
.layout-topbar-volcano .layout-menubutton.layout-menubutton-active {
  background-color: rgba(0, 0, 0, 0.32);
}
.layout-topbar-volcano .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-volcano .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-volcano .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-volcano.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-volcano.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-volcano.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-volcano.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #CDD1D2;
  }
  .layout-topbar-volcano.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-volcano.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-volcano.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-volcano .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-volcano .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-volcano .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-volcano .layout-breadcrumb li a {
  color: #ffc107;
}

.layout-topbar-pine .layout-top {
  background-image: url("../../../layout/images/special/pine-topbar.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-topbar-pine .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-pine .layout-topbar .layout-topbar-search i {
  color: #C3BDBE;
}
.layout-topbar-pine .layout-topbar .layout-topbar-search label {
  color: #C3BDBE;
}
.layout-topbar-pine .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-pine .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-pine .layout-menubutton .layout-menubutton-icon {
  background-color: #030003;
}
.layout-topbar-pine .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-pine .layout-menubutton .layout-menubutton-icon:after {
  background-color: #030003;
}
.layout-topbar-pine .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-pine .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #030003;
}
.layout-topbar-pine .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-pine .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #030003;
}
.layout-topbar-pine .layout-menubutton.layout-menubutton-active {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-topbar-pine .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-pine .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-pine .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-pine.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-pine.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-pine.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-pine.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #C3BDBE;
  }
  .layout-topbar-pine.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-pine.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-pine.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-pine .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-pine .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-pine .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-pine .layout-breadcrumb li a {
  color: #EC407A;
}

.layout-topbar-emptiness .layout-top {
  background-image: url("../../../layout/images/special/emptiness-topbar.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-topbar-emptiness .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-emptiness .layout-topbar .layout-topbar-search i {
  color: #B3B8C0;
}
.layout-topbar-emptiness .layout-topbar .layout-topbar-search label {
  color: #B3B8C0;
}
.layout-topbar-emptiness .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-emptiness .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-emptiness .layout-menubutton .layout-menubutton-icon {
  background-color: #001332;
}
.layout-topbar-emptiness .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-emptiness .layout-menubutton .layout-menubutton-icon:after {
  background-color: #001332;
}
.layout-topbar-emptiness .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-emptiness .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #001332;
}
.layout-topbar-emptiness .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-emptiness .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #001332;
}
.layout-topbar-emptiness .layout-menubutton.layout-menubutton-active {
  background-color: rgba(255, 255, 255, 0.2);
}
.layout-topbar-emptiness .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-emptiness .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-emptiness .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-emptiness.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-emptiness.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-emptiness.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-emptiness.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #B3B8C0;
  }
  .layout-topbar-emptiness.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-emptiness.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-emptiness.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-emptiness .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-emptiness .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-emptiness .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-emptiness .layout-breadcrumb li a {
  color: #ffc107;
}

.layout-topbar-splash .layout-top {
  background-image: url("../../../layout/images/special/splash-topbar.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-topbar-splash .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-splash .layout-topbar .layout-topbar-search i {
  color: #A5A5A5;
}
.layout-topbar-splash .layout-topbar .layout-topbar-search label {
  color: #A5A5A5;
}
.layout-topbar-splash .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-splash .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-splash .layout-menubutton .layout-menubutton-icon {
  background-color: #060606;
}
.layout-topbar-splash .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-splash .layout-menubutton .layout-menubutton-icon:after {
  background-color: #060606;
}
.layout-topbar-splash .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-splash .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #060606;
}
.layout-topbar-splash .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-splash .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #060606;
}
.layout-topbar-splash .layout-menubutton.layout-menubutton-active {
  background-color: rgba(0, 0, 0, 0.32);
}
.layout-topbar-splash .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-splash .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-splash .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-splash.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-splash.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-splash.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-splash.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #A5A5A5;
  }
  .layout-topbar-splash.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-splash.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-splash.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-splash .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-splash .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-splash .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-splash .layout-breadcrumb li a {
  color: #ffc107;
}

.layout-topbar-urban .layout-top {
  background-image: url("../../../layout/images/special/urban-topbar.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-topbar-urban .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-urban .layout-topbar .layout-topbar-search i {
  color: #B1B9BA;
}
.layout-topbar-urban .layout-topbar .layout-topbar-search label {
  color: #B1B9BA;
}
.layout-topbar-urban .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-urban .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-urban .layout-menubutton .layout-menubutton-icon {
  background-color: #25353B;
}
.layout-topbar-urban .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-urban .layout-menubutton .layout-menubutton-icon:after {
  background-color: #25353B;
}
.layout-topbar-urban .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-urban .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #25353B;
}
.layout-topbar-urban .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-urban .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #25353B;
}
.layout-topbar-urban .layout-menubutton.layout-menubutton-active {
  background-color: rgba(255, 255, 255, 0.3);
}
.layout-topbar-urban .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-urban .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-urban .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-urban.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-urban.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-urban.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-urban.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #B1B9BA;
  }
  .layout-topbar-urban.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-urban.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-urban.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-urban .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-urban .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-urban .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-urban .layout-breadcrumb li a {
  color: #ffc107;
}

.layout-topbar-bloom .layout-top {
  background-image: url("../../../layout/images/special/bloom-topbar.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-topbar-bloom .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-bloom .layout-topbar .layout-topbar-search i {
  color: #C9C9C0;
}
.layout-topbar-bloom .layout-topbar .layout-topbar-search label {
  color: #C9C9C0;
}
.layout-topbar-bloom .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-bloom .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-bloom .layout-menubutton .layout-menubutton-icon {
  background-color: #141509;
}
.layout-topbar-bloom .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-bloom .layout-menubutton .layout-menubutton-icon:after {
  background-color: #141509;
}
.layout-topbar-bloom .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-bloom .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #141509;
}
.layout-topbar-bloom .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-bloom .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #141509;
}
.layout-topbar-bloom .layout-menubutton.layout-menubutton-active {
  background-color: rgba(255, 255, 255, 0.3);
}
.layout-topbar-bloom .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-bloom .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-bloom .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-bloom.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-bloom.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-bloom.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-bloom.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #C9C9C0;
  }
  .layout-topbar-bloom.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-bloom.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-bloom.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-bloom .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-bloom .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-bloom .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-bloom .layout-breadcrumb li a {
  color: #ffc107;
}

.layout-topbar-tinfoil .layout-top {
  background-image: url("../../../layout/images/special/tinfoil-topbar.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-topbar-tinfoil .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-tinfoil .layout-topbar .layout-topbar-search i {
  color: #A5B2C0;
}
.layout-topbar-tinfoil .layout-topbar .layout-topbar-search label {
  color: #A5B2C0;
}
.layout-topbar-tinfoil .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-tinfoil .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-tinfoil .layout-menubutton .layout-menubutton-icon {
  background-color: #020E18;
}
.layout-topbar-tinfoil .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-tinfoil .layout-menubutton .layout-menubutton-icon:after {
  background-color: #020E18;
}
.layout-topbar-tinfoil .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-tinfoil .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #020E18;
}
.layout-topbar-tinfoil .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-tinfoil .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #020E18;
}
.layout-topbar-tinfoil .layout-menubutton.layout-menubutton-active {
  background-color: rgba(255, 255, 255, 0.4);
}
.layout-topbar-tinfoil .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-tinfoil .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-tinfoil .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-tinfoil.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-tinfoil.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-tinfoil.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-tinfoil.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #A5B2C0;
  }
  .layout-topbar-tinfoil.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-tinfoil.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-tinfoil.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-tinfoil .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-tinfoil .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-tinfoil .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-tinfoil .layout-breadcrumb li a {
  color: #ffc107;
}

.layout-topbar-hallway .layout-top {
  background-image: url("../../../layout/images/special/hallway-topbar.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-topbar-hallway .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-hallway .layout-topbar .layout-topbar-search i {
  color: #BDB8C7;
}
.layout-topbar-hallway .layout-topbar .layout-topbar-search label {
  color: #BDB8C7;
}
.layout-topbar-hallway .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-hallway .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-hallway .layout-menubutton .layout-menubutton-icon {
  background-color: #444270;
}
.layout-topbar-hallway .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-hallway .layout-menubutton .layout-menubutton-icon:after {
  background-color: #444270;
}
.layout-topbar-hallway .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-hallway .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #444270;
}
.layout-topbar-hallway .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-hallway .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #444270;
}
.layout-topbar-hallway .layout-menubutton.layout-menubutton-active {
  background-color: rgba(255, 255, 255, 0.3);
}
.layout-topbar-hallway .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-hallway .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-hallway .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-hallway.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-hallway.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-hallway.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-hallway.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #BDB8C7;
  }
  .layout-topbar-hallway.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-hallway.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-hallway.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-hallway .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-hallway .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-hallway .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-hallway .layout-breadcrumb li a {
  color: #ffc107;
}

.layout-topbar-seagull .layout-top {
  background-image: url("../../../layout/images/special/seagull-topbar.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-topbar-seagull .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-seagull .layout-topbar .layout-topbar-search i {
  color: #B3BEBF;
}
.layout-topbar-seagull .layout-topbar .layout-topbar-search label {
  color: #B3BEBF;
}
.layout-topbar-seagull .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-seagull .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-seagull .layout-menubutton .layout-menubutton-icon {
  background-color: #193C42;
}
.layout-topbar-seagull .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-seagull .layout-menubutton .layout-menubutton-icon:after {
  background-color: #193C42;
}
.layout-topbar-seagull .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-seagull .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #193C42;
}
.layout-topbar-seagull .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-seagull .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #193C42;
}
.layout-topbar-seagull .layout-menubutton.layout-menubutton-active {
  background-color: rgba(255, 255, 255, 0.3);
}
.layout-topbar-seagull .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-seagull .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-seagull .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-seagull.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-seagull.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-seagull.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-seagull.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #B3BEBF;
  }
  .layout-topbar-seagull.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-seagull.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-seagull.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-seagull .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-seagull .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-seagull .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-seagull .layout-breadcrumb li a {
  color: #FFA726;
}

.layout-topbar-city .layout-top {
  background-image: url("../../../layout/images/special/city-topbar.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-topbar-city .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-city .layout-topbar .layout-topbar-search i {
  color: #A3B0BD;
}
.layout-topbar-city .layout-topbar .layout-topbar-search label {
  color: #A3B0BD;
}
.layout-topbar-city .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-city .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-city .layout-menubutton .layout-menubutton-icon {
  background-color: #01122E;
}
.layout-topbar-city .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-city .layout-menubutton .layout-menubutton-icon:after {
  background-color: #01122E;
}
.layout-topbar-city .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-city .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #01122E;
}
.layout-topbar-city .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-city .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #01122E;
}
.layout-topbar-city .layout-menubutton.layout-menubutton-active {
  background-color: rgba(255, 255, 255, 0.3);
}
.layout-topbar-city .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-city .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-city .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-city.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-city.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-city.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-city.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #A3B0BD;
  }
  .layout-topbar-city.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-city.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-city.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-city .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-city .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-city .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-city .layout-breadcrumb li a {
  color: #ffc107;
}

.layout-topbar-jet .layout-top {
  background-image: url("../../../layout/images/special/jet-topbar.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-topbar-jet .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-jet .layout-topbar .layout-topbar-search i {
  color: #CCCFD6;
}
.layout-topbar-jet .layout-topbar .layout-topbar-search label {
  color: #CCCFD6;
}
.layout-topbar-jet .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-jet .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-jet .layout-menubutton .layout-menubutton-icon {
  background-color: #194958;
}
.layout-topbar-jet .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-jet .layout-menubutton .layout-menubutton-icon:after {
  background-color: #194958;
}
.layout-topbar-jet .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-jet .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #194958;
}
.layout-topbar-jet .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-jet .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #194958;
}
.layout-topbar-jet .layout-menubutton.layout-menubutton-active {
  background-color: rgba(255, 255, 255, 0.3);
}
.layout-topbar-jet .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-jet .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-jet .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-jet.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-jet.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-jet.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-jet.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #CCCFD6;
  }
  .layout-topbar-jet.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-jet.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-jet.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-jet .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-jet .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-jet .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-jet .layout-breadcrumb li a {
  color: #ffc107;
}

.layout-topbar-louisville .layout-top {
  background-image: url("../../../layout/images/special/louisville-topbar.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-topbar-louisville .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-louisville .layout-topbar .layout-topbar-search i {
  color: #AEC3C4;
}
.layout-topbar-louisville .layout-topbar .layout-topbar-search label {
  color: #AEC3C4;
}
.layout-topbar-louisville .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-louisville .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-louisville .layout-menubutton .layout-menubutton-icon {
  background-color: #11444D;
}
.layout-topbar-louisville .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-louisville .layout-menubutton .layout-menubutton-icon:after {
  background-color: #11444D;
}
.layout-topbar-louisville .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-louisville .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #11444D;
}
.layout-topbar-louisville .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-louisville .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #11444D;
}
.layout-topbar-louisville .layout-menubutton.layout-menubutton-active {
  background-color: rgba(255, 255, 255, 0.3);
}
.layout-topbar-louisville .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-louisville .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-louisville .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-louisville.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-louisville.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-louisville.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-louisville.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #AEC3C4;
  }
  .layout-topbar-louisville.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-louisville.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-louisville.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-louisville .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-louisville .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-louisville .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-louisville .layout-breadcrumb li a {
  color: #ffc107;
}

.layout-topbar-spray .layout-top {
  background-image: url("../../../layout/images/special/spray-topbar.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-topbar-spray .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-spray .layout-topbar .layout-topbar-search i {
  color: #BDC2C0;
}
.layout-topbar-spray .layout-topbar .layout-topbar-search label {
  color: #BDC2C0;
}
.layout-topbar-spray .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-spray .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-spray .layout-menubutton .layout-menubutton-icon {
  background-color: #126B6D;
}
.layout-topbar-spray .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-spray .layout-menubutton .layout-menubutton-icon:after {
  background-color: #126B6D;
}
.layout-topbar-spray .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-spray .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #126B6D;
}
.layout-topbar-spray .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-spray .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #126B6D;
}
.layout-topbar-spray .layout-menubutton.layout-menubutton-active {
  background-color: rgba(255, 255, 255, 0.3);
}
.layout-topbar-spray .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-spray .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-spray .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-spray.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-spray.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-spray.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-spray.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #BDC2C0;
  }
  .layout-topbar-spray.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-spray.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-spray.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-spray .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-spray .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-spray .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-spray .layout-breadcrumb li a {
  color: #ffc107;
}

.layout-topbar-symmetry .layout-top {
  background-image: url("../../../layout/images/special/symmetry-topbar.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-topbar-symmetry .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-symmetry .layout-topbar .layout-topbar-search i {
  color: #CECDCD;
}
.layout-topbar-symmetry .layout-topbar .layout-topbar-search label {
  color: #CECDCD;
}
.layout-topbar-symmetry .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-symmetry .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-symmetry .layout-menubutton .layout-menubutton-icon {
  background-color: #654630;
}
.layout-topbar-symmetry .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-symmetry .layout-menubutton .layout-menubutton-icon:after {
  background-color: #654630;
}
.layout-topbar-symmetry .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-symmetry .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #654630;
}
.layout-topbar-symmetry .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-symmetry .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #654630;
}
.layout-topbar-symmetry .layout-menubutton.layout-menubutton-active {
  background-color: rgba(255, 255, 255, 0.3);
}
.layout-topbar-symmetry .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-symmetry .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-symmetry .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-symmetry.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-symmetry.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-symmetry.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-symmetry.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #CECDCD;
  }
  .layout-topbar-symmetry.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-symmetry.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-symmetry.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-symmetry .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-symmetry .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-symmetry .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-symmetry .layout-breadcrumb li a {
  color: #00BCD4;
}

.layout-topbar-destination .layout-top {
  background-image: url("../../../layout/images/special/destination-topbar.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-topbar-destination .layout-topbar .layout-topbar-search .p-inputtext {
  color: #ffffff;
}
.layout-topbar-destination .layout-topbar .layout-topbar-search i {
  color: #A5B4B9;
}
.layout-topbar-destination .layout-topbar .layout-topbar-search label {
  color: #A5B4B9;
}
.layout-topbar-destination .layout-topbar .profile-menu-button:hover {
  border-color: #ffffff;
}
.layout-topbar-destination .layout-menubutton {
  background-color: #fafafa;
}
.layout-topbar-destination .layout-menubutton .layout-menubutton-icon {
  background-color: #002A39;
}
.layout-topbar-destination .layout-menubutton .layout-menubutton-icon:before, .layout-topbar-destination .layout-menubutton .layout-menubutton-icon:after {
  background-color: #002A39;
}
.layout-topbar-destination .layout-menubutton:hover {
  background-color: #e0e0e0;
}
.layout-topbar-destination .layout-menubutton:hover .layout-menubutton-icon {
  background-color: #126B6D;
}
.layout-topbar-destination .layout-menubutton:hover .layout-menubutton-icon:before, .layout-topbar-destination .layout-menubutton:hover .layout-menubutton-icon:after {
  background-color: #126B6D;
}
.layout-topbar-destination .layout-menubutton.layout-menubutton-active {
  background-color: rgba(8, 58, 75, 0.7);
}
.layout-topbar-destination .layout-menubutton.layout-menubutton-active .layout-menubutton-icon {
  background-color: #ffffff;
}
.layout-topbar-destination .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:before, .layout-topbar-destination .layout-menubutton.layout-menubutton-active .layout-menubutton-icon:after {
  background-color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-topbar-destination.layout-menu-horizontal .layout-menu > li:hover > a {
    background-color: transparent;
  }
  .layout-topbar-destination.layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-destination.layout-menu-horizontal .layout-menu > li > a i {
    color: #ffffff;
  }
  .layout-topbar-destination.layout-menu-horizontal .layout-menu > li > a:hover {
    border-bottom-color: #A5B4B9;
  }
  .layout-topbar-destination.layout-menu-horizontal .layout-menu > li.active-menuitem > a {
    background-color: transparent;
    border-bottom-color: #ffffff;
  }
  .layout-topbar-destination.layout-menu-horizontal .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }
  .layout-topbar-destination.layout-menu-horizontal .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
}
.layout-topbar-destination .layout-topbar-separator {
  background-color: #ffffff;
}
.layout-topbar-destination .layout-breadcrumb li {
  color: #ffffff;
}
.layout-topbar-destination .layout-breadcrumb li i {
  color: #ffffff;
}
.layout-topbar-destination .layout-breadcrumb li a {
  color: #ffc107;
}

.layout-container .layout-menubutton {
  display: block;
}
.layout-container .layout-logo {
  margin-right: 0;
}
.layout-container .layout-menu-container {
  position: fixed;
  overflow: auto;
  width: 240px;
  height: 100%;
  top: 68px;
  left: -250px;
  z-index: 999;
  -moz-transition: left 0.2s;
  -o-transition: left 0.2s;
  -webkit-transition: left 0.2s;
  transition: left 0.2s;
  -webkit-box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19), 0 8px 17px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19), 0 8px 17px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19), 0 8px 17px 0 rgba(0, 0, 0, 0.2);
}
.layout-container .layout-menu {
  list-style-type: none;
  margin: 0;
  padding: 0.75em 0.75em 120px;
}
.layout-container .layout-menu li .layout-submenu-toggler {
  -moz-transition: transform 0.2s;
  -o-transition: transform 0.2s;
  -webkit-transition: transform 0.2s;
  transition: transform 0.2s;
}
.layout-container .layout-menu li.active-menuitem > a .layout-submenu-toggler {
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
.layout-container .layout-menu > li.active-menuitem > a {
  -moz-border-radius-bottomleft: 0;
  -webkit-border-bottom-left-radius: 0;
  border-bottom-left-radius: 0;
  -moz-border-radius-bottomright: 0;
  -webkit-border-bottom-right-radius: 0;
  border-bottom-right-radius: 0;
}
.layout-container .layout-menu > li > .layout-submenu-container > .layout-megamenu > li .layout-megamenu-submenu-text {
  display: block;
  padding: 0.75em;
  font-weight: 500;
}
.layout-container .layout-menu > li > .layout-submenu-container > .layout-megamenu > li > .layout-submenu-container {
  display: block;
}
.layout-container .layout-menu > li > .layout-submenu-container > .layout-megamenu > li > .layout-submenu-container a {
  padding-left: 0.75em;
}
.layout-container .layout-menu a {
  display: block;
  position: relative;
  padding: 0.75em;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
}
.layout-container .layout-menu a .menuitem-text {
  vertical-align: middle;
}
.layout-container .layout-menu a.rotated-icon .layout-menuitem-icon {
  transform: rotate(90deg);
}
.layout-container .layout-menu a i {
  vertical-align: middle;
  font-size: 16px;
}
.layout-container .layout-menu a i.layout-submenu-toggler {
  position: absolute;
  right: 0.75em;
}
.layout-container .layout-menu a i:first-child {
  margin-right: 7px;
}
.layout-container .layout-menu .layout-submenu-container {
  overflow: hidden;
}
.layout-container .layout-menu .layout-submenu-container .layout-submenu {
  list-style-type: none;
  padding: 0;
  margin: 0;
  overflow: hidden;
}
.layout-container .layout-menu .layout-submenu-container .layout-submenu.layout-megamenu > li > a:first-child {
  display: none;
}
.layout-container .layout-menu .layout-submenu-container a {
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
.layout-container .layout-menu .layout-submenu-container li a {
  padding-left: 1.75em;
}
.layout-container .layout-menu .layout-submenu-container li li a {
  padding-left: 2.75em;
}
.layout-container .layout-menu .layout-submenu-container li li li a {
  padding-left: 3.75em;
}
.layout-container .layout-menu .layout-submenu-container li li li li a {
  padding-left: 4.75em;
}
.layout-container .layout-menu .layout-submenu-container li li li li li a {
  padding-left: 5.75em;
}
.layout-container.layout-menu-active .layout-menu-container {
  left: 0;
}
.layout-container.layout-menu-active .layout-menubutton {
  -webkit-box-shadow: inset 0 1px 10px 0 rgba(0, 0, 0, 0.12), inset 0 4px 5px 0 rgba(0, 0, 0, 0.14);
  -moz-box-shadow: inset 0 1px 10px 0 rgba(0, 0, 0, 0.12), inset 0 4px 5px 0 rgba(0, 0, 0, 0.14);
  box-shadow: inset 0 1px 10px 0 rgba(0, 0, 0, 0.12), inset 0 4px 5px 0 rgba(0, 0, 0, 0.14);
}
.layout-container.layout-menu-active .layout-menubutton .layout-menubutton-icon {
  width: 0;
}
.layout-container.layout-menu-active .layout-menubutton .layout-menubutton-icon:before {
  -webkit-transform: translateY(9px) rotate(45deg);
  -ms-transform: translateY(9px) rotate(45deg);
  transform: translateY(9px) rotate(45deg);
}
.layout-container.layout-menu-active .layout-menubutton .layout-menubutton-icon:after {
  -webkit-transform: translateY(-9px) rotate(-45deg);
  -ms-transform: translateY(-9px) rotate(-45deg);
  transform: translateY(-9px) rotate(-45deg);
}

@media screen and (min-width: 641px) {
  .layout-menu-horizontal .layout-menubutton {
    display: none;
  }
  .layout-menu-horizontal .layout-logo {
    margin-right: 2em;
  }
  .layout-menu-horizontal .layout-menu-container {
    position: static;
    display: block;
    z-index: auto;
    width: auto;
    height: auto;
    overflow: visible;
    -moz-transition: none;
    -o-transition: none;
    -webkit-transition: none;
    transition: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
  .layout-menu-horizontal .layout-menu {
    padding: 0;
  }
  .layout-menu-horizontal .layout-menu > li {
    display: inline-block;
    position: relative;
  }
  .layout-menu-horizontal .layout-menu > li > a {
    display: block;
    padding: 1em;
    font-weight: 500;
    position: relative;
    cursor: pointer;
    user-select: none;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    -moz-transition: border-bottom-color 0.2s;
    -o-transition: border-bottom-color 0.2s;
    -webkit-transition: border-bottom-color 0.2s;
    transition: border-bottom-color 0.2s;
  }
  .layout-menu-horizontal .layout-menu > li > a .menuitem-text {
    margin: 0 0.5em;
  }
  .layout-menu-horizontal .layout-menu > li > a i {
    font-size: 16px;
  }
  .layout-menu-horizontal .layout-menu > li > a i.layout-submenu-toggler {
    position: static;
  }
  .layout-menu-horizontal .layout-menu > li > a i:first-child {
    margin-right: 0;
  }
  .layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    position: absolute;
    min-width: 250px;
    z-index: 999;
    padding: 0.75em;
    max-height: 500px;
    overflow: auto;
    -webkit-box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19), 0 8px 17px 0 rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19), 0 8px 17px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19), 0 8px 17px 0 rgba(0, 0, 0, 0.2);
  }
  .layout-menu-horizontal .layout-menu > li > .layout-submenu-container > .layout-megamenu {
    max-height: none;
    width: auto;
    display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
  }
  .layout-menu-horizontal .layout-menu > li > .layout-submenu-container > .layout-megamenu > li {
    flex-grow: 1;
  }
  .layout-menu-horizontal .layout-menu > li > .layout-submenu-container > .layout-megamenu > li > .layout-submenu-container {
    min-width: 200px;
  }
  .layout-menu-horizontal .layout-menu > li > .layout-submenu-container > .layout-megamenu > li > .layout-submenu-container a {
    padding-left: 0.75em;
  }
  .layout-menu-horizontal .layout-menu li .layout-submenu-toggler {
    -moz-transition: transform 0.2s;
    -o-transition: transform 0.2s;
    -webkit-transition: transform 0.2s;
    transition: transform 0.2s;
  }
  .layout-menu-horizontal .layout-menu li.active-menuitem > a .layout-submenu-toggler {
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  .layout-menu-horizontal .layout-menu .layout-submenu li a {
    display: block;
    position: relative;
    padding: 0.75em;
    -moz-border-radius: 6px;
    -webkit-border-radius: 6px;
    border-radius: 6px;
  }
  .layout-menu-horizontal .layout-menu .layout-submenu li a .menuitem-text {
    vertical-align: middle;
  }
  .layout-menu-horizontal .layout-menu .layout-submenu li a i {
    vertical-align: middle;
    font-size: 16px;
  }
  .layout-menu-horizontal .layout-menu .layout-submenu li a i.layout-submenu-toggler {
    position: absolute;
    right: 0.75em;
  }
  .layout-menu-horizontal .layout-menu .layout-submenu li a i:first-child {
    margin-right: 7px;
  }
  .layout-menu-horizontal .layout-menu .layout-submenu li li a {
    padding-left: 1.75em;
  }
  .layout-menu-horizontal .layout-menu .layout-submenu li li li a {
    padding-left: 2.75em;
  }
  .layout-menu-horizontal .layout-menu .layout-submenu li li li li a {
    padding-left: 3.75em;
  }
  .layout-menu-horizontal .layout-menu .layout-submenu li li li li li a {
    padding-left: 4.75em;
  }
  .layout-menu-horizontal .layout-menu .layout-submenu li li li li li li a {
    padding-left: 5.75em;
  }
  .layout-menu-horizontal .layout-mask, .layout-menu-horizontal.layout-top-small .layout-mask {
    top: 0;
    left: 0;
  }
}
.menuitem-badge {
  background-color: #ffc107;
  color: #212121;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  text-align: center;
  font-size: 12px;
  min-width: 16px;
  min-height: 16px;
  line-height: 16px;
  display: inline-block;
  margin-left: 0.5em;
  vertical-align: middle;
}

.layout-menu-light .layout-menu-container {
  background-color: #ffffff;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(#ffffff));
  background-image: -webkit-linear-gradient(top, #ffffff, #ffffff);
  background-image: -moz-linear-gradient(top, #ffffff, #ffffff);
  background-image: -ms-linear-gradient(top, #ffffff, #ffffff);
  background-image: -o-linear-gradient(top, #ffffff, #ffffff);
  background-image: linear-gradient(to bottom, #ffffff, #ffffff);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#ffffff");
}
.layout-menu-light .layout-menu a .menuitem-text {
  color: #616161;
}
.layout-menu-light .layout-menu a i {
  color: #616161;
}
.layout-menu-light .layout-menu a:hover {
  background-color: #e0e0e0;
}
.layout-menu-light .layout-menu a:hover .menuitem-text {
  color: #212121;
}
.layout-menu-light .layout-menu a:hover i {
  color: #212121;
}
.layout-menu-light .layout-menu > li > .layout-submenu-container {
  background-color: #eeeeee;
}
.layout-menu-light .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #457fca;
}
.layout-menu-light .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #457fca;
}
.layout-menu-light .layout-menu li.active-menuitem > a .menuitem-text {
  color: #457fca;
}
.layout-menu-light .layout-menu li.active-menuitem > a i {
  color: #457fca;
}
.layout-menu-light .layout-menu > li.active-menuitem > a {
  background-color: #457fca;
}
.layout-menu-light .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #ffffff;
}
.layout-menu-light .layout-menu > li.active-menuitem > a i {
  color: #ffffff;
}
.layout-menu-light .layout-menu .layout-megamenu-submenu-text {
  color: #616161;
}
@media screen and (min-width: 641px) {
  .layout-menu-light.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-light.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #616161;
  }
  .layout-menu-light.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-color: #ffffff;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(#ffffff));
    background-image: -webkit-linear-gradient(top, #ffffff, #ffffff);
    background-image: -moz-linear-gradient(top, #ffffff, #ffffff);
    background-image: -ms-linear-gradient(top, #ffffff, #ffffff);
    background-image: -o-linear-gradient(top, #ffffff, #ffffff);
    background-image: linear-gradient(to bottom, #ffffff, #ffffff);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#ffffff");
  }
}
.layout-menu-light .layout-profile-menu {
  background-color: #ffffff;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(#ffffff));
  background-image: -webkit-linear-gradient(top, #ffffff, #ffffff);
  background-image: -moz-linear-gradient(top, #ffffff, #ffffff);
  background-image: -ms-linear-gradient(top, #ffffff, #ffffff);
  background-image: -o-linear-gradient(top, #ffffff, #ffffff);
  background-image: linear-gradient(to bottom, #ffffff, #ffffff);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#ffffff");
}
.layout-menu-light .layout-profile-menu a span {
  color: #616161;
}
.layout-menu-light .layout-profile-menu a i {
  color: #616161;
}
.layout-menu-light .layout-profile-menu a:hover {
  background-color: #e0e0e0;
}
.layout-menu-light .layout-profile-menu a:hover .menuitem-text {
  color: #212121;
}
.layout-menu-light .layout-profile-menu a:hover i {
  color: #212121;
}
.layout-menu-light .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #616161;
  background-image: linear-gradient(to bottom, #616161, #616161), linear-gradient(to bottom, #616161, #616161);
}
.layout-menu-light .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-light .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #616161;
}
.layout-menu-light .layout-profile-menu .layout-profile-menu-search i {
  color: #616161;
}
.layout-menu-light .layout-profile-menu .layout-profile-menu-search label {
  color: #616161;
}

.layout-menu-dark .layout-menu-container {
  background-color: #212121;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#212121), to(#212121));
  background-image: -webkit-linear-gradient(top, #212121, #212121);
  background-image: -moz-linear-gradient(top, #212121, #212121);
  background-image: -ms-linear-gradient(top, #212121, #212121);
  background-image: -o-linear-gradient(top, #212121, #212121);
  background-image: linear-gradient(to bottom, #212121, #212121);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#212121", endColorstr="#212121");
}
.layout-menu-dark .layout-menu a .menuitem-text {
  color: #eeeeee;
}
.layout-menu-dark .layout-menu a i {
  color: #eeeeee;
}
.layout-menu-dark .layout-menu a:hover {
  background-color: #424242;
}
.layout-menu-dark .layout-menu a:hover .menuitem-text {
  color: #eeeeee;
}
.layout-menu-dark .layout-menu a:hover i {
  color: #eeeeee;
}
.layout-menu-dark .layout-menu > li > .layout-submenu-container {
  background-color: #373737;
}
.layout-menu-dark .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #457fca;
}
.layout-menu-dark .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #457fca;
}
.layout-menu-dark .layout-menu li.active-menuitem > a .menuitem-text {
  color: #457fca;
}
.layout-menu-dark .layout-menu li.active-menuitem > a i {
  color: #457fca;
}
.layout-menu-dark .layout-menu > li.active-menuitem > a {
  background-color: #457fca;
}
.layout-menu-dark .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #ffffff;
}
.layout-menu-dark .layout-menu > li.active-menuitem > a i {
  color: #ffffff;
}
.layout-menu-dark .layout-menu .layout-megamenu-submenu-text {
  color: #eeeeee;
}
@media screen and (min-width: 641px) {
  .layout-menu-dark.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-dark.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #eeeeee;
  }
  .layout-menu-dark.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-color: #212121;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#212121), to(#212121));
    background-image: -webkit-linear-gradient(top, #212121, #212121);
    background-image: -moz-linear-gradient(top, #212121, #212121);
    background-image: -ms-linear-gradient(top, #212121, #212121);
    background-image: -o-linear-gradient(top, #212121, #212121);
    background-image: linear-gradient(to bottom, #212121, #212121);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#212121", endColorstr="#212121");
  }
}
.layout-menu-dark .layout-profile-menu {
  background-color: #212121;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#212121), to(#212121));
  background-image: -webkit-linear-gradient(top, #212121, #212121);
  background-image: -moz-linear-gradient(top, #212121, #212121);
  background-image: -ms-linear-gradient(top, #212121, #212121);
  background-image: -o-linear-gradient(top, #212121, #212121);
  background-image: linear-gradient(to bottom, #212121, #212121);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#212121", endColorstr="#212121");
}
.layout-menu-dark .layout-profile-menu a span {
  color: #eeeeee;
}
.layout-menu-dark .layout-profile-menu a i {
  color: #eeeeee;
}
.layout-menu-dark .layout-profile-menu a:hover {
  background-color: #424242;
}
.layout-menu-dark .layout-profile-menu a:hover .menuitem-text {
  color: #eeeeee;
}
.layout-menu-dark .layout-profile-menu a:hover i {
  color: #eeeeee;
}
.layout-menu-dark .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #eeeeee;
  background-image: linear-gradient(to bottom, #eeeeee, #eeeeee), linear-gradient(to bottom, #eeeeee, #eeeeee);
}
.layout-menu-dark .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-dark .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #eeeeee;
}
.layout-menu-dark .layout-profile-menu .layout-profile-menu-search i {
  color: #eeeeee;
}
.layout-menu-dark .layout-profile-menu .layout-profile-menu-search label {
  color: #eeeeee;
}

.layout-menu-blue .layout-menu-container {
  background-color: #457fca;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#457fca), to(#5691c8));
  background-image: -webkit-linear-gradient(top, #457fca, #5691c8);
  background-image: -moz-linear-gradient(top, #457fca, #5691c8);
  background-image: -ms-linear-gradient(top, #457fca, #5691c8);
  background-image: -o-linear-gradient(top, #457fca, #5691c8);
  background-image: linear-gradient(to bottom, #457fca, #5691c8);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#457fca", endColorstr="#5691c8");
}
.layout-menu-blue .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-blue .layout-menu a i {
  color: #ffffff;
}
.layout-menu-blue .layout-menu a:hover {
  background-color: #578bcf;
}
.layout-menu-blue .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-blue .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-blue .layout-menu > li > .layout-submenu-container {
  background-color: #6a98d4;
}
.layout-menu-blue .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #ffc107;
}
.layout-menu-blue .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #ffc107;
}
.layout-menu-blue .layout-menu li.active-menuitem > a .menuitem-text {
  color: #ffc107;
}
.layout-menu-blue .layout-menu li.active-menuitem > a i {
  color: #ffc107;
}
.layout-menu-blue .layout-menu > li.active-menuitem > a {
  background-color: #ffffff;
}
.layout-menu-blue .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #457fca;
}
.layout-menu-blue .layout-menu > li.active-menuitem > a i {
  color: #457fca;
}
.layout-menu-blue .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-blue.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-blue.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-blue.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-color: #457fca;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#457fca), to(#5691c8));
    background-image: -webkit-linear-gradient(top, #457fca, #5691c8);
    background-image: -moz-linear-gradient(top, #457fca, #5691c8);
    background-image: -ms-linear-gradient(top, #457fca, #5691c8);
    background-image: -o-linear-gradient(top, #457fca, #5691c8);
    background-image: linear-gradient(to bottom, #457fca, #5691c8);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#457fca", endColorstr="#5691c8");
  }
}
.layout-menu-blue .layout-profile-menu {
  background-color: #457fca;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#457fca), to(#5691c8));
  background-image: -webkit-linear-gradient(top, #457fca, #5691c8);
  background-image: -moz-linear-gradient(top, #457fca, #5691c8);
  background-image: -ms-linear-gradient(top, #457fca, #5691c8);
  background-image: -o-linear-gradient(top, #457fca, #5691c8);
  background-image: linear-gradient(to bottom, #457fca, #5691c8);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#457fca", endColorstr="#5691c8");
}
.layout-menu-blue .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-blue .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-blue .layout-profile-menu a:hover {
  background-color: #578bcf;
}
.layout-menu-blue .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-blue .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-blue .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-blue .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-blue .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-blue .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-blue .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-ash .layout-menu-container {
  background-color: #3F4C6B;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#3F4C6B), to(#334163));
  background-image: -webkit-linear-gradient(top, #3F4C6B, #334163);
  background-image: -moz-linear-gradient(top, #3F4C6B, #334163);
  background-image: -ms-linear-gradient(top, #3F4C6B, #334163);
  background-image: -o-linear-gradient(top, #3F4C6B, #334163);
  background-image: linear-gradient(to bottom, #3F4C6B, #334163);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#3F4C6B", endColorstr="#334163");
}
.layout-menu-ash .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-ash .layout-menu a i {
  color: #ffffff;
}
.layout-menu-ash .layout-menu a:hover {
  background-color: #1A253F;
}
.layout-menu-ash .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-ash .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-ash .layout-menu > li > .layout-submenu-container {
  background-color: #667290;
}
.layout-menu-ash .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #00BCD4;
}
.layout-menu-ash .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #00BCD4;
}
.layout-menu-ash .layout-menu li.active-menuitem > a .menuitem-text {
  color: #00BCD4;
}
.layout-menu-ash .layout-menu li.active-menuitem > a i {
  color: #00BCD4;
}
.layout-menu-ash .layout-menu > li.active-menuitem > a {
  background-color: #ffffff;
}
.layout-menu-ash .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #3F4C6B;
}
.layout-menu-ash .layout-menu > li.active-menuitem > a i {
  color: #3F4C6B;
}
.layout-menu-ash .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-ash.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-ash.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-ash.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-color: #3F4C6B;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#3F4C6B), to(#334163));
    background-image: -webkit-linear-gradient(top, #3F4C6B, #334163);
    background-image: -moz-linear-gradient(top, #3F4C6B, #334163);
    background-image: -ms-linear-gradient(top, #3F4C6B, #334163);
    background-image: -o-linear-gradient(top, #3F4C6B, #334163);
    background-image: linear-gradient(to bottom, #3F4C6B, #334163);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#3F4C6B", endColorstr="#334163");
  }
}
.layout-menu-ash .layout-profile-menu {
  background-color: #3F4C6B;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#3F4C6B), to(#334163));
  background-image: -webkit-linear-gradient(top, #3F4C6B, #334163);
  background-image: -moz-linear-gradient(top, #3F4C6B, #334163);
  background-image: -ms-linear-gradient(top, #3F4C6B, #334163);
  background-image: -o-linear-gradient(top, #3F4C6B, #334163);
  background-image: linear-gradient(to bottom, #3F4C6B, #334163);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#3F4C6B", endColorstr="#334163");
}
.layout-menu-ash .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-ash .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-ash .layout-profile-menu a:hover {
  background-color: #1A253F;
}
.layout-menu-ash .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-ash .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-ash .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-ash .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-ash .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-ash .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-ash .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-kashmir .layout-menu-container {
  background-color: #614385;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#614385), to(#516395));
  background-image: -webkit-linear-gradient(top, #614385, #516395);
  background-image: -moz-linear-gradient(top, #614385, #516395);
  background-image: -ms-linear-gradient(top, #614385, #516395);
  background-image: -o-linear-gradient(top, #614385, #516395);
  background-image: linear-gradient(to bottom, #614385, #516395);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#614385", endColorstr="#516395");
}
.layout-menu-kashmir .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-kashmir .layout-menu a i {
  color: #ffffff;
}
.layout-menu-kashmir .layout-menu a:hover {
  background-color: #412B5C;
}
.layout-menu-kashmir .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-kashmir .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-kashmir .layout-menu > li > .layout-submenu-container {
  background-color: #8A6DAD;
}
.layout-menu-kashmir .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #ffc107;
}
.layout-menu-kashmir .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #ffc107;
}
.layout-menu-kashmir .layout-menu li.active-menuitem > a .menuitem-text {
  color: #ffc107;
}
.layout-menu-kashmir .layout-menu li.active-menuitem > a i {
  color: #ffc107;
}
.layout-menu-kashmir .layout-menu > li.active-menuitem > a {
  background-color: #ffffff;
}
.layout-menu-kashmir .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #614385;
}
.layout-menu-kashmir .layout-menu > li.active-menuitem > a i {
  color: #614385;
}
.layout-menu-kashmir .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-kashmir.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-kashmir.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-kashmir.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-color: #614385;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#614385), to(#516395));
    background-image: -webkit-linear-gradient(top, #614385, #516395);
    background-image: -moz-linear-gradient(top, #614385, #516395);
    background-image: -ms-linear-gradient(top, #614385, #516395);
    background-image: -o-linear-gradient(top, #614385, #516395);
    background-image: linear-gradient(to bottom, #614385, #516395);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#614385", endColorstr="#516395");
  }
}
.layout-menu-kashmir .layout-profile-menu {
  background-color: #614385;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#614385), to(#516395));
  background-image: -webkit-linear-gradient(top, #614385, #516395);
  background-image: -moz-linear-gradient(top, #614385, #516395);
  background-image: -ms-linear-gradient(top, #614385, #516395);
  background-image: -o-linear-gradient(top, #614385, #516395);
  background-image: linear-gradient(to bottom, #614385, #516395);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#614385", endColorstr="#516395");
}
.layout-menu-kashmir .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-kashmir .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-kashmir .layout-profile-menu a:hover {
  background-color: #412B5C;
}
.layout-menu-kashmir .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-kashmir .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-kashmir .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-kashmir .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-kashmir .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-kashmir .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-kashmir .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-orange .layout-menu-container {
  background-color: #DD7919;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#DD7919), to(#E47307));
  background-image: -webkit-linear-gradient(top, #DD7919, #E47307);
  background-image: -moz-linear-gradient(top, #DD7919, #E47307);
  background-image: -ms-linear-gradient(top, #DD7919, #E47307);
  background-image: -o-linear-gradient(top, #DD7919, #E47307);
  background-image: linear-gradient(to bottom, #DD7919, #E47307);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#DD7919", endColorstr="#E47307");
}
.layout-menu-orange .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-orange .layout-menu a i {
  color: #ffffff;
}
.layout-menu-orange .layout-menu a:hover {
  background-color: #AD6119;
}
.layout-menu-orange .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-orange .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-orange .layout-menu > li > .layout-submenu-container {
  background-color: #E69E5A;
}
.layout-menu-orange .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #D4E157;
}
.layout-menu-orange .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #D4E157;
}
.layout-menu-orange .layout-menu li.active-menuitem > a .menuitem-text {
  color: #D4E157;
}
.layout-menu-orange .layout-menu li.active-menuitem > a i {
  color: #D4E157;
}
.layout-menu-orange .layout-menu > li.active-menuitem > a {
  background-color: #ffffff;
}
.layout-menu-orange .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #DD7919;
}
.layout-menu-orange .layout-menu > li.active-menuitem > a i {
  color: #DD7919;
}
.layout-menu-orange .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-orange.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-orange.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-orange.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-color: #DD7919;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#DD7919), to(#E47307));
    background-image: -webkit-linear-gradient(top, #DD7919, #E47307);
    background-image: -moz-linear-gradient(top, #DD7919, #E47307);
    background-image: -ms-linear-gradient(top, #DD7919, #E47307);
    background-image: -o-linear-gradient(top, #DD7919, #E47307);
    background-image: linear-gradient(to bottom, #DD7919, #E47307);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#DD7919", endColorstr="#E47307");
  }
}
.layout-menu-orange .layout-profile-menu {
  background-color: #DD7919;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#DD7919), to(#E47307));
  background-image: -webkit-linear-gradient(top, #DD7919, #E47307);
  background-image: -moz-linear-gradient(top, #DD7919, #E47307);
  background-image: -ms-linear-gradient(top, #DD7919, #E47307);
  background-image: -o-linear-gradient(top, #DD7919, #E47307);
  background-image: linear-gradient(to bottom, #DD7919, #E47307);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#DD7919", endColorstr="#E47307");
}
.layout-menu-orange .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-orange .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-orange .layout-profile-menu a:hover {
  background-color: #AD6119;
}
.layout-menu-orange .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-orange .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-orange .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-orange .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-orange .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-orange .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-orange .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-midnight .layout-menu-container {
  background-color: #232526;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#232526), to(#414345));
  background-image: -webkit-linear-gradient(top, #232526, #414345);
  background-image: -moz-linear-gradient(top, #232526, #414345);
  background-image: -ms-linear-gradient(top, #232526, #414345);
  background-image: -o-linear-gradient(top, #232526, #414345);
  background-image: linear-gradient(to bottom, #232526, #414345);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#232526", endColorstr="#414345");
}
.layout-menu-midnight .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-midnight .layout-menu a i {
  color: #ffffff;
}
.layout-menu-midnight .layout-menu a:hover {
  background-color: #0F1010;
}
.layout-menu-midnight .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-midnight .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-midnight .layout-menu > li > .layout-submenu-container {
  background-color: #575B5E;
}
.layout-menu-midnight .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #4FC3F7;
}
.layout-menu-midnight .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #4FC3F7;
}
.layout-menu-midnight .layout-menu li.active-menuitem > a .menuitem-text {
  color: #4FC3F7;
}
.layout-menu-midnight .layout-menu li.active-menuitem > a i {
  color: #4FC3F7;
}
.layout-menu-midnight .layout-menu > li.active-menuitem > a {
  background-color: #ffffff;
}
.layout-menu-midnight .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #232526;
}
.layout-menu-midnight .layout-menu > li.active-menuitem > a i {
  color: #232526;
}
.layout-menu-midnight .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-midnight.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-midnight.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-midnight.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-color: #232526;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#232526), to(#414345));
    background-image: -webkit-linear-gradient(top, #232526, #414345);
    background-image: -moz-linear-gradient(top, #232526, #414345);
    background-image: -ms-linear-gradient(top, #232526, #414345);
    background-image: -o-linear-gradient(top, #232526, #414345);
    background-image: linear-gradient(to bottom, #232526, #414345);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#232526", endColorstr="#414345");
  }
}
.layout-menu-midnight .layout-profile-menu {
  background-color: #232526;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#232526), to(#414345));
  background-image: -webkit-linear-gradient(top, #232526, #414345);
  background-image: -moz-linear-gradient(top, #232526, #414345);
  background-image: -ms-linear-gradient(top, #232526, #414345);
  background-image: -o-linear-gradient(top, #232526, #414345);
  background-image: linear-gradient(to bottom, #232526, #414345);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#232526", endColorstr="#414345");
}
.layout-menu-midnight .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-midnight .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-midnight .layout-profile-menu a:hover {
  background-color: #0F1010;
}
.layout-menu-midnight .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-midnight .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-midnight .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-midnight .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-midnight .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-midnight .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-midnight .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-sunset .layout-menu-container {
  background-color: #C94B4B;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#C94B4B), to(#DE6868));
  background-image: -webkit-linear-gradient(top, #C94B4B, #DE6868);
  background-image: -moz-linear-gradient(top, #C94B4B, #DE6868);
  background-image: -ms-linear-gradient(top, #C94B4B, #DE6868);
  background-image: -o-linear-gradient(top, #C94B4B, #DE6868);
  background-image: linear-gradient(to bottom, #C94B4B, #DE6868);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#C94B4B", endColorstr="#DE6868");
}
.layout-menu-sunset .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-sunset .layout-menu a i {
  color: #ffffff;
}
.layout-menu-sunset .layout-menu a:hover {
  background-color: #8D3232;
}
.layout-menu-sunset .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-sunset .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-sunset .layout-menu > li > .layout-submenu-container {
  background-color: #DF7575;
}
.layout-menu-sunset .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #ffc107;
}
.layout-menu-sunset .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #ffc107;
}
.layout-menu-sunset .layout-menu li.active-menuitem > a .menuitem-text {
  color: #ffc107;
}
.layout-menu-sunset .layout-menu li.active-menuitem > a i {
  color: #ffc107;
}
.layout-menu-sunset .layout-menu > li.active-menuitem > a {
  background-color: #ffffff;
}
.layout-menu-sunset .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #C94B4B;
}
.layout-menu-sunset .layout-menu > li.active-menuitem > a i {
  color: #C94B4B;
}
.layout-menu-sunset .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-sunset.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-sunset.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-sunset.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-color: #C94B4B;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#C94B4B), to(#DE6868));
    background-image: -webkit-linear-gradient(top, #C94B4B, #DE6868);
    background-image: -moz-linear-gradient(top, #C94B4B, #DE6868);
    background-image: -ms-linear-gradient(top, #C94B4B, #DE6868);
    background-image: -o-linear-gradient(top, #C94B4B, #DE6868);
    background-image: linear-gradient(to bottom, #C94B4B, #DE6868);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#C94B4B", endColorstr="#DE6868");
  }
}
.layout-menu-sunset .layout-profile-menu {
  background-color: #C94B4B;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#C94B4B), to(#DE6868));
  background-image: -webkit-linear-gradient(top, #C94B4B, #DE6868);
  background-image: -moz-linear-gradient(top, #C94B4B, #DE6868);
  background-image: -ms-linear-gradient(top, #C94B4B, #DE6868);
  background-image: -o-linear-gradient(top, #C94B4B, #DE6868);
  background-image: linear-gradient(to bottom, #C94B4B, #DE6868);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#C94B4B", endColorstr="#DE6868");
}
.layout-menu-sunset .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-sunset .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-sunset .layout-profile-menu a:hover {
  background-color: #8D3232;
}
.layout-menu-sunset .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-sunset .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-sunset .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-sunset .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-sunset .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-sunset .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-sunset .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-marley .layout-menu-container {
  background-color: #00838F;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#00838F), to(#0E7CAC));
  background-image: -webkit-linear-gradient(top, #00838F, #0E7CAC);
  background-image: -moz-linear-gradient(top, #00838F, #0E7CAC);
  background-image: -ms-linear-gradient(top, #00838F, #0E7CAC);
  background-image: -o-linear-gradient(top, #00838F, #0E7CAC);
  background-image: linear-gradient(to bottom, #00838F, #0E7CAC);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00838F", endColorstr="#0E7CAC");
}
.layout-menu-marley .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-marley .layout-menu a i {
  color: #ffffff;
}
.layout-menu-marley .layout-menu a:hover {
  background-color: #07575E;
}
.layout-menu-marley .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-marley .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-marley .layout-menu > li > .layout-submenu-container {
  background-color: #47A0C7;
}
.layout-menu-marley .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #ffc107;
}
.layout-menu-marley .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #ffc107;
}
.layout-menu-marley .layout-menu li.active-menuitem > a .menuitem-text {
  color: #ffc107;
}
.layout-menu-marley .layout-menu li.active-menuitem > a i {
  color: #ffc107;
}
.layout-menu-marley .layout-menu > li.active-menuitem > a {
  background-color: #ffffff;
}
.layout-menu-marley .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #0E7CAC;
}
.layout-menu-marley .layout-menu > li.active-menuitem > a i {
  color: #0E7CAC;
}
.layout-menu-marley .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-marley.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-marley.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-marley.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-color: #00838F;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#00838F), to(#0E7CAC));
    background-image: -webkit-linear-gradient(top, #00838F, #0E7CAC);
    background-image: -moz-linear-gradient(top, #00838F, #0E7CAC);
    background-image: -ms-linear-gradient(top, #00838F, #0E7CAC);
    background-image: -o-linear-gradient(top, #00838F, #0E7CAC);
    background-image: linear-gradient(to bottom, #00838F, #0E7CAC);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00838F", endColorstr="#0E7CAC");
  }
}
.layout-menu-marley .layout-profile-menu {
  background-color: #00838F;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#00838F), to(#0E7CAC));
  background-image: -webkit-linear-gradient(top, #00838F, #0E7CAC);
  background-image: -moz-linear-gradient(top, #00838F, #0E7CAC);
  background-image: -ms-linear-gradient(top, #00838F, #0E7CAC);
  background-image: -o-linear-gradient(top, #00838F, #0E7CAC);
  background-image: linear-gradient(to bottom, #00838F, #0E7CAC);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00838F", endColorstr="#0E7CAC");
}
.layout-menu-marley .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-marley .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-marley .layout-profile-menu a:hover {
  background-color: #07575E;
}
.layout-menu-marley .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-marley .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-marley .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-marley .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-marley .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-marley .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-marley .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-harvey .layout-menu-container {
  background-color: #1F4037;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#1F4037), to(#509D7C));
  background-image: -webkit-linear-gradient(top, #1F4037, #509D7C);
  background-image: -moz-linear-gradient(top, #1F4037, #509D7C);
  background-image: -ms-linear-gradient(top, #1F4037, #509D7C);
  background-image: -o-linear-gradient(top, #1F4037, #509D7C);
  background-image: linear-gradient(to bottom, #1F4037, #509D7C);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1F4037", endColorstr="#509D7C");
}
.layout-menu-harvey .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-harvey .layout-menu a i {
  color: #ffffff;
}
.layout-menu-harvey .layout-menu a:hover {
  background-color: #17342C;
}
.layout-menu-harvey .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-harvey .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-harvey .layout-menu > li > .layout-submenu-container {
  background-color: #538C76;
}
.layout-menu-harvey .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #ffc107;
}
.layout-menu-harvey .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #ffc107;
}
.layout-menu-harvey .layout-menu li.active-menuitem > a .menuitem-text {
  color: #ffc107;
}
.layout-menu-harvey .layout-menu li.active-menuitem > a i {
  color: #ffc107;
}
.layout-menu-harvey .layout-menu > li.active-menuitem > a {
  background-color: #ffffff;
}
.layout-menu-harvey .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #17342C;
}
.layout-menu-harvey .layout-menu > li.active-menuitem > a i {
  color: #17342C;
}
.layout-menu-harvey .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-harvey.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-harvey.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-harvey.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-color: #1F4037;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#1F4037), to(#509D7C));
    background-image: -webkit-linear-gradient(top, #1F4037, #509D7C);
    background-image: -moz-linear-gradient(top, #1F4037, #509D7C);
    background-image: -ms-linear-gradient(top, #1F4037, #509D7C);
    background-image: -o-linear-gradient(top, #1F4037, #509D7C);
    background-image: linear-gradient(to bottom, #1F4037, #509D7C);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1F4037", endColorstr="#509D7C");
  }
}
.layout-menu-harvey .layout-profile-menu {
  background-color: #1F4037;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#1F4037), to(#509D7C));
  background-image: -webkit-linear-gradient(top, #1F4037, #509D7C);
  background-image: -moz-linear-gradient(top, #1F4037, #509D7C);
  background-image: -ms-linear-gradient(top, #1F4037, #509D7C);
  background-image: -o-linear-gradient(top, #1F4037, #509D7C);
  background-image: linear-gradient(to bottom, #1F4037, #509D7C);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1F4037", endColorstr="#509D7C");
}
.layout-menu-harvey .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-harvey .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-harvey .layout-profile-menu a:hover {
  background-color: #17342C;
}
.layout-menu-harvey .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-harvey .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-harvey .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-harvey .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-harvey .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-harvey .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-harvey .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-vanusa .layout-menu-container {
  background-color: #89216B;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#89216B), to(#9E2A64));
  background-image: -webkit-linear-gradient(top, #89216B, #9E2A64);
  background-image: -moz-linear-gradient(top, #89216B, #9E2A64);
  background-image: -ms-linear-gradient(top, #89216B, #9E2A64);
  background-image: -o-linear-gradient(top, #89216B, #9E2A64);
  background-image: linear-gradient(to bottom, #89216B, #9E2A64);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#89216B", endColorstr="#9E2A64");
}
.layout-menu-vanusa .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-vanusa .layout-menu a i {
  color: #ffffff;
}
.layout-menu-vanusa .layout-menu a:hover {
  background-color: #66154F;
}
.layout-menu-vanusa .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-vanusa .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-vanusa .layout-menu > li > .layout-submenu-container {
  background-color: #AE3F86;
}
.layout-menu-vanusa .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #ffc107;
}
.layout-menu-vanusa .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #ffc107;
}
.layout-menu-vanusa .layout-menu li.active-menuitem > a .menuitem-text {
  color: #ffc107;
}
.layout-menu-vanusa .layout-menu li.active-menuitem > a i {
  color: #ffc107;
}
.layout-menu-vanusa .layout-menu > li.active-menuitem > a {
  background-color: #ffffff;
}
.layout-menu-vanusa .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #89216B;
}
.layout-menu-vanusa .layout-menu > li.active-menuitem > a i {
  color: #89216B;
}
.layout-menu-vanusa .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-vanusa.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-vanusa.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-vanusa.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-color: #89216B;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#89216B), to(#9E2A64));
    background-image: -webkit-linear-gradient(top, #89216B, #9E2A64);
    background-image: -moz-linear-gradient(top, #89216B, #9E2A64);
    background-image: -ms-linear-gradient(top, #89216B, #9E2A64);
    background-image: -o-linear-gradient(top, #89216B, #9E2A64);
    background-image: linear-gradient(to bottom, #89216B, #9E2A64);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#89216B", endColorstr="#9E2A64");
  }
}
.layout-menu-vanusa .layout-profile-menu {
  background-color: #89216B;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#89216B), to(#9E2A64));
  background-image: -webkit-linear-gradient(top, #89216B, #9E2A64);
  background-image: -moz-linear-gradient(top, #89216B, #9E2A64);
  background-image: -ms-linear-gradient(top, #89216B, #9E2A64);
  background-image: -o-linear-gradient(top, #89216B, #9E2A64);
  background-image: linear-gradient(to bottom, #89216B, #9E2A64);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#89216B", endColorstr="#9E2A64");
}
.layout-menu-vanusa .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-vanusa .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-vanusa .layout-profile-menu a:hover {
  background-color: #66154F;
}
.layout-menu-vanusa .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-vanusa .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-vanusa .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-vanusa .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-vanusa .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-vanusa .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-vanusa .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-skyline .layout-menu-container {
  background-color: #2764B6;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#2764B6), to(#303BB5));
  background-image: -webkit-linear-gradient(top, #2764B6, #303BB5);
  background-image: -moz-linear-gradient(top, #2764B6, #303BB5);
  background-image: -ms-linear-gradient(top, #2764B6, #303BB5);
  background-image: -o-linear-gradient(top, #2764B6, #303BB5);
  background-image: linear-gradient(to bottom, #2764B6, #303BB5);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#2764B6", endColorstr="#303BB5");
}
.layout-menu-skyline .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-skyline .layout-menu a i {
  color: #ffffff;
}
.layout-menu-skyline .layout-menu a:hover {
  background-color: #174280;
}
.layout-menu-skyline .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-skyline .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-skyline .layout-menu > li > .layout-submenu-container {
  background-color: #4F74C0;
}
.layout-menu-skyline .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #ffc107;
}
.layout-menu-skyline .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #ffc107;
}
.layout-menu-skyline .layout-menu li.active-menuitem > a .menuitem-text {
  color: #ffc107;
}
.layout-menu-skyline .layout-menu li.active-menuitem > a i {
  color: #ffc107;
}
.layout-menu-skyline .layout-menu > li.active-menuitem > a {
  background-color: #ffffff;
}
.layout-menu-skyline .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #303BB5;
}
.layout-menu-skyline .layout-menu > li.active-menuitem > a i {
  color: #303BB5;
}
.layout-menu-skyline .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-skyline.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-skyline.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-skyline.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-color: #2764B6;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#2764B6), to(#303BB5));
    background-image: -webkit-linear-gradient(top, #2764B6, #303BB5);
    background-image: -moz-linear-gradient(top, #2764B6, #303BB5);
    background-image: -ms-linear-gradient(top, #2764B6, #303BB5);
    background-image: -o-linear-gradient(top, #2764B6, #303BB5);
    background-image: linear-gradient(to bottom, #2764B6, #303BB5);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#2764B6", endColorstr="#303BB5");
  }
}
.layout-menu-skyline .layout-profile-menu {
  background-color: #2764B6;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#2764B6), to(#303BB5));
  background-image: -webkit-linear-gradient(top, #2764B6, #303BB5);
  background-image: -moz-linear-gradient(top, #2764B6, #303BB5);
  background-image: -ms-linear-gradient(top, #2764B6, #303BB5);
  background-image: -o-linear-gradient(top, #2764B6, #303BB5);
  background-image: linear-gradient(to bottom, #2764B6, #303BB5);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#2764B6", endColorstr="#303BB5");
}
.layout-menu-skyline .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-skyline .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-skyline .layout-profile-menu a:hover {
  background-color: #174280;
}
.layout-menu-skyline .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-skyline .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-skyline .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-skyline .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-skyline .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-skyline .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-skyline .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-royal .layout-menu-container {
  background-color: #141E30;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#141E30), to(#243B55));
  background-image: -webkit-linear-gradient(top, #141E30, #243B55);
  background-image: -moz-linear-gradient(top, #141E30, #243B55);
  background-image: -ms-linear-gradient(top, #141E30, #243B55);
  background-image: -o-linear-gradient(top, #141E30, #243B55);
  background-image: linear-gradient(to bottom, #141E30, #243B55);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#141E30", endColorstr="#243B55");
}
.layout-menu-royal .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-royal .layout-menu a i {
  color: #ffffff;
}
.layout-menu-royal .layout-menu a:hover {
  background-color: #738CB8;
}
.layout-menu-royal .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-royal .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-royal .layout-menu > li > .layout-submenu-container {
  background-color: #566B91;
}
.layout-menu-royal .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #ffc107;
}
.layout-menu-royal .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #ffc107;
}
.layout-menu-royal .layout-menu li.active-menuitem > a .menuitem-text {
  color: #ffc107;
}
.layout-menu-royal .layout-menu li.active-menuitem > a i {
  color: #ffc107;
}
.layout-menu-royal .layout-menu > li.active-menuitem > a {
  background-color: #ffffff;
}
.layout-menu-royal .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #141E30;
}
.layout-menu-royal .layout-menu > li.active-menuitem > a i {
  color: #141E30;
}
.layout-menu-royal .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-royal.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-royal.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-royal.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-color: #141E30;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#141E30), to(#243B55));
    background-image: -webkit-linear-gradient(top, #141E30, #243B55);
    background-image: -moz-linear-gradient(top, #141E30, #243B55);
    background-image: -ms-linear-gradient(top, #141E30, #243B55);
    background-image: -o-linear-gradient(top, #141E30, #243B55);
    background-image: linear-gradient(to bottom, #141E30, #243B55);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#141E30", endColorstr="#243B55");
  }
}
.layout-menu-royal .layout-profile-menu {
  background-color: #141E30;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#141E30), to(#243B55));
  background-image: -webkit-linear-gradient(top, #141E30, #243B55);
  background-image: -moz-linear-gradient(top, #141E30, #243B55);
  background-image: -ms-linear-gradient(top, #141E30, #243B55);
  background-image: -o-linear-gradient(top, #141E30, #243B55);
  background-image: linear-gradient(to bottom, #141E30, #243B55);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#141E30", endColorstr="#243B55");
}
.layout-menu-royal .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-royal .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-royal .layout-profile-menu a:hover {
  background-color: #738CB8;
}
.layout-menu-royal .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-royal .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-royal .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-royal .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-royal .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-royal .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-royal .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-disco .layout-menu-container {
  background-color: #556270;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#556270), to(#2C8F88));
  background-image: -webkit-linear-gradient(top, #556270, #2C8F88);
  background-image: -moz-linear-gradient(top, #556270, #2C8F88);
  background-image: -ms-linear-gradient(top, #556270, #2C8F88);
  background-image: -o-linear-gradient(top, #556270, #2C8F88);
  background-image: linear-gradient(to bottom, #556270, #2C8F88);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#556270", endColorstr="#2C8F88");
}
.layout-menu-disco .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-disco .layout-menu a i {
  color: #ffffff;
}
.layout-menu-disco .layout-menu a:hover {
  background-color: #3D4650;
}
.layout-menu-disco .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-disco .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-disco .layout-menu > li > .layout-submenu-container {
  background-color: #899DAA;
}
.layout-menu-disco .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #ffc107;
}
.layout-menu-disco .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #ffc107;
}
.layout-menu-disco .layout-menu li.active-menuitem > a .menuitem-text {
  color: #ffc107;
}
.layout-menu-disco .layout-menu li.active-menuitem > a i {
  color: #ffc107;
}
.layout-menu-disco .layout-menu > li.active-menuitem > a {
  background-color: #ffffff;
}
.layout-menu-disco .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #556270;
}
.layout-menu-disco .layout-menu > li.active-menuitem > a i {
  color: #556270;
}
.layout-menu-disco .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-disco.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-disco.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-disco.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-color: #556270;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#556270), to(#2C8F88));
    background-image: -webkit-linear-gradient(top, #556270, #2C8F88);
    background-image: -moz-linear-gradient(top, #556270, #2C8F88);
    background-image: -ms-linear-gradient(top, #556270, #2C8F88);
    background-image: -o-linear-gradient(top, #556270, #2C8F88);
    background-image: linear-gradient(to bottom, #556270, #2C8F88);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#556270", endColorstr="#2C8F88");
  }
}
.layout-menu-disco .layout-profile-menu {
  background-color: #556270;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#556270), to(#2C8F88));
  background-image: -webkit-linear-gradient(top, #556270, #2C8F88);
  background-image: -moz-linear-gradient(top, #556270, #2C8F88);
  background-image: -ms-linear-gradient(top, #556270, #2C8F88);
  background-image: -o-linear-gradient(top, #556270, #2C8F88);
  background-image: linear-gradient(to bottom, #556270, #2C8F88);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#556270", endColorstr="#2C8F88");
}
.layout-menu-disco .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-disco .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-disco .layout-profile-menu a:hover {
  background-color: #3D4650;
}
.layout-menu-disco .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-disco .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-disco .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-disco .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-disco .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-disco .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-disco .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-sky .layout-menu-container {
  background-color: #026E9D;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#026E9D), to(#1BA6DD));
  background-image: -webkit-linear-gradient(top, #026E9D, #1BA6DD);
  background-image: -moz-linear-gradient(top, #026E9D, #1BA6DD);
  background-image: -ms-linear-gradient(top, #026E9D, #1BA6DD);
  background-image: -o-linear-gradient(top, #026E9D, #1BA6DD);
  background-image: linear-gradient(to bottom, #026E9D, #1BA6DD);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#026E9D", endColorstr="#1BA6DD");
}
.layout-menu-sky .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-sky .layout-menu a i {
  color: #ffffff;
}
.layout-menu-sky .layout-menu a:hover {
  background-color: #065476;
}
.layout-menu-sky .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-sky .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-sky .layout-menu > li > .layout-submenu-container {
  background-color: #4BA1C5;
}
.layout-menu-sky .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #FFB300;
}
.layout-menu-sky .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #FFB300;
}
.layout-menu-sky .layout-menu li.active-menuitem > a .menuitem-text {
  color: #FFB300;
}
.layout-menu-sky .layout-menu li.active-menuitem > a i {
  color: #FFB300;
}
.layout-menu-sky .layout-menu > li.active-menuitem > a {
  background-color: #ffffff;
}
.layout-menu-sky .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #065476;
}
.layout-menu-sky .layout-menu > li.active-menuitem > a i {
  color: #065476;
}
.layout-menu-sky .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-sky.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-sky.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-sky.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-color: #026E9D;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#026E9D), to(#1BA6DD));
    background-image: -webkit-linear-gradient(top, #026E9D, #1BA6DD);
    background-image: -moz-linear-gradient(top, #026E9D, #1BA6DD);
    background-image: -ms-linear-gradient(top, #026E9D, #1BA6DD);
    background-image: -o-linear-gradient(top, #026E9D, #1BA6DD);
    background-image: linear-gradient(to bottom, #026E9D, #1BA6DD);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#026E9D", endColorstr="#1BA6DD");
  }
}
.layout-menu-sky .layout-profile-menu {
  background-color: #026E9D;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#026E9D), to(#1BA6DD));
  background-image: -webkit-linear-gradient(top, #026E9D, #1BA6DD);
  background-image: -moz-linear-gradient(top, #026E9D, #1BA6DD);
  background-image: -ms-linear-gradient(top, #026E9D, #1BA6DD);
  background-image: -o-linear-gradient(top, #026E9D, #1BA6DD);
  background-image: linear-gradient(to bottom, #026E9D, #1BA6DD);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#026E9D", endColorstr="#1BA6DD");
}
.layout-menu-sky .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-sky .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-sky .layout-profile-menu a:hover {
  background-color: #065476;
}
.layout-menu-sky .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-sky .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-sky .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-sky .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-sky .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-sky .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-sky .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-rose .layout-menu-container {
  background-color: #833859;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#833859), to(#833859));
  background-image: -webkit-linear-gradient(top, #833859, #833859);
  background-image: -moz-linear-gradient(top, #833859, #833859);
  background-image: -ms-linear-gradient(top, #833859, #833859);
  background-image: -o-linear-gradient(top, #833859, #833859);
  background-image: linear-gradient(to bottom, #833859, #833859);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#833859", endColorstr="#833859");
}
.layout-menu-rose .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-rose .layout-menu a i {
  color: #ffffff;
}
.layout-menu-rose .layout-menu a:hover {
  background-color: #A06780;
}
.layout-menu-rose .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-rose .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-rose .layout-menu > li > .layout-submenu-container {
  background-color: #522036;
}
.layout-menu-rose .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #66BB6A;
}
.layout-menu-rose .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #66BB6A;
}
.layout-menu-rose .layout-menu li.active-menuitem > a .menuitem-text {
  color: #66BB6A;
}
.layout-menu-rose .layout-menu li.active-menuitem > a i {
  color: #66BB6A;
}
.layout-menu-rose .layout-menu > li.active-menuitem > a {
  background-color: #ffffff;
}
.layout-menu-rose .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #522036;
}
.layout-menu-rose .layout-menu > li.active-menuitem > a i {
  color: #522036;
}
.layout-menu-rose .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-rose.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-rose.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-rose.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-color: #833859;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#833859), to(#833859));
    background-image: -webkit-linear-gradient(top, #833859, #833859);
    background-image: -moz-linear-gradient(top, #833859, #833859);
    background-image: -ms-linear-gradient(top, #833859, #833859);
    background-image: -o-linear-gradient(top, #833859, #833859);
    background-image: linear-gradient(to bottom, #833859, #833859);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#833859", endColorstr="#833859");
  }
}
.layout-menu-rose .layout-profile-menu {
  background-color: #833859;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#833859), to(#833859));
  background-image: -webkit-linear-gradient(top, #833859, #833859);
  background-image: -moz-linear-gradient(top, #833859, #833859);
  background-image: -ms-linear-gradient(top, #833859, #833859);
  background-image: -o-linear-gradient(top, #833859, #833859);
  background-image: linear-gradient(to bottom, #833859, #833859);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#833859", endColorstr="#833859");
}
.layout-menu-rose .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-rose .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-rose .layout-profile-menu a:hover {
  background-color: #A06780;
}
.layout-menu-rose .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-rose .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-rose .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-rose .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-rose .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-rose .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-rose .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-revolt .layout-menu-container {
  background-color: #474982;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#474982), to(#6D97A2));
  background-image: -webkit-linear-gradient(top, #474982, #6D97A2);
  background-image: -moz-linear-gradient(top, #474982, #6D97A2);
  background-image: -ms-linear-gradient(top, #474982, #6D97A2);
  background-image: -o-linear-gradient(top, #474982, #6D97A2);
  background-image: linear-gradient(to bottom, #474982, #6D97A2);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#474982", endColorstr="#6D97A2");
}
.layout-menu-revolt .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-revolt .layout-menu a i {
  color: #ffffff;
}
.layout-menu-revolt .layout-menu a:hover {
  background-color: #787AA3;
}
.layout-menu-revolt .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-revolt .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-revolt .layout-menu > li > .layout-submenu-container {
  background-color: #2D2E5B;
}
.layout-menu-revolt .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #FFC107;
}
.layout-menu-revolt .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #FFC107;
}
.layout-menu-revolt .layout-menu li.active-menuitem > a .menuitem-text {
  color: #FFC107;
}
.layout-menu-revolt .layout-menu li.active-menuitem > a i {
  color: #FFC107;
}
.layout-menu-revolt .layout-menu > li.active-menuitem > a {
  background-color: #ffffff;
}
.layout-menu-revolt .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #474982;
}
.layout-menu-revolt .layout-menu > li.active-menuitem > a i {
  color: #474982;
}
.layout-menu-revolt .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-revolt.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-revolt.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-revolt.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-color: #474982;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#474982), to(#6D97A2));
    background-image: -webkit-linear-gradient(top, #474982, #6D97A2);
    background-image: -moz-linear-gradient(top, #474982, #6D97A2);
    background-image: -ms-linear-gradient(top, #474982, #6D97A2);
    background-image: -o-linear-gradient(top, #474982, #6D97A2);
    background-image: linear-gradient(to bottom, #474982, #6D97A2);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#474982", endColorstr="#6D97A2");
  }
}
.layout-menu-revolt .layout-profile-menu {
  background-color: #474982;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#474982), to(#6D97A2));
  background-image: -webkit-linear-gradient(top, #474982, #6D97A2);
  background-image: -moz-linear-gradient(top, #474982, #6D97A2);
  background-image: -ms-linear-gradient(top, #474982, #6D97A2);
  background-image: -o-linear-gradient(top, #474982, #6D97A2);
  background-image: linear-gradient(to bottom, #474982, #6D97A2);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#474982", endColorstr="#6D97A2");
}
.layout-menu-revolt .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-revolt .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-revolt .layout-profile-menu a:hover {
  background-color: #787AA3;
}
.layout-menu-revolt .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-revolt .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-revolt .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-revolt .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-revolt .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-revolt .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-revolt .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-forest .layout-menu-container {
  background-color: #224C3D;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#224C3D), to(#95A850));
  background-image: -webkit-linear-gradient(top, #224C3D, #95A850);
  background-image: -moz-linear-gradient(top, #224C3D, #95A850);
  background-image: -ms-linear-gradient(top, #224C3D, #95A850);
  background-image: -o-linear-gradient(top, #224C3D, #95A850);
  background-image: linear-gradient(to bottom, #224C3D, #95A850);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#224C3D", endColorstr="#95A850");
}
.layout-menu-forest .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-forest .layout-menu a i {
  color: #ffffff;
}
.layout-menu-forest .layout-menu a:hover {
  background-color: #16372B;
}
.layout-menu-forest .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-forest .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-forest .layout-menu > li > .layout-submenu-container {
  background-color: #466E60;
}
.layout-menu-forest .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #CDDC39;
}
.layout-menu-forest .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #CDDC39;
}
.layout-menu-forest .layout-menu li.active-menuitem > a .menuitem-text {
  color: #CDDC39;
}
.layout-menu-forest .layout-menu li.active-menuitem > a i {
  color: #CDDC39;
}
.layout-menu-forest .layout-menu > li.active-menuitem > a {
  background-color: #ffffff;
}
.layout-menu-forest .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #224C3D;
}
.layout-menu-forest .layout-menu > li.active-menuitem > a i {
  color: #224C3D;
}
.layout-menu-forest .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-forest.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-forest.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-forest.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-color: #224C3D;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#224C3D), to(#95A850));
    background-image: -webkit-linear-gradient(top, #224C3D, #95A850);
    background-image: -moz-linear-gradient(top, #224C3D, #95A850);
    background-image: -ms-linear-gradient(top, #224C3D, #95A850);
    background-image: -o-linear-gradient(top, #224C3D, #95A850);
    background-image: linear-gradient(to bottom, #224C3D, #95A850);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#224C3D", endColorstr="#95A850");
  }
}
.layout-menu-forest .layout-profile-menu {
  background-color: #224C3D;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#224C3D), to(#95A850));
  background-image: -webkit-linear-gradient(top, #224C3D, #95A850);
  background-image: -moz-linear-gradient(top, #224C3D, #95A850);
  background-image: -ms-linear-gradient(top, #224C3D, #95A850);
  background-image: -o-linear-gradient(top, #224C3D, #95A850);
  background-image: linear-gradient(to bottom, #224C3D, #95A850);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#224C3D", endColorstr="#95A850");
}
.layout-menu-forest .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-forest .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-forest .layout-profile-menu a:hover {
  background-color: #16372B;
}
.layout-menu-forest .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-forest .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-forest .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-forest .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-forest .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-forest .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-forest .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-night .layout-menu-container {
  background-color: #363C7E;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#363C7E), to(#0D68AF));
  background-image: -webkit-linear-gradient(top, #363C7E, #0D68AF);
  background-image: -moz-linear-gradient(top, #363C7E, #0D68AF);
  background-image: -ms-linear-gradient(top, #363C7E, #0D68AF);
  background-image: -o-linear-gradient(top, #363C7E, #0D68AF);
  background-image: linear-gradient(to bottom, #363C7E, #0D68AF);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#363C7E", endColorstr="#0D68AF");
}
.layout-menu-night .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-night .layout-menu a i {
  color: #ffffff;
}
.layout-menu-night .layout-menu a:hover {
  background-color: #20255A;
}
.layout-menu-night .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-night .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-night .layout-menu > li > .layout-submenu-container {
  background-color: #0D1030;
}
.layout-menu-night .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #FFCA28;
}
.layout-menu-night .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #FFCA28;
}
.layout-menu-night .layout-menu li.active-menuitem > a .menuitem-text {
  color: #FFCA28;
}
.layout-menu-night .layout-menu li.active-menuitem > a i {
  color: #FFCA28;
}
.layout-menu-night .layout-menu > li.active-menuitem > a {
  background-color: #ffffff;
}
.layout-menu-night .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #363C7E;
}
.layout-menu-night .layout-menu > li.active-menuitem > a i {
  color: #363C7E;
}
.layout-menu-night .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-night.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-night.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-night.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-color: #363C7E;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#363C7E), to(#0D68AF));
    background-image: -webkit-linear-gradient(top, #363C7E, #0D68AF);
    background-image: -moz-linear-gradient(top, #363C7E, #0D68AF);
    background-image: -ms-linear-gradient(top, #363C7E, #0D68AF);
    background-image: -o-linear-gradient(top, #363C7E, #0D68AF);
    background-image: linear-gradient(to bottom, #363C7E, #0D68AF);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#363C7E", endColorstr="#0D68AF");
  }
}
.layout-menu-night .layout-profile-menu {
  background-color: #363C7E;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#363C7E), to(#0D68AF));
  background-image: -webkit-linear-gradient(top, #363C7E, #0D68AF);
  background-image: -moz-linear-gradient(top, #363C7E, #0D68AF);
  background-image: -ms-linear-gradient(top, #363C7E, #0D68AF);
  background-image: -o-linear-gradient(top, #363C7E, #0D68AF);
  background-image: linear-gradient(to bottom, #363C7E, #0D68AF);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#363C7E", endColorstr="#0D68AF");
}
.layout-menu-night .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-night .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-night .layout-profile-menu a:hover {
  background-color: #20255A;
}
.layout-menu-night .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-night .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-night .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-night .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-night .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-night .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-night .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-apricot .layout-menu-container {
  background-color: #F1719A;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#F1719A), to(#FE9473));
  background-image: -webkit-linear-gradient(top, #F1719A, #FE9473);
  background-image: -moz-linear-gradient(top, #F1719A, #FE9473);
  background-image: -ms-linear-gradient(top, #F1719A, #FE9473);
  background-image: -o-linear-gradient(top, #F1719A, #FE9473);
  background-image: linear-gradient(to bottom, #F1719A, #FE9473);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#F1719A", endColorstr="#FE9473");
}
.layout-menu-apricot .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-apricot .layout-menu a i {
  color: #ffffff;
}
.layout-menu-apricot .layout-menu a:hover {
  background-color: #A93559;
}
.layout-menu-apricot .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-apricot .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-apricot .layout-menu > li > .layout-submenu-container {
  background-color: #5A1329;
}
.layout-menu-apricot .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #80CBC4;
}
.layout-menu-apricot .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #80CBC4;
}
.layout-menu-apricot .layout-menu li.active-menuitem > a .menuitem-text {
  color: #80CBC4;
}
.layout-menu-apricot .layout-menu li.active-menuitem > a i {
  color: #80CBC4;
}
.layout-menu-apricot .layout-menu > li.active-menuitem > a {
  background-color: #ffffff;
}
.layout-menu-apricot .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #A93559;
}
.layout-menu-apricot .layout-menu > li.active-menuitem > a i {
  color: #A93559;
}
.layout-menu-apricot .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-apricot.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-apricot.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-apricot.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-color: #F1719A;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#F1719A), to(#FE9473));
    background-image: -webkit-linear-gradient(top, #F1719A, #FE9473);
    background-image: -moz-linear-gradient(top, #F1719A, #FE9473);
    background-image: -ms-linear-gradient(top, #F1719A, #FE9473);
    background-image: -o-linear-gradient(top, #F1719A, #FE9473);
    background-image: linear-gradient(to bottom, #F1719A, #FE9473);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#F1719A", endColorstr="#FE9473");
  }
}
.layout-menu-apricot .layout-profile-menu {
  background-color: #F1719A;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#F1719A), to(#FE9473));
  background-image: -webkit-linear-gradient(top, #F1719A, #FE9473);
  background-image: -moz-linear-gradient(top, #F1719A, #FE9473);
  background-image: -ms-linear-gradient(top, #F1719A, #FE9473);
  background-image: -o-linear-gradient(top, #F1719A, #FE9473);
  background-image: linear-gradient(to bottom, #F1719A, #FE9473);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#F1719A", endColorstr="#FE9473");
}
.layout-menu-apricot .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-apricot .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-apricot .layout-profile-menu a:hover {
  background-color: #A93559;
}
.layout-menu-apricot .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-apricot .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-apricot .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-apricot .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-apricot .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-apricot .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-apricot .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-faraway .layout-menu-container {
  background-color: #D0664E;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#D0664E), to(#612338));
  background-image: -webkit-linear-gradient(top, #D0664E, #612338);
  background-image: -moz-linear-gradient(top, #D0664E, #612338);
  background-image: -ms-linear-gradient(top, #D0664E, #612338);
  background-image: -o-linear-gradient(top, #D0664E, #612338);
  background-image: linear-gradient(to bottom, #D0664E, #612338);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#D0664E", endColorstr="#612338");
}
.layout-menu-faraway .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-faraway .layout-menu a i {
  color: #ffffff;
}
.layout-menu-faraway .layout-menu a:hover {
  background-color: #6E2A1C;
}
.layout-menu-faraway .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-faraway .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-faraway .layout-menu > li > .layout-submenu-container {
  background-color: #993F2C;
}
.layout-menu-faraway .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #00BCD4;
}
.layout-menu-faraway .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #00BCD4;
}
.layout-menu-faraway .layout-menu li.active-menuitem > a .menuitem-text {
  color: #00BCD4;
}
.layout-menu-faraway .layout-menu li.active-menuitem > a i {
  color: #00BCD4;
}
.layout-menu-faraway .layout-menu > li.active-menuitem > a {
  background-color: #ffffff;
}
.layout-menu-faraway .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #612338;
}
.layout-menu-faraway .layout-menu > li.active-menuitem > a i {
  color: #612338;
}
.layout-menu-faraway .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-faraway.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-faraway.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-faraway.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-color: #D0664E;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#D0664E), to(#612338));
    background-image: -webkit-linear-gradient(top, #D0664E, #612338);
    background-image: -moz-linear-gradient(top, #D0664E, #612338);
    background-image: -ms-linear-gradient(top, #D0664E, #612338);
    background-image: -o-linear-gradient(top, #D0664E, #612338);
    background-image: linear-gradient(to bottom, #D0664E, #612338);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#D0664E", endColorstr="#612338");
  }
}
.layout-menu-faraway .layout-profile-menu {
  background-color: #D0664E;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#D0664E), to(#612338));
  background-image: -webkit-linear-gradient(top, #D0664E, #612338);
  background-image: -moz-linear-gradient(top, #D0664E, #612338);
  background-image: -ms-linear-gradient(top, #D0664E, #612338);
  background-image: -o-linear-gradient(top, #D0664E, #612338);
  background-image: linear-gradient(to bottom, #D0664E, #612338);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#D0664E", endColorstr="#612338");
}
.layout-menu-faraway .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-faraway .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-faraway .layout-profile-menu a:hover {
  background-color: #6E2A1C;
}
.layout-menu-faraway .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-faraway .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-faraway .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-faraway .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-faraway .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-faraway .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-faraway .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-grape .layout-menu-container {
  background-color: #5854F7;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#5854F7), to(#7A91D8));
  background-image: -webkit-linear-gradient(top, #5854F7, #7A91D8);
  background-image: -moz-linear-gradient(top, #5854F7, #7A91D8);
  background-image: -ms-linear-gradient(top, #5854F7, #7A91D8);
  background-image: -o-linear-gradient(top, #5854F7, #7A91D8);
  background-image: linear-gradient(to bottom, #5854F7, #7A91D8);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#5854F7", endColorstr="#7A91D8");
}
.layout-menu-grape .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-grape .layout-menu a i {
  color: #ffffff;
}
.layout-menu-grape .layout-menu a:hover {
  background-color: #262389;
}
.layout-menu-grape .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-grape .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-grape .layout-menu > li > .layout-submenu-container {
  background-color: #3C39B5;
}
.layout-menu-grape .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #F06292;
}
.layout-menu-grape .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #F06292;
}
.layout-menu-grape .layout-menu li.active-menuitem > a .menuitem-text {
  color: #F06292;
}
.layout-menu-grape .layout-menu li.active-menuitem > a i {
  color: #F06292;
}
.layout-menu-grape .layout-menu > li.active-menuitem > a {
  background-color: #ffffff;
}
.layout-menu-grape .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #5854F7;
}
.layout-menu-grape .layout-menu > li.active-menuitem > a i {
  color: #5854F7;
}
.layout-menu-grape .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-grape.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-grape.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-grape.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-color: #5854F7;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#5854F7), to(#7A91D8));
    background-image: -webkit-linear-gradient(top, #5854F7, #7A91D8);
    background-image: -moz-linear-gradient(top, #5854F7, #7A91D8);
    background-image: -ms-linear-gradient(top, #5854F7, #7A91D8);
    background-image: -o-linear-gradient(top, #5854F7, #7A91D8);
    background-image: linear-gradient(to bottom, #5854F7, #7A91D8);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#5854F7", endColorstr="#7A91D8");
  }
}
.layout-menu-grape .layout-profile-menu {
  background-color: #5854F7;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#5854F7), to(#7A91D8));
  background-image: -webkit-linear-gradient(top, #5854F7, #7A91D8);
  background-image: -moz-linear-gradient(top, #5854F7, #7A91D8);
  background-image: -ms-linear-gradient(top, #5854F7, #7A91D8);
  background-image: -o-linear-gradient(top, #5854F7, #7A91D8);
  background-image: linear-gradient(to bottom, #5854F7, #7A91D8);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#5854F7", endColorstr="#7A91D8");
}
.layout-menu-grape .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-grape .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-grape .layout-profile-menu a:hover {
  background-color: #262389;
}
.layout-menu-grape .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-grape .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-grape .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-grape .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-grape .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-grape .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-grape .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-river .layout-menu-container {
  background-color: #054769;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#054769), to(#0596B7));
  background-image: -webkit-linear-gradient(top, #054769, #0596B7);
  background-image: -moz-linear-gradient(top, #054769, #0596B7);
  background-image: -ms-linear-gradient(top, #054769, #0596B7);
  background-image: -o-linear-gradient(top, #054769, #0596B7);
  background-image: linear-gradient(to bottom, #054769, #0596B7);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#054769", endColorstr="#0596B7");
}
.layout-menu-river .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-river .layout-menu a i {
  color: #ffffff;
}
.layout-menu-river .layout-menu a:hover {
  background-color: #061C27;
}
.layout-menu-river .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-river .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-river .layout-menu > li > .layout-submenu-container {
  background-color: #072F41;
}
.layout-menu-river .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #FFB300;
}
.layout-menu-river .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #FFB300;
}
.layout-menu-river .layout-menu li.active-menuitem > a .menuitem-text {
  color: #FFB300;
}
.layout-menu-river .layout-menu li.active-menuitem > a i {
  color: #FFB300;
}
.layout-menu-river .layout-menu > li.active-menuitem > a {
  background-color: #ffffff;
}
.layout-menu-river .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #054769;
}
.layout-menu-river .layout-menu > li.active-menuitem > a i {
  color: #054769;
}
.layout-menu-river .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-river.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-river.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-river.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-color: #054769;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#054769), to(#0596B7));
    background-image: -webkit-linear-gradient(top, #054769, #0596B7);
    background-image: -moz-linear-gradient(top, #054769, #0596B7);
    background-image: -ms-linear-gradient(top, #054769, #0596B7);
    background-image: -o-linear-gradient(top, #054769, #0596B7);
    background-image: linear-gradient(to bottom, #054769, #0596B7);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#054769", endColorstr="#0596B7");
  }
}
.layout-menu-river .layout-profile-menu {
  background-color: #054769;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#054769), to(#0596B7));
  background-image: -webkit-linear-gradient(top, #054769, #0596B7);
  background-image: -moz-linear-gradient(top, #054769, #0596B7);
  background-image: -ms-linear-gradient(top, #054769, #0596B7);
  background-image: -o-linear-gradient(top, #054769, #0596B7);
  background-image: linear-gradient(to bottom, #054769, #0596B7);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#054769", endColorstr="#0596B7");
}
.layout-menu-river .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-river .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-river .layout-profile-menu a:hover {
  background-color: #061C27;
}
.layout-menu-river .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-river .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-river .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-river .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-river .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-river .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-river .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-dock .layout-menu-container {
  background-color: #422072;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#422072), to(#F29C7B));
  background-image: -webkit-linear-gradient(top, #422072, #F29C7B);
  background-image: -moz-linear-gradient(top, #422072, #F29C7B);
  background-image: -ms-linear-gradient(top, #422072, #F29C7B);
  background-image: -o-linear-gradient(top, #422072, #F29C7B);
  background-image: linear-gradient(to bottom, #422072, #F29C7B);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#422072", endColorstr="#F29C7B");
}
.layout-menu-dock .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-dock .layout-menu a i {
  color: #ffffff;
}
.layout-menu-dock .layout-menu a:hover {
  background-color: #1C0A31;
}
.layout-menu-dock .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-dock .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-dock .layout-menu > li > .layout-submenu-container {
  background-color: #2F1251;
}
.layout-menu-dock .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #FFB300;
}
.layout-menu-dock .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #FFB300;
}
.layout-menu-dock .layout-menu li.active-menuitem > a .menuitem-text {
  color: #FFB300;
}
.layout-menu-dock .layout-menu li.active-menuitem > a i {
  color: #FFB300;
}
.layout-menu-dock .layout-menu > li.active-menuitem > a {
  background-color: #ffffff;
}
.layout-menu-dock .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #422072;
}
.layout-menu-dock .layout-menu > li.active-menuitem > a i {
  color: #422072;
}
.layout-menu-dock .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-dock.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-dock.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-dock.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-color: #422072;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#422072), to(#F29C7B));
    background-image: -webkit-linear-gradient(top, #422072, #F29C7B);
    background-image: -moz-linear-gradient(top, #422072, #F29C7B);
    background-image: -ms-linear-gradient(top, #422072, #F29C7B);
    background-image: -o-linear-gradient(top, #422072, #F29C7B);
    background-image: linear-gradient(to bottom, #422072, #F29C7B);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#422072", endColorstr="#F29C7B");
  }
}
.layout-menu-dock .layout-profile-menu {
  background-color: #422072;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#422072), to(#F29C7B));
  background-image: -webkit-linear-gradient(top, #422072, #F29C7B);
  background-image: -moz-linear-gradient(top, #422072, #F29C7B);
  background-image: -ms-linear-gradient(top, #422072, #F29C7B);
  background-image: -o-linear-gradient(top, #422072, #F29C7B);
  background-image: linear-gradient(to bottom, #422072, #F29C7B);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#422072", endColorstr="#F29C7B");
}
.layout-menu-dock .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-dock .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-dock .layout-profile-menu a:hover {
  background-color: #1C0A31;
}
.layout-menu-dock .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-dock .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-dock .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-dock .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-dock .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-dock .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-dock .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-materialone .layout-menu-container {
  background-image: url("../../../layout/images/special/materialone-menu.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-menu-materialone .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-materialone .layout-menu a i {
  color: #ffffff;
}
.layout-menu-materialone .layout-menu a:hover {
  background-color: rgba(0, 0, 0, 0.32);
}
.layout-menu-materialone .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-materialone .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-materialone .layout-menu > li > .layout-submenu-container {
  background-color: rgba(255, 255, 255, 0.2);
}
.layout-menu-materialone .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #ffc107;
}
.layout-menu-materialone .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #ffc107;
}
.layout-menu-materialone .layout-menu li.active-menuitem > a .menuitem-text {
  color: #ffc107;
}
.layout-menu-materialone .layout-menu li.active-menuitem > a i {
  color: #ffc107;
}
.layout-menu-materialone .layout-menu > li.active-menuitem > a {
  background-color: #f15c52;
}
.layout-menu-materialone .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #ffffff;
}
.layout-menu-materialone .layout-menu > li.active-menuitem > a i {
  color: #ffffff;
}
.layout-menu-materialone .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-materialone.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-materialone.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-materialone.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-image: url("../../../layout/images/special/materialone-menu.jpg");
    background-position: 0 0;
    background-size: 100% auto;
  }
}
.layout-menu-materialone .layout-profile-menu {
  background-image: url("../../../layout/images/special/materialone-menu.jpg");
  background-position: 0 0;
  background-size: 100% auto;
}
.layout-menu-materialone .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-materialone .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-materialone .layout-profile-menu a:hover {
  background-color: rgba(0, 0, 0, 0.32);
}
.layout-menu-materialone .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-materialone .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-materialone .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-materialone .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-materialone .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-materialone .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-materialone .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-materialtwo .layout-menu-container {
  background-image: url("../../../layout/images/special/materialtwo-menu.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-menu-materialtwo .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-materialtwo .layout-menu a i {
  color: #ffffff;
}
.layout-menu-materialtwo .layout-menu a:hover {
  background-color: rgba(0, 0, 0, 0.32);
}
.layout-menu-materialtwo .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-materialtwo .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-materialtwo .layout-menu > li > .layout-submenu-container {
  background-color: rgba(255, 255, 255, 0.2);
}
.layout-menu-materialtwo .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #ffc107;
}
.layout-menu-materialtwo .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #ffc107;
}
.layout-menu-materialtwo .layout-menu li.active-menuitem > a .menuitem-text {
  color: #ffc107;
}
.layout-menu-materialtwo .layout-menu li.active-menuitem > a i {
  color: #ffc107;
}
.layout-menu-materialtwo .layout-menu > li.active-menuitem > a {
  background-color: #34fcd6;
}
.layout-menu-materialtwo .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #181a21;
}
.layout-menu-materialtwo .layout-menu > li.active-menuitem > a i {
  color: #181a21;
}
.layout-menu-materialtwo .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-materialtwo.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-materialtwo.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-materialtwo.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-image: url("../../../layout/images/special/materialtwo-menu.jpg");
    background-position: 0 0;
    background-size: 100% auto;
  }
}
.layout-menu-materialtwo .layout-profile-menu {
  background-image: url("../../../layout/images/special/materialtwo-menu.jpg");
  background-position: 0 0;
  background-size: 100% auto;
}
.layout-menu-materialtwo .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-materialtwo .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-materialtwo .layout-profile-menu a:hover {
  background-color: rgba(0, 0, 0, 0.32);
}
.layout-menu-materialtwo .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-materialtwo .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-materialtwo .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-materialtwo .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-materialtwo .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-materialtwo .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-materialtwo .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-polygons .layout-menu-container {
  background-image: url("../../../layout/images/special/polygons-menu.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-menu-polygons .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-polygons .layout-menu a i {
  color: #ffffff;
}
.layout-menu-polygons .layout-menu a:hover {
  background-color: rgba(0, 0, 0, 0.32);
}
.layout-menu-polygons .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-polygons .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-polygons .layout-menu > li > .layout-submenu-container {
  background-color: rgba(255, 255, 255, 0.2);
}
.layout-menu-polygons .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #e1ab41;
}
.layout-menu-polygons .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #e1ab41;
}
.layout-menu-polygons .layout-menu li.active-menuitem > a .menuitem-text {
  color: #e1ab41;
}
.layout-menu-polygons .layout-menu li.active-menuitem > a i {
  color: #e1ab41;
}
.layout-menu-polygons .layout-menu > li.active-menuitem > a {
  background-color: #c54424;
}
.layout-menu-polygons .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #ffffff;
}
.layout-menu-polygons .layout-menu > li.active-menuitem > a i {
  color: #ffffff;
}
.layout-menu-polygons .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-polygons.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-polygons.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-polygons.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-image: url("../../../layout/images/special/polygons-menu.jpg");
    background-position: 0 0;
    background-size: 100% auto;
  }
}
.layout-menu-polygons .layout-profile-menu {
  background-image: url("../../../layout/images/special/polygons-menu.jpg");
  background-position: 0 0;
  background-size: 100% auto;
}
.layout-menu-polygons .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-polygons .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-polygons .layout-profile-menu a:hover {
  background-color: rgba(0, 0, 0, 0.32);
}
.layout-menu-polygons .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-polygons .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-polygons .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-polygons .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-polygons .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-polygons .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-polygons .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-connectionsone .layout-menu-container {
  background-image: url("../../../layout/images/special/connectionsone-menu.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-menu-connectionsone .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-connectionsone .layout-menu a i {
  color: #ffffff;
}
.layout-menu-connectionsone .layout-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-connectionsone .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-connectionsone .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-connectionsone .layout-menu > li > .layout-submenu-container {
  background-color: rgba(255, 255, 255, 0.2);
}
.layout-menu-connectionsone .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #ffc107;
}
.layout-menu-connectionsone .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #ffc107;
}
.layout-menu-connectionsone .layout-menu li.active-menuitem > a .menuitem-text {
  color: #ffc107;
}
.layout-menu-connectionsone .layout-menu li.active-menuitem > a i {
  color: #ffc107;
}
.layout-menu-connectionsone .layout-menu > li.active-menuitem > a {
  background-color: #dc439b;
}
.layout-menu-connectionsone .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #ffffff;
}
.layout-menu-connectionsone .layout-menu > li.active-menuitem > a i {
  color: #ffffff;
}
.layout-menu-connectionsone .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-connectionsone.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-connectionsone.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-connectionsone.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-image: url("../../../layout/images/special/connectionsone-menu.jpg");
    background-position: 0 0;
    background-size: 100% auto;
  }
}
.layout-menu-connectionsone .layout-profile-menu {
  background-image: url("../../../layout/images/special/connectionsone-menu.jpg");
  background-position: 0 0;
  background-size: 100% auto;
}
.layout-menu-connectionsone .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-connectionsone .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-connectionsone .layout-profile-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-connectionsone .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-connectionsone .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-connectionsone .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-connectionsone .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-connectionsone .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-connectionsone .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-connectionsone .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-connectionstwo .layout-menu-container {
  background-image: url("../../../layout/images/special/connectionstwo-menu.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-menu-connectionstwo .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-connectionstwo .layout-menu a i {
  color: #ffffff;
}
.layout-menu-connectionstwo .layout-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-connectionstwo .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-connectionstwo .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-connectionstwo .layout-menu > li > .layout-submenu-container {
  background-color: rgba(255, 255, 255, 0.2);
}
.layout-menu-connectionstwo .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #ffc107;
}
.layout-menu-connectionstwo .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #ffc107;
}
.layout-menu-connectionstwo .layout-menu li.active-menuitem > a .menuitem-text {
  color: #ffc107;
}
.layout-menu-connectionstwo .layout-menu li.active-menuitem > a i {
  color: #ffc107;
}
.layout-menu-connectionstwo .layout-menu > li.active-menuitem > a {
  background-color: #00BCD4;
}
.layout-menu-connectionstwo .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #ffffff;
}
.layout-menu-connectionstwo .layout-menu > li.active-menuitem > a i {
  color: #ffffff;
}
.layout-menu-connectionstwo .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-connectionstwo.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-connectionstwo.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-connectionstwo.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-image: url("../../../layout/images/special/connectionstwo-menu.jpg");
    background-position: 0 0;
    background-size: 100% auto;
  }
}
.layout-menu-connectionstwo .layout-profile-menu {
  background-image: url("../../../layout/images/special/connectionstwo-menu.jpg");
  background-position: 0 0;
  background-size: 100% auto;
}
.layout-menu-connectionstwo .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-connectionstwo .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-connectionstwo .layout-profile-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-connectionstwo .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-connectionstwo .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-connectionstwo .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-connectionstwo .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-connectionstwo .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-connectionstwo .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-connectionstwo .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-road .layout-menu-container {
  background-image: url("../../../layout/images/special/road-menu.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-menu-road .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-road .layout-menu a i {
  color: #ffffff;
}
.layout-menu-road .layout-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-road .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-road .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-road .layout-menu > li > .layout-submenu-container {
  background-color: rgba(255, 255, 255, 0.2);
}
.layout-menu-road .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #ffc107;
}
.layout-menu-road .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #ffc107;
}
.layout-menu-road .layout-menu li.active-menuitem > a .menuitem-text {
  color: #ffc107;
}
.layout-menu-road .layout-menu li.active-menuitem > a i {
  color: #ffc107;
}
.layout-menu-road .layout-menu > li.active-menuitem > a {
  background-color: #FF8F00;
}
.layout-menu-road .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #ffffff;
}
.layout-menu-road .layout-menu > li.active-menuitem > a i {
  color: #ffffff;
}
.layout-menu-road .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-road.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-road.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-road.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-image: url("../../../layout/images/special/road-menu.jpg");
    background-position: 0 0;
    background-size: 100% auto;
  }
}
.layout-menu-road .layout-profile-menu {
  background-image: url("../../../layout/images/special/road-menu.jpg");
  background-position: 0 0;
  background-size: 100% auto;
}
.layout-menu-road .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-road .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-road .layout-profile-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-road .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-road .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-road .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-road .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-road .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-road .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-road .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-reflection .layout-menu-container {
  background-image: url("../../../layout/images/special/reflection-menu.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-menu-reflection .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-reflection .layout-menu a i {
  color: #ffffff;
}
.layout-menu-reflection .layout-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-reflection .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-reflection .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-reflection .layout-menu > li > .layout-submenu-container {
  background-color: rgba(255, 255, 255, 0.2);
}
.layout-menu-reflection .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #4DD0E1;
}
.layout-menu-reflection .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #4DD0E1;
}
.layout-menu-reflection .layout-menu li.active-menuitem > a .menuitem-text {
  color: #4DD0E1;
}
.layout-menu-reflection .layout-menu li.active-menuitem > a i {
  color: #4DD0E1;
}
.layout-menu-reflection .layout-menu > li.active-menuitem > a {
  background-color: #00BCD4;
}
.layout-menu-reflection .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #ffffff;
}
.layout-menu-reflection .layout-menu > li.active-menuitem > a i {
  color: #ffffff;
}
.layout-menu-reflection .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-reflection.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-reflection.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-reflection.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-image: url("../../../layout/images/special/reflection-menu.jpg");
    background-position: 0 0;
    background-size: 100% auto;
  }
}
.layout-menu-reflection .layout-profile-menu {
  background-image: url("../../../layout/images/special/reflection-menu.jpg");
  background-position: 0 0;
  background-size: 100% auto;
}
.layout-menu-reflection .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-reflection .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-reflection .layout-profile-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-reflection .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-reflection .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-reflection .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-reflection .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-reflection .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-reflection .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-reflection .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-waves .layout-menu-container {
  background-image: url("../../../layout/images/special/waves-menu.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-menu-waves .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-waves .layout-menu a i {
  color: #ffffff;
}
.layout-menu-waves .layout-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-waves .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-waves .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-waves .layout-menu > li > .layout-submenu-container {
  background-color: rgba(255, 255, 255, 0.2);
}
.layout-menu-waves .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #ffc107;
}
.layout-menu-waves .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #ffc107;
}
.layout-menu-waves .layout-menu li.active-menuitem > a .menuitem-text {
  color: #ffc107;
}
.layout-menu-waves .layout-menu li.active-menuitem > a i {
  color: #ffc107;
}
.layout-menu-waves .layout-menu > li.active-menuitem > a {
  background-color: #0288D1;
}
.layout-menu-waves .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #ffffff;
}
.layout-menu-waves .layout-menu > li.active-menuitem > a i {
  color: #ffffff;
}
.layout-menu-waves .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-waves.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-waves.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-waves.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-image: url("../../../layout/images/special/waves-menu.jpg");
    background-position: 0 0;
    background-size: 100% auto;
  }
}
.layout-menu-waves .layout-profile-menu {
  background-image: url("../../../layout/images/special/waves-menu.jpg");
  background-position: 0 0;
  background-size: 100% auto;
}
.layout-menu-waves .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-waves .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-waves .layout-profile-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-waves .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-waves .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-waves .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-waves .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-waves .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-waves .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-waves .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-sandiego .layout-menu-container {
  background-image: url("../../../layout/images/special/sandiego-menu.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-menu-sandiego .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-sandiego .layout-menu a i {
  color: #ffffff;
}
.layout-menu-sandiego .layout-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-sandiego .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-sandiego .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-sandiego .layout-menu > li > .layout-submenu-container {
  background-color: rgba(0, 0, 0, 0.2);
}
.layout-menu-sandiego .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #e1ab41;
}
.layout-menu-sandiego .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #e1ab41;
}
.layout-menu-sandiego .layout-menu li.active-menuitem > a .menuitem-text {
  color: #e1ab41;
}
.layout-menu-sandiego .layout-menu li.active-menuitem > a i {
  color: #e1ab41;
}
.layout-menu-sandiego .layout-menu > li.active-menuitem > a {
  background-color: #FFD54F;
}
.layout-menu-sandiego .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #212121;
}
.layout-menu-sandiego .layout-menu > li.active-menuitem > a i {
  color: #212121;
}
.layout-menu-sandiego .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-sandiego.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-sandiego.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-sandiego.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-image: url("../../../layout/images/special/sandiego-menu.jpg");
    background-position: 0 0;
    background-size: 100% auto;
  }
}
.layout-menu-sandiego .layout-profile-menu {
  background-image: url("../../../layout/images/special/sandiego-menu.jpg");
  background-position: 0 0;
  background-size: 100% auto;
}
.layout-menu-sandiego .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-sandiego .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-sandiego .layout-profile-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-sandiego .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-sandiego .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-sandiego .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-sandiego .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-sandiego .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-sandiego .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-sandiego .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-architecture .layout-menu-container {
  background-image: url("../../../layout/images/special/architecture-menu.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-menu-architecture .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-architecture .layout-menu a i {
  color: #ffffff;
}
.layout-menu-architecture .layout-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-architecture .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-architecture .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-architecture .layout-menu > li > .layout-submenu-container {
  background-color: rgba(0, 0, 0, 0.2);
}
.layout-menu-architecture .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #B39DDB;
}
.layout-menu-architecture .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #B39DDB;
}
.layout-menu-architecture .layout-menu li.active-menuitem > a .menuitem-text {
  color: #B39DDB;
}
.layout-menu-architecture .layout-menu li.active-menuitem > a i {
  color: #B39DDB;
}
.layout-menu-architecture .layout-menu > li.active-menuitem > a {
  background-color: #673AB7;
}
.layout-menu-architecture .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #ffffff;
}
.layout-menu-architecture .layout-menu > li.active-menuitem > a i {
  color: #ffffff;
}
.layout-menu-architecture .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-architecture.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-architecture.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-architecture.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-image: url("../../../layout/images/special/architecture-menu.jpg");
    background-position: 0 0;
    background-size: 100% auto;
  }
}
.layout-menu-architecture .layout-profile-menu {
  background-image: url("../../../layout/images/special/architecture-menu.jpg");
  background-position: 0 0;
  background-size: 100% auto;
}
.layout-menu-architecture .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-architecture .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-architecture .layout-profile-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-architecture .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-architecture .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-architecture .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-architecture .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-architecture .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-architecture .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-architecture .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-snow .layout-menu-container {
  background-image: url("../../../layout/images/special/snow-menu.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-menu-snow .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-snow .layout-menu a i {
  color: #ffffff;
}
.layout-menu-snow .layout-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-snow .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-snow .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-snow .layout-menu > li > .layout-submenu-container {
  background-color: rgba(255, 255, 255, 0.24);
}
.layout-menu-snow .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #ffc107;
}
.layout-menu-snow .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #ffc107;
}
.layout-menu-snow .layout-menu li.active-menuitem > a .menuitem-text {
  color: #ffc107;
}
.layout-menu-snow .layout-menu li.active-menuitem > a i {
  color: #ffc107;
}
.layout-menu-snow .layout-menu > li.active-menuitem > a {
  background-color: #009688;
}
.layout-menu-snow .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #ffffff;
}
.layout-menu-snow .layout-menu > li.active-menuitem > a i {
  color: #ffffff;
}
.layout-menu-snow .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-snow.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-snow.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-snow.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-image: url("../../../layout/images/special/snow-menu.jpg");
    background-position: 0 0;
    background-size: 100% auto;
  }
}
.layout-menu-snow .layout-profile-menu {
  background-image: url("../../../layout/images/special/snow-menu.jpg");
  background-position: 0 0;
  background-size: 100% auto;
}
.layout-menu-snow .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-snow .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-snow .layout-profile-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-snow .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-snow .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-snow .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-snow .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-snow .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-snow .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-snow .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-palm .layout-menu-container {
  background-image: url("../../../layout/images/special/palm-menu.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-menu-palm .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-palm .layout-menu a i {
  color: #ffffff;
}
.layout-menu-palm .layout-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-palm .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-palm .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-palm .layout-menu > li > .layout-submenu-container {
  background-color: rgba(255, 255, 255, 0.2);
}
.layout-menu-palm .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #FFC107;
}
.layout-menu-palm .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #FFC107;
}
.layout-menu-palm .layout-menu li.active-menuitem > a .menuitem-text {
  color: #FFC107;
}
.layout-menu-palm .layout-menu li.active-menuitem > a i {
  color: #FFC107;
}
.layout-menu-palm .layout-menu > li.active-menuitem > a {
  background-color: #FF9800;
}
.layout-menu-palm .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #ffffff;
}
.layout-menu-palm .layout-menu > li.active-menuitem > a i {
  color: #ffffff;
}
.layout-menu-palm .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-palm.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-palm.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-palm.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-image: url("../../../layout/images/special/palm-menu.jpg");
    background-position: 0 0;
    background-size: 100% auto;
  }
}
.layout-menu-palm .layout-profile-menu {
  background-image: url("../../../layout/images/special/palm-menu.jpg");
  background-position: 0 0;
  background-size: 100% auto;
}
.layout-menu-palm .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-palm .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-palm .layout-profile-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-palm .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-palm .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-palm .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-palm .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-palm .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-palm .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-palm .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-fluid .layout-menu-container {
  background-image: url("../../../layout/images/special/fluid-menu.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-menu-fluid .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-fluid .layout-menu a i {
  color: #ffffff;
}
.layout-menu-fluid .layout-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-fluid .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-fluid .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-fluid .layout-menu > li > .layout-submenu-container {
  background-color: rgba(255, 255, 255, 0.2);
}
.layout-menu-fluid .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #ffc107;
}
.layout-menu-fluid .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #ffc107;
}
.layout-menu-fluid .layout-menu li.active-menuitem > a .menuitem-text {
  color: #ffc107;
}
.layout-menu-fluid .layout-menu li.active-menuitem > a i {
  color: #ffc107;
}
.layout-menu-fluid .layout-menu > li.active-menuitem > a {
  background-color: #A60776;
}
.layout-menu-fluid .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #ffffff;
}
.layout-menu-fluid .layout-menu > li.active-menuitem > a i {
  color: #ffffff;
}
.layout-menu-fluid .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-fluid.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-fluid.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-fluid.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-image: url("../../../layout/images/special/fluid-menu.jpg");
    background-position: 0 0;
    background-size: 100% auto;
  }
}
.layout-menu-fluid .layout-profile-menu {
  background-image: url("../../../layout/images/special/fluid-menu.jpg");
  background-position: 0 0;
  background-size: 100% auto;
}
.layout-menu-fluid .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-fluid .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-fluid .layout-profile-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-fluid .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-fluid .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-fluid .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-fluid .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-fluid .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-fluid .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-fluid .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-balloon .layout-menu-container {
  background-image: url("../../../layout/images/special/balloon-menu.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-menu-balloon .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-balloon .layout-menu a i {
  color: #ffffff;
}
.layout-menu-balloon .layout-menu a:hover {
  background-color: rgba(0, 0, 0, 0.32);
}
.layout-menu-balloon .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-balloon .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-balloon .layout-menu > li > .layout-submenu-container {
  background-color: rgba(255, 255, 255, 0.2);
}
.layout-menu-balloon .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #55E1F3;
}
.layout-menu-balloon .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #55E1F3;
}
.layout-menu-balloon .layout-menu li.active-menuitem > a .menuitem-text {
  color: #55E1F3;
}
.layout-menu-balloon .layout-menu li.active-menuitem > a i {
  color: #55E1F3;
}
.layout-menu-balloon .layout-menu > li.active-menuitem > a {
  background-color: #B44A75;
}
.layout-menu-balloon .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #ffffff;
}
.layout-menu-balloon .layout-menu > li.active-menuitem > a i {
  color: #ffffff;
}
.layout-menu-balloon .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-balloon.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-balloon.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-balloon.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-image: url("../../../layout/images/special/balloon-menu.jpg");
    background-position: 0 0;
    background-size: 100% auto;
  }
}
.layout-menu-balloon .layout-profile-menu {
  background-image: url("../../../layout/images/special/balloon-menu.jpg");
  background-position: 0 0;
  background-size: 100% auto;
}
.layout-menu-balloon .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-balloon .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-balloon .layout-profile-menu a:hover {
  background-color: rgba(0, 0, 0, 0.32);
}
.layout-menu-balloon .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-balloon .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-balloon .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-balloon .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-balloon .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-balloon .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-balloon .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-downtown .layout-menu-container {
  background-image: url("../../../layout/images/special/downtown-menu.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-menu-downtown .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-downtown .layout-menu a i {
  color: #ffffff;
}
.layout-menu-downtown .layout-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-downtown .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-downtown .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-downtown .layout-menu > li > .layout-submenu-container {
  background-color: rgba(0, 0, 0, 0.2);
}
.layout-menu-downtown .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #00BCD4;
}
.layout-menu-downtown .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #00BCD4;
}
.layout-menu-downtown .layout-menu li.active-menuitem > a .menuitem-text {
  color: #00BCD4;
}
.layout-menu-downtown .layout-menu li.active-menuitem > a i {
  color: #00BCD4;
}
.layout-menu-downtown .layout-menu > li.active-menuitem > a {
  background-color: #00BCD4;
}
.layout-menu-downtown .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #ffffff;
}
.layout-menu-downtown .layout-menu > li.active-menuitem > a i {
  color: #ffffff;
}
.layout-menu-downtown .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-downtown.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-downtown.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-downtown.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-image: url("../../../layout/images/special/downtown-menu.jpg");
    background-position: 0 0;
    background-size: 100% auto;
  }
}
.layout-menu-downtown .layout-profile-menu {
  background-image: url("../../../layout/images/special/downtown-menu.jpg");
  background-position: 0 0;
  background-size: 100% auto;
}
.layout-menu-downtown .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-downtown .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-downtown .layout-profile-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-downtown .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-downtown .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-downtown .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-downtown .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-downtown .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-downtown .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-downtown .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-perfection .layout-menu-container {
  background-image: url("../../../layout/images/special/perfection-menu.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-menu-perfection .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-perfection .layout-menu a i {
  color: #ffffff;
}
.layout-menu-perfection .layout-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-perfection .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-perfection .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-perfection .layout-menu > li > .layout-submenu-container {
  background-color: rgba(0, 0, 0, 0.2);
}
.layout-menu-perfection .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #E91E63;
}
.layout-menu-perfection .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #E91E63;
}
.layout-menu-perfection .layout-menu li.active-menuitem > a .menuitem-text {
  color: #E91E63;
}
.layout-menu-perfection .layout-menu li.active-menuitem > a i {
  color: #E91E63;
}
.layout-menu-perfection .layout-menu > li.active-menuitem > a {
  background-color: #E91E63;
}
.layout-menu-perfection .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #ffffff;
}
.layout-menu-perfection .layout-menu > li.active-menuitem > a i {
  color: #ffffff;
}
.layout-menu-perfection .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-perfection.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-perfection.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-perfection.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-image: url("../../../layout/images/special/perfection-menu.jpg");
    background-position: 0 0;
    background-size: 100% auto;
  }
}
.layout-menu-perfection .layout-profile-menu {
  background-image: url("../../../layout/images/special/perfection-menu.jpg");
  background-position: 0 0;
  background-size: 100% auto;
}
.layout-menu-perfection .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-perfection .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-perfection .layout-profile-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-perfection .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-perfection .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-perfection .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-perfection .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-perfection .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-perfection .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-perfection .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-northern .layout-menu-container {
  background-image: url("../../../layout/images/special/northern-menu.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-menu-northern .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-northern .layout-menu a i {
  color: #ffffff;
}
.layout-menu-northern .layout-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-northern .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-northern .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-northern .layout-menu > li > .layout-submenu-container {
  background-color: rgba(0, 0, 0, 0.2);
}
.layout-menu-northern .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #FFA726;
}
.layout-menu-northern .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #FFA726;
}
.layout-menu-northern .layout-menu li.active-menuitem > a .menuitem-text {
  color: #FFA726;
}
.layout-menu-northern .layout-menu li.active-menuitem > a i {
  color: #FFA726;
}
.layout-menu-northern .layout-menu > li.active-menuitem > a {
  background-color: #FF9800;
}
.layout-menu-northern .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #ffffff;
}
.layout-menu-northern .layout-menu > li.active-menuitem > a i {
  color: #ffffff;
}
.layout-menu-northern .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-northern.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-northern.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-northern.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-image: url("../../../layout/images/special/northern-menu.jpg");
    background-position: 0 0;
    background-size: 100% auto;
  }
}
.layout-menu-northern .layout-profile-menu {
  background-image: url("../../../layout/images/special/northern-menu.jpg");
  background-position: 0 0;
  background-size: 100% auto;
}
.layout-menu-northern .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-northern .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-northern .layout-profile-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-northern .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-northern .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-northern .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-northern .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-northern .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-northern .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-northern .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-highline .layout-menu-container {
  background-image: url("../../../layout/images/special/highline-menu.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-menu-highline .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-highline .layout-menu a i {
  color: #ffffff;
}
.layout-menu-highline .layout-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-highline .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-highline .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-highline .layout-menu > li > .layout-submenu-container {
  background-color: rgba(0, 0, 0, 0.2);
}
.layout-menu-highline .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #E91E63;
}
.layout-menu-highline .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #E91E63;
}
.layout-menu-highline .layout-menu li.active-menuitem > a .menuitem-text {
  color: #E91E63;
}
.layout-menu-highline .layout-menu li.active-menuitem > a i {
  color: #E91E63;
}
.layout-menu-highline .layout-menu > li.active-menuitem > a {
  background-color: #E91E63;
}
.layout-menu-highline .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #ffffff;
}
.layout-menu-highline .layout-menu > li.active-menuitem > a i {
  color: #ffffff;
}
.layout-menu-highline .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-highline.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-highline.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-highline.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-image: url("../../../layout/images/special/highline-menu.jpg");
    background-position: 0 0;
    background-size: 100% auto;
  }
}
.layout-menu-highline .layout-profile-menu {
  background-image: url("../../../layout/images/special/highline-menu.jpg");
  background-position: 0 0;
  background-size: 100% auto;
}
.layout-menu-highline .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-highline .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-highline .layout-profile-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-highline .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-highline .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-highline .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-highline .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-highline .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-highline .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-highline .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-mural .layout-menu-container {
  background-image: url("../../../layout/images/special/mural-menu.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-menu-mural .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-mural .layout-menu a i {
  color: #ffffff;
}
.layout-menu-mural .layout-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-mural .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-mural .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-mural .layout-menu > li > .layout-submenu-container {
  background-color: rgba(0, 0, 0, 0.2);
}
.layout-menu-mural .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #66BB6A;
}
.layout-menu-mural .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #66BB6A;
}
.layout-menu-mural .layout-menu li.active-menuitem > a .menuitem-text {
  color: #66BB6A;
}
.layout-menu-mural .layout-menu li.active-menuitem > a i {
  color: #66BB6A;
}
.layout-menu-mural .layout-menu > li.active-menuitem > a {
  background-color: #4CAF50;
}
.layout-menu-mural .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #ffffff;
}
.layout-menu-mural .layout-menu > li.active-menuitem > a i {
  color: #ffffff;
}
.layout-menu-mural .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-mural.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-mural.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-mural.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-image: url("../../../layout/images/special/mural-menu.jpg");
    background-position: 0 0;
    background-size: 100% auto;
  }
}
.layout-menu-mural .layout-profile-menu {
  background-image: url("../../../layout/images/special/mural-menu.jpg");
  background-position: 0 0;
  background-size: 100% auto;
}
.layout-menu-mural .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-mural .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-mural .layout-profile-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-mural .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-mural .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-mural .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-mural .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-mural .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-mural .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-mural .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-aeriel .layout-menu-container {
  background-image: url("../../../layout/images/special/aeriel-menu.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-menu-aeriel .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-aeriel .layout-menu a i {
  color: #ffffff;
}
.layout-menu-aeriel .layout-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-aeriel .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-aeriel .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-aeriel .layout-menu > li > .layout-submenu-container {
  background-color: rgba(0, 0, 0, 0.2);
}
.layout-menu-aeriel .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #D4E157;
}
.layout-menu-aeriel .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #D4E157;
}
.layout-menu-aeriel .layout-menu li.active-menuitem > a .menuitem-text {
  color: #D4E157;
}
.layout-menu-aeriel .layout-menu li.active-menuitem > a i {
  color: #D4E157;
}
.layout-menu-aeriel .layout-menu > li.active-menuitem > a {
  background-color: #CDDC39;
}
.layout-menu-aeriel .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #212121;
}
.layout-menu-aeriel .layout-menu > li.active-menuitem > a i {
  color: #212121;
}
.layout-menu-aeriel .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-aeriel.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-aeriel.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-aeriel.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-image: url("../../../layout/images/special/aeriel-menu.jpg");
    background-position: 0 0;
    background-size: 100% auto;
  }
}
.layout-menu-aeriel .layout-profile-menu {
  background-image: url("../../../layout/images/special/aeriel-menu.jpg");
  background-position: 0 0;
  background-size: 100% auto;
}
.layout-menu-aeriel .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-aeriel .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-aeriel .layout-profile-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-aeriel .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-aeriel .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-aeriel .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-aeriel .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-aeriel .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-aeriel .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-aeriel .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-wing .layout-menu-container {
  background-image: url("../../../layout/images/special/wing-menu.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-menu-wing .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-wing .layout-menu a i {
  color: #ffffff;
}
.layout-menu-wing .layout-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-wing .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-wing .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-wing .layout-menu > li > .layout-submenu-container {
  background-color: rgba(255, 255, 255, 0.2);
}
.layout-menu-wing .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #FFEE58;
}
.layout-menu-wing .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #FFEE58;
}
.layout-menu-wing .layout-menu li.active-menuitem > a .menuitem-text {
  color: #FFEE58;
}
.layout-menu-wing .layout-menu li.active-menuitem > a i {
  color: #FFEE58;
}
.layout-menu-wing .layout-menu > li.active-menuitem > a {
  background-color: #FFEB3B;
}
.layout-menu-wing .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #212121;
}
.layout-menu-wing .layout-menu > li.active-menuitem > a i {
  color: #212121;
}
.layout-menu-wing .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-wing.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-wing.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-wing.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-image: url("../../../layout/images/special/wing-menu.jpg");
    background-position: 0 0;
    background-size: 100% auto;
  }
}
.layout-menu-wing .layout-profile-menu {
  background-image: url("../../../layout/images/special/wing-menu.jpg");
  background-position: 0 0;
  background-size: 100% auto;
}
.layout-menu-wing .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-wing .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-wing .layout-profile-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-wing .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-wing .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-wing .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-wing .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-wing .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-wing .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-wing .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-skyscraper .layout-menu-container {
  background-image: url("../../../layout/images/special/skyscraper-menu.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-menu-skyscraper .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-skyscraper .layout-menu a i {
  color: #ffffff;
}
.layout-menu-skyscraper .layout-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-skyscraper .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-skyscraper .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-skyscraper .layout-menu > li > .layout-submenu-container {
  background-color: rgba(255, 255, 255, 0.2);
}
.layout-menu-skyscraper .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #FDD835;
}
.layout-menu-skyscraper .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #FDD835;
}
.layout-menu-skyscraper .layout-menu li.active-menuitem > a .menuitem-text {
  color: #FDD835;
}
.layout-menu-skyscraper .layout-menu li.active-menuitem > a i {
  color: #FDD835;
}
.layout-menu-skyscraper .layout-menu > li.active-menuitem > a {
  background-color: #673AB7;
}
.layout-menu-skyscraper .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #ffffff;
}
.layout-menu-skyscraper .layout-menu > li.active-menuitem > a i {
  color: #ffffff;
}
.layout-menu-skyscraper .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-skyscraper.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-skyscraper.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-skyscraper.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-image: url("../../../layout/images/special/skyscraper-menu.jpg");
    background-position: 0 0;
    background-size: 100% auto;
  }
}
.layout-menu-skyscraper .layout-profile-menu {
  background-image: url("../../../layout/images/special/skyscraper-menu.jpg");
  background-position: 0 0;
  background-size: 100% auto;
}
.layout-menu-skyscraper .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-skyscraper .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-skyscraper .layout-profile-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-skyscraper .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-skyscraper .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-skyscraper .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-skyscraper .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-skyscraper .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-skyscraper .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-skyscraper .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-wall .layout-menu-container {
  background-image: url("../../../layout/images/special/wall-menu.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-menu-wall .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-wall .layout-menu a i {
  color: #ffffff;
}
.layout-menu-wall .layout-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-wall .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-wall .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-wall .layout-menu > li > .layout-submenu-container {
  background-color: rgba(0, 0, 0, 0.32);
}
.layout-menu-wall .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #00BCD4;
}
.layout-menu-wall .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #00BCD4;
}
.layout-menu-wall .layout-menu li.active-menuitem > a .menuitem-text {
  color: #00BCD4;
}
.layout-menu-wall .layout-menu li.active-menuitem > a i {
  color: #00BCD4;
}
.layout-menu-wall .layout-menu > li.active-menuitem > a {
  background-color: #00BCD4;
}
.layout-menu-wall .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #ffffff;
}
.layout-menu-wall .layout-menu > li.active-menuitem > a i {
  color: #ffffff;
}
.layout-menu-wall .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-wall.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-wall.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-wall.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-image: url("../../../layout/images/special/wall-menu.jpg");
    background-position: 0 0;
    background-size: 100% auto;
  }
}
.layout-menu-wall .layout-profile-menu {
  background-image: url("../../../layout/images/special/wall-menu.jpg");
  background-position: 0 0;
  background-size: 100% auto;
}
.layout-menu-wall .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-wall .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-wall .layout-profile-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-wall .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-wall .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-wall .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-wall .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-wall .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-wall .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-wall .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-dawn .layout-menu-container {
  background-image: url("../../../layout/images/special/dawn-menu.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-menu-dawn .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-dawn .layout-menu a i {
  color: #ffffff;
}
.layout-menu-dawn .layout-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-dawn .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-dawn .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-dawn .layout-menu > li > .layout-submenu-container {
  background-color: rgba(255, 255, 255, 0.2);
}
.layout-menu-dawn .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #ffc107;
}
.layout-menu-dawn .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #ffc107;
}
.layout-menu-dawn .layout-menu li.active-menuitem > a .menuitem-text {
  color: #ffc107;
}
.layout-menu-dawn .layout-menu li.active-menuitem > a i {
  color: #ffc107;
}
.layout-menu-dawn .layout-menu > li.active-menuitem > a {
  background-color: #0288D1;
}
.layout-menu-dawn .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #ffffff;
}
.layout-menu-dawn .layout-menu > li.active-menuitem > a i {
  color: #ffffff;
}
.layout-menu-dawn .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-dawn.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-dawn.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-dawn.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-image: url("../../../layout/images/special/dawn-menu.jpg");
    background-position: 0 0;
    background-size: 100% auto;
  }
}
.layout-menu-dawn .layout-profile-menu {
  background-image: url("../../../layout/images/special/dawn-menu.jpg");
  background-position: 0 0;
  background-size: 100% auto;
}
.layout-menu-dawn .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-dawn .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-dawn .layout-profile-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-dawn .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-dawn .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-dawn .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-dawn .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-dawn .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-dawn .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-dawn .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-lille .layout-menu-container {
  background-image: url("../../../layout/images/special/lille-menu.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-menu-lille .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-lille .layout-menu a i {
  color: #ffffff;
}
.layout-menu-lille .layout-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-lille .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-lille .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-lille .layout-menu > li > .layout-submenu-container {
  background-color: rgba(255, 255, 255, 0.2);
}
.layout-menu-lille .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #ffc107;
}
.layout-menu-lille .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #ffc107;
}
.layout-menu-lille .layout-menu li.active-menuitem > a .menuitem-text {
  color: #ffc107;
}
.layout-menu-lille .layout-menu li.active-menuitem > a i {
  color: #ffc107;
}
.layout-menu-lille .layout-menu > li.active-menuitem > a {
  background-color: #FDD835;
}
.layout-menu-lille .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #212121;
}
.layout-menu-lille .layout-menu > li.active-menuitem > a i {
  color: #212121;
}
.layout-menu-lille .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-lille.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-lille.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-lille.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-image: url("../../../layout/images/special/lille-menu.jpg");
    background-position: 0 0;
    background-size: 100% auto;
  }
}
.layout-menu-lille .layout-profile-menu {
  background-image: url("../../../layout/images/special/lille-menu.jpg");
  background-position: 0 0;
  background-size: 100% auto;
}
.layout-menu-lille .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-lille .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-lille .layout-profile-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-lille .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-lille .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-lille .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-lille .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-lille .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-lille .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-lille .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-condo .layout-menu-container {
  background-image: url("../../../layout/images/special/condo-menu.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-menu-condo .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-condo .layout-menu a i {
  color: #ffffff;
}
.layout-menu-condo .layout-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-condo .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-condo .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-condo .layout-menu > li > .layout-submenu-container {
  background-color: rgba(0, 0, 0, 0.32);
}
.layout-menu-condo .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #ffc107;
}
.layout-menu-condo .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #ffc107;
}
.layout-menu-condo .layout-menu li.active-menuitem > a .menuitem-text {
  color: #ffc107;
}
.layout-menu-condo .layout-menu li.active-menuitem > a i {
  color: #ffc107;
}
.layout-menu-condo .layout-menu > li.active-menuitem > a {
  background-color: #3F51B5;
}
.layout-menu-condo .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #ffffff;
}
.layout-menu-condo .layout-menu > li.active-menuitem > a i {
  color: #ffffff;
}
.layout-menu-condo .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-condo.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-condo.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-condo.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-image: url("../../../layout/images/special/condo-menu.jpg");
    background-position: 0 0;
    background-size: 100% auto;
  }
}
.layout-menu-condo .layout-profile-menu {
  background-image: url("../../../layout/images/special/condo-menu.jpg");
  background-position: 0 0;
  background-size: 100% auto;
}
.layout-menu-condo .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-condo .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-condo .layout-profile-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-condo .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-condo .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-condo .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-condo .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-condo .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-condo .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-condo .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-waterfall .layout-menu-container {
  background-image: url("../../../layout/images/special/waterfall-menu.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-menu-waterfall .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-waterfall .layout-menu a i {
  color: #ffffff;
}
.layout-menu-waterfall .layout-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-waterfall .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-waterfall .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-waterfall .layout-menu > li > .layout-submenu-container {
  background-color: rgba(255, 255, 255, 0.2);
}
.layout-menu-waterfall .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #FFCA28;
}
.layout-menu-waterfall .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #FFCA28;
}
.layout-menu-waterfall .layout-menu li.active-menuitem > a .menuitem-text {
  color: #FFCA28;
}
.layout-menu-waterfall .layout-menu li.active-menuitem > a i {
  color: #FFCA28;
}
.layout-menu-waterfall .layout-menu > li.active-menuitem > a {
  background-color: #009688;
}
.layout-menu-waterfall .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #ffffff;
}
.layout-menu-waterfall .layout-menu > li.active-menuitem > a i {
  color: #ffffff;
}
.layout-menu-waterfall .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-waterfall.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-waterfall.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-waterfall.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-image: url("../../../layout/images/special/waterfall-menu.jpg");
    background-position: 0 0;
    background-size: 100% auto;
  }
}
.layout-menu-waterfall .layout-profile-menu {
  background-image: url("../../../layout/images/special/waterfall-menu.jpg");
  background-position: 0 0;
  background-size: 100% auto;
}
.layout-menu-waterfall .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-waterfall .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-waterfall .layout-profile-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-waterfall .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-waterfall .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-waterfall .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-waterfall .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-waterfall .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-waterfall .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-waterfall .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-coffee .layout-menu-container {
  background-image: url("../../../layout/images/special/coffee-menu.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-menu-coffee .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-coffee .layout-menu a i {
  color: #ffffff;
}
.layout-menu-coffee .layout-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-coffee .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-coffee .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-coffee .layout-menu > li > .layout-submenu-container {
  background-color: rgba(255, 255, 255, 0.2);
}
.layout-menu-coffee .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #ffc107;
}
.layout-menu-coffee .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #ffc107;
}
.layout-menu-coffee .layout-menu li.active-menuitem > a .menuitem-text {
  color: #ffc107;
}
.layout-menu-coffee .layout-menu li.active-menuitem > a i {
  color: #ffc107;
}
.layout-menu-coffee .layout-menu > li.active-menuitem > a {
  background-color: #FF5722;
}
.layout-menu-coffee .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #ffffff;
}
.layout-menu-coffee .layout-menu > li.active-menuitem > a i {
  color: #ffffff;
}
.layout-menu-coffee .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-coffee.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-coffee.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-coffee.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-image: url("../../../layout/images/special/coffee-menu.jpg");
    background-position: 0 0;
    background-size: 100% auto;
  }
}
.layout-menu-coffee .layout-profile-menu {
  background-image: url("../../../layout/images/special/coffee-menu.jpg");
  background-position: 0 0;
  background-size: 100% auto;
}
.layout-menu-coffee .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-coffee .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-coffee .layout-profile-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-coffee .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-coffee .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-coffee .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-coffee .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-coffee .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-coffee .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-coffee .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-mountain .layout-menu-container {
  background-image: url("../../../layout/images/special/mountain-menu.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-menu-mountain .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-mountain .layout-menu a i {
  color: #ffffff;
}
.layout-menu-mountain .layout-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-mountain .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-mountain .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-mountain .layout-menu > li > .layout-submenu-container {
  background-color: rgba(255, 255, 255, 0.2);
}
.layout-menu-mountain .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #ffc107;
}
.layout-menu-mountain .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #ffc107;
}
.layout-menu-mountain .layout-menu li.active-menuitem > a .menuitem-text {
  color: #ffc107;
}
.layout-menu-mountain .layout-menu li.active-menuitem > a i {
  color: #ffc107;
}
.layout-menu-mountain .layout-menu > li.active-menuitem > a {
  background-color: #0288D1;
}
.layout-menu-mountain .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #ffffff;
}
.layout-menu-mountain .layout-menu > li.active-menuitem > a i {
  color: #ffffff;
}
.layout-menu-mountain .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-mountain.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-mountain.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-mountain.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-image: url("../../../layout/images/special/mountain-menu.jpg");
    background-position: 0 0;
    background-size: 100% auto;
  }
}
.layout-menu-mountain .layout-profile-menu {
  background-image: url("../../../layout/images/special/mountain-menu.jpg");
  background-position: 0 0;
  background-size: 100% auto;
}
.layout-menu-mountain .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-mountain .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-mountain .layout-profile-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-mountain .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-mountain .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-mountain .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-mountain .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-mountain .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-mountain .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-mountain .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-lights .layout-menu-container {
  background-image: url("../../../layout/images/special/lights-menu.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-menu-lights .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-lights .layout-menu a i {
  color: #ffffff;
}
.layout-menu-lights .layout-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-lights .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-lights .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-lights .layout-menu > li > .layout-submenu-container {
  background-color: rgba(255, 255, 255, 0.2);
}
.layout-menu-lights .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #ffc107;
}
.layout-menu-lights .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #ffc107;
}
.layout-menu-lights .layout-menu li.active-menuitem > a .menuitem-text {
  color: #ffc107;
}
.layout-menu-lights .layout-menu li.active-menuitem > a i {
  color: #ffc107;
}
.layout-menu-lights .layout-menu > li.active-menuitem > a {
  background-color: #2196F3;
}
.layout-menu-lights .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #ffffff;
}
.layout-menu-lights .layout-menu > li.active-menuitem > a i {
  color: #ffffff;
}
.layout-menu-lights .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-lights.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-lights.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-lights.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-image: url("../../../layout/images/special/lights-menu.jpg");
    background-position: 0 0;
    background-size: 100% auto;
  }
}
.layout-menu-lights .layout-profile-menu {
  background-image: url("../../../layout/images/special/lights-menu.jpg");
  background-position: 0 0;
  background-size: 100% auto;
}
.layout-menu-lights .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-lights .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-lights .layout-profile-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-lights .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-lights .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-lights .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-lights .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-lights .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-lights .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-lights .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-desert .layout-menu-container {
  background-image: url("../../../layout/images/special/desert-menu.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-menu-desert .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-desert .layout-menu a i {
  color: #ffffff;
}
.layout-menu-desert .layout-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-desert .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-desert .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-desert .layout-menu > li > .layout-submenu-container {
  background-color: rgba(255, 255, 255, 0.2);
}
.layout-menu-desert .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #ffc107;
}
.layout-menu-desert .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #ffc107;
}
.layout-menu-desert .layout-menu li.active-menuitem > a .menuitem-text {
  color: #ffc107;
}
.layout-menu-desert .layout-menu li.active-menuitem > a i {
  color: #ffc107;
}
.layout-menu-desert .layout-menu > li.active-menuitem > a {
  background-color: #FFD54F;
}
.layout-menu-desert .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #212121;
}
.layout-menu-desert .layout-menu > li.active-menuitem > a i {
  color: #212121;
}
.layout-menu-desert .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-desert.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-desert.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-desert.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-image: url("../../../layout/images/special/desert-menu.jpg");
    background-position: 0 0;
    background-size: 100% auto;
  }
}
.layout-menu-desert .layout-profile-menu {
  background-image: url("../../../layout/images/special/desert-menu.jpg");
  background-position: 0 0;
  background-size: 100% auto;
}
.layout-menu-desert .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-desert .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-desert .layout-profile-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-desert .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-desert .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-desert .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-desert .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-desert .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-desert .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-desert .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-beach .layout-menu-container {
  background-image: url("../../../layout/images/special/beach-menu.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-menu-beach .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-beach .layout-menu a i {
  color: #ffffff;
}
.layout-menu-beach .layout-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-beach .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-beach .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-beach .layout-menu > li > .layout-submenu-container {
  background-color: rgba(255, 255, 255, 0.2);
}
.layout-menu-beach .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #ffc107;
}
.layout-menu-beach .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #ffc107;
}
.layout-menu-beach .layout-menu li.active-menuitem > a .menuitem-text {
  color: #ffc107;
}
.layout-menu-beach .layout-menu li.active-menuitem > a i {
  color: #ffc107;
}
.layout-menu-beach .layout-menu > li.active-menuitem > a {
  background-color: #FFC107;
}
.layout-menu-beach .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #212121;
}
.layout-menu-beach .layout-menu > li.active-menuitem > a i {
  color: #212121;
}
.layout-menu-beach .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-beach.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-beach.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-beach.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-image: url("../../../layout/images/special/beach-menu.jpg");
    background-position: 0 0;
    background-size: 100% auto;
  }
}
.layout-menu-beach .layout-profile-menu {
  background-image: url("../../../layout/images/special/beach-menu.jpg");
  background-position: 0 0;
  background-size: 100% auto;
}
.layout-menu-beach .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-beach .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-beach .layout-profile-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-beach .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-beach .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-beach .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-beach .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-beach .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-beach .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-beach .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-classic .layout-menu-container {
  background-image: url("../../../layout/images/special/classic-menu.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-menu-classic .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-classic .layout-menu a i {
  color: #ffffff;
}
.layout-menu-classic .layout-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-classic .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-classic .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-classic .layout-menu > li > .layout-submenu-container {
  background-color: rgba(255, 255, 255, 0.2);
}
.layout-menu-classic .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #FFA726;
}
.layout-menu-classic .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #FFA726;
}
.layout-menu-classic .layout-menu li.active-menuitem > a .menuitem-text {
  color: #FFA726;
}
.layout-menu-classic .layout-menu li.active-menuitem > a i {
  color: #FFA726;
}
.layout-menu-classic .layout-menu > li.active-menuitem > a {
  background-color: #8BC34A;
}
.layout-menu-classic .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #212121;
}
.layout-menu-classic .layout-menu > li.active-menuitem > a i {
  color: #212121;
}
.layout-menu-classic .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-classic.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-classic.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-classic.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-image: url("../../../layout/images/special/classic-menu.jpg");
    background-position: 0 0;
    background-size: 100% auto;
  }
}
.layout-menu-classic .layout-profile-menu {
  background-image: url("../../../layout/images/special/classic-menu.jpg");
  background-position: 0 0;
  background-size: 100% auto;
}
.layout-menu-classic .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-classic .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-classic .layout-profile-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-classic .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-classic .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-classic .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-classic .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-classic .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-classic .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-classic .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-hazy .layout-menu-container {
  background-image: url("../../../layout/images/special/hazy-menu.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-menu-hazy .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-hazy .layout-menu a i {
  color: #ffffff;
}
.layout-menu-hazy .layout-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-hazy .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-hazy .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-hazy .layout-menu > li > .layout-submenu-container {
  background-color: rgba(255, 255, 255, 0.2);
}
.layout-menu-hazy .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #00BCD4;
}
.layout-menu-hazy .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #00BCD4;
}
.layout-menu-hazy .layout-menu li.active-menuitem > a .menuitem-text {
  color: #00BCD4;
}
.layout-menu-hazy .layout-menu li.active-menuitem > a i {
  color: #00BCD4;
}
.layout-menu-hazy .layout-menu > li.active-menuitem > a {
  background-color: #E91E63;
}
.layout-menu-hazy .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #ffffff;
}
.layout-menu-hazy .layout-menu > li.active-menuitem > a i {
  color: #ffffff;
}
.layout-menu-hazy .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-hazy.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-hazy.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-hazy.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-image: url("../../../layout/images/special/hazy-menu.jpg");
    background-position: 0 0;
    background-size: 100% auto;
  }
}
.layout-menu-hazy .layout-profile-menu {
  background-image: url("../../../layout/images/special/hazy-menu.jpg");
  background-position: 0 0;
  background-size: 100% auto;
}
.layout-menu-hazy .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-hazy .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-hazy .layout-profile-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-hazy .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-hazy .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-hazy .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-hazy .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-hazy .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-hazy .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-hazy .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-exposure .layout-menu-container {
  background-image: url("../../../layout/images/special/exposure-menu.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-menu-exposure .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-exposure .layout-menu a i {
  color: #ffffff;
}
.layout-menu-exposure .layout-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-exposure .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-exposure .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-exposure .layout-menu > li > .layout-submenu-container {
  background-color: rgba(255, 255, 255, 0.2);
}
.layout-menu-exposure .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #FFA000;
}
.layout-menu-exposure .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #FFA000;
}
.layout-menu-exposure .layout-menu li.active-menuitem > a .menuitem-text {
  color: #FFA000;
}
.layout-menu-exposure .layout-menu li.active-menuitem > a i {
  color: #FFA000;
}
.layout-menu-exposure .layout-menu > li.active-menuitem > a {
  background-color: #FFA000;
}
.layout-menu-exposure .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #ffffff;
}
.layout-menu-exposure .layout-menu > li.active-menuitem > a i {
  color: #ffffff;
}
.layout-menu-exposure .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-exposure.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-exposure.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-exposure.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-image: url("../../../layout/images/special/exposure-menu.jpg");
    background-position: 0 0;
    background-size: 100% auto;
  }
}
.layout-menu-exposure .layout-profile-menu {
  background-image: url("../../../layout/images/special/exposure-menu.jpg");
  background-position: 0 0;
  background-size: 100% auto;
}
.layout-menu-exposure .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-exposure .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-exposure .layout-profile-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-exposure .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-exposure .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-exposure .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-exposure .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-exposure .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-exposure .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-exposure .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-norge .layout-menu-container {
  background-image: url("../../../layout/images/special/norge-menu.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-menu-norge .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-norge .layout-menu a i {
  color: #ffffff;
}
.layout-menu-norge .layout-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-norge .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-norge .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-norge .layout-menu > li > .layout-submenu-container {
  background-color: rgba(255, 255, 255, 0.2);
}
.layout-menu-norge .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #ffc107;
}
.layout-menu-norge .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #ffc107;
}
.layout-menu-norge .layout-menu li.active-menuitem > a .menuitem-text {
  color: #ffc107;
}
.layout-menu-norge .layout-menu li.active-menuitem > a i {
  color: #ffc107;
}
.layout-menu-norge .layout-menu > li.active-menuitem > a {
  background-color: #3949AB;
}
.layout-menu-norge .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #ffffff;
}
.layout-menu-norge .layout-menu > li.active-menuitem > a i {
  color: #ffffff;
}
.layout-menu-norge .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-norge.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-norge.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-norge.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-image: url("../../../layout/images/special/norge-menu.jpg");
    background-position: 0 0;
    background-size: 100% auto;
  }
}
.layout-menu-norge .layout-profile-menu {
  background-image: url("../../../layout/images/special/norge-menu.jpg");
  background-position: 0 0;
  background-size: 100% auto;
}
.layout-menu-norge .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-norge .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-norge .layout-profile-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-norge .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-norge .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-norge .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-norge .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-norge .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-norge .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-norge .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-island .layout-menu-container {
  background-image: url("../../../layout/images/special/island-menu.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-menu-island .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-island .layout-menu a i {
  color: #ffffff;
}
.layout-menu-island .layout-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-island .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-island .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-island .layout-menu > li > .layout-submenu-container {
  background-color: rgba(255, 255, 255, 0.2);
}
.layout-menu-island .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #ffc107;
}
.layout-menu-island .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #ffc107;
}
.layout-menu-island .layout-menu li.active-menuitem > a .menuitem-text {
  color: #ffc107;
}
.layout-menu-island .layout-menu li.active-menuitem > a i {
  color: #ffc107;
}
.layout-menu-island .layout-menu > li.active-menuitem > a {
  background-color: #00897B;
}
.layout-menu-island .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #ffffff;
}
.layout-menu-island .layout-menu > li.active-menuitem > a i {
  color: #ffffff;
}
.layout-menu-island .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-island.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-island.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-island.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-image: url("../../../layout/images/special/island-menu.jpg");
    background-position: 0 0;
    background-size: 100% auto;
  }
}
.layout-menu-island .layout-profile-menu {
  background-image: url("../../../layout/images/special/island-menu.jpg");
  background-position: 0 0;
  background-size: 100% auto;
}
.layout-menu-island .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-island .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-island .layout-profile-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-island .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-island .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-island .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-island .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-island .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-island .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-island .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-station .layout-menu-container {
  background-image: url("../../../layout/images/special/station-menu.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-menu-station .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-station .layout-menu a i {
  color: #ffffff;
}
.layout-menu-station .layout-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-station .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-station .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-station .layout-menu > li > .layout-submenu-container {
  background-color: rgba(255, 255, 255, 0.2);
}
.layout-menu-station .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #ffc107;
}
.layout-menu-station .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #ffc107;
}
.layout-menu-station .layout-menu li.active-menuitem > a .menuitem-text {
  color: #ffc107;
}
.layout-menu-station .layout-menu li.active-menuitem > a i {
  color: #ffc107;
}
.layout-menu-station .layout-menu > li.active-menuitem > a {
  background-color: #0097A7;
}
.layout-menu-station .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #ffffff;
}
.layout-menu-station .layout-menu > li.active-menuitem > a i {
  color: #ffffff;
}
.layout-menu-station .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-station.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-station.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-station.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-image: url("../../../layout/images/special/station-menu.jpg");
    background-position: 0 0;
    background-size: 100% auto;
  }
}
.layout-menu-station .layout-profile-menu {
  background-image: url("../../../layout/images/special/station-menu.jpg");
  background-position: 0 0;
  background-size: 100% auto;
}
.layout-menu-station .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-station .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-station .layout-profile-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-station .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-station .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-station .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-station .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-station .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-station .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-station .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-fruity .layout-menu-container {
  background-image: url("../../../layout/images/special/fruity-menu.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-menu-fruity .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-fruity .layout-menu a i {
  color: #ffffff;
}
.layout-menu-fruity .layout-menu a:hover {
  background-color: rgba(0, 0, 0, 0.5);
}
.layout-menu-fruity .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-fruity .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-fruity .layout-menu > li > .layout-submenu-container {
  background-color: rgba(0, 0, 0, 0.3);
}
.layout-menu-fruity .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #E65100;
}
.layout-menu-fruity .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #E65100;
}
.layout-menu-fruity .layout-menu li.active-menuitem > a .menuitem-text {
  color: #E65100;
}
.layout-menu-fruity .layout-menu li.active-menuitem > a i {
  color: #E65100;
}
.layout-menu-fruity .layout-menu > li.active-menuitem > a {
  background-color: #E65100;
}
.layout-menu-fruity .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #ffffff;
}
.layout-menu-fruity .layout-menu > li.active-menuitem > a i {
  color: #ffffff;
}
.layout-menu-fruity .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-fruity.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-fruity.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-fruity.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-image: url("../../../layout/images/special/fruity-menu.jpg");
    background-position: 0 0;
    background-size: 100% auto;
  }
}
.layout-menu-fruity .layout-profile-menu {
  background-image: url("../../../layout/images/special/fruity-menu.jpg");
  background-position: 0 0;
  background-size: 100% auto;
}
.layout-menu-fruity .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-fruity .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-fruity .layout-profile-menu a:hover {
  background-color: rgba(0, 0, 0, 0.5);
}
.layout-menu-fruity .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-fruity .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-fruity .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-fruity .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-fruity .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-fruity .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-fruity .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-tropical .layout-menu-container {
  background-image: url("../../../layout/images/special/tropical-menu.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-menu-tropical .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-tropical .layout-menu a i {
  color: #ffffff;
}
.layout-menu-tropical .layout-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-tropical .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-tropical .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-tropical .layout-menu > li > .layout-submenu-container {
  background-color: rgba(255, 255, 255, 0.2);
}
.layout-menu-tropical .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #D4E157;
}
.layout-menu-tropical .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #D4E157;
}
.layout-menu-tropical .layout-menu li.active-menuitem > a .menuitem-text {
  color: #D4E157;
}
.layout-menu-tropical .layout-menu li.active-menuitem > a i {
  color: #D4E157;
}
.layout-menu-tropical .layout-menu > li.active-menuitem > a {
  background-color: #4CAF50;
}
.layout-menu-tropical .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #ffffff;
}
.layout-menu-tropical .layout-menu > li.active-menuitem > a i {
  color: #ffffff;
}
.layout-menu-tropical .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-tropical.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-tropical.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-tropical.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-image: url("../../../layout/images/special/tropical-menu.jpg");
    background-position: 0 0;
    background-size: 100% auto;
  }
}
.layout-menu-tropical .layout-profile-menu {
  background-image: url("../../../layout/images/special/tropical-menu.jpg");
  background-position: 0 0;
  background-size: 100% auto;
}
.layout-menu-tropical .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-tropical .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-tropical .layout-profile-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-tropical .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-tropical .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-tropical .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-tropical .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-tropical .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-tropical .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-tropical .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-beyoglu .layout-menu-container {
  background-image: url("../../../layout/images/special/beyoglu-menu.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-menu-beyoglu .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-beyoglu .layout-menu a i {
  color: #ffffff;
}
.layout-menu-beyoglu .layout-menu a:hover {
  background-color: rgba(0, 0, 0, 0.5);
}
.layout-menu-beyoglu .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-beyoglu .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-beyoglu .layout-menu > li > .layout-submenu-container {
  background-color: rgba(0, 0, 0, 0.32);
}
.layout-menu-beyoglu .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #ffc107;
}
.layout-menu-beyoglu .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #ffc107;
}
.layout-menu-beyoglu .layout-menu li.active-menuitem > a .menuitem-text {
  color: #ffc107;
}
.layout-menu-beyoglu .layout-menu li.active-menuitem > a i {
  color: #ffc107;
}
.layout-menu-beyoglu .layout-menu > li.active-menuitem > a {
  background-color: #F4511E;
}
.layout-menu-beyoglu .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #ffffff;
}
.layout-menu-beyoglu .layout-menu > li.active-menuitem > a i {
  color: #ffffff;
}
.layout-menu-beyoglu .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-beyoglu.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-beyoglu.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-beyoglu.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-image: url("../../../layout/images/special/beyoglu-menu.jpg");
    background-position: 0 0;
    background-size: 100% auto;
  }
}
.layout-menu-beyoglu .layout-profile-menu {
  background-image: url("../../../layout/images/special/beyoglu-menu.jpg");
  background-position: 0 0;
  background-size: 100% auto;
}
.layout-menu-beyoglu .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-beyoglu .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-beyoglu .layout-profile-menu a:hover {
  background-color: rgba(0, 0, 0, 0.5);
}
.layout-menu-beyoglu .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-beyoglu .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-beyoglu .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-beyoglu .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-beyoglu .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-beyoglu .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-beyoglu .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-timelapse .layout-menu-container {
  background-image: url("../../../layout/images/special/timelapse-menu.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-menu-timelapse .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-timelapse .layout-menu a i {
  color: #ffffff;
}
.layout-menu-timelapse .layout-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-timelapse .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-timelapse .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-timelapse .layout-menu > li > .layout-submenu-container {
  background-color: rgba(255, 255, 255, 0.2);
}
.layout-menu-timelapse .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #ffc107;
}
.layout-menu-timelapse .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #ffc107;
}
.layout-menu-timelapse .layout-menu li.active-menuitem > a .menuitem-text {
  color: #ffc107;
}
.layout-menu-timelapse .layout-menu li.active-menuitem > a i {
  color: #ffc107;
}
.layout-menu-timelapse .layout-menu > li.active-menuitem > a {
  background-color: #673AB7;
}
.layout-menu-timelapse .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #ffffff;
}
.layout-menu-timelapse .layout-menu > li.active-menuitem > a i {
  color: #ffffff;
}
.layout-menu-timelapse .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-timelapse.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-timelapse.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-timelapse.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-image: url("../../../layout/images/special/timelapse-menu.jpg");
    background-position: 0 0;
    background-size: 100% auto;
  }
}
.layout-menu-timelapse .layout-profile-menu {
  background-image: url("../../../layout/images/special/timelapse-menu.jpg");
  background-position: 0 0;
  background-size: 100% auto;
}
.layout-menu-timelapse .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-timelapse .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-timelapse .layout-profile-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-timelapse .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-timelapse .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-timelapse .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-timelapse .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-timelapse .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-timelapse .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-timelapse .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-crystal .layout-menu-container {
  background-image: url("../../../layout/images/special/crystal-menu.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-menu-crystal .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-crystal .layout-menu a i {
  color: #ffffff;
}
.layout-menu-crystal .layout-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-crystal .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-crystal .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-crystal .layout-menu > li > .layout-submenu-container {
  background-color: rgba(255, 255, 255, 0.2);
}
.layout-menu-crystal .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #ffc107;
}
.layout-menu-crystal .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #ffc107;
}
.layout-menu-crystal .layout-menu li.active-menuitem > a .menuitem-text {
  color: #ffc107;
}
.layout-menu-crystal .layout-menu li.active-menuitem > a i {
  color: #ffc107;
}
.layout-menu-crystal .layout-menu > li.active-menuitem > a {
  background-color: #0288D1;
}
.layout-menu-crystal .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #ffffff;
}
.layout-menu-crystal .layout-menu > li.active-menuitem > a i {
  color: #ffffff;
}
.layout-menu-crystal .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-crystal.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-crystal.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-crystal.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-image: url("../../../layout/images/special/crystal-menu.jpg");
    background-position: 0 0;
    background-size: 100% auto;
  }
}
.layout-menu-crystal .layout-profile-menu {
  background-image: url("../../../layout/images/special/crystal-menu.jpg");
  background-position: 0 0;
  background-size: 100% auto;
}
.layout-menu-crystal .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-crystal .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-crystal .layout-profile-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-crystal .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-crystal .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-crystal .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-crystal .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-crystal .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-crystal .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-crystal .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-aquarelle .layout-menu-container {
  background-image: url("../../../layout/images/special/aquarelle-menu.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-menu-aquarelle .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-aquarelle .layout-menu a i {
  color: #ffffff;
}
.layout-menu-aquarelle .layout-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-aquarelle .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-aquarelle .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-aquarelle .layout-menu > li > .layout-submenu-container {
  background-color: rgba(255, 255, 255, 0.2);
}
.layout-menu-aquarelle .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #FDD835;
}
.layout-menu-aquarelle .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #FDD835;
}
.layout-menu-aquarelle .layout-menu li.active-menuitem > a .menuitem-text {
  color: #FDD835;
}
.layout-menu-aquarelle .layout-menu li.active-menuitem > a i {
  color: #FDD835;
}
.layout-menu-aquarelle .layout-menu > li.active-menuitem > a {
  background-color: #5E35B1;
}
.layout-menu-aquarelle .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #ffffff;
}
.layout-menu-aquarelle .layout-menu > li.active-menuitem > a i {
  color: #ffffff;
}
.layout-menu-aquarelle .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-aquarelle.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-aquarelle.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-aquarelle.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-image: url("../../../layout/images/special/aquarelle-menu.jpg");
    background-position: 0 0;
    background-size: 100% auto;
  }
}
.layout-menu-aquarelle .layout-profile-menu {
  background-image: url("../../../layout/images/special/aquarelle-menu.jpg");
  background-position: 0 0;
  background-size: 100% auto;
}
.layout-menu-aquarelle .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-aquarelle .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-aquarelle .layout-profile-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-aquarelle .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-aquarelle .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-aquarelle .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-aquarelle .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-aquarelle .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-aquarelle .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-aquarelle .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-canvas .layout-menu-container {
  background-image: url("../../../layout/images/special/canvas-menu.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-menu-canvas .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-canvas .layout-menu a i {
  color: #ffffff;
}
.layout-menu-canvas .layout-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-canvas .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-canvas .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-canvas .layout-menu > li > .layout-submenu-container {
  background-color: rgba(255, 255, 255, 0.2);
}
.layout-menu-canvas .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #ffc107;
}
.layout-menu-canvas .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #ffc107;
}
.layout-menu-canvas .layout-menu li.active-menuitem > a .menuitem-text {
  color: #ffc107;
}
.layout-menu-canvas .layout-menu li.active-menuitem > a i {
  color: #ffc107;
}
.layout-menu-canvas .layout-menu > li.active-menuitem > a {
  background-color: #AD1457;
}
.layout-menu-canvas .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #ffffff;
}
.layout-menu-canvas .layout-menu > li.active-menuitem > a i {
  color: #ffffff;
}
.layout-menu-canvas .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-canvas.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-canvas.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-canvas.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-image: url("../../../layout/images/special/canvas-menu.jpg");
    background-position: 0 0;
    background-size: 100% auto;
  }
}
.layout-menu-canvas .layout-profile-menu {
  background-image: url("../../../layout/images/special/canvas-menu.jpg");
  background-position: 0 0;
  background-size: 100% auto;
}
.layout-menu-canvas .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-canvas .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-canvas .layout-profile-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-canvas .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-canvas .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-canvas .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-canvas .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-canvas .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-canvas .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-canvas .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-olympic .layout-menu-container {
  background-image: url("../../../layout/images/special/olympic-menu.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-menu-olympic .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-olympic .layout-menu a i {
  color: #ffffff;
}
.layout-menu-olympic .layout-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-olympic .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-olympic .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-olympic .layout-menu > li > .layout-submenu-container {
  background-color: rgba(255, 255, 255, 0.2);
}
.layout-menu-olympic .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #ffc107;
}
.layout-menu-olympic .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #ffc107;
}
.layout-menu-olympic .layout-menu li.active-menuitem > a .menuitem-text {
  color: #ffc107;
}
.layout-menu-olympic .layout-menu li.active-menuitem > a i {
  color: #ffc107;
}
.layout-menu-olympic .layout-menu > li.active-menuitem > a {
  background-color: #D81B60;
}
.layout-menu-olympic .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #ffffff;
}
.layout-menu-olympic .layout-menu > li.active-menuitem > a i {
  color: #ffffff;
}
.layout-menu-olympic .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-olympic.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-olympic.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-olympic.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-image: url("../../../layout/images/special/olympic-menu.jpg");
    background-position: 0 0;
    background-size: 100% auto;
  }
}
.layout-menu-olympic .layout-profile-menu {
  background-image: url("../../../layout/images/special/olympic-menu.jpg");
  background-position: 0 0;
  background-size: 100% auto;
}
.layout-menu-olympic .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-olympic .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-olympic .layout-profile-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-olympic .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-olympic .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-olympic .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-olympic .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-olympic .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-olympic .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-olympic .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-circuit .layout-menu-container {
  background-image: url("../../../layout/images/special/circuit-menu.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-menu-circuit .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-circuit .layout-menu a i {
  color: #ffffff;
}
.layout-menu-circuit .layout-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-circuit .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-circuit .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-circuit .layout-menu > li > .layout-submenu-container {
  background-color: rgba(255, 255, 255, 0.2);
}
.layout-menu-circuit .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #FF9800;
}
.layout-menu-circuit .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #FF9800;
}
.layout-menu-circuit .layout-menu li.active-menuitem > a .menuitem-text {
  color: #FF9800;
}
.layout-menu-circuit .layout-menu li.active-menuitem > a i {
  color: #FF9800;
}
.layout-menu-circuit .layout-menu > li.active-menuitem > a {
  background-color: #EF6C00;
}
.layout-menu-circuit .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #ffffff;
}
.layout-menu-circuit .layout-menu > li.active-menuitem > a i {
  color: #ffffff;
}
.layout-menu-circuit .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-circuit.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-circuit.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-circuit.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-image: url("../../../layout/images/special/circuit-menu.jpg");
    background-position: 0 0;
    background-size: 100% auto;
  }
}
.layout-menu-circuit .layout-profile-menu {
  background-image: url("../../../layout/images/special/circuit-menu.jpg");
  background-position: 0 0;
  background-size: 100% auto;
}
.layout-menu-circuit .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-circuit .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-circuit .layout-profile-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-circuit .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-circuit .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-circuit .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-circuit .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-circuit .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-circuit .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-circuit .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-flamingo .layout-menu-container {
  background-image: url("../../../layout/images/special/flamingo-menu.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-menu-flamingo .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-flamingo .layout-menu a i {
  color: #ffffff;
}
.layout-menu-flamingo .layout-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-flamingo .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-flamingo .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-flamingo .layout-menu > li > .layout-submenu-container {
  background-color: rgba(255, 255, 255, 0.2);
}
.layout-menu-flamingo .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #CDDC39;
}
.layout-menu-flamingo .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #CDDC39;
}
.layout-menu-flamingo .layout-menu li.active-menuitem > a .menuitem-text {
  color: #CDDC39;
}
.layout-menu-flamingo .layout-menu li.active-menuitem > a i {
  color: #CDDC39;
}
.layout-menu-flamingo .layout-menu > li.active-menuitem > a {
  background-color: #7CB342;
}
.layout-menu-flamingo .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #ffffff;
}
.layout-menu-flamingo .layout-menu > li.active-menuitem > a i {
  color: #ffffff;
}
.layout-menu-flamingo .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-flamingo.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-flamingo.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-flamingo.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-image: url("../../../layout/images/special/flamingo-menu.jpg");
    background-position: 0 0;
    background-size: 100% auto;
  }
}
.layout-menu-flamingo .layout-profile-menu {
  background-image: url("../../../layout/images/special/flamingo-menu.jpg");
  background-position: 0 0;
  background-size: 100% auto;
}
.layout-menu-flamingo .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-flamingo .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-flamingo .layout-profile-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-flamingo .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-flamingo .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-flamingo .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-flamingo .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-flamingo .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-flamingo .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-flamingo .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-flight .layout-menu-container {
  background-image: url("../../../layout/images/special/flight-menu.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-menu-flight .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-flight .layout-menu a i {
  color: #ffffff;
}
.layout-menu-flight .layout-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-flight .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-flight .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-flight .layout-menu > li > .layout-submenu-container {
  background-color: rgba(255, 255, 255, 0.2);
}
.layout-menu-flight .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #03A9F4;
}
.layout-menu-flight .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #03A9F4;
}
.layout-menu-flight .layout-menu li.active-menuitem > a .menuitem-text {
  color: #03A9F4;
}
.layout-menu-flight .layout-menu li.active-menuitem > a i {
  color: #03A9F4;
}
.layout-menu-flight .layout-menu > li.active-menuitem > a {
  background-color: #0277BD;
}
.layout-menu-flight .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #ffffff;
}
.layout-menu-flight .layout-menu > li.active-menuitem > a i {
  color: #ffffff;
}
.layout-menu-flight .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-flight.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-flight.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-flight.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-image: url("../../../layout/images/special/flight-menu.jpg");
    background-position: 0 0;
    background-size: 100% auto;
  }
}
.layout-menu-flight .layout-profile-menu {
  background-image: url("../../../layout/images/special/flight-menu.jpg");
  background-position: 0 0;
  background-size: 100% auto;
}
.layout-menu-flight .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-flight .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-flight .layout-profile-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-flight .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-flight .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-flight .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-flight .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-flight .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-flight .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-flight .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-tractor .layout-menu-container {
  background-image: url("../../../layout/images/special/tractor-menu.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-menu-tractor .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-tractor .layout-menu a i {
  color: #ffffff;
}
.layout-menu-tractor .layout-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-tractor .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-tractor .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-tractor .layout-menu > li > .layout-submenu-container {
  background-color: rgba(255, 255, 255, 0.2);
}
.layout-menu-tractor .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #D4E157;
}
.layout-menu-tractor .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #D4E157;
}
.layout-menu-tractor .layout-menu li.active-menuitem > a .menuitem-text {
  color: #D4E157;
}
.layout-menu-tractor .layout-menu li.active-menuitem > a i {
  color: #D4E157;
}
.layout-menu-tractor .layout-menu > li.active-menuitem > a {
  background-color: #4CAF50;
}
.layout-menu-tractor .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #ffffff;
}
.layout-menu-tractor .layout-menu > li.active-menuitem > a i {
  color: #ffffff;
}
.layout-menu-tractor .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-tractor.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-tractor.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-tractor.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-image: url("../../../layout/images/special/tractor-menu.jpg");
    background-position: 0 0;
    background-size: 100% auto;
  }
}
.layout-menu-tractor .layout-profile-menu {
  background-image: url("../../../layout/images/special/tractor-menu.jpg");
  background-position: 0 0;
  background-size: 100% auto;
}
.layout-menu-tractor .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-tractor .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-tractor .layout-profile-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-tractor .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-tractor .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-tractor .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-tractor .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-tractor .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-tractor .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-tractor .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-volcano .layout-menu-container {
  background-image: url("../../../layout/images/special/volcano-menu.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-menu-volcano .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-volcano .layout-menu a i {
  color: #ffffff;
}
.layout-menu-volcano .layout-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-volcano .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-volcano .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-volcano .layout-menu > li > .layout-submenu-container {
  background-color: rgba(255, 255, 255, 0.2);
}
.layout-menu-volcano .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #ffc107;
}
.layout-menu-volcano .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #ffc107;
}
.layout-menu-volcano .layout-menu li.active-menuitem > a .menuitem-text {
  color: #ffc107;
}
.layout-menu-volcano .layout-menu li.active-menuitem > a i {
  color: #ffc107;
}
.layout-menu-volcano .layout-menu > li.active-menuitem > a {
  background-color: #90A4AE;
}
.layout-menu-volcano .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #212121;
}
.layout-menu-volcano .layout-menu > li.active-menuitem > a i {
  color: #212121;
}
.layout-menu-volcano .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-volcano.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-volcano.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-volcano.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-image: url("../../../layout/images/special/volcano-menu.jpg");
    background-position: 0 0;
    background-size: 100% auto;
  }
}
.layout-menu-volcano .layout-profile-menu {
  background-image: url("../../../layout/images/special/volcano-menu.jpg");
  background-position: 0 0;
  background-size: 100% auto;
}
.layout-menu-volcano .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-volcano .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-volcano .layout-profile-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-volcano .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-volcano .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-volcano .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-volcano .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-volcano .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-volcano .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-volcano .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-pine .layout-menu-container {
  background-image: url("../../../layout/images/special/pine-menu.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-menu-pine .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-pine .layout-menu a i {
  color: #ffffff;
}
.layout-menu-pine .layout-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-pine .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-pine .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-pine .layout-menu > li > .layout-submenu-container {
  background-color: rgba(255, 255, 255, 0.2);
}
.layout-menu-pine .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #EC407A;
}
.layout-menu-pine .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #EC407A;
}
.layout-menu-pine .layout-menu li.active-menuitem > a .menuitem-text {
  color: #EC407A;
}
.layout-menu-pine .layout-menu li.active-menuitem > a i {
  color: #EC407A;
}
.layout-menu-pine .layout-menu > li.active-menuitem > a {
  background-color: #00897B;
}
.layout-menu-pine .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #ffffff;
}
.layout-menu-pine .layout-menu > li.active-menuitem > a i {
  color: #ffffff;
}
.layout-menu-pine .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-pine.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-pine.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-pine.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-image: url("../../../layout/images/special/pine-menu.jpg");
    background-position: 0 0;
    background-size: 100% auto;
  }
}
.layout-menu-pine .layout-profile-menu {
  background-image: url("../../../layout/images/special/pine-menu.jpg");
  background-position: 0 0;
  background-size: 100% auto;
}
.layout-menu-pine .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-pine .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-pine .layout-profile-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-pine .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-pine .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-pine .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-pine .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-pine .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-pine .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-pine .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-emptiness .layout-menu-container {
  background-image: url("../../../layout/images/special/emptiness-menu.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-menu-emptiness .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-emptiness .layout-menu a i {
  color: #ffffff;
}
.layout-menu-emptiness .layout-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-emptiness .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-emptiness .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-emptiness .layout-menu > li > .layout-submenu-container {
  background-color: rgba(255, 255, 255, 0.2);
}
.layout-menu-emptiness .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #ffc107;
}
.layout-menu-emptiness .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #ffc107;
}
.layout-menu-emptiness .layout-menu li.active-menuitem > a .menuitem-text {
  color: #ffc107;
}
.layout-menu-emptiness .layout-menu li.active-menuitem > a i {
  color: #ffc107;
}
.layout-menu-emptiness .layout-menu > li.active-menuitem > a {
  background-color: #5E35B1;
}
.layout-menu-emptiness .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #ffffff;
}
.layout-menu-emptiness .layout-menu > li.active-menuitem > a i {
  color: #ffffff;
}
.layout-menu-emptiness .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-emptiness.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-emptiness.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-emptiness.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-image: url("../../../layout/images/special/emptiness-menu.jpg");
    background-position: 0 0;
    background-size: 100% auto;
  }
}
.layout-menu-emptiness .layout-profile-menu {
  background-image: url("../../../layout/images/special/emptiness-menu.jpg");
  background-position: 0 0;
  background-size: 100% auto;
}
.layout-menu-emptiness .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-emptiness .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-emptiness .layout-profile-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-emptiness .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-emptiness .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-emptiness .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-emptiness .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-emptiness .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-emptiness .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-emptiness .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-splash .layout-menu-container {
  background-image: url("../../../layout/images/special/splash-menu.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-menu-splash .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-splash .layout-menu a i {
  color: #ffffff;
}
.layout-menu-splash .layout-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-splash .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-splash .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-splash .layout-menu > li > .layout-submenu-container {
  background-color: rgba(255, 255, 255, 0.2);
}
.layout-menu-splash .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #ffc107;
}
.layout-menu-splash .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #ffc107;
}
.layout-menu-splash .layout-menu li.active-menuitem > a .menuitem-text {
  color: #ffc107;
}
.layout-menu-splash .layout-menu li.active-menuitem > a i {
  color: #ffc107;
}
.layout-menu-splash .layout-menu > li.active-menuitem > a {
  background-color: #EC407A;
}
.layout-menu-splash .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #ffffff;
}
.layout-menu-splash .layout-menu > li.active-menuitem > a i {
  color: #ffffff;
}
.layout-menu-splash .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-splash.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-splash.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-splash.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-image: url("../../../layout/images/special/splash-menu.jpg");
    background-position: 0 0;
    background-size: 100% auto;
  }
}
.layout-menu-splash .layout-profile-menu {
  background-image: url("../../../layout/images/special/splash-menu.jpg");
  background-position: 0 0;
  background-size: 100% auto;
}
.layout-menu-splash .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-splash .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-splash .layout-profile-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-splash .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-splash .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-splash .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-splash .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-splash .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-splash .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-splash .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-urban .layout-menu-container {
  background-image: url("../../../layout/images/special/urban-menu.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-menu-urban .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-urban .layout-menu a i {
  color: #ffffff;
}
.layout-menu-urban .layout-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-urban .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-urban .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-urban .layout-menu > li > .layout-submenu-container {
  background-color: rgba(255, 255, 255, 0.2);
}
.layout-menu-urban .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #ffc107;
}
.layout-menu-urban .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #ffc107;
}
.layout-menu-urban .layout-menu li.active-menuitem > a .menuitem-text {
  color: #ffc107;
}
.layout-menu-urban .layout-menu li.active-menuitem > a i {
  color: #ffc107;
}
.layout-menu-urban .layout-menu > li.active-menuitem > a {
  background-color: #26A69A;
}
.layout-menu-urban .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #212121;
}
.layout-menu-urban .layout-menu > li.active-menuitem > a i {
  color: #212121;
}
.layout-menu-urban .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-urban.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-urban.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-urban.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-image: url("../../../layout/images/special/urban-menu.jpg");
    background-position: 0 0;
    background-size: 100% auto;
  }
}
.layout-menu-urban .layout-profile-menu {
  background-image: url("../../../layout/images/special/urban-menu.jpg");
  background-position: 0 0;
  background-size: 100% auto;
}
.layout-menu-urban .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-urban .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-urban .layout-profile-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-urban .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-urban .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-urban .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-urban .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-urban .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-urban .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-urban .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-bloom .layout-menu-container {
  background-image: url("../../../layout/images/special/bloom-menu.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-menu-bloom .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-bloom .layout-menu a i {
  color: #ffffff;
}
.layout-menu-bloom .layout-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-bloom .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-bloom .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-bloom .layout-menu > li > .layout-submenu-container {
  background-color: rgba(255, 255, 255, 0.2);
}
.layout-menu-bloom .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #ffc107;
}
.layout-menu-bloom .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #ffc107;
}
.layout-menu-bloom .layout-menu li.active-menuitem > a .menuitem-text {
  color: #ffc107;
}
.layout-menu-bloom .layout-menu li.active-menuitem > a i {
  color: #ffc107;
}
.layout-menu-bloom .layout-menu > li.active-menuitem > a {
  background-color: #3F51B5;
}
.layout-menu-bloom .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #ffffff;
}
.layout-menu-bloom .layout-menu > li.active-menuitem > a i {
  color: #ffffff;
}
.layout-menu-bloom .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-bloom.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-bloom.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-bloom.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-image: url("../../../layout/images/special/bloom-menu.jpg");
    background-position: 0 0;
    background-size: 100% auto;
  }
}
.layout-menu-bloom .layout-profile-menu {
  background-image: url("../../../layout/images/special/bloom-menu.jpg");
  background-position: 0 0;
  background-size: 100% auto;
}
.layout-menu-bloom .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-bloom .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-bloom .layout-profile-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-bloom .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-bloom .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-bloom .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-bloom .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-bloom .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-bloom .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-bloom .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-tinfoil .layout-menu-container {
  background-image: url("../../../layout/images/special/tinfoil-menu.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-menu-tinfoil .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-tinfoil .layout-menu a i {
  color: #ffffff;
}
.layout-menu-tinfoil .layout-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-tinfoil .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-tinfoil .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-tinfoil .layout-menu > li > .layout-submenu-container {
  background-color: rgba(255, 255, 255, 0.2);
}
.layout-menu-tinfoil .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #FFCA28;
}
.layout-menu-tinfoil .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #FFCA28;
}
.layout-menu-tinfoil .layout-menu li.active-menuitem > a .menuitem-text {
  color: #FFCA28;
}
.layout-menu-tinfoil .layout-menu li.active-menuitem > a i {
  color: #FFCA28;
}
.layout-menu-tinfoil .layout-menu > li.active-menuitem > a {
  background-color: #E91E63;
}
.layout-menu-tinfoil .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #ffffff;
}
.layout-menu-tinfoil .layout-menu > li.active-menuitem > a i {
  color: #ffffff;
}
.layout-menu-tinfoil .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-tinfoil.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-tinfoil.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-tinfoil.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-image: url("../../../layout/images/special/tinfoil-menu.jpg");
    background-position: 0 0;
    background-size: 100% auto;
  }
}
.layout-menu-tinfoil .layout-profile-menu {
  background-image: url("../../../layout/images/special/tinfoil-menu.jpg");
  background-position: 0 0;
  background-size: 100% auto;
}
.layout-menu-tinfoil .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-tinfoil .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-tinfoil .layout-profile-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-tinfoil .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-tinfoil .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-tinfoil .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-tinfoil .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-tinfoil .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-tinfoil .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-tinfoil .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-hallway .layout-menu-container {
  background-image: url("../../../layout/images/special/hallway-menu.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-menu-hallway .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-hallway .layout-menu a i {
  color: #ffffff;
}
.layout-menu-hallway .layout-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-hallway .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-hallway .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-hallway .layout-menu > li > .layout-submenu-container {
  background-color: rgba(255, 255, 255, 0.2);
}
.layout-menu-hallway .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #ffc107;
}
.layout-menu-hallway .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #ffc107;
}
.layout-menu-hallway .layout-menu li.active-menuitem > a .menuitem-text {
  color: #ffc107;
}
.layout-menu-hallway .layout-menu li.active-menuitem > a i {
  color: #ffc107;
}
.layout-menu-hallway .layout-menu > li.active-menuitem > a {
  background-color: #4CAF50;
}
.layout-menu-hallway .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #ffffff;
}
.layout-menu-hallway .layout-menu > li.active-menuitem > a i {
  color: #ffffff;
}
.layout-menu-hallway .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-hallway.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-hallway.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-hallway.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-image: url("../../../layout/images/special/hallway-menu.jpg");
    background-position: 0 0;
    background-size: 100% auto;
  }
}
.layout-menu-hallway .layout-profile-menu {
  background-image: url("../../../layout/images/special/hallway-menu.jpg");
  background-position: 0 0;
  background-size: 100% auto;
}
.layout-menu-hallway .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-hallway .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-hallway .layout-profile-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-hallway .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-hallway .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-hallway .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-hallway .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-hallway .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-hallway .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-hallway .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-seagull .layout-menu-container {
  background-image: url("../../../layout/images/special/seagull-menu.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-menu-seagull .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-seagull .layout-menu a i {
  color: #ffffff;
}
.layout-menu-seagull .layout-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-seagull .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-seagull .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-seagull .layout-menu > li > .layout-submenu-container {
  background-color: rgba(255, 255, 255, 0.2);
}
.layout-menu-seagull .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #FFA726;
}
.layout-menu-seagull .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #FFA726;
}
.layout-menu-seagull .layout-menu li.active-menuitem > a .menuitem-text {
  color: #FFA726;
}
.layout-menu-seagull .layout-menu li.active-menuitem > a i {
  color: #FFA726;
}
.layout-menu-seagull .layout-menu > li.active-menuitem > a {
  background-color: #FFA726;
}
.layout-menu-seagull .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #212121;
}
.layout-menu-seagull .layout-menu > li.active-menuitem > a i {
  color: #212121;
}
.layout-menu-seagull .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-seagull.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-seagull.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-seagull.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-image: url("../../../layout/images/special/seagull-menu.jpg");
    background-position: 0 0;
    background-size: 100% auto;
  }
}
.layout-menu-seagull .layout-profile-menu {
  background-image: url("../../../layout/images/special/seagull-menu.jpg");
  background-position: 0 0;
  background-size: 100% auto;
}
.layout-menu-seagull .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-seagull .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-seagull .layout-profile-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-seagull .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-seagull .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-seagull .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-seagull .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-seagull .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-seagull .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-seagull .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-city .layout-menu-container {
  background-image: url("../../../layout/images/special/city-menu.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-menu-city .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-city .layout-menu a i {
  color: #ffffff;
}
.layout-menu-city .layout-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-city .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-city .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-city .layout-menu > li > .layout-submenu-container {
  background-color: rgba(255, 255, 255, 0.2);
}
.layout-menu-city .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #D4E157;
}
.layout-menu-city .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #D4E157;
}
.layout-menu-city .layout-menu li.active-menuitem > a .menuitem-text {
  color: #D4E157;
}
.layout-menu-city .layout-menu li.active-menuitem > a i {
  color: #D4E157;
}
.layout-menu-city .layout-menu > li.active-menuitem > a {
  background-color: #4CAF50;
}
.layout-menu-city .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #ffffff;
}
.layout-menu-city .layout-menu > li.active-menuitem > a i {
  color: #ffffff;
}
.layout-menu-city .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-city.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-city.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-city.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-image: url("../../../layout/images/special/city-menu.jpg");
    background-position: 0 0;
    background-size: 100% auto;
  }
}
.layout-menu-city .layout-profile-menu {
  background-image: url("../../../layout/images/special/city-menu.jpg");
  background-position: 0 0;
  background-size: 100% auto;
}
.layout-menu-city .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-city .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-city .layout-profile-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-city .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-city .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-city .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-city .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-city .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-city .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-city .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-jet .layout-menu-container {
  background-image: url("../../../layout/images/special/jet-menu.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-menu-jet .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-jet .layout-menu a i {
  color: #ffffff;
}
.layout-menu-jet .layout-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-jet .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-jet .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-jet .layout-menu > li > .layout-submenu-container {
  background-color: rgba(255, 255, 255, 0.2);
}
.layout-menu-jet .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #ffc107;
}
.layout-menu-jet .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #ffc107;
}
.layout-menu-jet .layout-menu li.active-menuitem > a .menuitem-text {
  color: #ffc107;
}
.layout-menu-jet .layout-menu li.active-menuitem > a i {
  color: #ffc107;
}
.layout-menu-jet .layout-menu > li.active-menuitem > a {
  background-color: #26C6DA;
}
.layout-menu-jet .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #212121;
}
.layout-menu-jet .layout-menu > li.active-menuitem > a i {
  color: #212121;
}
.layout-menu-jet .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-jet.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-jet.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-jet.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-image: url("../../../layout/images/special/jet-menu.jpg");
    background-position: 0 0;
    background-size: 100% auto;
  }
}
.layout-menu-jet .layout-profile-menu {
  background-image: url("../../../layout/images/special/jet-menu.jpg");
  background-position: 0 0;
  background-size: 100% auto;
}
.layout-menu-jet .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-jet .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-jet .layout-profile-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-jet .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-jet .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-jet .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-jet .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-jet .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-jet .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-jet .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-louisville .layout-menu-container {
  background-image: url("../../../layout/images/special/louisville-menu.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-menu-louisville .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-louisville .layout-menu a i {
  color: #ffffff;
}
.layout-menu-louisville .layout-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-louisville .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-louisville .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-louisville .layout-menu > li > .layout-submenu-container {
  background-color: rgba(255, 255, 255, 0.2);
}
.layout-menu-louisville .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #ffc107;
}
.layout-menu-louisville .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #ffc107;
}
.layout-menu-louisville .layout-menu li.active-menuitem > a .menuitem-text {
  color: #ffc107;
}
.layout-menu-louisville .layout-menu li.active-menuitem > a i {
  color: #ffc107;
}
.layout-menu-louisville .layout-menu > li.active-menuitem > a {
  background-color: #FF9800;
}
.layout-menu-louisville .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #ffffff;
}
.layout-menu-louisville .layout-menu > li.active-menuitem > a i {
  color: #ffffff;
}
.layout-menu-louisville .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-louisville.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-louisville.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-louisville.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-image: url("../../../layout/images/special/louisville-menu.jpg");
    background-position: 0 0;
    background-size: 100% auto;
  }
}
.layout-menu-louisville .layout-profile-menu {
  background-image: url("../../../layout/images/special/louisville-menu.jpg");
  background-position: 0 0;
  background-size: 100% auto;
}
.layout-menu-louisville .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-louisville .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-louisville .layout-profile-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-louisville .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-louisville .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-louisville .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-louisville .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-louisville .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-louisville .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-louisville .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-spray .layout-menu-container {
  background-image: url("../../../layout/images/special/spray-menu.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-menu-spray .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-spray .layout-menu a i {
  color: #ffffff;
}
.layout-menu-spray .layout-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-spray .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-spray .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-spray .layout-menu > li > .layout-submenu-container {
  background-color: rgba(255, 255, 255, 0.2);
}
.layout-menu-spray .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #ffc107;
}
.layout-menu-spray .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #ffc107;
}
.layout-menu-spray .layout-menu li.active-menuitem > a .menuitem-text {
  color: #ffc107;
}
.layout-menu-spray .layout-menu li.active-menuitem > a i {
  color: #ffc107;
}
.layout-menu-spray .layout-menu > li.active-menuitem > a {
  background-color: #0097A7;
}
.layout-menu-spray .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #ffffff;
}
.layout-menu-spray .layout-menu > li.active-menuitem > a i {
  color: #ffffff;
}
.layout-menu-spray .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-spray.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-spray.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-spray.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-image: url("../../../layout/images/special/spray-menu.jpg");
    background-position: 0 0;
    background-size: 100% auto;
  }
}
.layout-menu-spray .layout-profile-menu {
  background-image: url("../../../layout/images/special/spray-menu.jpg");
  background-position: 0 0;
  background-size: 100% auto;
}
.layout-menu-spray .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-spray .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-spray .layout-profile-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-spray .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-spray .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-spray .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-spray .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-spray .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-spray .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-spray .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-symmetry .layout-menu-container {
  background-image: url("../../../layout/images/special/symmetry-menu.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-menu-symmetry .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-symmetry .layout-menu a i {
  color: #ffffff;
}
.layout-menu-symmetry .layout-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-symmetry .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-symmetry .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-symmetry .layout-menu > li > .layout-submenu-container {
  background-color: rgba(255, 255, 255, 0.2);
}
.layout-menu-symmetry .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #00BCD4;
}
.layout-menu-symmetry .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #00BCD4;
}
.layout-menu-symmetry .layout-menu li.active-menuitem > a .menuitem-text {
  color: #00BCD4;
}
.layout-menu-symmetry .layout-menu li.active-menuitem > a i {
  color: #00BCD4;
}
.layout-menu-symmetry .layout-menu > li.active-menuitem > a {
  background-color: #795548;
}
.layout-menu-symmetry .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #ffffff;
}
.layout-menu-symmetry .layout-menu > li.active-menuitem > a i {
  color: #ffffff;
}
.layout-menu-symmetry .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-symmetry.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-symmetry.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-symmetry.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-image: url("../../../layout/images/special/symmetry-menu.jpg");
    background-position: 0 0;
    background-size: 100% auto;
  }
}
.layout-menu-symmetry .layout-profile-menu {
  background-image: url("../../../layout/images/special/symmetry-menu.jpg");
  background-position: 0 0;
  background-size: 100% auto;
}
.layout-menu-symmetry .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-symmetry .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-symmetry .layout-profile-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-symmetry .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-symmetry .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-symmetry .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-symmetry .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-symmetry .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-symmetry .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-symmetry .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-menu-destination .layout-menu-container {
  background-image: url("../../../layout/images/special/destination-menu.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-menu-destination .layout-menu a .menuitem-text {
  color: #ffffff;
}
.layout-menu-destination .layout-menu a i {
  color: #ffffff;
}
.layout-menu-destination .layout-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-destination .layout-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-destination .layout-menu a:hover i {
  color: #ffffff;
}
.layout-menu-destination .layout-menu > li > .layout-submenu-container {
  background-color: rgba(255, 255, 255, 0.2);
}
.layout-menu-destination .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink .menuitem-text {
  color: #ffc107;
}
.layout-menu-destination .layout-menu li.active-menuitem:not(:first-child) a.active-menuitem-routerlink i {
  color: #ffc107;
}
.layout-menu-destination .layout-menu li.active-menuitem > a .menuitem-text {
  color: #ffc107;
}
.layout-menu-destination .layout-menu li.active-menuitem > a i {
  color: #ffc107;
}
.layout-menu-destination .layout-menu > li.active-menuitem > a {
  background-color: #00BCD4;
}
.layout-menu-destination .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #212121;
}
.layout-menu-destination .layout-menu > li.active-menuitem > a i {
  color: #212121;
}
.layout-menu-destination .layout-menu .layout-megamenu-submenu-text {
  color: #ffffff;
}
@media screen and (min-width: 641px) {
  .layout-menu-destination.layout-menu-horizontal .layout-menu-container {
    background-image: none;
    background-color: transparent;
  }
  .layout-menu-destination.layout-menu-horizontal .layout-menu .layout-megamenu-submenu-text {
    color: #ffffff;
  }
  .layout-menu-destination.layout-menu-horizontal .layout-menu > li > .layout-submenu-container {
    background-image: url("../../../layout/images/special/destination-menu.jpg");
    background-position: 0 0;
    background-size: 100% auto;
  }
}
.layout-menu-destination .layout-profile-menu {
  background-image: url("../../../layout/images/special/destination-menu.jpg");
  background-position: 0 0;
  background-size: 100% auto;
}
.layout-menu-destination .layout-profile-menu a span {
  color: #ffffff;
}
.layout-menu-destination .layout-profile-menu a i {
  color: #ffffff;
}
.layout-menu-destination .layout-profile-menu a:hover {
  background-color: rgba(255, 255, 255, 0.32);
}
.layout-menu-destination .layout-profile-menu a:hover .menuitem-text {
  color: #ffffff;
}
.layout-menu-destination .layout-profile-menu a:hover i {
  color: #ffffff;
}
.layout-menu-destination .layout-profile-menu .layout-profile-menu-search .ui-inputtext {
  color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
}
.layout-menu-destination .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ label, .layout-menu-destination .layout-profile-menu .layout-profile-menu-search .ui-inputtext:focus ~ i {
  color: #ffffff;
}
.layout-menu-destination .layout-profile-menu .layout-profile-menu-search i {
  color: #ffffff;
}
.layout-menu-destination .layout-profile-menu .layout-profile-menu-search label {
  color: #ffffff;
}

.layout-breadcrumb {
  -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.03), 0 2px 5px 0 rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.03), 0 2px 5px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.03), 0 2px 5px 0 rgba(0, 0, 0, 0.05);
}
.layout-breadcrumb ul {
  list-style-type: none;
  padding: 0.75em;
  margin: 0;
}
.layout-breadcrumb li {
  display: inline-block;
  vertical-align: middle;
}
.layout-breadcrumb li:nth-child(2n) i {
  font-size: 14px;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.layout-breadcrumb li i {
  font-size: 20px;
  vertical-align: middle;
}

@media screen and (max-width: 640px) {
  .layout-breadcrumb ul {
    padding-left: 1em;
  }
}
.layout-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.layout-content {
  padding: 0 1rem;
  flex: 1 1 0;
}

.layout-mask {
  background-color: #252529;
  position: fixed;
  top: 68px;
  left: 0;
  z-index: 998;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 640px) {
  .layout-content {
    padding: 0 1em;
    margin-top: -120px;
  }
}
.layout-footer {
  background-color: #212121;
  color: #9f9f9f;
  padding: 0 1rem;
  margin-top: 3em;
}
.layout-footer .layout-footer-appname {
  margin-top: 0.5em;
}
.layout-footer .p-col {
  padding: 2em 0.5em;
}
.layout-footer .p-col:last-child {
  text-align: right;
}
.layout-footer .p-col:last-child span {
  vertical-align: middle;
}

h1, h2, h3, h4, h5, h6 {
  margin: 1.5rem 0 1rem 0;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
}
h1:first-child, h2:first-child, h3:first-child, h4:first-child, h5:first-child, h6:first-child {
  margin-top: 0;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

mark {
  background: #FFF8E1;
  padding: 0.25rem 0.4rem;
  border-radius: 2px;
  font-family: monospace;
}

blockquote {
  margin: 1rem 0;
  padding: 0 2rem;
  border-left: 4px solid #90A4AE;
}

hr {
  border-top: solid #dbdbdb;
  border-width: 1px 0 0 0;
  margin: 1rem 0;
}

p {
  margin: 0 0 1rem 0;
  line-height: 1.5;
}
p:last-child {
  margin-bottom: 0;
}

.widget-overview-box .overview-numbers {
  font-size: 16px;
  color: #4c87c9;
  font-weight: 700;
  margin: 0.5em 0 1em 0;
}
.widget-overview-box.monthly-sales img {
  width: 100%;
  margin-top: 1em;
}
.widget-overview-box.total-views img {
  width: 100%;
  margin-top: 4em;
}
.widget-overview-box.connections .legend-arrow {
  color: #4caf50;
}
.widget-overview-box.connections .dot {
  margin-right: 0.5em;
  vertical-align: middle;
}
.widget-overview-box.connections .connection-chart {
  text-align: center;
  padding-top: 2em;
}
.widget-overview-box.connections .connection-chart-legend {
  padding-left: 4%;
  padding-top: 1.5em;
  color: #616161;
}

.widget-summary-box {
  position: relative;
}
.widget-summary-box .card {
  min-height: 100px;
}
.widget-summary-box .summary-box-title {
  font-size: 16px;
  margin: 0;
  color: #ffffff;
}
.widget-summary-box p {
  margin: 0.25em 0;
  color: #ffffff;
}
.widget-summary-box .summary-box-icon {
  position: absolute;
  right: 24px;
  top: 32px;
}
.widget-summary-box .summary-box-graph {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.widget-summary-box.summary-box-messages .card {
  border-top: 4px solid #0097a7;
  background-color: #00bcd4;
}
.widget-summary-box.summary-box-checkins .card {
  border-top: 4px solid #ad1457;
  background-color: #e91e63;
}
.widget-summary-box.summary-box-filessynced .card {
  border-top: 4px solid #f47b08;
  background-color: #ffb300;
}
.widget-summary-box.summary-box-usersonline .card {
  border-top: 4px solid #455a64;
  background-color: #607d8b;
}

.widget-team .dot {
  height: 0.5em;
  width: 0.5em;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  display: inline-block;
}
.widget-team .dot.dot-green {
  background-color: #4caf50;
}
.widget-team .dot.dot-red {
  background-color: #e91e63;
}
.widget-team .dot.dot-orange {
  background-color: #ff9800;
}
.widget-team ul {
  list-style-type: none;
  margin: 0.25em 0 0 0;
  padding: 0;
}
.widget-team ul li {
  border-bottom: 1px solid #e4e4e4;
}
.widget-team ul li:last-child {
  border-bottom: 0 none;
}
.widget-team img {
  width: 40px;
  height: 40px;
}
.widget-team .member-content {
  padding: 1em;
}
.widget-team .member-name {
  margin-bottom: 0.5em;
}
.widget-team button:last-child {
  margin-left: 0.5em;
}
.widget-team .member-status {
  color: #616161;
}
.widget-team .member-status span {
  vertical-align: middle;
}
.widget-team .dot {
  margin-right: 0.3em;
  vertical-align: middle;
}

.widget-timeline .icon-container {
  width: 48px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  background-color: #eeeeee;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.widget-timeline .icon-container i {
  color: #9e9e9e;
  font-size: 20px;
  line-height: inherit;
}
.widget-timeline h3 {
  margin: 0;
  font-size: 16px;
}
.widget-timeline ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.widget-timeline li {
  padding: 1em 0;
  border-bottom: 1px solid #e4e4e4;
}
.widget-timeline li:last-child {
  border-bottom: 0 none;
}
.widget-timeline img {
  width: 100%;
}
.widget-timeline .p-nogutter .p-col-fixed {
  padding: 0 1em 0.5em 0;
}
.widget-timeline .event-owner {
  color: #616161;
  margin-bottom: 0.5em;
}
.widget-timeline .event-detail {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.87);
}
.widget-timeline .timeline-clock {
  display: inline-block;
  margin-top: 0.5em;
  background-color: #eeeeee;
  color: #616161;
  padding: 0.5em;
  font-size: 13px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
}
.widget-timeline .timeline-clock i {
  margin-right: 7px;
  vertical-align: middle;
}
.widget-timeline .timeline-clock span {
  vertical-align: middle;
}

.widget-location {
  padding: 0;
  margin-top: 1em;
}
.widget-location .location-content {
  padding: 1em;
}
.widget-location .location-name {
  margin-bottom: 0.5em;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.2px;
}
.widget-location p {
  margin: 0.75em 0;
  color: #616161;
}
.widget-location img {
  width: 100%;
}
.widget-location a {
  color: #00bcd4;
}
.widget-location a:hover {
  color: #0097a7;
}

.widget-userprofile {
  padding: 0;
  height: 100%;
}
.widget-userprofile .userprofile-bg {
  width: 100%;
}
.widget-userprofile .userprofile-avatar {
  border: 4px solid #ffffff;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  margin: 0 auto;
  width: 96px;
  height: 96px;
  display: block;
  margin-top: -48px;
}
.widget-userprofile .userprofile-name {
  text-align: center;
  margin: 0.5em 0;
}
.widget-userprofile .userprofile-role {
  color: #616161;
  text-align: center;
  margin-bottom: 2em;
  font-size: 12px;
  letter-spacing: 0.4px;
}
.widget-userprofile .userprofile-circle {
  width: 16px;
  height: 16px;
  background-color: #ffffff;
  border-width: 4px;
  border-style: solid;
  margin: 0 auto;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  margin-bottom: 0.5em;
}
.widget-userprofile .userprofile-status {
  color: #616161;
  letter-spacing: 2px;
  font-size: 12px;
}
.widget-userprofile .userprofile-value {
  margin-top: 0.5em;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.2px;
}
.widget-userprofile .userprofile-legend {
  text-align: center;
}
.widget-userprofile .userprofile-completed .userprofile-circle {
  border-color: #00bcd4;
}
.widget-userprofile .userprofile-snoozed .userprofile-circle {
  border-color: #ffc107;
}
.widget-userprofile .userprofile-overdue .userprofile-circle {
  border-color: #e91e63;
}

.widget-weather {
  padding: 0;
}
.widget-weather .weather-image {
  background-image: url("../../layout/images/dashboard/primecity.jpg");
  background-size: cover;
  text-align: center;
  color: rgba(255, 255, 255, 0.87);
  min-height: 250px;
  padding: 1em;
}
.widget-weather .weather-image .city-name {
  font-size: 24px;
  margin-bottom: 40px;
}
.widget-weather .weather-image .weather-today {
  font-size: 20px;
  margin-bottom: 8px;
}
.widget-weather .weather-image .weather-degree {
  font-size: 40px;
}
.widget-weather .weekdays {
  margin: 1em;
  margin-bottom: 0;
}
.widget-weather .weekdays > div {
  padding: 0.857em;
}
.widget-weather .weather-degree-col {
  text-align: right;
}
.widget-weather .weather-degree-col img {
  vertical-align: middle;
  margin-left: 0.5em;
}

.widget-pricing-box {
  width: 100%;
  max-height: 358px;
  text-align: left;
  background-color: #fafafa;
  border-left: 6px solid rgba(97, 97, 97, 0.07);
  border-radius: 4px;
  box-shadow: 0 5px 6px -3px rgba(0, 0, 0, 0.2), 0 3px 16px 2px rgba(0, 0, 0, 0.12), 0 9px 12px 1px rgba(0, 0, 0, 0.14);
}
.widget-pricing-box .pricing-header {
  padding: 24px 24px 18px 24px;
  text-align: center;
}
.widget-pricing-box .pricing-header h3 {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.3px;
  color: #212121;
  margin: 0;
}
.widget-pricing-box .pricing-header .pricing-fee {
  font-size: 20px;
  letter-spacing: 0.3px;
  color: #616161;
  margin: 0;
}
.widget-pricing-box .pricing-header .pricing-fee b {
  margin: 0;
  font-size: 34px;
  font-weight: 500;
  letter-spacing: 0.3px;
  color: #212121;
  text-align: center;
}
.widget-pricing-box .pricing-content {
  padding: 0 20px 19px 20px;
  min-height: 228px;
  position: relative;
}
.widget-pricing-box .pricing-content ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.widget-pricing-box .pricing-content ul li {
  padding: 12px 0 0 0;
  font-size: 16px;
  letter-spacing: 0.3px;
  color: #212121;
}
.widget-pricing-box .pricing-content ul li i {
  color: #43a047;
  font-size: 18px;
  vertical-align: middle;
  margin-right: 6px;
}
.widget-pricing-box .pricing-content button {
  background-color: #457fca;
  position: absolute;
  min-width: 136px;
  bottom: 20px;
  left: 50%;
  margin-left: -68px;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
}
.widget-pricing-box .pricing-content button:enabled:hover {
  background-color: #1565c0;
}

.help-page p {
  margin: 0;
}
.help-page .help-search {
  background: url("../../layout/images/pages/bg-help.png") repeat;
  background-color: #f4f4f7;
  padding: 0;
  text-align: center;
}
.help-page .help-search .help-search-content {
  padding: 5rem 12rem;
}
.help-page .help-search .search-container {
  font-size: 1.5rem;
  padding: 1rem;
  position: relative;
}
.help-page .help-search .search-container input {
  appearance: none;
  font-size: 1.5rem;
  text-indent: 2.5rem;
  padding: 0.5rem;
  width: 100%;
}
.help-page .help-search .search-container i {
  color: #212121;
  width: 2rem;
  position: absolute;
  margin-left: 1rem;
  top: 50%;
  margin-top: -0.5rem;
}
.help-page .status-bars {
  margin-top: 1rem;
  display: -ms-flexbox;
  display: flex;
}
.help-page .status-bars .status-bar {
  flex: 1 1 0;
  -ms-flex: 1 1 0px;
  background: #8BC34A;
  height: 50px;
  margin-right: 0.25rem;
  transition: transform 0.2s;
}
.help-page .status-bars .status-bar:last-child {
  margin-right: 0;
}
.help-page .status-bars .status-bar.status-bar-failure {
  background: #EC407A;
}
.help-page .status-bars .status-bar:hover {
  transform: scale(1.1);
}
.help-page .status-bar-footer {
  padding: 1rem 0 0 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.help-page .blog-post {
  height: 150px;
  border-radius: 4px;
  margin: 3rem 2rem;
  position: relative;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.help-page .blog-post:last-child {
  margin-bottom: 1rem;
}
.help-page .blog-post img {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.help-page .blog-post .blog-text {
  position: absolute;
  left: 20px;
  top: 30px;
}
.help-page .blog-post .blog-text h1 {
  font-size: 1.25rem;
  color: #ffffff;
  margin-bottom: 1rem;
  font-weight: 700;
}
.help-page .blog-post .blog-text span {
  color: #ffffff;
  font-weight: 600;
}
.help-page .blog-post .blog-profile {
  position: absolute;
  top: -25px;
  left: -25px;
}
.help-page .blog-post .blog-profile img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.help-page .blog-post:nth-child(1) {
  background-image: url("../../layout/images/pages/help/blog1.jpg");
}
.help-page .blog-post:nth-child(2) {
  background-image: url("../../layout/images/pages/help/blog2.jpg");
}
.help-page .blog-post:nth-child(3) {
  background-image: url("../../layout/images/pages/help/blog3.jpg");
}

@media screen and (max-width: 768px) {
  .help-page .help-search .help-search-content {
    padding: 6rem 2rem;
  }
}
.invoice {
  padding: 2rem;
}
.invoice .invoice-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.invoice .invoice-company .logo-image {
  width: 120px;
  margin-bottom: 0.5rem;
}
.invoice .invoice-company div {
  margin-bottom: 0.5rem;
}
.invoice .invoice-company .company-name {
  font-weight: 700;
  font-size: 1.5rem;
}
.invoice .invoice-title {
  font-size: 2rem;
  margin-bottom: 2rem;
  text-align: right;
}
.invoice .invoice-details {
  width: 15rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.invoice .invoice-details > div {
  width: 50%;
  margin-bottom: 0.5rem;
}
.invoice .invoice-details .invoice-label {
  text-align: left;
  font-weight: 700;
}
.invoice .invoice-details .invoice-value {
  text-align: right;
}
.invoice .invoice-to {
  margin-top: 1.5rem;
  padding-top: 2rem;
  border-top: 1px solid #dbdbdb;
}
.invoice .invoice-to .bill-to {
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}
.invoice .invoice-to .invoice-to-info div {
  margin-bottom: 0.5rem;
}
.invoice .invoice-items {
  margin-top: 2rem;
  padding-top: 2rem;
}
.invoice .invoice-items table {
  width: 100%;
  border-collapse: collapse;
}
.invoice .invoice-items table tr {
  border-bottom: 1px solid #dbdbdb;
}
.invoice .invoice-items table th {
  font-weight: 700;
}
.invoice .invoice-items table th, .invoice .invoice-items table td {
  padding: 1rem;
  text-align: right;
}
.invoice .invoice-items table th:first-child, .invoice .invoice-items table td:first-child {
  text-align: left;
}
.invoice .invoice-summary {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 2.5rem;
  padding-top: 2.5rem;
}
.invoice .invoice-summary .invoice-value {
  font-weight: 700;
}

@media print {
  body * {
    visibility: hidden;
  }

  #invoice-content * {
    visibility: visible;
  }

  #invoice-content {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    padding: 0;
    margin: 0;
  }
}
/* Add your customizations of layout here */
@font-face {
  font-family: "Lato";
  src: local("Lato"), url("/assets/fonts/Lato/Lato-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Raleway";
  src: local("Raleway"), url("/assets/fonts/Raleway/Raleway-VariableFont_wght.ttf") format("truetype");
}
html,
body {
  font-size: 14px;
  @apply min-h-screen dark:text-white font-sans w-full overflow-x-hidden;
}

body {
  @apply relative;
}

h1 {
  @apply text-3xl font-raleway;
}

h1 {
  @apply font-bold font-raleway;
}

h2 {
  @apply font-bold font-raleway;
}

h3 {
  @apply font-bold font-raleway;
}

h4 {
  @apply font-bold font-raleway;
}

h5 {
  @apply font-bold font-raleway;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 1.5rem 0 1rem 0;
  line-height: 1.2;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

hr {
  @apply my-4 md:my-8;
}

label {
  @apply font-raleway;
}

a {
  @apply text-blue-600 dark:text-blue-400;
}

a:hover {
  @apply underline;
}

router-outlet {
  display: none;
}

.ng-invalid.ng-touched {
  @apply border-red-600;
}

.btn {
  @apply px-4 py-2 rounded-md;
}

.alert {
  @apply pt-2 text-red-500 italic visible;
}

.mat-dialog-container {
  @apply dark:bg-gray-700;
}

.mat-expansion-panel-header .mat-expansion-indicator::after {
  @apply border-gray-400;
}

.mat-accordion .mat-expansion-panel-header-description {
  @apply dark:text-gray-200;
}
.mat-accordion mat-expansion-panel.mat-expansion-panel:not(.mat-expanded),
.mat-accordion mat-expansion-panel.mat-expansion-panel:not(.mat-expansion-panel-spacing),
.mat-accordion mat-expansion-panel.mat-expansion-panel:is(.mat-expanded),
.mat-accordion mat-expansion-panel.mat-expansion-panel:is(.mat-expansion-panel-spacing) {
  @apply rounded-lg mb-4 shadow-md max-w-full dark:bg-gray-700;
}
.mat-accordion mat-expansion-panel.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header,
.mat-accordion mat-expansion-panel.mat-expansion-panel:not(.mat-expansion-panel-spacing) .mat-expansion-panel-header,
.mat-accordion mat-expansion-panel.mat-expansion-panel:is(.mat-expanded) .mat-expansion-panel-header,
.mat-accordion mat-expansion-panel.mat-expansion-panel:is(.mat-expansion-panel-spacing) .mat-expansion-panel-header {
  @apply p-4 md:p-6 rounded-lg;
}
.mat-accordion mat-expansion-panel.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-content .mat-expansion-panel-body,
.mat-accordion mat-expansion-panel.mat-expansion-panel:not(.mat-expansion-panel-spacing) .mat-expansion-panel-content .mat-expansion-panel-body,
.mat-accordion mat-expansion-panel.mat-expansion-panel:is(.mat-expanded) .mat-expansion-panel-content .mat-expansion-panel-body,
.mat-accordion mat-expansion-panel.mat-expansion-panel:is(.mat-expansion-panel-spacing) .mat-expansion-panel-content .mat-expansion-panel-body {
  @apply p-4 md:p-6;
}
.mat-accordion mat-expansion-panel.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header-description,
.mat-accordion mat-expansion-panel.mat-expansion-panel:not(.mat-expansion-panel-spacing) .mat-expansion-panel-header-description,
.mat-accordion mat-expansion-panel.mat-expansion-panel:is(.mat-expanded) .mat-expansion-panel-header-description,
.mat-accordion mat-expansion-panel.mat-expansion-panel:is(.mat-expansion-panel-spacing) .mat-expansion-panel-header-description {
  @apply mr-0;
}
.mat-accordion mat-expansion-panel.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header .mat-expansion-indicator::after,
.mat-accordion mat-expansion-panel.mat-expansion-panel:not(.mat-expansion-panel-spacing) .mat-expansion-panel-header .mat-expansion-indicator::after,
.mat-accordion mat-expansion-panel.mat-expansion-panel:is(.mat-expanded) .mat-expansion-panel-header .mat-expansion-indicator::after,
.mat-accordion mat-expansion-panel.mat-expansion-panel:is(.mat-expansion-panel-spacing) .mat-expansion-panel-header .mat-expansion-indicator::after {
  @apply text-gray-800 border-gray-700;
}
.mat-accordion .mat-expansion-panel-content {
  @apply border-t dark:bg-gray-600 dark:text-gray-200;
}
.mat-accordion .mat-expansion-panel-content .mat-action-row {
  @apply justify-start;
}

.mat-paginator {
  @apply dark:bg-gray-700 dark:text-gray-200;
}

.mat-paginator-navigation-previous.mat-button-disabled,
.mat-paginator-navigation-next.mat-button-disabled {
  @apply dark:text-gray-500;
}

.mat-paginator-container .mat-paginator-range-label {
  @apply hidden md:flex;
}

.mat-select-value {
  @apply dark:text-gray-200;
}

.mat-select-arrow {
  @apply dark:text-gray-200;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
  @apply dark:bg-gray-200;
}

.mat-expansion-panel-content .mat-expansion-panel-body {
  @apply p-4 md:p-6;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  @apply text-opacity-100 text-gray-400;
}
.mat-form-field-appearance-outline .mat-form-field-outline .mat-form-field-outline-start,
.mat-form-field-appearance-outline .mat-form-field-outline .mat-form-field-outline-gap,
.mat-form-field-appearance-outline .mat-form-field-outline .mat-form-field-outline-end {
  @apply border border-gray-400 text-gray-400;
}
.mat-form-field-label {
  @apply dark:text-gray-200;
}

.mat-select-panel {
  @apply dark:bg-gray-800;
}
.mat-select-panel .mat-option {
  @apply dark:text-gray-200;
}
.mat-select-panel .mat-option.mat-selected {
  @apply dark:text-blue-400 dark:font-bold;
}

.mat-form-field-subscript-wrapper {
  margin-top: 0;
}

.box-ratio {
  aspect-ratio: 1/1;
}

.ng-select.ng-select-single .ng-select-container {
  @apply rounded-none h-full px-4 py-4 border-gray-400;
}
.ng-select.ng-select-single .ng-select-container .ng-value-container {
  @apply pl-0;
}
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  @apply top-1/2 -translate-y-1/2 pl-4;
}

.p-dialog .p-dialog-content {
  @apply overflow-y-visible;
}

.p-button {
  color: #fff;
  background-color: #2196f3;
}