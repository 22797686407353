$fontSize:14px;
$fontFamily:"Roboto","Helvetica Neue",sans-serif;
$textColor:#212121;
$textSecondaryColor:#616161;
$borderRadius:2px;
$letterSpacing:.25px;
$transitionDuration:.2s;
$tabletBreakpoint:991px !default;
$layoutLogoLeftMargin: 1em;

//icons
$iconWidth:20px;
$iconHeight:20px;
$iconFontSize:20px;

//list item hover
$hoverBgColor:#e8e8e8;
$hoverTextColor:#000000;

$dividerColor:#dbdbdb;
$dividerLightColor:#f8f8f8;

$bodyBgColor:#f4f4f7;
// $bodySidePadding:100px;
$bodySidePadding:1rem;
$mobileBreakpoint:1024px;
$footerBgColor:#212121;
$footerTextColor:#9f9f9f;
$menuitemBorderRadius:6px;
$maskBgColor:#252529;

//horizontal menu
$horizontalOverlaySubmenuShadow:0 6px 20px 0 rgba(0, 0, 0, 0.19), 0 8px 17px 0 rgba(0, 0, 0, 0.2);
