.layout-menu-polygons {
    $menuBgImage:'polygons-menu.jpg';
    $menuitemTextColor:#ffffff;
    $menuitemIconColor:#ffffff;
    $menuitemHoverBgColor:rgba(0,0,0,0.32);
    $menuitemHoverTextColor:#ffffff;
    $menuitemHoverIconColor:#ffffff;
    $menuitemActiveTextColor:#e1ab41;
    $menuitemActiveIconColor:#e1ab41;
    $verticalActiveRootMenuitemBgColor:#c54424; 
    $verticalActiveRootMenuitemTextColor:#ffffff;   
    $verticalActiveRootMenuitemIconColor:#ffffff;
    $verticalSubmenuBgColor:rgba(255,255,255,0.2);

    @import '../_menu_theme';
}