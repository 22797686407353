html {
    height: 100%;
    font-size: $fontSize;
}

body {
    font-family: $fontFamily;
    font-size: $fontSize;
    color: $textColor;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding: 0;
    margin: 0;
    min-height: 100%;
    background-color: $bodyBgColor;

    .ajax-loader {
        font-size: 32px;
        color: $primaryColor;
    }

    &.blocked-scroll,
    &.blocked-scroll-config {
        overflow: hidden;
    }

    a {
        text-decoration: none;
        color: $primaryColor;
    }
}
