.widget-overview-box {
  .overview-numbers {
    font-size: 16px;
    color: #4c87c9;
    font-weight: 700;
    margin: 0.5em 0 1em 0;
  }

  &.monthly-sales {
    img {
      width: 100%;
      margin-top: 1em;
    }
  }

  &.total-views {
    img {
      width: 100%;
      margin-top: 4em;
    }
  }

  &.connections {
    .legend-arrow {
      color: #4caf50;
    }

    .dot {
      margin-right: 0.5em;
      vertical-align: middle;
    }

    .connection-chart {
      text-align: center;
      padding-top: 2em;
    }

    .connection-chart-legend {
      padding-left: 4%;
      padding-top: 1.5em;
      color: $textSecondaryColor;
    }
  }
}

.widget-summary-box {
  position: relative;

  .card {
    min-height: 100px;
  }

  .summary-box-title {
    font-size: 16px;
    margin: 0;
    color: #ffffff;
  }

  p {
    margin: 0.25em 0;
    color: #ffffff;
  }

  .summary-box-icon {
    position: absolute;
    right: 24px;
    top: 32px;
  }

  .summary-box-graph {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  &.summary-box-messages {
    .card {
      border-top: 4px solid #0097a7;
      background-color: #00bcd4;
    }
  }

  &.summary-box-checkins {
    .card {
      border-top: 4px solid #ad1457;
      background-color: #e91e63;
    }
  }

  &.summary-box-filessynced {
    .card {
      border-top: 4px solid #f47b08;
      background-color: #ffb300;
    }
  }

  &.summary-box-usersonline {
    .card {
      border-top: 4px solid #455a64;
      background-color: #607d8b;
    }
  }
}

.widget-team {
  .dot {
    height: 0.5em;
    width: 0.5em;
    @include border-radius(50%);
    display: inline-block;

    &.dot-green {
      background-color: #4caf50;
    }

    &.dot-red {
      background-color: #e91e63;
    }

    &.dot-orange {
      background-color: #ff9800;
    }
  }

  ul {
    list-style-type: none;
    margin: 0.25em 0 0 0;
    padding: 0;

    li {
      border-bottom: 1px solid #e4e4e4;

      &:last-child {
        border-bottom: 0 none;
      }
    }
  }

  img {
    width: 40px;
    height: 40px;
  }

  .member-content {
    padding: 1em;
  }

  .member-name {
    margin-bottom: 0.5em;
  }

  button:last-child {
    margin-left: 0.5em;
  }

  .member-status {
    color: $textSecondaryColor;

    span {
      vertical-align: middle;
    }
  }

  .dot {
    margin-right: 0.3em;
    vertical-align: middle;
  }
}

.widget-timeline {
  .icon-container {
    width: 48px;
    height: 48px;
    line-height: 48px;
    text-align: center;
    background-color: #eeeeee;
    @include border-radius(50%);

    i {
      color: #9e9e9e;
      font-size: 20px;
      line-height: inherit;
    }
  }

  h3 {
    margin: 0;
    font-size: 16px;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  li {
    padding: 1em 0;
    border-bottom: 1px solid #e4e4e4;

    &:last-child {
      border-bottom: 0 none;
    }
  }

  img {
    width: 100%;
  }

  .p-nogutter .p-col-fixed {
    padding: 0 1em 0.5em 0;
  }

  .event-owner {
    color: $textSecondaryColor;
    margin-bottom: 0.5em;
  }

  .event-detail {
    margin-bottom: 0.5em;
    color: rgba(0, 0, 0, 0.87);
  }

  .timeline-clock {
    display: inline-block;
    margin-top: 0.5em;
    background-color: #eeeeee;
    color: $textSecondaryColor;
    padding: 0.5em;
    font-size: 13px;
    @include border-radius($borderRadius);

    i {
      margin-right: 7px;
      vertical-align: middle;
    }

    span {
      vertical-align: middle;
    }
  }
}

.widget-location {
  padding: 0;
  margin-top: 1em;

  .location-content {
    padding: 1em;
  }

  .location-name {
    margin-bottom: 0.5em;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.2px;
  }

  p {
    margin: 0.75em 0;
    color: $textSecondaryColor;
  }

  img {
    width: 100%;
  }

  a {
    color: #00bcd4;

    &:hover {
      color: #0097a7;
    }
  }
}

.widget-userprofile {
  padding: 0;
  height: 100%;

  .userprofile-bg {
    width: 100%;
  }

  .userprofile-avatar {
    border: 4px solid #ffffff;
    @include border-radius(50%);
    margin: 0 auto;
    width: 96px;
    height: 96px;
    display: block;
    margin-top: -48px;
  }

  .userprofile-name {
    text-align: center;
    margin: 0.5em 0;
  }

  .userprofile-role {
    color: $textSecondaryColor;
    text-align: center;
    margin-bottom: 2em;
    font-size: 12px;
    letter-spacing: 0.4px;
  }

  .userprofile-circle {
    width: 16px;
    height: 16px;
    background-color: #ffffff;
    border-width: 4px;
    border-style: solid;
    margin: 0 auto;
    @include border-radius(50%);
    margin-bottom: 0.5em;
  }

  .userprofile-status {
    color: $textSecondaryColor;
    letter-spacing: 2px;
    font-size: 12px;
  }

  .userprofile-value {
    margin-top: 0.5em;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.2px;
  }

  .userprofile-legend {
    text-align: center;
  }

  .userprofile-completed {
    .userprofile-circle {
      border-color: #00bcd4;
    }
  }

  .userprofile-snoozed {
    .userprofile-circle {
      border-color: #ffc107;
    }
  }

  .userprofile-overdue {
    .userprofile-circle {
      border-color: #e91e63;
    }
  }
}

.widget-weather {
  padding: 0;

  .weather-image {
    background-image: url('../../layout/images/dashboard/primecity.jpg');
    background-size: cover;
    text-align: center;
    color: rgba(255, 255, 255, 0.87);
    min-height: 250px;
    padding: 1em;

    .city-name {
      font-size: 24px;
      margin-bottom: 40px;
    }

    .weather-today {
      font-size: 20px;
      margin-bottom: 8px;
    }

    .weather-degree {
      font-size: 40px;
    }
  }

  .weekdays {
    margin: 1em;
    margin-bottom: 0;

    > div {
      padding: 0.857em;
    }
  }

  .weather-degree-col {
    text-align: right;

    img {
      vertical-align: middle;
      margin-left: 0.5em;
    }
  }
}

.widget-pricing-box {
  width: 100%;
  max-height: 358px;
  text-align: left;
  background-color: #fafafa;
  border-left: 6px solid rgba(97, 97, 97, 0.07);
  border-radius: 4px;
  box-shadow: 0 5px 6px -3px rgba(0, 0, 0, 0.2), 0 3px 16px 2px rgba(0, 0, 0, 0.12), 0 9px 12px 1px rgba(0, 0, 0, 0.14);

  .pricing-header {
    padding: 24px 24px 18px 24px;
    text-align: center;

    h3 {
      font-size: 20px;
      font-weight: 500;
      letter-spacing: 0.3px;
      color: #212121;
      margin: 0;
    }

    .pricing-fee {
      font-size: 20px;
      letter-spacing: 0.3px;
      color: #616161;
      margin: 0;

      b {
        margin: 0;
        font-size: 34px;
        font-weight: 500;
        letter-spacing: 0.3px;
        color: #212121;
        text-align: center;
      }
    }
  }

  .pricing-content {
    padding: 0 20px 19px 20px;
    min-height: 228px;
    position: relative;

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        padding: 12px 0 0 0;
        font-size: 16px;
        letter-spacing: 0.3px;
        color: #212121;

        i {
          color: #43a047;
          font-size: 18px;
          vertical-align: middle;
          margin-right: 6px;
        }
      }
    }

    button {
      background-color: #457fca;
      position: absolute;
      min-width: 136px;
      bottom: 20px;
      left: 50%;
      margin-left: -68px;
      border-radius: 4px;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);

      &:enabled:hover {
        background-color: #1565c0;
      }
    }
  }
}
