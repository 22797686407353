.layout-menu-classic {
    $menuBgImage:'classic-menu.jpg';
    $menuitemTextColor:#ffffff;
    $menuitemIconColor:#ffffff;
    $menuitemHoverBgColor:rgba(255,255,255,0.32);
    $menuitemHoverTextColor:#ffffff;
    $menuitemHoverIconColor:#ffffff;
    $menuitemActiveTextColor:#FFA726;
    $menuitemActiveIconColor:#FFA726;
    $verticalActiveRootMenuitemBgColor:#8BC34A; 
    $verticalActiveRootMenuitemTextColor:#212121;   
    $verticalActiveRootMenuitemIconColor:#212121;
    $verticalSubmenuBgColor:rgba(255,255,255,0.2);

    @import '../_menu_theme';
}