.layout-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
}

.layout-content {
    padding: 0 $bodySidePadding;
    flex: 1 1 0;
}

.layout-mask {
    background-color: $maskBgColor;
    position: fixed;
    top: 68px;
    left: 0;
    z-index: 998;
    width: 100%;
    height: 100%;
}

@media screen and (max-width: $mobileBreakpoint) {
    .layout-content {
        padding: 0 1em;
        margin-top: -120px;
    }
}
